import {produce} from 'immer';
import {ActionTypes} from './actions';
import {REHYDRATE} from 'redux-persist';
import sort from '../util/sort';
import {isDiscarded, isInPool, isLocalId} from "../util/juror-helpers";

function getInitialState() {
  return {
    sessionData: null,
    checkingForCaseChanges: false,
    lastCaseChangeCheckTimestamp: 0,
    caseListLoading: false,
    caseListError: null,
    cases: {},
    casesLoading: {},
    casesErrors: {},
    casesLoadTimestamps: {},
    pendingCreateCases: {},
    pendingDeleteCases: {},
    pendingUpdateCases: {},
    jurors: {},
    seatIndexedJurors: {}, // caseId, seatNumber
    alternateSeatIndexedJurors: {}, // caseId, seatNumber
    jurorsErrors: {},
    jurorsLoadTimestamps: {},
    pendingCreateJurors: {},
    pendingDeleteJurors: {},
    pendingUpdateJurors: {},
    processingPendingStart: null,
    processingPendingError: null,
    oldSessionData: null,
    casesNeedingUpdateFromTwilio: {},
    editingJuror: null,
    editingSeatNumber: null,
    focusOnEmoji: null,
    focusOnOccurrence: null,
    previewingJuror: false,
    editingCase: null,
    trackedBounds: {},
    poolSort: {}
  }
}

export function cleanUpSpacers(spacers, juryBoxSize) {
  juryBoxSize = parseInt(juryBoxSize);
  let spacersSorted = spacers.sort((a, b) => parseInt(a) - parseInt(b));

  let usedSpacers = 0;
  for (let i = 0; i < juryBoxSize + spacersSorted.length; i++) {
    let spaceNumber = (i + 1);

    let spacerIndex = spacersSorted.indexOf(spaceNumber);

    if (spacerIndex >= 0) {
      usedSpacers++;
    }

    let seatNumber = spaceNumber - usedSpacers;
    if (seatNumber >= juryBoxSize) {
      usedSpacers--;
    }

    if (seatNumber >= juryBoxSize) {
      break;
    }
  }

  return spacersSorted.filter((oneSpacer) => oneSpacer <= (juryBoxSize + usedSpacers));
}

export default produce((draft, action) => {

  function markJurorAsUpdated(caseId, jurorId) {
    draft.jurors[jurorId].updated = (new Date()).toISOString();
    if (isLocalId(jurorId) && !draft.pendingCreateJurors[jurorId]) {
      //console.log(`Adding to pendingCreateJurors [markJurorAsUpdated] ${jurorId}`)
      draft.pendingCreateJurors[jurorId] = {
        caseId
      };
    }
    else {
      if (!draft.pendingCreateJurors[jurorId] && !draft.pendingUpdateJurors[jurorId]) {
        draft.pendingUpdateJurors[jurorId] = {
          caseId
        };
      }
    }
  }

  function setSeatNumber(caseId, jurorId, seatNumber, markUpdates = true) {
    if (!draft.seatIndexedJurors[caseId]) {
      draft.seatIndexedJurors[caseId] = {};
    }
    // Move existing seated juror to jury pool
    let seatedJurorId = draft.seatIndexedJurors[caseId][seatNumber];
    if (seatedJurorId && draft.jurors[seatedJurorId] && jurorId !== seatedJurorId && draft.jurors[seatedJurorId].seatNumber === seatNumber) {
      /*console.log("Marking seated juror seat number to null", {
        seatedJurorId,
        seatNumber,
        jurorId
      })*/
      draft.jurors[seatedJurorId].seatNumber = null;
      if (markUpdates) {
        markJurorAsUpdated(caseId, seatedJurorId);
      }
    }
    draft.seatIndexedJurors[caseId][seatNumber] = jurorId;
  }

  function setAlternateSeatNumber(caseId, jurorId, seatNumber, markUpdates = true) {
    if (!draft.alternateSeatIndexedJurors) {
      draft.alternateSeatIndexedJurors = {};
    }
    if (!draft.alternateSeatIndexedJurors[caseId]) {
      draft.alternateSeatIndexedJurors[caseId] = {};
    }

    let seatedAlternateId = draft.alternateSeatIndexedJurors[caseId][seatNumber];
    if (seatedAlternateId && draft.jurors[seatedAlternateId] && seatedAlternateId !== jurorId && draft.jurors[seatedAlternateId].alternateSeatNumber === seatNumber) {
      draft.jurors[seatedAlternateId].alternateSeatNumber = null;
      if (markUpdates) {
        markJurorAsUpdated(caseId, seatedAlternateId);
      }
    }

    draft.alternateSeatIndexedJurors[caseId][seatNumber] = jurorId;
  }

  function batchedJurorUpdate(caseId, jurorId, updatedJuror, pendingStart) {
    if (draft.jurors[jurorId] !== undefined && draft.jurors[jurorId].updated) {
      if (draft.jurors[jurorId].updated <= pendingStart) {
        draft.jurors[jurorId] = updatedJuror;
        if (updatedJuror.seatNumber && !isDiscarded(updatedJuror)) {
          setSeatNumber(caseId, jurorId, updatedJuror.seatNumber, false);
        }
        if (updatedJuror.alternateSeatNumber && !isDiscarded(updatedJuror)) {
          setAlternateSeatNumber(caseId, jurorId, updatedJuror.alternateSeatNumber, false);
        }
        if (draft.previewingJuror) {
          if (draft.editingJuror && draft.editingJuror._id === jurorId) {
            draft.editingJuror = { ...updatedJuror };
          }
        }
      }
      else {
        console.warn("Skipping juror update. Juror updated locally and still needs to be synced: ", {
          jurorLocalUpdate: draft.jurors[jurorId].updated,
          pendingStart
        });
        // Leave it alone, it'll get updated later
        // TODO some kind of reconciliation?
      }
    }
    else {
      draft.jurors[jurorId] = updatedJuror;
      if (updatedJuror.seatNumber && !isDiscarded(updatedJuror)) {
        setSeatNumber(caseId, jurorId, updatedJuror.seatNumber, false);
      }
      if (updatedJuror.alternateSeatNumber && !isDiscarded(updatedJuror)) {
        setAlternateSeatNumber(caseId, jurorId, updatedJuror.alternateSeatNumber, false);
      }
    }
  }

  function removeUnseatedJurors(caseId) {
    if (draft.seatIndexedJurors[caseId]) {
      for (let seatNumber of Object.keys(draft.seatIndexedJurors[caseId])) {
        if (draft.seatIndexedJurors[caseId][seatNumber]) {
          let jurorId = draft.seatIndexedJurors[caseId][seatNumber];
          if (!draft.jurors[jurorId] || !draft.jurors[jurorId].seatNumber || isDiscarded(draft.jurors[jurorId]) || draft.jurors[jurorId].seatNumber != seatNumber) {
            setSeatNumber(caseId, undefined, seatNumber);
          }
        }
      }
    }
  }

  function removeUnseatedAlternateJurors(caseId) {
    if (draft.alternateSeatIndexedJurors && draft.alternateSeatIndexedJurors[caseId]) {
      for (let seatNumber of Object.keys(draft.alternateSeatIndexedJurors[caseId])) {
        if (draft.alternateSeatIndexedJurors[caseId][seatNumber]) {
          let jurorId = draft.alternateSeatIndexedJurors[caseId][seatNumber];
          if (!draft.jurors[jurorId] || !draft.jurors[jurorId].alternateSeatNumber || isDiscarded(draft.jurors[jurorId]) || draft.jurors[jurorId].alternateSeatNumber != seatNumber) {
            setAlternateSeatNumber(caseId, undefined, seatNumber);
          }
        }
      }
    }
  }

  function removePendingJurorUpdate(jurorId, pendingStart) {
    if (draft.jurors[jurorId] !== undefined && draft.jurors[jurorId].updated) {
      if (draft.jurors[jurorId].updated <= pendingStart) {
        delete draft.pendingUpdateJurors[jurorId];
      }
      else {
        // Leave it alone, it'll get updated later
        console.warn("Skipping juror removal. Juror updated locally and still needs to be synced: ", {
          jurorLocalUpdate: draft.jurors[jurorId].updated,
          pendingStart
        });
        // Leave it alone, it'll get updated later
        // TODO some kind of reconciliation?
      }
    }
    else {
      delete draft.pendingUpdateJurors[jurorId];
    }
  }

  const juryPoolSort = (sortSettings) => (a,b) => {
    if (Object.keys(sortSettings).length === 0) {
      if (a.created && b.created) {
        return b.created.localeCompare(a.created) * -1; // ASC
      }
      else if (a.created) {
        return 1;
      }
      else if (b.created) {
        return -1;
      }
      return a._id.localeCompare(b._id);
    }
    else {
      let multiplier = 1;
      if (sortSettings[0] === "desc") {
        multiplier = -1;
      }

      let r = sort(a.nameAndId, b.nameAndId);
      return r * multiplier;
    }
  };

  function addJuror(caseId, id, jurorInfo) {
    delete draft.jurorsErrors[id];
    draft.jurors[id] = {
      _id: id,
      ...jurorInfo,
      updated: (new Date()).toISOString()
    };

    if (jurorInfo.seatNumber && !isDiscarded(jurorInfo)) {
      setSeatNumber(caseId, id, jurorInfo.seatNumber);
    }
    if (jurorInfo.alternateSeatNumber && !isDiscarded(jurorInfo)) {
      setAlternateSeatNumber(caseId, id, jurorInfo.alternateSeatNumber)
    }
    //console.log(`adding to pendingCreateJurors [addJuror] ${id}...`)
    draft.pendingCreateJurors[id] = {
      caseId
    };
    if (draft.cases[caseId]) {
      draft.cases[caseId].jurors.push(id);
      draft.cases[caseId].updatedAt = (new Date()).toISOString();
    }
  }

  switch(action.type) {
    case ActionTypes.sessionCreated:
      if (draft.oldSessionData && draft.oldSessionData.email === action.payload.data.email) {
        // We don't need to reset the data
        draft.caseListError = false;
        draft.caseListLoading = false;
        draft.sessionData = action.payload.data;
      }
      else {
        return {
          ...getInitialState(),
          sessionData: action.payload.data
        };
      }
      break;
    case ActionTypes.updateSessionDataPoint:
      draft.sessionData[action.payload.name] = action.payload.value;
      break;
    case ActionTypes.sessionEnded:
      // Reset the state completely
      let oldSessionData = null;
      if (draft.sessionData && draft.sessionData.email) {
        oldSessionData = {
          email: draft.sessionData.email
        };
      }
      if (action.payload.clearAllData) {
        console.log("Resetting state due to user logging out...");
        return {
          ...getInitialState(),
          oldSessionData
        };
      }
      else {
        draft.sessionData = null;
        draft.oldSessionData = oldSessionData;
      }
      break;
    case ActionTypes.checkingForCaseChanges:
      draft.checkingForCaseChanges = (new Date()).toISOString();
      break;
    case ActionTypes.checkingForCaseChangesSuccess:
      draft.checkingForCaseChanges = false;
      draft.lastCaseChangeCheckTimestamp = action.payload.timestamp;
      break;
    case ActionTypes.checkingForCaseChangesFailure:
      draft.checkingForCaseChanges = false;
      draft.caseListError = {
        type: 'changes-check',
        canDismiss: true,
        error: action.payload.error
      };
      break;
    case ActionTypes.clearCaseList:
      draft.cases = {};
      draft.casesLoading = {};
      draft.casesErrors = {};
      draft.pendingCreateCases = {};
      draft.pendingDeleteCases = {};
      draft.pendingUpdateCases = {};
      draft.jurors = {};
      draft.jurorsErrors = {};
      draft.seatIndexedJurors = {};
      draft.alternateSeatIndexedJurors = {};
      draft.casesLoadTimestamps = {};
      draft.jurorsLoadTimestamps = {};
      draft.pendingCreateJurors = {};
      draft.pendingDeleteJurors = {};
      draft.pendingUpdateJurors = {};
      draft.processingPendingStart = null;
      draft.processingPendingError = null;
      draft.casesNeedingUpdateFromTwilio = {};
      draft.editingJuror = null;
      draft.editingSeatNumber = null;
      draft.editingCase = null;

      break;
    case ActionTypes.listCases:
      draft.caseListLoading = true;
      draft.caseListError = null;
      break;
    case ActionTypes.listCasesSuccess:
      draft.caseListLoading = false;
      for (let oneCase of action.payload.caseList) {
        if (!draft.cases[oneCase._id]) {
          draft.cases[oneCase._id] = {
            _id: oneCase._id,
            jurors: []
          };
        }
        draft.cases[oneCase._id].name = oneCase.name;
        draft.cases[oneCase._id].number = oneCase.number;
        draft.cases[oneCase._id].details = oneCase.details;
        draft.cases[oneCase._id].createdAt = oneCase.createdAt;
        draft.cases[oneCase._id].updatedAt = oneCase.updatedAt;
      }
      break;
    case ActionTypes.listCasesFailure:
      draft.caseListLoading = false;
      if (action.payload.error) {
        draft.caseListError = {
          type: 'list',
          canDismiss: true,
          error: action.payload.error
        };
      }
      break;
    case ActionTypes.removeCaseListError:
      draft.caseListError = null;
      break;
    case ActionTypes.getCaseInfo:
      delete draft.casesErrors[action.payload.caseId];
      draft.casesLoading[action.payload.caseId] = true;
      break;
    case ActionTypes.getCaseInfoSuccess:
      if (draft.cases[action.payload.caseId] && draft.cases[action.payload.caseId].updatedAt && action.payload.pendingStart < draft.cases[action.payload.caseId].updatedAt) {
        // don't update anything
        console.log("Skipping case update because case has pending update...", {
          caseId: action.payload.caseId,
          pendingStart: action.payload.pendingStart,
          caseUpdatedAt: draft.cases[action.payload.caseId].updatedAt
        });
      }
      else {
        delete draft.casesLoading[action.payload.caseId];
        // Normalize the jurors and cases
        let jurorIds = [];
        for (let juror of action.payload.caseInfo.jurors) {
          batchedJurorUpdate(action.payload.caseId, juror._id, juror, action.payload.pendingStart);
          jurorIds.push(juror._id);
        }

        removeUnseatedJurors(action.payload.caseId);
        removeUnseatedAlternateJurors(action.payload.caseId);

        let derivedCaseInfo = { ...action.payload.caseInfo, jurors: jurorIds };
        draft.cases[action.payload.caseId] = derivedCaseInfo;
        draft.casesLoadTimestamps[action.payload.caseId] = Date.now();
        if (action.payload.fromTwilio) {
          delete draft.casesNeedingUpdateFromTwilio[action.payload.caseId];
        }
      }
      break;
    case ActionTypes.getCaseInfoFailure:
      delete draft.casesLoading[action.payload.caseId];
      if (action.payload.error) {
        draft.casesErrors[action.payload.caseId] = {
          type: 'get',
          canDismiss: true,
          error: action.payload.error
        };
      }
      break;
    case ActionTypes.removeCaseError:
      delete draft.casesErrors[action.payload.caseId];
      break;
    case ActionTypes.createCase:
      delete draft.casesErrors[action.payload.tmpId];
      draft.pendingCreateCases[action.payload.tmpId] = action.payload;
      let existingJurors = [];
      if (draft.cases[action.payload.tmpId] && draft.cases[action.payload.tmpId].jurors.length > 0) {
        existingJurors = [...draft.cases[action.payload.tmpId].jurors];
      }
      draft.cases[action.payload.tmpId] = {
        _id: action.payload.tmpId,
        ...action.payload.createCaseBody,
        jurors: existingJurors,
        updatedAt: (new Date()).toISOString()
      };
      break;
    case ActionTypes.createCaseSuccess:
      delete draft.pendingCreateCases[action.payload.tmpId];
      let oldCaseInfo = draft.cases[action.payload.tmpId];

      let createdJurorIds = [...oldCaseInfo.jurors];

      for (let pendingJurorId of createdJurorIds) {
        if (draft.jurors[pendingJurorId]) {
          if (draft.pendingCreateJurors[pendingJurorId]) {
            draft.pendingCreateJurors[pendingJurorId].caseId = action.payload.serverId;
          }
          if (draft.pendingUpdateJurors[pendingJurorId]) {
            draft.pendingUpdateJurors[pendingJurorId].caseId = action.payload.serverId;
          }
        }
      }

      if (action.payload.caseInfo.jurors && action.payload.caseInfo.jurors.length > 0) {
        for (let oneJuror of action.payload.caseInfo.jurors) {
          batchedJurorUpdate(action.payload.serverId, oneJuror._id, oneJuror, action.payload.pendingStart);
          createdJurorIds.push(oneJuror._id);
        }
      }

      let normalizedCaseInfo = {...action.payload.caseInfo, jurors: createdJurorIds};
      draft.cases[action.payload.serverId] = normalizedCaseInfo;
      delete draft.cases[action.payload.tmpId];
      break;
    case ActionTypes.createCaseFailure:
      draft.casesErrors[action.payload.tmpId] = {
        type: "create",
        canDismiss: false,
        error: action.payload.error
      };
      delete draft.pendingCreateCases[action.payload.tmpId];
      break;
    case ActionTypes.updateCase:
      delete draft.casesErrors[action.payload.caseId];
      draft.pendingUpdateCases[action.payload.caseId] = action.payload;

      if (action.payload.editCaseBody.juryBoxSize < draft.cases[action.payload.caseId].juryBoxSize) {
        // Make sure spacers don't exceed new juryBoxSize
        if (draft.cases[action.payload.caseId].spacers) {
          draft.cases[action.payload.caseId].spacers = cleanUpSpacers([...draft.cases[action.payload.caseId].spacers], action.payload.editCaseBody.juryBoxSize);
        }
      }

      draft.cases[action.payload.caseId] = {
        ...draft.cases[action.payload.caseId],
        ...action.payload.editCaseBody,
        updatedAt: (new Date()).toISOString()
      };

      break;
    case ActionTypes.addSpacer:
      if (draft.cases[action.payload.caseId]) {
        if (!draft.cases[action.payload.caseId].spacers) {
          draft.cases[action.payload.caseId].spacers = [];
        }
        if (draft.cases[action.payload.caseId].spacers.indexOf(action.payload.spaceNumber) === -1) {
          draft.pendingUpdateCases[action.payload.caseId] = action.payload;
          draft.cases[action.payload.caseId].spacers.push(action.payload.spaceNumber);
          draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
        }
      }
      break;
    case ActionTypes.removeSpacer:
      if (draft.cases[action.payload.caseId]) {
        if (!draft.cases[action.payload.caseId].spacers) {
          draft.cases[action.payload.caseId].spacers = [];
        }
        let spacerIndex = draft.cases[action.payload.caseId].spacers.indexOf(action.payload.spaceNumber);
        if (spacerIndex >= 0) {
          draft.pendingUpdateCases[action.payload.caseId] = action.payload;
          draft.cases[action.payload.caseId].spacers.splice(spacerIndex, 1);

          draft.cases[action.payload.caseId].spacers = cleanUpSpacers([...draft.cases[action.payload.caseId].spacers], draft.cases[action.payload.caseId].juryBoxSize);

          draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
        }
      }
      break;
    case ActionTypes.updateCaseSuccess:
      let needToRemovePending = true;
      if (action.payload.updateStarted) {
        if (draft.cases[action.payload.caseId] && draft.cases[action.payload.caseId].updatedAt && draft.cases[action.payload.caseId].updatedAt > action.payload.updateStarted) {
          needToRemovePending = false;
          //console.log("Don't remove the pending update, because the update started before the last update was made to the case.");
        }
      }

      if (needToRemovePending) {
        //console.log("Pending update removed.");
        delete draft.pendingUpdateCases[action.payload.caseId];
      }
      // Normalize the jurors and cases
      let jurorIds = [];
      for (let juror of action.payload.caseInfo.jurors) {
        batchedJurorUpdate(action.payload.caseId, juror._id, juror, action.payload.updateStarted);
        jurorIds.push(juror._id);
      }

      removeUnseatedJurors(action.payload.caseId);
      removeUnseatedAlternateJurors(action.payload.caseId);

      let derivedCaseInfo = { ...action.payload.caseInfo, jurors: jurorIds };
      draft.cases[action.payload.caseId] = derivedCaseInfo;
      draft.casesLoadTimestamps[action.payload.caseId] = Date.now();
      if (action.payload.fromTwilio) {
        delete draft.casesNeedingUpdateFromTwilio[action.payload.caseId];
      }

      break;
    case ActionTypes.updateCaseFailure:
      draft.casesErrors[action.payload.caseId] = {
        type: 'update',
        canDismiss: false,
        error: action.payload.error
      };
      delete draft.pendingUpdateCases[action.payload.caseId];
      break;
    case ActionTypes.deleteCase:
      draft.pendingDeleteCases[action.payload.caseId] = draft.cases[action.payload.caseId];
      delete draft.cases[action.payload.caseId];
      delete draft.casesErrors[action.payload.caseId];
      delete draft.pendingCreateCases[action.payload.caseId];
      delete draft.pendingUpdateCases[action.payload.caseId];
      break;
    case ActionTypes.deleteCasesSuccess:
      // Remove all the jurors associated with it too
      for (let caseId of action.payload.caseIds) {
        let jurorsToDelete = [];
        if (draft.pendingDeleteCases[caseId] && draft.pendingDeleteCases[caseId].jurors) {
          jurorsToDelete = draft.pendingDeleteCases[caseId].jurors;
        }

        if (draft.cases[caseId]) {
          for (let oneCaseJurorId of draft.cases[caseId].jurors) {
            jurorsToDelete.push(oneCaseJurorId);
          }
        }

        delete draft.seatIndexedJurors[caseId];
        delete draft.alternateSeatIndexedJurors[caseId];

        for (let jurorToDelete of jurorsToDelete) {
          delete draft.jurors[jurorToDelete];
          delete draft.pendingCreateJurors[jurorToDelete];
          delete draft.pendingUpdateJurors[jurorToDelete];
        }
        delete draft.pendingDeleteCases[caseId];
        delete draft.cases[caseId];
      }
      break;
    case ActionTypes.deleteCaseFailure:
      // Put the case back and attach an error to it
      draft.cases[action.payload.caseId] = draft.pendingDeleteCases[action.payload.caseId];
      draft.casesErrors[action.payload.caseId] = {
        type: 'delete',
        canDismiss: true,
        error: action.payload.error
      };
      delete draft.pendingDeleteCases[action.payload.caseId];
      break;
    case ActionTypes.createJuror:
      addJuror(action.payload.caseId, action.payload.tmpId, action.payload.jurorBody);
      break;
    case ActionTypes.createJurors:
      for (let jurorPayload of action.payload.jurorPayloads) {
        addJuror(action.payload.caseId, jurorPayload.tmpId, jurorPayload.jurorBody);
      }
      break;
    case ActionTypes.createJurorSuccess:
      delete draft.pendingCreateJurors[action.payload.tmpId];
      if (draft.cases[action.payload.caseId]) {
        // Delete the tmp juror id from the case
        let jurorIndex = draft.cases[action.payload.caseId].jurors.indexOf(action.payload.tmpId);
        if (jurorIndex >= 0) {
          draft.cases[action.payload.caseId].jurors.splice(jurorIndex, 1);
        }
        // Add the new server based id
        draft.cases[action.payload.caseId].jurors.push(action.payload.serverId);
      }
      // Add the juror
      draft.jurors[action.payload.serverId] = action.payload.jurorBody;
      if (action.payload.jurorBody.seatNumber && !isDiscarded(action.payload.jurorBody)) {
        setSeatNumber(action.payload.caseId, action.payload.serverId, action.payload.jurorBody.seatNumber, false);
      }
      if (action.payload.jurorBody.alternateSeatNumber && !isDiscarded(action.payload.jurorBody)) {
        setAlternateSeatNumber(action.payload.caseId, action.payload.serverId, action.payload.jurorBody.alternateSeatNumber, false);
      }
      // Remove the tmp entry
      delete draft.jurors[action.payload.tmpId];
      break;
    case ActionTypes.createJurorFailure:
      draft.jurorsErrors[action.payload.tmpId] = {
        type: 'create',
        canDismiss: false,
        error: action.payload.error
      };
      delete draft.pendingCreateJurors[action.payload.tmpId];
      delete draft.pendingUpdateJurors[action.payload.tmpId];
      delete draft.pendingDeleteJurors[action.payload.tmpId];
      break;
    case ActionTypes.removePendingJurorCreate:
      delete draft.pendingCreateJurors[action.payload.jurorId];
      delete draft.pendingUpdateJurors[action.payload.jurorId];
      break;
    case ActionTypes.updateJuror:
      delete draft.jurorsErrors[action.payload.juror._id];

      if (draft.jurors[action.payload.juror._id]) {
        // Get the old seat number
        let oldSeatNumber = null;
        let oldAlternateSeatNumber = null;
        if (draft.jurors[action.payload.juror._id].seatNumber && !isDiscarded(draft.jurors[action.payload.juror._id])) {
          oldSeatNumber = draft.jurors[action.payload.juror._id].seatNumber;
        }
        if (draft.jurors[action.payload.juror._id].alternateSeatNumber && !isDiscarded(draft.jurors[action.payload.juror._id])) {
          oldAlternateSeatNumber = draft.jurors[action.payload.juror._id].alternateSeatNumber;
        }
        draft.jurors[action.payload.juror._id] = {...action.payload.juror, updated: (new Date()).toISOString()};
        if (draft.cases[action.payload.caseId]) {
          draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
        }
        if (draft.pendingCreateJurors[action.payload.juror._id] === undefined) {
          draft.pendingUpdateJurors[action.payload.juror._id] = action.payload;
        }

        console.log("Updating juror: ", {
          oldSeatNumber,
          oldAlternateSeatNumber,
          updated: action.payload.juror
        });

        if (isDiscarded(draft.jurors[action.payload.juror._id]) && draft.jurors[action.payload.juror._id].seatNumber) {
          setSeatNumber(action.payload.caseId, undefined, draft.jurors[action.payload.juror._id].seatNumber);
        }

        if (isDiscarded(draft.jurors[action.payload.juror._id]) && draft.jurors[action.payload.juror._id].alternateSeatNumber) {
          setAlternateSeatNumber(action.payload.caseId, undefined, draft.jurors[action.payload.juror._id].alternateSeatNumber);
        }

        // Clear any juror(s) that have the destination seat number
        if (action.payload.juror.seatNumber) {
          let jurorIds = draft.cases[action.payload.caseId].jurors;
          for(let jurorId of jurorIds) {
            if (jurorId !== action.payload.juror._id) {
              if (draft.jurors[jurorId]?.seatNumber === action.payload.juror.seatNumber) {
                draft.jurors[jurorId].seatNumber = null;
                if (draft.pendingUpdateJurors[jurorId] === undefined) {
                  draft.pendingUpdateJurors[jurorId] = {jurorId, caseId: action.payload.caseId, seatNumber: null};
                }
              }
            }
          }
        }

        if (action.payload.juror.alternateSeatNumber) {
          let jurorIds = draft.cases[action.payload.caseId].jurors;
          for(let jurorId of jurorIds) {
            if (jurorId !== action.payload.juror._id) {
              if (draft.jurors[jurorId]?.alternateSeatNumber === action.payload.juror.alternateSeatNumber) {
                draft.jurors[jurorId].alternateSeatNumber = null;
                if (draft.pendingUpdateJurors[jurorId] === undefined) {
                  draft.pendingUpdateJurors[jurorId] = {jurorId, caseId: action.payload.caseId, alternateSeatNumber: null};
                }
              }
            }
          }
        }

        if (action.payload.juror.seatNumber !== oldSeatNumber) {
          if (oldSeatNumber) {
            setSeatNumber(action.payload.caseId, undefined, oldSeatNumber);
          }
          if (action.payload.juror.seatNumber && !isDiscarded(action.payload.juror)) {
            setSeatNumber(action.payload.caseId, action.payload.juror._id, action.payload.juror.seatNumber);
          }
        }
        if (action.payload.juror.alternateSeatNumber !== oldAlternateSeatNumber) {
          if (oldAlternateSeatNumber) {
            setAlternateSeatNumber(action.payload.caseId, undefined, oldAlternateSeatNumber);
          }
          if (action.payload.juror.alternateSeatNumber && !isDiscarded(action.payload.juror)) {
            setAlternateSeatNumber(action.payload.caseId, action.payload.juror._id, action.payload.juror.alternateSeatNumber);
          }
        }
      }
      break;
    case ActionTypes.updateJurorSuccess:
      // This line was making the "reset" happen when you move jurors quickly while online
      // draft.jurors[action.payload.jurorId] = action.payload.juror;
      delete draft.pendingUpdateJurors[action.payload.jurorId];
      break;
    case ActionTypes.updateJurorFailure:
      draft.jurorsErrors[action.payload.jurorId] = {
        type: 'update',
        canDismiss: false,
        error: action.payload.error
      };
      delete draft.pendingUpdateJurors[action.payload.jurorId];
      break;
    case ActionTypes.multiJurorDemographics:
      for (let oneJurorId of action.payload.jurorIds) {
        if (draft.jurors[oneJurorId]) {
          if (!draft.jurors[oneJurorId].demographics) {
            draft.jurors[oneJurorId].demographics = [];
          }

          for (let oneDemographic of action.payload.demographics) {
            let foundIndex = draft.jurors[oneJurorId].demographics.findIndex((item) => item.name === oneDemographic.name);
            if (foundIndex >= 0) {
              draft.jurors[oneJurorId].demographics[foundIndex].value = oneDemographic.value;
            }
            else {
              draft.jurors[oneJurorId].demographics.push(oneDemographic);
            }
          }
        }
      }
      break;
    case ActionTypes.multiJurorComment:
      for (let oneJurorId of action.payload.jurorIds) {
        if (draft.jurors[oneJurorId]) {
          let newComments = (draft.jurors[oneJurorId].comments || "");
          let currentCommentLength = newComments.length;
          if (newComments.length === 0) {
            newComments = action.payload.commentText;
          }
          else {
            newComments += "\n" + action.payload.commentText;
          }
          draft.jurors[oneJurorId].comments = newComments;
          if (!draft.jurors[oneJurorId].commentsStyles) {
            draft.jurors[oneJurorId].commentsStyles = [];
          }
          for (let oneNewStyle of action.payload.commentsStyles) {
            draft.jurors[oneJurorId].commentsStyles.push({
              ...oneNewStyle,
              offset: currentCommentLength + oneNewStyle.offset + 1, // Adding one more for newline
            });
          }

          draft.jurors[oneJurorId].updated = (new Date()).toISOString();
          if (draft.pendingCreateJurors[oneJurorId] === undefined) {
            draft.pendingUpdateJurors[oneJurorId] = {
              caseId: action.payload.caseId
            };
          }
        }
      }

      if (draft.cases[action.payload.caseId]) {
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
      }
      break;
    case ActionTypes.fillEmptyAlternateSpaces:
      if (!draft.alternateSeatIndexedJurors[action.payload.caseId]) {
        draft.alternateSeatIndexedJurors[action.payload.caseId] = {};
      }

      if (draft.cases[action.payload.caseId]) {
        let newAlternateSeatIndex = {...draft.alternateSeatIndexedJurors[action.payload.caseId]};
        let maxAlternateSeat = draft.cases[action.payload.caseId].numberOfAlternates;
        for (let i = 1; i <= maxAlternateSeat; i++) {
          if (!newAlternateSeatIndex[i]) {
            // Look for the next one and put it in this index, then delete the old entry
            for (let j = i; j <= maxAlternateSeat; j++) {
              if (newAlternateSeatIndex[j]) {
                newAlternateSeatIndex[i] = newAlternateSeatIndex[j];
                newAlternateSeatIndex[j] = undefined;
                break;
              }
            }
            if (newAlternateSeatIndex[i]) {
              // Update the juror as well
              draft.jurors[newAlternateSeatIndex[i]].alternateSeatNumber = i;
              draft.jurors[newAlternateSeatIndex[i]].updated = (new Date).toISOString();

              if (draft.pendingCreateJurors[newAlternateSeatIndex[i]] === undefined) {
                draft.pendingUpdateJurors[newAlternateSeatIndex[i]] = {
                  caseId: action.payload.caseId
                };
              }
            }
          }
        }
        draft.alternateSeatIndexedJurors[action.payload.caseId] = newAlternateSeatIndex;
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
      }
      break;
    case ActionTypes.fillEmptySpaces:
      if (draft.cases[action.payload.caseId]) {
        if (!draft.seatIndexedJurors[action.payload.caseId]) {
          draft.seatIndexedJurors[action.payload.caseId] = {};
        }

        let newSeatIndex = {...draft.seatIndexedJurors[action.payload.caseId]};
        let maxSeat = draft.cases[action.payload.caseId].juryBoxSize;
        for (let i = 1; i <= maxSeat; i++) {
          if (!newSeatIndex[i]) {
            // Look for the next one and put it in this index, then delete the old entry
            for (let j = i; j <= maxSeat; j++) {
              if (newSeatIndex[j]) {
                newSeatIndex[i] = newSeatIndex[j];
                newSeatIndex[j] = undefined;
                break;
              }
            }
            if (newSeatIndex[i]) {
              // Update the juror as well
              draft.jurors[newSeatIndex[i]].seatNumber = i;
              draft.jurors[newSeatIndex[i]].updated = (new Date).toISOString();

              if (draft.pendingCreateJurors[newSeatIndex[i]] === undefined) {
                draft.pendingUpdateJurors[newSeatIndex[i]] = {
                  caseId: action.payload.caseId
                };
              }
            }
          }
        }
        draft.seatIndexedJurors[action.payload.caseId] = newSeatIndex;
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
      }
      break;
    case ActionTypes.emptyAlternates:
      if (draft.cases[action.payload.caseId]) {
        let emptyAlternateSeatIndex = {};

        if (!draft.alternateSeatIndexedJurors[action.payload.caseId]) {
          draft.alternateSeatIndexedJurors[action.payload.caseId] = {};
        }

        for (let oneJurorId of draft.cases[action.payload.caseId].jurors) {
          let oneJuror = draft.jurors[oneJurorId];
          if (!oneJuror.alternateDiscarded) {
            if (oneJuror.alternateSeatNumber) {
              oneJuror.alternateSeatNumber = null;
              oneJuror.updated = (new Date).toISOString();
              if (draft.pendingCreateJurors[oneJurorId] === undefined) {
                draft.pendingUpdateJurors[oneJurorId] = {
                  caseId: action.payload.caseId
                };
              }
            }
          }
        }

        draft.alternateSeatIndexedJurors[action.payload.caseId] = emptyAlternateSeatIndex;
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
      }
      break;
    case ActionTypes.emptyJuryBox:
      if (draft.cases[action.payload.caseId]) {
        let emptySeatIndex = {};

        if (!draft.seatIndexedJurors[action.payload.caseId]) {
          draft.seatIndexedJurors[action.payload.caseId] = {};
        }

        for (let oneJurorId of draft.cases[action.payload.caseId].jurors) {
          let oneJuror = draft.jurors[oneJurorId];
          if (!oneJuror.discarded) {
            if (oneJuror.seatNumber) {
              oneJuror.seatNumber = null;
              oneJuror.updated = (new Date).toISOString();
              if (draft.pendingCreateJurors[oneJurorId] === undefined) {
                draft.pendingUpdateJurors[oneJurorId] = {
                  caseId: action.payload.caseId
                };
              }
            }
          }
        }

        draft.seatIndexedJurors[action.payload.caseId] = emptySeatIndex;
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
      }
      break;
    case ActionTypes.fillAlternatesFromPool:
      if (draft.cases[action.payload.caseId]) {
        // Get all the jurors in jury pool for the case
        let inPoolJurorsForAlternates = [];
        for (let oneTmpJurorId of draft.cases[action.payload.caseId].jurors) {
          if (isInPool(draft.jurors[oneTmpJurorId])) {
            inPoolJurorsForAlternates.push(draft.jurors[oneTmpJurorId]);
          }
        }
        // Sort them correctly
        let sortSettingsForAlternates = draft.poolSort[action.payload.caseId] || {};
        inPoolJurorsForAlternates = inPoolJurorsForAlternates.sort(juryPoolSort(sortSettingsForAlternates));
        // Make sure there is a seat index for this case already
        if (!draft.alternateSeatIndexedJurors[action.payload.caseId]) {
          draft.alternateSeatIndexedJurors[action.payload.caseId] = {};
        }
        // Fill every empty seat from the pool, if possible
        let seatsFilledForAlternates = 0;
        let maxSeatToFillForAlternates = draft.cases[action.payload.caseId].numberOfAlternates;
        for (let i = 1; i <= maxSeatToFillForAlternates; i++) {
          if (!draft.alternateSeatIndexedJurors[action.payload.caseId][i]) {
            if (inPoolJurorsForAlternates[seatsFilledForAlternates]) {
              let newlySeatedJuror = inPoolJurorsForAlternates[seatsFilledForAlternates];
              seatsFilledForAlternates++;
              let newlySeatedJurorId = newlySeatedJuror._id;
              setAlternateSeatNumber(action.payload.caseId, newlySeatedJurorId, i);
              draft.jurors[newlySeatedJurorId].alternateSeatNumber = i;
              draft.jurors[newlySeatedJurorId].updated = (new Date).toISOString();
              if (draft.pendingCreateJurors[newlySeatedJurorId] === undefined) {
                draft.pendingUpdateJurors[newlySeatedJurorId] = {
                  caseId: action.payload.caseId
                };
              }
            } else {
              // No more jurors to consider
              break;
            }
          }
        }
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
      }
      break;
    case ActionTypes.fillFromPool:
      if (draft.cases[action.payload.caseId]) {
        // Get all the jurors in jury pool for the case
        let inPoolJurors = [];
        for (let oneTmpJurorId of draft.cases[action.payload.caseId].jurors) {
          if (isInPool(draft.jurors[oneTmpJurorId])) {
            inPoolJurors.push(draft.jurors[oneTmpJurorId]);
          }
        }
        // Sort them correctly
        let sortSettings = draft.poolSort[action.payload.caseId] || {};
        inPoolJurors = inPoolJurors.sort(juryPoolSort(sortSettings));
        // Make sure there is a seat index for this case already
        if (!draft.seatIndexedJurors[action.payload.caseId]) {
          draft.seatIndexedJurors[action.payload.caseId] = {};
        }
        // Fill every empty seat from the pool, if possible
        let seatsFilled = 0;
        let maxSeatToFill = draft.cases[action.payload.caseId].juryBoxSize;
        for (let i = 1; i <= maxSeatToFill; i++) {
          if (!draft.seatIndexedJurors[action.payload.caseId][i]) {
            if (inPoolJurors[seatsFilled]) {
              let newlySeatedJuror = inPoolJurors[seatsFilled];
              seatsFilled++;
              let newlySeatedJurorId = newlySeatedJuror._id;
              setSeatNumber(action.payload.caseId, newlySeatedJurorId, i);
              draft.jurors[newlySeatedJurorId].seatNumber = i;
              draft.jurors[newlySeatedJurorId].updated = (new Date).toISOString();
              if (draft.pendingCreateJurors[newlySeatedJurorId] === undefined) {
                draft.pendingUpdateJurors[newlySeatedJurorId] = {
                  caseId: action.payload.caseId
                };
              }
            } else {
              // No more jurors to consider
              break;
            }
          }
        }
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
      }
      break;
    case ActionTypes.removeJurorError:
      delete draft.jurorsErrors[action.payload.jurorId];
      break;
    case ActionTypes.deleteJuror:
      draft.pendingDeleteJurors[action.payload.jurorId] = {
        payload: action.payload,
        deleted: draft.jurors[action.payload.jurorId]
      };

      if (draft.cases[action.payload.caseId]) {
        // Remove the juror from the case's juror id list
        let jurorIndexToDelete = draft.cases[action.payload.caseId].jurors.indexOf(action.payload.jurorId);
        if (jurorIndexToDelete >= 0) {
          draft.cases[action.payload.caseId].jurors.splice(jurorIndexToDelete, 1);
        }
        draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
        if (draft.jurors[action.payload.jurorId].seatNumber && !isDiscarded(draft.jurors[action.payload.jurorId])) {
          setSeatNumber(action.payload.caseId, undefined, draft.jurors[action.payload.jurorId].seatNumber, false);
        }
        if (draft.jurors[action.payload.jurorId].alternateSeatNumber && !isDiscarded(draft.jurors[action.payload.jurorId])) {
          setAlternateSeatNumber(action.payload.caseId, undefined, draft.jurors[action.payload.jurorId].alternateSeatNumber, false);
        }
      }
      delete draft.jurors[action.payload.jurorId];
      delete draft.jurorsErrors[action.payload.jurorId];
      delete draft.pendingCreateJurors[action.payload.jurorId];
      delete draft.pendingUpdateJurors[action.payload.jurorId];
      break;
    case ActionTypes.deleteJurorSuccess:
      delete draft.pendingDeleteJurors[action.payload.jurorId];
      break;
    case ActionTypes.deleteJurorFailure:
      // Put the juror back
      if (draft.pendingDeleteJurors[action.payload.jurorId]?.deleted) {
        draft.jurors[action.payload.jurorId] = draft.pendingDeleteJurors[action.payload.jurorId].deleted;
      }
      if (draft.cases[action.payload.caseId]) {
        draft.cases[action.payload.caseId].jurors.push(action.payload.jurorId);
      }
      draft.jurorsErrors[action.payload.jurorId] = {
        type: 'delete',
        canDismiss: false,
        error: action.payload.error
      };
      delete draft.pendingDeleteJurors[action.payload.jurorId];
      break;
    case ActionTypes.batchUpdateJurorsSuccess:
      if (draft.cases[action.payload.caseId]) {
        for (let oneBatchCreated of action.payload.data.created) {
          console.log("Removing juror by tmpId: " + oneBatchCreated.tmpId);
          delete draft.pendingCreateJurors[oneBatchCreated.tmpId];
          // Delete the tmp juror id from the case
          let jurorIndex = draft.cases[action.payload.caseId].jurors.indexOf(oneBatchCreated.tmpId);
          if (jurorIndex >= 0) {
            draft.cases[action.payload.caseId].jurors.splice(jurorIndex, 1);
          }
          // Add the new server based id
          draft.cases[action.payload.caseId].jurors.push(oneBatchCreated._id);
          // Add the juror
          batchedJurorUpdate(action.payload.caseId, oneBatchCreated._id, oneBatchCreated, action.payload.pendingStart);

          // Remove the tmp entry
          delete draft.jurors[oneBatchCreated.tmpId];

          draft.cases[action.payload.caseId].updatedAt = (new Date()).toISOString();
        }
      }
      for (let oneBatchCreatedFailed of action.payload.data.createdFailed) {
        draft.jurorsErrors[oneBatchCreatedFailed.juror._id] = {
          type: 'create',
          canDismiss: false,
          error: oneBatchCreatedFailed.error
        };
        delete draft.pendingCreateJurors[oneBatchCreatedFailed.juror._id];
        delete draft.pendingUpdateJurors[oneBatchCreatedFailed.juror._id];
        delete draft.pendingDeleteJurors[oneBatchCreatedFailed.juror._id];
      }
      for (let oneBatchUpdated of action.payload.data.updated) {
        removePendingJurorUpdate(oneBatchUpdated._id, action.payload.pendingStart);
        //console.log("Updating batched juror: ", oneBatchUpdated);
        draft.jurors[oneBatchUpdated._id] = oneBatchUpdated;
      }
      for (let oneBatchUpdatedFailed of action.payload.data.updatedFailed) {
        console.warn("Failed to update juror: ", {
          data: oneBatchUpdatedFailed.data
        });
        draft.jurorsErrors[oneBatchUpdatedFailed.juror._id] = {
          type: 'update',
          canDismiss: false,
          error: oneBatchUpdatedFailed.error
        };

        removePendingJurorUpdate(oneBatchUpdatedFailed._id, action.payload.pendingStart);
      }
      for (let oneBatchDeleted of action.payload.data.deleted) {
        delete draft.pendingDeleteJurors[oneBatchDeleted];
      }
      for (let oneBatchDeletedFailed of action.payload.data.deletedFailed) {
        // Put the juror back
        draft.jurors[oneBatchDeletedFailed.juror] = draft.pendingDeleteJurors[oneBatchDeletedFailed.juror].deleted;
        if (draft.cases[action.payload.caseId]) {
          draft.cases[action.payload.caseId].jurors.push(oneBatchDeletedFailed.juror);
        }
        draft.jurorsErrors[oneBatchDeletedFailed.juror] = {
          type: 'delete',
          canDismiss: false,
          error: oneBatchDeletedFailed.error
        };
        delete draft.pendingDeleteJurors[oneBatchDeletedFailed.juror];
      }
      break;
    case ActionTypes.processingPending:
      draft.processingPendingError = null;
      draft.processingPendingStart = (new Date()).toISOString();
      break;
    case ActionTypes.processingPendingSuccess:
      draft.processingPendingStart = null;
      break;
    case ActionTypes.processingPendingFailure:
      draft.processingPendingStart = null;
      draft.processingPendingError = {
        type: 'pending-changes',
        canDismiss: true,
        error: action.payload.error
      };
      break;
    case ActionTypes.openCaseModal:
      draft.editingCase = action.payload.caseId;
      break;
    case ActionTypes.closeCaseModal:
      draft.editingCase = null;
      break;
    case ActionTypes.openJurorModal:
      let editingJuror;
      if (draft.jurors[action.payload.jurorId]) {
        editingJuror = {...draft.jurors[action.payload.jurorId]};
      }
      else {
        editingJuror = {};
      }
      draft.editingJuror = editingJuror;
      draft.editingSeatNumber = action.payload.seatNumber;
      draft.focusOnEmoji = action.payload.focusOn;
      draft.focusOnOccurrence = action.payload.focusAfter;
      break;
    case ActionTypes.previewJurorStart:
      draft.previewingJuror = true;
      break;
    case ActionTypes.previewJurorEnd:
      draft.previewingJuror = false;
      break;
    case ActionTypes.closeJurorModal:
      draft.editingJuror = null;
      draft.editingSeatNumber = null;
      draft.previewingJuror = false;
      draft.focusOnEmoji = null;
      draft.focusOnOccurrence = null;
      break;
    case ActionTypes.updateEditingJuror:
      for (let change of action.payload.changes) {
        draft.editingJuror[change.name] = change.value;
      }
      break;
    case ActionTypes.caseNeedsUpdateFromTwilio:
      draft.casesNeedingUpdateFromTwilio[action.payload.caseId] = true;
      break;
    case ActionTypes.updateBounds:
      draft.trackedBounds[action.payload.name] = action.payload.bounds;
      break;
    case ActionTypes.updatePoolSort:
      if (!draft.poolSort[action.payload.caseId]) {
        draft.poolSort[action.payload.caseId] = {};
      }
      if (action.payload.columnNumber === -1) {
        draft.poolSort[action.payload.caseId] = {};
      }
      else {
        draft.poolSort[action.payload.caseId][action.payload.columnNumber] = action.payload.sortingDirection;
      }
      break;
    case REHYDRATE:
      //console.log("Rehydrating...", action.payload);
      if (action.payload && action.payload.app) {
        let cases = action.payload.app.cases;
        if (Array.isArray(cases)) {
          cases = cases.reduce((acc, oneCase) => {
            acc[oneCase._id] = oneCase;
            return acc;
          }, {})
        }
        let seatIndexedJurors = null;
        let alternateSeatIndexedJurors = null;
        if (action.payload.app.seatIndexedJurors) {
          seatIndexedJurors = action.payload.app.seatIndexedJurors;
        }
        if (action.payload.app.alternateSeatIndexedJurors) {
          alternateSeatIndexedJurors = action.payload.app.alternateSeatIndexedJurors;
        }

        if (seatIndexedJurors === null || alternateSeatIndexedJurors === null) {
          // Index the jurors
          for (let caseId of Object.keys(cases)) {
            if (cases[caseId].jurors.length > 0) {
              for (let jurorId of cases[caseId].jurors) {
                if (action.payload.app.jurors[jurorId] && action.payload.app.jurors[jurorId].seatNumber && !isDiscarded(action.payload.app.jurors[jurorId])) {
                  if (!seatIndexedJurors) {
                    seatIndexedJurors = {};
                  }
                  if (!seatIndexedJurors[caseId]) {
                    seatIndexedJurors[caseId] = {};
                  }
                  seatIndexedJurors[caseId][action.payload.app.jurors[jurorId].seatNumber] = jurorId;
                }
                if (action.payload.app.jurors[jurorId] && action.payload.app.jurors[jurorId].alternateSeatNumber && !isDiscarded(action.payload.app.jurors[jurorId])) {
                  if (!alternateSeatIndexedJurors) {
                    alternateSeatIndexedJurors = {};
                  }
                  if (!alternateSeatIndexedJurors[caseId]) {
                    alternateSeatIndexedJurors[caseId] = {};
                  }
                  alternateSeatIndexedJurors[caseId][action.payload.app.jurors[jurorId].alternateSeatNumber] = jurorId;
                }
              }
            }
          }
        }

        if (!alternateSeatIndexedJurors) {
          alternateSeatIndexedJurors = {};
        }

        return {
          poolSort: {},
          ...action.payload.app,
          cases,
          seatIndexedJurors,
          alternateSeatIndexedJurors,
          caseListError: false,
          caseListLoading: false,
          processingPendingStart: null,
          checkingForCaseChanges: false,
          editingCase: null,
          editingJuror: null,
          previewingJuror: false,
          editingSeatNumber: null,
          focusOnEmoji: null,
          focusOnOccurrence: null,
          casesNeedingUpdateFromTwilio: {},
          casesLoadTimestamps: {},
          trackedBounds: {}
        };
      }
      break;
    default:
      // nothing
      break;
  }
}, getInitialState());
