import React from "react";
import {getMode} from "../../state/ethereal/selectors";
import {useDispatch, useSelector} from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {ActionCreators as EtherealActionCreators} from "../../state/ethereal/actions";

export default ({caseId}) => {
    let mode = useSelector(getMode);
    let showSwitch = useSelector((state) => {
        let alternatesSetting = state.app.cases[caseId]?.numberOfAlternates;
        return alternatesSetting !== undefined && alternatesSetting !== null && alternatesSetting > 0;
    });
    let dispatch = useDispatch();

    const handleToggleMode = () => {
        let newMode = mode === "jury-box" ? "alternates" : "jury-box";
        dispatch(EtherealActionCreators.setMode(newMode));
    };

    if (showSwitch) {
        return <FormControlLabel
            key={'mode-switch'}
            style={{marginLeft: "5px"}}
            control={
                <Switch checked={mode === "alternates"}
                        onChange={handleToggleMode}
                        name={'mode'}/>
            }
            label={'Alternates'}/>
    }
    return <div key={'mode-switch'}/>
};
