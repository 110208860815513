import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import JuryBoxAppBar from '../../components/JuryBoxAppBar';
import OfflineBar from '../../components/OfflineBar';
import AccountInfo from './AccountInfo';
import GroupList from './GroupList';
import Typography from '@material-ui/core/Typography';
import SubscriptionInfo from './SubscriptionInfo';
import axios from 'axios';
import EditUserModal from './Modals/EditUserModal';
import format from 'date-fns/format';
import EditSubscriptionModal from './Modals/EditSubscriptionModal';
import AddUserModal from './Modals/AddUserModal';
import SelectGroupModal from './Modals/SelectGroupModal';
import MfaSetupModal from './Modals/MfaSetupModal';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CheckCircle from '@material-ui/icons/CheckCircle';
import PaymentMethodsModal from './Modals/PaymentMethodsModal';
import packageData from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  note: {
    padding: '10px',
  },
  version: {
    fontSize: '9px',
    padding: '2px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Account = () => {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [userList, setUserList] = useState([]);
  const [userGroupCount, setUserGroupCount] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [
    editSubscriptionModalOpen,
    setEditSubscriptionModalOpen,
  ] = useState(false);
  const [userListFetched, setUserListFetched] = useState(false);
  const [subscriptionFetched, setSubscriptionFetched] = useState(
    false,
  );
  const [selectGroupModalOpen, setSelectGroupModalOpen] = useState(false);
  const [mfaModalOpen, setMfaModalOpen] = useState(false);
  const [mfaRequiredLoading, setMfaRequiredLoading] = useState(false);
  const [mfaErrors, setMfaErrors] = useState([]);
  const [paymentMethodsModalOpen, setPaymentMethodsModalOpen] = useState(false);

  const openEditUserModal = (editUser) => () => {
    setEditUserData(editUser);
    setEditUserModalOpen(true);
  };

  const closeEditUserModal = (needsRefresh) => {
    setEditUserModalOpen(false);
    setEditUserData(null);

    if (needsRefresh) {
      refreshData();
    }
  };

  const closeSelectGroupModal = (needsRefresh) => {
    setSelectGroupModalOpen(false);
    setEditUserData(null);

    if (needsRefresh) {
      refreshData();
    }
  };

  const closeAddUserModal = (needsRefresh) => {
    setAddUserModalOpen(false);

    if (needsRefresh) {
      refreshData();
    }
  };

  const refreshData = () => {
    initUser();
    getUserList();
  };

  const openEditSubscriptionModal = () => {
    setEditSubscriptionModalOpen(true);
  };

  const closeEditSubscriptionModal = (needsRefresh) => {
    setEditSubscriptionModalOpen(false);

    if (needsRefresh) {
      refreshData();
    }
  };

  const deleteUser = async (email) => {
    const result = await axios
      .delete('/api/user', {
        data: {
          email: email,
        },
      })
      .catch((error) => console.log(error));
    if (result) {
      refreshData();
    }
  };

  const initUser = async () => {
    const result = await axios
      .get('/api/user')
      .catch((error) => console.log(error));
    if (result?.data) {
      setUser(result.data);
      setIsAdmin(result.data.role && result.data.role === 'Admin');
    }
  };

  const getUserList = async () => {
    setUserListFetched(false);
    const result = await axios
      .get('/api/companymembers')
      .catch((error) => console.log(error));
    if (result?.data && result.data?.length) {
      const groupCount = {};
      let userListTmp = result.data.map((userListItem) => {
        if (groupCount[userListItem.groupID]) {
          groupCount[userListItem.groupID] += 1;
        } else {
          groupCount[userListItem.groupID] = 1;
        }

        return {
          ...userListItem,
          role: userListItem.role ? userListItem.role : 'User',
          canResend: userListItem.status !== 'Pending',
        };
      });

      setUserGroupCount(groupCount);
      setUserList(userListTmp);
      setUserListFetched(true);
    }
  };

  const getSubscription = async () => {
    setSubscriptionFetched(false);
    const result = await axios
      .get('/api/subscription', {
        params: {
          coupon: '',
        },
      })
      .catch((error) => console.log(error));
    if (
      result?.data &&
      Object.keys(result.data.subscription).length
    ) {

      if (result.data.subscription.subscriptionStatus === "limited evaluation") {
        setSubscriptionData(result.data.subscription);
      } else {

        const currentPricePerUnit =
            result.data.subscription.pricePerUnitInCents / 100;
        const currentEnd = result.data.subscription.endDate ? new Date(
            result.data.subscription.endDate * 1000,
        ) : null;
        let currentQuantity = result.data.subscription.userQuantity;
        if (result.data.subscription.free_admin === 'true') {
          currentQuantity += 1;
        }


        let invoiceData = {
          currPricePerUnit: currentPricePerUnit,
          currEnd: currentEnd ? format(currentEnd, 'MM/dd/yyyy') : null,
          currUserQuantity: currentQuantity,
          currQuantityAvailable: currentQuantity - userList.length,
          canceled: result.data.subscription.canceled,
          unlimited: result.data.subscription.unlimited === 'true',
          subscriptionStatus: result.data.subscription.subscriptionStatus,
          hasNext: false
        };

        if (!result.data.subscription.canceled) {
          if (result.data.nextInvoice) {
            const nextPricePerUnit =
                result.data.nextInvoice.pricePerUnitInCents / 100;
            const nextEnd = new Date(
                result.data.nextInvoice.endDate * 1000,
            );
            let nextQuantity = result.data.nextInvoice.userQuantity;
            if (result.data.subscription.free_admin === 'true') {
              nextQuantity += 1;
            }

            invoiceData = {
              ...invoiceData,
              hasNext: true,
              nextPricePerUnit: nextPricePerUnit,
              nextEnd: format(nextEnd, 'MM/dd/yyyy'),
              nextUserQuantity: nextQuantity,
              nextQuantityAvailable: nextQuantity - userList.length,
            };
          }
        }

        setSubscriptionData(invoiceData);
      }
    }
    setSubscriptionFetched(true);
  };

  const handleRequireMfaChange = async (event) => {
    setMfaRequiredLoading(true);
    try {
      let payload = {
        requireMfa: !user.companyRequiresMfa,
      };

      let response = await axios.patch('/api/company/update', payload);

      if (response && response.status === 200) {
        setUser({
          ...user,
          companyRequiresMfa: !user.companyRequiresMfa,
        });
      } else {
        setMfaErrors(['Unexpected response for updating MFA requirements']);
      }
    } catch (error) {
      console.error('Failed to update company MFA requirements.', error);
      setMfaErrors(['Failed to update MFA requirements.']);
    }
    setMfaRequiredLoading(false);
  };

  useEffect(() => {
    initUser();
    getUserList();
  }, []);

  useEffect(() => {
    if (userListFetched) {
      getSubscription();
    }
  }, [userListFetched]);

  const showSubscriptionData =
    user &&
    isAdmin &&
    subscriptionData &&
    subscriptionData.subscriptionStatus !== "limited evaluation" &&
    !subscriptionData.unlimited;
  const canAddUsers =
    userList &&
    subscriptionData &&
    (subscriptionData.subscriptionStatus === "limited evaluation" ||
        subscriptionData.unlimited ||
      (subscriptionData.currUserQuantity > userList.length &&
      subscriptionData.nextUserQuantity > userList.length));

  return (
    <div id="app-wrapper">
      <OfflineBar/>
      <JuryBoxAppBar/>
      <div className={classes.root}>
        <div className={classes.infoContainer}>
          <div style={{ flex: 1 }}>
            <AccountInfo
              user={user}
              onEditOpen={openEditUserModal(user)}>
              <Button onClick={() => {
                setMfaModalOpen(true);
              }}
                      style={{ marginTop: '7px' }}
                      variant={'outlined'}
                      color={'primary'}>MFA setup{user && user.preferredMfa !== 'none' &&
                <span> &nbsp; <CheckCircle style={{ marginBottom: '-7px', color: 'green' }}/> </span>}</Button>
            </AccountInfo>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
            <div style={{marginTop: "10px", marginBottom: "15px"}}>
              <div style={{textAlign: "right", marginBottom: "7px"}}>
                <strong>Subscription status:</strong> {subscriptionData?.subscriptionStatus}
              </div>
              {(subscriptionData?.subscriptionStatus === "unpaid" || subscriptionData?.subscriptionStatus === "past_due") &&
                  <Alert severity={"error"}>
                    You will not be able to use this account until you have updated
                    your payment method and paid your unpaid balance. Please contact
                    &nbsp; <a href={"mailto:support@juryboxapp.com"}>support@juryboxapp.com</a> with any questions.
                  </Alert>
              }
            </div>
            {showSubscriptionData && (
              <SubscriptionInfo
                subscriptionData={subscriptionData}
                onEditOpen={openEditSubscriptionModal}
              >
              </SubscriptionInfo>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isAdmin &&
            <React.Fragment>
              <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                <Collapse in={!!mfaErrors && mfaErrors.length > 0}>
                  <Alert severity="error">
                    <div>
                      MFA configuration errors:
                      <ul>
                        {mfaErrors.map((error) => (
                          <li key={error}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  </Alert>
                </Collapse>
                <FormControlLabel control={<Switch checked={user.companyRequiresMfa}
                                                   onChange={handleRequireMfaChange}
                                                   name={'requireMfa'}/>}
                                  label={'Require MFA for all users'}
                                  disabled={mfaRequiredLoading}/>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant={'text'} color={'primary'} onClick={() => {
                  setPaymentMethodsModalOpen(true);
                }}>Payment methods</Button>
              </div>
            </React.Fragment>
          }
        </div>
        <br/>
        <GroupList
          userList={userList}
          isAdmin={isAdmin}
          user={user}
          onEditOpen={(userData) => {
            openEditUserModal(userData)();
          }}
          onAddOpen={() => setAddUserModalOpen(true)}
          onDelete={(userData) => {
            if (userGroupCount[userData.groupID] <= 1) {
              setEditUserData(userData);
              setSelectGroupModalOpen(true);
            } else {
              deleteUser(userData.email);
            }
          }}
          isTrial={subscriptionData === null}
          canAdd={canAddUsers}
        />
        <Typography
          align="right"
          variant="body2"
          className={classes.version}
        >
          version: {packageData.version}
        </Typography>
      </div>
      <AddUserModal
        key="add-user-modal"
        open={addUserModalOpen}
        onClose={closeAddUserModal}
      />
      <EditUserModal
        key="edit-user-modal"
        open={editUserModalOpen}
        onClose={closeEditUserModal}
        userData={editUserData}
        isAdmin={isAdmin}
        selfUserEmail={user?.email}
      />
      <EditSubscriptionModal
        key="edit-subscription-modal"
        open={editSubscriptionModalOpen}
        onClose={closeEditSubscriptionModal}
        subscriptionData={subscriptionData}
        numCurrentUsers={userList.length}
      />
      <SelectGroupModal
        key="select-group-modal"
        open={selectGroupModalOpen}
        onClose={closeSelectGroupModal}
        userData={editUserData}
      />

      <PaymentMethodsModal key={'payment-methods-modal'}
                           open={paymentMethodsModalOpen}
                           onClose={() => {
                             setPaymentMethodsModalOpen(false);
                           }}/>

      {
        user &&
        <MfaSetupModal key={'mfa-setup-modal'}
                       open={mfaModalOpen}
                       onClose={() => {
                         setMfaModalOpen(false);
                         refreshData();
                       }}
                       phone={user.phone}
                       preferredMfa={user.preferredMfa}
                       mfaEnabled={user.mfaEnabled}
                       requireMfa={user.companyRequiresMfa}/>
      }
    </div>
  );
};

export default Account;
