import React from "react";
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

export default () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);

    if (registration.waiting) {
      // This will make it update on a regular reload as well,
      // once it knows the update is available
      registration.waiting.postMessage({type: 'SKIP_WAITING'});
    }
  };

  const reloadPage = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
    setShowReload(false);
    window.location.reload(true);
  };

  React.useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);


  return (
    <Snackbar
      open={showReload}
      message="A new version is available!"
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          Reload
        </Button>
      }
    />
  );
}
