import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getAll, getJurorNames } from '../../state/multiSelect/selectors';
import Modes from '../../state/multiSelect/modes';
import { ActionCreators } from '../../state/multiSelect/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Chip from "@material-ui/core/Chip";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { multiJurorEdit } from '../../state/asyncActions';
import Typography from '@material-ui/core/Typography';
import RichTextEditor from '../RichTextEditor';
import { getCase } from '../../state/selectors';
import Grid from '@material-ui/core/Grid';
import DemographicsOptions from '../Demographics/DemographicsOptions';
import {isMacOs, isWindows} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600
  },
  jurorNameList: {
    listStyle: "none",
    padding: theme.spacing(0.5),
    display: "flex",
    flexWrap: "wrap"
  },
  jurorNameChip: {
    padding: theme.spacing(0.1),
    marginRight: theme.spacing(0.2),
    marginBottom: theme.spacing(0.2)
  },
  actions: {
    '&.MuiDialogActions-root': {
      padding: theme.spacing(2, 3),
    },
  },
  commentsLabel: {
    color: '#808080',
    marginLeft: '5px',
    userSelect: 'none',
  },
  submitInstructions: {
    marginRight: 'auto',
    color: 'gray',
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    gap: "0.5em"
  },
  code: {
    backgroundColor: "#333",
    color: "white",
    borderRadius: "4px",
    padding: "3px 5px"
  }
}));

export default ({caseId}) => {
  const dispatch = useDispatch();
  const multiSelect = useSelector(getAll);
  const matchingJurorNames = useSelector(getJurorNames);

  const caseInfo = useSelector((state) => {
    return getCase(state, {caseId});
  });

  const [demographicsOptions, setDemographicsOptions] = React.useState([]);

  //console.log('Demographics options: ', demographicsOptions, tmpDemographicsOptions);
  const [demographics, setDemographics] = React.useState([]);
  const [comments, setComments] = React.useState({
    text: "",
    styles: []
  });

  const classes = useStyles();
  const editorRef = React.useRef(null);

  function handleClose() {
    dispatch(ActionCreators.setMode(Modes.ON));
  }

  function handleSave() {
    const content = editorRef.current.getContent();
    dispatch(multiJurorEdit(caseId, multiSelect.selectedJurorIds, content.comments, content.richTextStyles, demographics));
    // Reset demographics
    setDemographics([]);
    setComments({
      text: "",
      styles: []
    });
  }

  return (
    <Dialog open={multiSelect.mode === Modes.COMPOSING_COMMENT}
            onClose={handleClose}
            aria-labelledby="multi-edit-dialog"
            width="lg">
      <DialogContent className={classes.dialog}>
        <DialogContentText>Multi-Juror Edit</DialogContentText>
        <ul className={classes.jurorNameList}>
          {matchingJurorNames.map((jurorName, index) => {
            return (
              <li key={jurorName + "-" + index}>
                <Chip icon={null} label={jurorName} className={classes.jurorNameChip}/>
              </li>
            )
          })}
        </ul>

        {(caseInfo && caseInfo.demographicCategories && caseInfo.demographicCategories.length > 0) &&
        <Grid item xs={12}>
          <DemographicsOptions caseId={caseId}
                               options={demographicsOptions}
                               demographics={demographics}
                               setOptions={setDemographicsOptions}
                               setDemographics={setDemographics} />
        </Grid>
        }

        <RichTextEditor label={<Typography variant={"caption"} className={classes.commentsLabel}>Comments</Typography>}
                        richTextStyles={comments.styles}
                        text={comments.text}
                        onSave={handleSave}
                        autoFocus
                        ref={editorRef} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div className={classes.submitInstructions}>
          {isWindows && <div>Emoji keyboard: <code className={classes.code}>Win</code> + <code className={classes.code}>.</code></div>}
          {isMacOs && <div>Emoji keyboard: <code className={classes.code}>Cmd</code> + <code className={classes.code}>Ctrl</code> + <code className={classes.code}>Space</code></div>}
          <div>Confirm: <code className={classes.code}>Shift</code> + <code className={classes.code}>Enter</code></div>
        </div>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
