import {produce} from 'immer';
import {ActionTypes} from "./actions";
import Modes from "./modes";


function getInitialState() {
  return {
    mode: 'off',
    selectedJurorIds: []
  }
}

export default produce((draft, action) => {

  switch(action.type) {
    case ActionTypes.setMode:
      let oldMode = draft.mode,
        resetSelection = false;
      if (action.payload.mode === Modes.ON) {
        if (oldMode === Modes.OFF) {
          resetSelection = true;
        }
        else if (oldMode === Modes.COMPOSING_COMMENT) {
          // Moving back to 'on' from 'composingComment' (the editing modal was closed, so we don't want to clear the selection)
        }
      }
      if (action.payload.mode === Modes.OFF) {
        resetSelection = true;
      }
      if (resetSelection) {
        draft.selectedJurorIds = []; // Reset selection to empty array
      }
      draft.mode = action.payload.mode;
      break;
    case ActionTypes.addJurorId:
      let jurorToAddIndex = draft.selectedJurorIds.indexOf(action.payload.jurorId);
      if (jurorToAddIndex === -1) {
        draft.selectedJurorIds.push(action.payload.jurorId);
      }
      break;
    case ActionTypes.removeJurorId:
      let jurorToRemoveIndex = draft.selectedJurorIds.indexOf(action.payload.jurorId);
      if (jurorToRemoveIndex >= 0) {
        draft.selectedJurorIds.splice(jurorToRemoveIndex, 1);
      }
      break;
    case ActionTypes.resetSelectedJurors:
      draft.selectedJurorIds = [];
      break;
    default:
      // Nothing here.
      break;
  }
}, getInitialState());
