import React from 'react';
import { COLORS, colorStyleMap } from './renderUtils';
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import BorderColorIcon from '@material-ui/icons/BorderColor';

const useStyles = makeStyles(theme => {
  return {
    container: {
      padding: theme.spacing(0),
      flex: 0
    },
    colorOption: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      color: theme.palette.text.secondary
    },
    colorOptionItem: {
      padding: theme.spacing(0),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    colorOptionBar: {
      borderBottomWidth: 15,
      width: 50
    },
    toolbarIcon: {
      color: theme.palette.text.secondary
    }
  };
});

export default (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [lastSelected, setLastSelected] = React.useState(COLORS[0].style);
  const anchorRef = React.useRef(null);

  const handleClear = () => {
    setLastSelected(COLORS[0].style);
    toggleColors(null);
    setOpen(false);
  };

  const handleClick = () => {
    toggleColors(lastSelected);
  };

  const handleMenuItemClick = (event, option) => {
    setLastSelected(option.style);
    toggleColors(option.style);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const toggleColors = (toggledColor = null) => {

    let selection = props.editorState.getSelection();

    let nextContentState = props.editorState.getCurrentContent();

    if (selection.getStartKey() === selection.getEndKey() &&
      selection.getStartOffset() === selection.getEndOffset()) {
      if (toggledColor === null ) {
        // Remove the color from any whitespace?
        let start = selection.getStartOffset(),
            end = selection.getEndOffset(),
            tmpStart = start,
            tmpEnd = end;

        let block = props.editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
        let text = block.getText(),
          keepCheckingBack = true,
          keepCheckingForward = true;

        while(keepCheckingBack || keepCheckingForward) {
          if (keepCheckingBack) {
            tmpStart--;
            let str = text.substr(tmpStart, 1);
            if (str !== " ") {
              keepCheckingBack = false;
              tmpStart++;
            }
          }
          if (keepCheckingForward) {
            tmpEnd++;
            let str = text.substr(tmpEnd-1, 1);
            if (str !== " ") {
              keepCheckingForward = false;
              tmpEnd--;
            }
          }
        }
        if (tmpStart === start && tmpEnd === end) {
          if (tmpEnd === text.length) {
            tmpEnd++;
            let moddedSelection = SelectionState.createEmpty(selection.getStartKey());
            moddedSelection = moddedSelection.set("anchorOffset", text.length);
            moddedSelection = moddedSelection.set("focusOffset", text.length);
            moddedSelection = moddedSelection.set("anchorKey", selection.getStartKey());
            moddedSelection = moddedSelection.set("focusKey", selection.getStartKey());
            nextContentState = Modifier.insertText(nextContentState, moddedSelection, " ");
          }
        }

        if (tmpStart !== start) {
          selection = selection.set("anchorOffset", tmpStart);
        }
        if (tmpEnd !== end) {
          selection = selection.set("focusOffset", tmpEnd);
        }
      }
    }

    // Let's just allow one color at a time. Turn off all active colors.
    nextContentState = Object.keys(colorStyleMap)
      .reduce((contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      }, nextContentState);

    let nextEditorState = EditorState.push(
      props.editorState,
      nextContentState,
      'change-inline-style',
    );

    const currentStyle = props.editorState.getCurrentInlineStyle();

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    // If the color is being toggled on, apply it.
    if (toggledColor) {
      if (!currentStyle.has(toggledColor)) {
        //console.log('Applying color...');
        nextEditorState = RichUtils.toggleInlineStyle(
          nextEditorState,
          toggledColor,
        );
      }
    }

    nextEditorState = EditorState.forceSelection(nextEditorState, selection);

    props.onChange(nextEditorState);
  };

  return (
    <div className={classes.container}>
      <ButtonGroup ref={anchorRef}
                   aria-label="split button"
                   size="small">
        <Button tabIndex={-1}
                onClick={handleClick}
                title={"Highlight text color"}
                style={colorStyleMap[lastSelected]}>
          <BorderColorIcon fontSize='small' className={classes.toolbarIcon} />
        </Button>
        <Button
          tabIndex={-1}
          size="small"
          title={"Choose highlight color"}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select highlight color"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon fontSize={"small"} className={classes.toolbarIcon}/>
        </Button>
        <Button tabIndex={-1}
          onClick={handleClear}
          title={"Clear highlight"}>
          <FormatColorResetIcon fontSize={"small"} />
        </Button>
      </ButtonGroup>
      <Popper open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              style={{ zIndex: 10 }}
              transition
              disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {COLORS.map((option) => (
                    <MenuItem
                      className={classes.colorOptionItem}
                      key={option.style}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      <div className={classes.colorOption}>
                        <hr className={classes.colorOptionBar} style={{borderColor: colorStyleMap[option.style].backgroundColor}} />
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
