import { createSelector } from "@reduxjs/toolkit";
import {isDiscarded, isInPool} from "../util/juror-helpers";

export const TIMED_OUT = 'timed-out';

export const getCases = (state) => {
  //console.log("State: ", state);
  return state.app.cases
};
export const getSessionData = (state) => {
  return state.app.sessionData;
};
export const getCaseListLoading = (state) => state.app.caseListLoading;
export const getCaseListError = (state) => state.app.caseListError;
export const getCasesErrors = (state) => state.app.casesErrors;
export const getCase = (state, props) => state.app.cases[props.caseId] || {_id: props.caseId, jurors: [], name: "", spacers: [], numberOfAlternates: 0, revision: 0};
export const getCivilCase = (state, props) => state.app.cases[props.caseId] && state.app.cases[props.caseId].civilCase;
export const getCaseModalOpen = (state) => !!state.app.editingCase;
export const getCaseError = (state, props) => state.app.casesErrors[props.caseId];
export const getJurorError = (state, props) => state.app.jurorsErrors[props.jurorId];
export const getEditingStatuses = (state) => {
  return {
    editingCase: state.app.editingCase,
    editingJuror: state.app.editingJuror,
    previewingJuror: state.app.previewingJuror
  }
};
export const listDemographicsOptions = (state, props) => {
  let caseInfo = state.app.cases[props.caseId] || { jurors: [], demographicCategories: [] };
  let options = caseInfo.demographicCategories || [],
    optionsMap = {};
  if (options.length > 0) {
    // Reduce jurors' values to options list
    for (let option of options) {
      optionsMap[option] = new Set();
    }
    for (let jurorId of caseInfo.jurors) {
      let juror = state.app.jurors[jurorId];
      if (juror) {
        if (juror.demographics) {
          for (let oneDemographic of juror.demographics) {
            if (oneDemographic && options.indexOf(oneDemographic.name) >= 0) {
              if (oneDemographic.value && oneDemographic.value.length > 0) {
                optionsMap[oneDemographic.name].add(oneDemographic.value);
              }
            }
          }
        }
      }
    }
  }

  return optionsMap;
};
export const getModalStatuses = createSelector(
  [getEditingStatuses],
  ({editingCase, editingJuror, previewingJuror}) => {
  return {
    jurorModalOpen: !!editingJuror,
    caseModalOpen: !!editingCase,
    previewingJuror
  }
})
export const getJurorsForCase = (state, props) => {
  let jurors = [];

  let caseInfo = state.app.cases[props.caseId] || { jurors: [] };
  for (let jurorId of caseInfo.jurors) {
    if (state.app.jurors[jurorId]) {
      jurors.push(state.app.jurors[jurorId]);
    }
  }

  return jurors;
};

export const getScorecardDataForCase = (state, props) => {
  let causeCount = 0,
    hardshipCount = 0,
    prosecutionPeremptCount = 0,
    defensePeremptCount = 0;
  
  let discardedAttributeName = props.mode === "jury-box" ? "discarded" : "alternateDiscarded";

  let {caseInfo, jurorsInfo} = getCaseInfo(state, props);

  if (jurorsInfo && jurorsInfo.length > 0) {
    for (let i = 0; i < jurorsInfo.length; i++) {
      if (jurorsInfo[i][discardedAttributeName]) {
        if (
          jurorsInfo[i].discardedReason === 'cause'
        ) {
          causeCount++;
        }
        if (
          jurorsInfo[i].discardedReason === 'hardship'
        ) {
          hardshipCount++;
        }
        if (
          jurorsInfo[i].discardedReason === 'peremptory' &&
          jurorsInfo[i].discarder === 'prosecutor'
        ) {
          prosecutionPeremptCount++;
        }
        if (
          jurorsInfo[i].discardedReason === 'peremptory' &&
          jurorsInfo[i].discarder === 'defense'
        ) {
          defensePeremptCount++;
        }

        if (
            jurorsInfo[i].discardedReason === "peremptory" &&
            jurorsInfo[i].discarder === "both"
        ) {
          defensePeremptCount += 0.5;
          prosecutionPeremptCount += 0.5;
        }
      }
    }
  }

  return {
    prosecutionPeremptStrikes: props.mode === 'jury-box' ? caseInfo.prosecutionPeremptStrikes : caseInfo.alternateProsecutionPeremptStrikes,
    defendantPeremptStrikes: props.mode === 'jury-box' ? caseInfo.defendantPeremptStrikes : caseInfo.alternateDefendantPeremptStrikes,
    civilCase: caseInfo.civilCase,
    causeCount,
    hardshipCount,
    prosecutionPeremptCount,
    defensePeremptCount
  }
};

export const getJurorsErrorsForCase = (state, props) => {
  let jurorsErrors = {};

  let caseInfo = state.app.cases[props.caseId] || {jurors: []};

  for (let jurorId of caseInfo.jurors) {
    if (state.app.jurorsErrors[jurorId]) {
      jurorsErrors[jurorId] = state.app.jurorsErrors[jurorId];
    }
  }

  return jurorsErrors;
}

export const selectBasicCaseInfo = createSelector(
  [getCase, getCaseModalOpen],
  (caseInfo, caseModalOpen) => {
  return {
    caseInfo,
    caseModalOpen
  }
});

export const getCaseInfo = createSelector(
  [getCase, getJurorsForCase, getCaseError, getCaseModalOpen],
  (caseInfo, jurorsInfo, caseError, caseModalOpen) => {
    return {
      caseInfo,
      jurorsInfo,
      error: caseError,
      caseModalOpen: caseModalOpen
    }
  }
);

export const getJuryBoxInfo = createSelector(
  [getCase, getJurorsForCase],
  (caseInfo, jurorsInfo) => {

    let isJuryBoxEmpty = true;
    for (let jurorInfo of jurorsInfo) {
      if (jurorInfo.seatNumber && jurorInfo.seatNumber < caseInfo.juryBoxSize && !jurorInfo.discarded) {
        isJuryBoxEmpty = false;
        break;
      }
    }

    return {
      juryBoxSize: parseInt(caseInfo.juryBoxSize),
      jurorsPerRow: parseInt(caseInfo.jurorsPerRow),
      isJuryBoxEmpty: isJuryBoxEmpty,
      spacers: caseInfo.spacers || [],
      numberOfAlternates: caseInfo.numberOfAlternates || 0
    };
  }
)

export const getJurorDemographics = (juror, demographicCategories) => {
  return juror.demographics.filter(demographic => !!demographic && demographic.value && demographic.value.length > 0 && demographicCategories.indexOf(demographic.name) >= 0)
};

export const getDemographicsData = createSelector(
  [getCaseInfo, listDemographicsOptions],
  ({caseInfo, jurorsInfo}, options) => {
    let statistics = {
      overall: {
        total: 0,
        totalDismissed: 0,
        totalInBox: 0,
        totalDismissedByProsecutor: 0,
        totalDismissedByDefense: 0
      },
      details: {}
    };
    for (let option of Object.keys(options)) {
      statistics.details[option] = [...options[option]].reduce((agg, oneOption) => {
        agg[oneOption] = {
          total: 0,
          totalDismissed: 0,
          totalInBox: 0,
          dismissed: {
            all: 0,
            prosecutorPeremptory: 0,
            defensePeremptory: 0,
            cause: 0,
            hardship: 0
          }
        };
        return agg;
      }, {}); // convert to array of options
    }

    for (let juror of jurorsInfo) {
      // Gather statistics
      statistics.overall.total++;
      if (juror.discarded) {
        statistics.overall.totalDismissed++;
        if (juror.discarder === "prosecutor") {
          statistics.overall.totalDismissedByProsecutor++;
        }
        if (juror.discarder === "defense") {
          statistics.overall.totalDismissedByDefense++;
        }
        if (juror.discarder === "both") {
          statistics.overall.totalDismissedByDefense += 0.5;
          statistics.overall.totalDismissedByProsecutor += 0.5;
        }
      }
      else if (juror.seatNumber && juror.seatNumber <= caseInfo.juryBoxSize) {
        statistics.overall.totalInBox++;
      }

      for (let oneDemographic of juror.demographics) {
        if (oneDemographic && oneDemographic.value && oneDemographic.value.length > 0) {
          if (statistics.details[oneDemographic.name]) {
            statistics.details[oneDemographic.name][oneDemographic.value].total++;
            if (juror.discarded) {
              statistics.details[oneDemographic.name][oneDemographic.value].totalDismissed++;
              statistics.details[oneDemographic.name][oneDemographic.value].dismissed.all++;
              switch (juror.discardedReason) {
                case 'cause':
                  statistics.details[oneDemographic.name][oneDemographic.value].dismissed.cause++;
                  break;
                case 'hardship':
                  statistics.details[oneDemographic.name][oneDemographic.value].dismissed.hardship++;
                  break;
                case 'peremptory':
                  if (juror.discarder === "prosecutor") {
                    statistics.details[oneDemographic.name][oneDemographic.value].dismissed.prosecutorPeremptory++;
                  } else if (juror.discarder === "defense") {
                    statistics.details[oneDemographic.name][oneDemographic.value].dismissed.defensePeremptory++;
                  } else if (juror.discarder === "both") {
                    statistics.details[oneDemographic.name][oneDemographic.value].dismissed.prosecutorPeremptory += 0.5;
                    statistics.details[oneDemographic.name][oneDemographic.value].dismissed.defensePeremptory += 0.5;
                  }
                  break;
              }
            } else {
              if (juror.seatNumber && juror.seatNumber <= caseInfo.juryBoxSize) {
                // In the box
                statistics.details[oneDemographic.name][oneDemographic.value].totalInBox++;
              }
            }
          }
        }
      }
    }

    return statistics;
  }
);

export const getJurorsForRow = (state, {rowNum, numCols, caseId, mode}) => {
  let seatedJurors = [];
  let spacers = [];
  if (mode === "jury-box") {
    spacers = [...(state.app.cases[caseId].spacers || [])];
    if (!spacers) {
      spacers = [];
    }
  }
  spacers = spacers.sort((a, b) => parseInt(a) - parseInt(b));
  let previousSpacers = [];
  let rowSpacers = [];
  for (let oneSpacer of spacers) {
    if (oneSpacer <= (rowNum * numCols)) {
      previousSpacers.push(oneSpacer);
    }
    if (oneSpacer > (rowNum * numCols) && oneSpacer <= ((rowNum * numCols) + numCols)) {
      rowSpacers.push(oneSpacer);
    }
  }

  let seatIndex= state.app.seatIndexedJurors;
  if (mode === 'alternates') {
    seatIndex = state.app.alternateSeatIndexedJurors || {};
  }

  for (let i = 0; i < numCols; i++) {
    let jurorInfo = null;
    let isSpacer = false;
    let spaceNumber = i + 1 + rowNum * numCols;

    if (rowSpacers.indexOf(spaceNumber) >= 0) {
      isSpacer = true;
      if (previousSpacers.indexOf(spaceNumber) === -1) {
        previousSpacers.push(spaceNumber);
      }
    }

    let actualSeatNumber = spaceNumber - previousSpacers.length;

    if (seatIndex[caseId] && seatIndex[caseId][actualSeatNumber]) {
      let jurorId = seatIndex[caseId][actualSeatNumber];
      jurorInfo = state.app.jurors[jurorId];
    }

    if (jurorInfo && isDiscarded(jurorInfo)) {
      jurorInfo = null;
    }

    seatedJurors.push({
      spaceNumber,
      actualSeatNumber,
      isSpacer,
      jurorInfo
    });
  }

  return seatedJurors;
};

export const getDismissedJurors = (state, props) => {
  let jurors = getJurorsForCase(state, props);
  
  let discardedAttributeName = props.mode === "jury-box" ? "discarded" : "alternateDiscarded";

  jurors.sort((a, b) => {
    if (a.updated && b.updated) {
      return a.updated.localeCompare(b.updated) * -1;
    }
    return 0;
  });

  let dismissedJurors = [];
  let totalDismissed = 0;
  let lastUpdated = null;
  for (let i = 0; i < jurors.length; i++) {
    if (jurors[i][discardedAttributeName]) {

      let matches = true;
      if (props.search && props.search.length > 0) {
        matches = false;
        if (jurors[i].nameAndId && jurors[i].nameAndId.indexOf(props.search) >= 0) {
          matches = true;
        }
        if (jurors[i].comments && jurors[i].comments.indexOf(props.search) >= 0) {
          matches = true;
        }
        if (jurors[i].demographics) {
          for (let oneDemographic of jurors[i].demographics) {
            if (oneDemographic.value?.indexOf && oneDemographic.value.indexOf(props.search) >= 0) {
              matches = true;
              break;
            }
          }
        }
      }

      if (totalDismissed >= props.scrollLeft && dismissedJurors.length < 10) {
        if (matches) {
          dismissedJurors.push(jurors[i]);
        }
      }
      if (matches) {
        totalDismissed++;
        if (jurors[i].updated && (lastUpdated == null || jurors[i].updated > lastUpdated)) {
          lastUpdated = jurors[i].updated;
        }
      }
    }
  }

  return {
    total: totalDismissed,
    items: dismissedJurors,
    updated: lastUpdated
  };
};

export const getPending = (state) => {
  let totalPending = (
    Object.keys(state.app.pendingCreateCases).length +
    Object.keys(state.app.pendingUpdateCases).length +
    Object.keys(state.app.pendingDeleteCases).length +
    Object.keys(state.app.pendingCreateJurors).length +
    Object.keys(state.app.pendingUpdateJurors).length +
    Object.keys(state.app.pendingDeleteJurors).length
  );
  return {
    totalPending,
    processingPendingStart: state.app.processingPendingStart,
    cases: {
      toCreate: state.app.pendingCreateCases,
      toUpdate: state.app.pendingUpdateCases,
      toDelete: state.app.pendingDeleteCases
    },
    jurors: {
      toCreate: state.app.pendingCreateJurors,
      toUpdate: state.app.pendingUpdateJurors,
      toDelete: state.app.pendingDeleteJurors
    }
  }
}

export const hasPendingDataToSync = (state, timeout) => {
  let pending = getPending(state);
  if (pending.totalPending > 0 || pending.processingPendingStart !== null) {
    // Yes, it has pending data to sync
    console.log("Pending status: ", {
      pending
    })
    if (pending.processingPendingStart !== null) {
      // Make sure the pending process isn't stuck.
      let now = new Date();
      let processingPendingTimestamp = new Date(pending.processingPendingStart);
      if (processingPendingTimestamp instanceof Date && isFinite(processingPendingTimestamp)) {
        let diff = now - processingPendingTimestamp;
        if (diff >= timeout) { // It's been more than 5 minutes since starting the pending sync
          // Something probably went wrong.
          console.warn("It's been too long for a pending sync to resolve: " + diff + " milliseconds.");
          return TIMED_OUT;
        }
        else {
          // Still within a reasonable amount of time.
          // Wait until later

          return true;
        }
      }
    }

    return true;
  }

  return false;
}

export const getCaseList = createSelector(
  [getCases, getSessionData],
  (cases, sessionData) => {
    //console.log("Cases in getCaseList: ", cases);
    if (!sessionData || !sessionData.email) {
      return [];
    }

    return Object.values(cases).map((caseInfo) => {
      return {
        _id: caseInfo._id,
        creator: caseInfo.creator || "",
        name: caseInfo.name,
        number: caseInfo.number,
        details: caseInfo.details,
        createdAt: caseInfo.createdAt,
        updatedAt: caseInfo.updatedAt,
        canDelete: caseInfo.creator === undefined || caseInfo.creator === sessionData.email,
        canShare: caseInfo.canShare
      }
    });
  }
);

export const getCaseListState = createSelector(
  [getCaseList, getCaseListError, getCaseListLoading, getCasesErrors],
  (caseList, caseListError, caseListLoading, casesErrors) => {
    return {
      caseList,
      caseListError,
      caseListLoading,
      casesErrors
    }
  }
);

export const getJuryPoolJurors = createSelector(
  [getJurorsForCase, getCaseInfo],
  (jurors, caseInfo) => {
    //console.log('Getting pool jurors...', caseInfo.caseInfo);
    let juryPoolJurors = [];
    let lastUpdated = null;

    for (let juror of jurors) {
      if (isInPool(juror)) {
        juryPoolJurors.push({...juror});
        if (juror.updated && (lastUpdated == null || juror.updated > lastUpdated)) {
          lastUpdated = juror.updated;
        }
      }
    }

    return {
      jurors: juryPoolJurors,
      updated: lastUpdated,
      caseUpdated: caseInfo.caseInfo.updatedAt
    };
  }
);


export const getJuryPoolErrorsForCase = createSelector(
  [getJuryPoolJurors, getJurorsErrorsForCase],
  (juryPoolJurors, jurorsErrors) => {

  let juryPoolErrors = {};

  for (let juryPoolJuror of juryPoolJurors.jurors) {
    if (jurorsErrors[juryPoolJuror._id]) {
      juryPoolErrors[juryPoolJuror._id] = jurorsErrors[juryPoolJuror.id];
    }
  }

  return juryPoolErrors;
});

export const getJuryPoolSorting = (state, props) => {
  if (state.app.poolSort) {
    if (state.app.poolSort[props.caseId]) {
      return state.app.poolSort[props.caseId]
    }
  }

  return {};
};
