import React from "react";
import { FormControl, FormLabel } from '@material-ui/core';
import CreatableSelect from 'react-select/creatable/dist/react-select.esm';

export default ({ name, value, options, onCreateOption, onChange, disabled }) => {
  return (
    <FormControl fullWidth={true}>
      <FormLabel>{name}</FormLabel>
      <CreatableSelect options={options}
                       isClearable={true}
                       isDisabled={disabled}
                       styles={{
                         menu: (menuStyles, menuObject) => {
                           return {
                             ...menuStyles,
                             zIndex: 1000,
                           };
                         },
                       }}
                       value={value}
                       fullWidth={true}
                       onChange={onChange}
                       onCreateOption={onCreateOption}/>
    </FormControl>
  );
};
