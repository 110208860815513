import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useFormFields } from '../../util/hooks';
import axios from 'axios';
import { useAuth } from '../../util/authContext';
import { formStyles } from '../../util/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import MfaSetupModal from '../Account/Modals/MfaSetupModal';
import { Card, CardActions, CardContent } from '@material-ui/core';

const SignUpForm = ({ token }) => {
  const classes = formStyles();
  const [fields, handleFieldChange] = useFormFields({
    name: '',
    password: '',
  });
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [presetEmail, setPresetEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errors, setErrors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [configureMfa, setConfigureMfa] = useState(false);
  const [allowedMfaChannels, setAllowMfaChannels] = useState([]);
  const [mfaMessage, setMfaMessage] = useState(null);
  const [mfaPromptOpen, setMfaPromptOpen] = useState(false);
  const [mfaToken, setMfaToken] = useState('');
  const [showMfaTokenInput, setShowMfaTokenInput] = useState(false);
  const [preferredMfaChannel, setPreferredMfaChannel] = useState(null);

  const { createSession } = useAuth();
  const [validLink, setValidLink] = useState(true);

  useEffect(() => {
    // Check if valid sign up token and get email
    const checkToken = async () => {
      try {
        let res = await axios.post('/api/validinvitetoken', {
          inviteToken: token,
        });
        if (res && res.data.error) {
          console.log(res.data.error);
          setValidLink(false);
        } else {
          setEmail(res.data.email);
          setCompany(res.data.company);
          setPresetEmail(true);
        }
      } catch (error) {
        console.log(error);
        setValidLink(false);
      }
    };
    if (token) {
      checkToken();
    }
  }, [token]);

  const getUserInfo = async () => {
    setIsLoading(true);

    let tmpUserData = null;
    try {
      const result = await axios
        .get(`/api/user?${(new URLSearchParams({ email: email, password: fields.password })).toString()}`);
      if (result && result.data) {
        tmpUserData = result.data;
        setUserInfo(result.data);
      }
    } catch (e) {
      console.error('Error while getting user info for MFA setup: ', e);
      setErrors(['Failed to get user info. Please check your password']);
    }

    setIsLoading(false);

    return tmpUserData;
  };

  const handleMfaTokenChange = (e) => {
    let tmpMfaToken = e.target.value;
    setMfaToken(tmpMfaToken);
    if (tmpMfaToken.length === 6) {
      setTimeout(() => {
        handleSubmit(null, tmpMfaToken);
      }, 300);
    }
  };

  const sendSms = async (event) => {
    event.preventDefault();
    await sendMfaToken('sms');
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    await sendMfaToken('email');
  };

  const sendMfaToken = async (channel) => {
    setPreferredMfaChannel(channel);
    try {
      setIsLoading(true);
      setMfaMessage('Sending...');
      let res = await axios.post('/api/mfa/send-token', {
        email: email,
        channel: channel,
      });

      console.log('Token sent status: ', res.data);
      if (res && res.data.status) {
        setMfaMessage('Token sent via ' + channel + '!');
      }
    } catch (error) {
      console.error('Failed to send MFA token via ' + channel, error);
      setMfaMessage('An error occurred.');
      setErrors(['Failed to send MFA token via ' + channel]);
    }

    setIsLoading(false);
  };

  const signIn = async (tmpMfaToken, tmpChannel) => {
    let payload = {
      email: email,
      password: fields.password,
    };

    if (showMfaTokenInput || tmpMfaToken || tmpChannel) {
      payload.token = tmpMfaToken || mfaToken;
      payload.channel = tmpChannel || preferredMfaChannel;
    }

    let res = null;
    setErrors([])
    setIsLoading(true);
    try {
      res = await axios.post('/api/signin', payload);
      if (res && res.status === 200 && res.data.user) {
        await createSession(
          {
            name: res.data.user.name,
            email: res.data.user.email,
            groupsLastUpdated: res.data.user.groupsLastUpdated,
            mfaEnabled: res.data.user.mfaEnabled,
            companyRequiresMfa: res.data.user.companyRequiresMfa,
            preferredMfa: res.data.user.preferredMfa
          },
          res.data.expires,
        );
      }
    }
    catch (error) {
      if (error.response) {
        res = error.response;
      }

      if (res && res.data.error) {
        console.log("Error on sign-in: ", {
          data: res.data
        })
        if (res && res.data.error === 'Missing MFA token') {
          setShowMfaTokenInput(true);
          let message = 'Please provide the token.';
          switch (res.data.channel) {
            case 'authenticator':
              message += ' Check your authenticator app.';
              break;
            case 'sms':
              message += ' A text message has been sent with the token.';
              break;
            case 'email':
              message += ' An email has been sent with the token.';
              break;
          }
          setMfaMessage(message);
          setAllowMfaChannels(res.data.allowedChannels);
          setPreferredMfaChannel(res.data.channel);
        } else if (res && res.data.error === 'Invalid MFA token') {
          setErrors([
            'The MFA token entered is incorrect. Please try again.',
          ]);
          setMfaMessage('Token is invalid');
        }
        if (res.data.errorType === 'MFA_REQUIRED_BY_COMPANY') {
          await getUserInfo();
          setMfaPromptOpen(true);
          setConfigureMfa(true);
        } else {
          setErrors([
            res.data.error,
          ]);
        }
      }
    }

    setIsLoading(false);
  }

  const handleSubmit = async (event, tmpMfaToken = null, tmpChannel = null) => {
    if (event) {
      event.preventDefault();
    }
    let errorList = [];

    if (!fields.name || fields.name.length < 1) {
      errorList.push('Name must be populated');
    }

    if (!company || company.length < 1) {
      errorList.push('Company must be populated');
    }

    if (!email || !email.includes('@')) {
      errorList.push('Email is not valid');
    }

    if (!fields.password || fields.password.length < 8) {
      errorList.push('Password must be longer than 8 characters');
    }

    if (errorList.length > 0) {
      setErrors(errorList);
    } else {
      setIsLoading(true);
      try {
        let res = null;
        if (!presetEmail && userInfo === null) {
          res = await axios.post('/api/signup', {
            name: fields.name,
            company: company,
            email: email,
            password: fields.password,
            platform: 'web',
          });
        } else {
          if (userInfo === null) {
            res = await axios.put('/api/userfrominvite', {
              name: fields.name,
              email: email,
              password: fields.password,
              platform: 'web',
              inviteToken: token,
            });
          }
          await signIn(tmpMfaToken, tmpChannel);
        }

        console.log("Res: ", {
          res
        });

        if (res && res.status === 200 && res.data.user) {

          gtag("event", "sign_up", {
            method: tmpMfaToken ? "MFA" : "Password"
          });

          await createSession(
            {
              name: res.data.user.name,
              email: res.data.user.email,
              groupsLastUpdated: res.data.user.groupsLastUpdated,
              mfaEnabled: res.data.user.mfaEnabled,
              companyRequiresMfa: res.data.user.companyRequiresMfa,
              preferredMfa: res.data.user.preferredMfa,
            },
            res.data.expires,
          );
        }
      } catch (error) {
        console.log(error);
        let res = error.response,
          hasResponse = false;
        if (res && res.data && res.data.error) {
          setErrors([
            res.data.error,
          ]);
          hasResponse = true;
        }
        if (!hasResponse) {
          setErrors([error.message]);
        }
      }

      setIsLoading(false);
    }
  };

  const checkboxTerms = (
    <Typography variant="body2">
      I have reviewed and agree to the terms outlined in the&nbsp;
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.juryboxapp.com/license-agreement"
      >
        License Agreement
      </a>{' '}
      and&nbsp;
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.juryboxapp.com/privacy-policy"
      >
        Privacy Policy
      </a>
    </Typography>
  );

  if (validLink) {
    return (
      <>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Collapse in={errors && errors.length > 0}>
                <Alert severity="error">
                  <div>
                    Sign Up Failed:
                    <ul>
                      {errors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </div>
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                fullWidth
                id="name"
                label="Name"
                autoFocus
                value={fields.name}
                onChange={handleFieldChange}
                InputProps={{
                  classes: {
                    root: classes.outlinedRoot,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="company"
                name="company"
                variant="outlined"
                fullWidth
                id="company"
                label="Organization"
                value={company}
                disabled={presetEmail}
                onChange={(e) => setCompany(e.target.value)}
                InputProps={{
                  classes: {
                    root: classes.outlinedRoot,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                name="email"
                label="Email"
                autoComplete="email"
                disabled={presetEmail}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  classes: {
                    root: classes.outlinedRoot,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                label="Create Password"
                autoComplete="current-password"
                value={fields.password}
                onChange={handleFieldChange}
                InputProps={{
                  classes: {
                    root: classes.outlinedRoot,
                  },
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(event) =>
                            event.preventDefault()
                          }
                        >
                          {showPassword ? (
                            <Visibility/>
                          ) : (
                            <VisibilityOff/>
                          )}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Grid>

            {showMfaTokenInput &&
              <Card style={{ width: '100%', margin: '7px' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Multi-factor Authentication
                  </Typography>
                  <Grid item xs={12}>
                    {mfaMessage &&
                      <Typography variant={'body2'}>{mfaMessage}</Typography>
                    }
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="token"
                      name="token"
                      label="Token"
                      autoComplete="token"
                      autoFocus
                      value={mfaToken}
                      onChange={handleMfaTokenChange}
                      InputProps={{
                        classes: {
                          root: classes.outlinedRoot,
                        },
                      }}
                      style={{ marginTop: '7px' }}
                    />
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={3}>
                        <hr/>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'center' }}> Having trouble? </Grid>
                      <Grid item xs={3}>
                        <hr/>
                      </Grid>
                    </Grid>
                    {allowedMfaChannels.includes('sms') &&
                      <Button type={'button'} color={'primary'} onClick={sendSms}
                              disabled={isLoading}>{preferredMfaChannel === 'sms' ? 'Re-send SMS' : 'Send SMS'}</Button>
                    }
                    <Button type={'button'} color={'primary'} onClick={sendEmail}
                            disabled={isLoading}>{preferredMfaChannel === 'email' ? 'Re-send Email' : 'Send Email'}</Button>
                  </Grid>
                </CardActions>
              </Card>
            }

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="default"/>}
                label={checkboxTerms}
                onChange={() => setAgreedToTerms(!agreedToTerms)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                disabled={!agreedToTerms || isLoading}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Link
                href="/signin"
                variant="body1"
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>

          {configureMfa && userInfo !== null &&
            <MfaSetupModal key={'mfa-prompt-modal'}
                           open={mfaPromptOpen}
                           onComplete={(mfaToken, channel) => {
                             setMfaPromptOpen(false);
                             setShowMfaTokenInput(true);

                             if (channel && channel === 'email' && !mfaToken) {
                               sendMfaToken('email');
                             }

                             if (mfaToken) {
                               if (channel) {
                                 setPreferredMfaChannel(channel);
                               }
                               setMfaToken(mfaToken);
                               handleSubmit(null, mfaToken, channel);
                             }
                           }}
                           loginInfo={{
                             email: email,
                             password: fields.password,
                           }}
                           phone={userInfo.phone || ''}
                           preferredMfa={userInfo.preferredMfa}
                           requireMfa={userInfo.companyRequiresMfa}
                           mfaEnabled={userInfo.mfaEnabled}
            />}
        </form>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h5" align="center" style={{ padding: 5 }}>Not a valid signup link</Typography>
        <Link href="/signup" variant="h5" align="center" style={{ padding: 5 }}>Sign up here</Link>
      </>
    );
  }
};

export default SignUpForm;
