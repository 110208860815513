export const getActiveDrag = (state) => {
  return state.ethereal.activeDrag;
}

export const isDemographicsModalOpen = (state) => {
  return state.ethereal.demographicsModal;
};

export const isDismissedQuadrantVisible = (state) => {
  return state.ethereal.dismissedQuadrantVisible;
};

export const getHighlightedQuadrant = (state) => {
  return state.ethereal.highlightedQuadrant;
};

export const getJuryBoxBounds = (state) => {
  return state.ethereal.juryBoxBounds;
};

export const getJuryPoolBounds = (state) => {
  return state.ethereal.juryPoolBounds;
};

export const getDismissedBoxBounds = (state) => {
  return state.ethereal.dismissedBoxBounds;
};

export const getDismissedQuadrantBounds = (state) => {
  return state.ethereal.dismissedQuadrantBounds;
};

export const getMode = (state) => {
  return state.ethereal.mode;
}

export const getAlerts = (state) => {
  return state.ethereal.alerts || [];
}
