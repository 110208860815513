import React from 'react';
import SignUpForm from './SignUpForm';
import Logo from '../../util/Logo';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  logo: {
    marginTop: '-50px',
    padding: theme.spacing(0, 4, 4),
  },
}));

const Signup = ({token}) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.logo}>
        <Logo />
      </div>
      <SignUpForm token={token}/>
    </Container>
  );
};

export default Signup;
