import {useEffect, useState} from 'react';

export const useLocalStorage = (initialState, key) => {
  const rawStateFromStorage = localStorage.getItem(key);
  const stateFromStorage =
    rawStateFromStorage && JSON.parse(rawStateFromStorage);

  const [value, setValue] = useState(
    stateFromStorage || initialState,
  );

  return [
    value,
    (data) => {
      localStorage.setItem(key, JSON.stringify(data));
      setValue(data);
    },
  ];
};

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

export function useViewBasedCardWidth()
{
  const [width, setWidth] = useState(200);
  function calculateWidth() {
    const viewWidth = window.document.documentElement.clientWidth - 48 - 50;
    // 10 jurors per row always visible, if 11 or greater, allow it to scroll offscreen
    return viewWidth / 10.0;
  }

  useEffect(() => {
    setWidth(calculateWidth());
    window.addEventListener('resize', (e) => {
      setWidth(calculateWidth());
    });
  }, []);

  return `${width}px`;
}
