import React from 'react';
import JurorCard from './JurorCard/JurorCard';
import {Paper, Tooltip} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
    emptyJuryBox, fillEmptySpaces, fillFromPool,
    emptyAlternates, fillEmptyAlternateSpaces, fillAlternatesFromPool,
    updateJuror
} from '../../state/asyncActions';
import {useDispatch, useSelector} from 'react-redux';
import useStyles from './Case.styles';
import {getJuryBoxInfo, getJurorsForRow} from '../../state/selectors';
import {getAll} from '../../state/multiSelect/selectors';
import PublishIcon from '@material-ui/icons/Publish';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from "@material-ui/icons/LastPage";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import jsonifyCompare from '../../util/jsonifyCompare';
import Spacer from './Spacer';
import {getMode} from "../../state/ethereal/selectors";
import EmptyJuryBoxSeat from "./JurorCard/EmptyJuryBoxSeat";

const FormRow = ({
                     numCols, rowNum, juryBoxSize, mode, caseId, civilCase, demographicCategories, showDemographics,
                     addEmptyJuror, getEmptyJurors, reverseRow, handleUpdateJuror, multiSelect,
                 }) => {

    const classes = useStyles();
    const jurorsForRow = useSelector((state) =>
        getJurorsForRow(state, {rowNum, numCols, caseId, mode})
    );

    const items = [];
    for (let jurorInRow of jurorsForRow) {
        if (jurorInRow.actualSeatNumber > juryBoxSize) {
            items.push(<div style={{flex: 1}} key={`empty-juror-${jurorInRow.spaceNumber}`}>&nbsp;</div>);
            continue;
        }

        if (jurorInRow.isSpacer) {
            items.push(<Spacer key={`space-${jurorInRow.spaceNumber}`}
                               caseId={caseId}
                               spaceNumber={jurorInRow.spaceNumber}/>);
        } else if (!jurorInRow.jurorInfo) {
            items.push(<EmptyJuryBoxSeat caseId={caseId}
                                         key={'empty-juror' + jurorInRow.spaceNumber}
                                         spaceNumber={jurorInRow.spaceNumber}
                                         seatNumber={jurorInRow.actualSeatNumber}/>);
        } else {
            items.push(
                <JurorCard
                    type="juror"
                    jurorId={jurorInRow.jurorInfo ? jurorInRow.jurorInfo._id : null}
                    juror={jurorInRow.jurorInfo}
                    seatNumber={jurorInRow.actualSeatNumber}
                    spaceNumber={jurorInRow.spaceNumber}
                    key={`space-${jurorInRow.spaceNumber}`}
                    multiSelect={multiSelect}
                    caseId={caseId}
                    mode={mode}
                    civilCase={civilCase}
                    demographicCategories={demographicCategories}
                    showDemographics={showDemographics}
                    addEmptyJuror={addEmptyJuror}
                    getEmptyJurors={getEmptyJurors}
                    updateJurorInParent={handleUpdateJuror}
                />
            );
        }
    }
    return (
        <React.Fragment>
            <div className={classes.formRow} style={{flexDirection: reverseRow ? 'row-reverse' : 'row'}}>
                {items}
            </div>
        </React.Fragment>
    );
};

const FormGrid = ({
                      numRows,
                      numCols,
                      mode,
                      caseId,
                      juryBoxSize,
                      civilCase,
                      demographicCategories,
                      showDemographics,
                      addEmptyJuror,
                      getEmptyJurors,
                      reverseRow,
                      multiSelect,
                      handleUpdateJuror,
                      seatNumberFlow
                  }) => {
    const rows = [];
    for (let i = 0; i < numRows; i++) {
        let tmpReverseRow = reverseRow;
        if (seatNumberFlow === "snake" && i % 2 > 0) {
            tmpReverseRow = !reverseRow;
        }
        rows.push(
            <FormRow key={i}
                     rowNum={i}
                     caseId={caseId}
                     mode={mode}
                     juryBoxSize={juryBoxSize}
                     civilCase={civilCase}
                     demographicCategories={demographicCategories}
                     showDemographics={showDemographics}
                     addEmptyJuror={addEmptyJuror}
                     getEmptyJurors={getEmptyJurors}
                     reverseRow={tmpReverseRow}
                     handleUpdateJuror={handleUpdateJuror}
                     multiSelect={multiSelect}
                     numCols={numCols}/>,
        );
    }
    return <React.Fragment>{rows}</React.Fragment>;
};

const JuryBox = ({
                     caseId,
                     civilCase,
                     demographicCategories,
                     showDemographics,
                     jurorsPerRow,
                     seatNumberStartPosition,
                     seatNumberFlow
                 }) => {

    //console.log("Rendering jurybox...");

    const classes = useStyles();

    const {
        juryBoxSize,
        isJuryBoxEmpty,
        spacers,
        numberOfAlternates
    } = useSelector((state) => getJuryBoxInfo(state, {caseId}), (next, prev) => {
        const isSame = next.juryBoxSize === prev.juryBoxSize &&
            next.numberOfAlternates === prev.numberOfAlternates &&
            next.isJuryBoxEmpty === prev.isJuryBoxEmpty &&
            JSON.stringify(prev.spacers) === JSON.stringify(next.spacers);
        return isSame;
    });
    const mode = useSelector(getMode);
    const multiSelect = useSelector(getAll, jsonifyCompare('multi-select[jurybox]'));

    const numCols = jurorsPerRow;
    let numRows = Math.ceil((juryBoxSize + spacers.length) / jurorsPerRow);
    const reverseRow = seatNumberStartPosition.indexOf('Right') >= 0;
    const reverseColumn = seatNumberStartPosition.indexOf('bottom') >= 0;
    let emptyJurors = {};
    let prompt = '';

    if (mode === "alternates") {
        numRows = Math.ceil(numberOfAlternates / jurorsPerRow);
    }

    const dispatch = useDispatch();

    const handleUpdateJuror = async (juror) => {
        dispatch(updateJuror(caseId, juror._id, juror));
    };

    const addEmptyJuror = (seatNumber, jurorRef) => {
        emptyJurors[seatNumber] = jurorRef;
    };

    const getEmptyJurors = () => {
        return emptyJurors;
    };

    if (isJuryBoxEmpty) {
        prompt = 'Click an empty juror card to add info';
    }

    let label = mode === 'jury-box' ? 'Jury Box' : "Alternates";

    return (
        <Paper elevation={0} className={classes.container}>
            <Grid container>
                <Grid item xs>
                    <Typography
                        className={classes.boxTitle}
                        variant="h6"
                    >
                        <b>{label}</b>
                    </Typography>
                </Grid>
                <Grid item xs style={{textAlign: "center"}}>
                    <Typography variant="body2" style={{whiteSpace: 'nowrap'}}><b>{prompt}</b></Typography>
                </Grid>
                <Grid item xs style={{display: "flex", justifyContent: "flex-end"}} className={classes.boxButtons}>
                    <Tooltip title={`Clear ${label} / Send all to jury pool`}>
                        <IconButton onClick={async () => {
                            const confirmed = confirm(`Are you sure you want to empty the ${label} and send everyone to the jury pool?`);
                            if (confirmed) {
                                if (mode === 'jury-box') {
                                    dispatch(emptyJuryBox(caseId))
                                } else {
                                    dispatch(emptyAlternates(caseId))
                                }
                            }
                        }}>
                            <SystemUpdateAltIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Slide jurors over to fill empty seats"}>
                        <IconButton onClick={async () => {
                            if (mode === 'jury-box') {
                                dispatch(fillEmptySpaces(caseId));
                            } else {
                                dispatch(fillEmptyAlternateSpaces(caseId));
                            }
                        }}>
                            {reverseRow ? <LastPageIcon/> : <FirstPageIcon/>}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Fill from jury pool"}>
                        <IconButton onClick={async () => {
                            if (mode === 'jury-box') {
                                dispatch(fillFromPool(caseId));
                            } else {
                                dispatch(fillAlternatesFromPool(caseId));
                            }
                        }}>
                            <PublishIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <div id="jury-box-grid-container"
                style={{
                minHeight: (numRows * 143),
                display: 'flex',
                flexDirection: reverseColumn ? 'column-reverse' : 'column',
                overflowX: 'auto'
            }}>
                <FormGrid numCols={numCols}
                          numRows={numRows}
                          mode={mode}
                          caseId={caseId}
                          civilCase={civilCase}
                          demographicCategories={demographicCategories}
                          showDemographics={showDemographics}
                          multiSelect={multiSelect}
                          handleUpdateJuror={handleUpdateJuror}
                          reverseRow={reverseRow}
                          seatNumberFlow={seatNumberFlow}
                          getEmptyJurors={getEmptyJurors}
                          addEmptyJuror={addEmptyJuror}
                          juryBoxSize={mode === "jury-box" ? juryBoxSize : numberOfAlternates}/>
            </div>
        </Paper>
    );
};

export default JuryBox;
