import React from "react";
import {EditorState} from "draft-js";

export default function useEmojiSelector(editor, editorState, setEditorState, focusOn, focusAfter) {
    React.useEffect(() => {
        if (editor.current !== null && focusOn != null) {
            let content = editorState.getCurrentContent();
            let selection = editorState.getSelection();
            let numFound = 0;
            for (let [key, block] of content.getBlockMap().entries()) {
                if (block.getText().includes(focusOn)) {
                    numFound++;
                    if (numFound > focusAfter) {
                        // set the selection
                        selection = selection.merge({anchorKey: key, anchorOffset: 0, focusKey: key, focusOffset: block.getText().length});
                        let divSelector = `div[data-offset-key^="${key}"]`;
                        let div = document.querySelector(divSelector);
                        if (div && div.scrollIntoViewIfNeeded !== undefined) {
                            div.scrollIntoViewIfNeeded();
                        }
                        setEditorState(EditorState.forceSelection(editorState, selection));
                        break;
                    }
                }
            }
        }
    }, [editor.current, focusOn, focusAfter]);
}