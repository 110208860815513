import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getActiveDrag} from "../../state/ethereal/selectors";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {updateJurorSeat} from "../../state/asyncActions";
import {ActionCreators as EtherealActionCreators} from "../../state/ethereal/actions";

const useStyles = makeStyles((theme) => ({
    juryPoolDropSection: {
        minHeight: "250px",
        border: `3px solid transparent`,
        borderRadius: "4px",
        backgroundColor: theme.palette.shade.light,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    outline: {
        border: `3px solid ${theme.palette.secondary.light}`
    }
}));

export default function JuryPoolDropSection({caseId}) {
    const classes = useStyles();

    const [isHighlighted, setHighlighted] = React.useState(false);

    const dispatch = useDispatch();
    const activeDrag = useSelector(getActiveDrag);

    function handleDragEnter(e) {
        e.preventDefault();
        setHighlighted(true);
    }
    function handleDragOver(e) {
        e.preventDefault();
        if (!isHighlighted) {
            setHighlighted(true);
        }
    }
    function handleDragLeave(e) {
        e.preventDefault();
        setHighlighted(false);
    }
    function handleDrop(e) {
        dispatch(updateJurorSeat(caseId, activeDrag, null));
        dispatch(EtherealActionCreators.setDrag(null));
    }

    let classesToRender = [classes.juryPoolDropSection];
    if (isHighlighted) {
        classesToRender.push(classes.outline);
    }

    return (
        <div onDragEnter={handleDragEnter}
             onDragLeave={handleDragLeave}
             onDragOver={handleDragOver}
             onDrop={handleDrop}
             className={classNames(...classesToRender)}>
            <h4>Drop here to move the juror into the jury pool...</h4>
        </div>
    )
}