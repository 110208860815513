import axios from 'axios';
import {SyncClient} from 'twilio-sync';

let client = null;

let documentListeners = {};

export const initSync = async () => {
  try {
    const result = await axios.get('/api/twiliotoken/');
    if (result && result.data.token) {
      client = new SyncClient(result.data.token);
      client.on('tokenAboutToExpire', async function() {
        try {
          const result = await axios.get('/api/twiliotoken/');
          client.updateToken(result.data.token);
        }
        catch(error) {
          console.log("Failed to renew twilio token: ", error);
        }
      });
    }
  }
  catch(error) {
    console.log("Failed to get initial twilio token: ", error);
  }
}

export const getClient = async () => {
    if(client){
        return client;
    }
    else {
        await initSync();
        return client;
    }
}

export const resetClient = () => {
  client = null;
}

export const addDocumentListener = async (uniqueId, onUpdated) => {
  closeDocumentListener(uniqueId);
  let client = await getClient();
  let doc = await client.document(uniqueId);
  doc.on("updated", onUpdated);
  documentListeners[uniqueId] = doc;
  return doc;
}

export const closeDocumentListener = (uniqueId) => {
  if (documentListeners[uniqueId] && documentListeners[uniqueId].close && typeof documentListeners[uniqueId].close === 'function') {
    documentListeners[uniqueId].close();
    delete documentListeners[uniqueId];
  }
}
