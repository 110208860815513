import React, {useMemo} from "react";
import {indexEmoji} from "../../util/emoji";

const EmojiIndex = ({text = "", onClick, className=""}) => {
    const emojiIndex = useMemo(() => {
        return indexEmoji(text || "");
    }, [text]);

    if (emojiIndex.emojis.length === 0) {
        return null;
    }

    return (
        <div key={'emoji-index'} className={"emoji-index " + className}>
            {emojiIndex.emojis.map((entry, index) => {
                const handleClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (onClick) {
                        onClick(entry.emoji, entry.occurrence);
                    }
                }
                return (
                    <span key={`emoji-${index}`} className="emoji-item" onClick={handleClick}>{entry.emoji}</span>
                )
            })}
        </div>
    )
};

export default EmojiIndex;