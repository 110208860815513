import {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

export default function DropdownMenu({label, options, onChange, size="md"}) {
    const [open, setOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({left: 0, top: 0});
    const el = useRef(null);

    useEffect(() => {
        function clickListener(e) {
            let className = e?.target?.className;
            let isItem = false;
            if (typeof className === "string" && className.indexOf("dropdown-menu-item") >= 0 || e.target.closest(".dropdown-menu-item")) {
                isItem = true;
            }

            if (!isItem) {
                setOpen(false);
            }
        }

        if (open) {
            document.body.addEventListener('click', clickListener);

            let left = el.current.getBoundingClientRect().left;
            let top = el.current.getBoundingClientRect().top;

            const width = window.document.documentElement.clientWidth;
            const height = window.document.documentElement.clientHeight;

            const menuElem = document.getElementById("dropdown").children[0];

            let position = {
                left: left + window.scrollX,
                top: top + window.scrollY
            };

            if (left > width - menuElem.clientWidth) {
                position.left -= (left - (width -  menuElem.clientWidth) + 20);
            }

            if (top > height - menuElem.clientHeight) {
                let moveUp = (top - (height - menuElem.clientHeight)) + 20;
                position.top -= moveUp;
            }

            setDropdownPosition(position);

        }

        return () => {
            document.body.removeEventListener('click', clickListener);
        }
    }, [open]);

    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        setOpen(prev => {
            return !prev;
        });
    }

    function handleSelect(value) {
        onChange(value);
        setOpen(false);
    }

    return (
        <div className={`dropdown-menu dropdown-menu-${size}`} onClick={handleClick} ref={el}>
            {label}
            {open &&
                createPortal(
                    <ul className="dropdown-menu-items" key={'items'} style={dropdownPosition}>
                        {options.map((o, index) => {
                            return (
                                <DropdownMenuItem key={`item-${index}-${o.value}`}
                                                  disabled={o.disabled ?? false}
                                                  value={o.value}
                                                  onClick={handleSelect}>{o.label}</DropdownMenuItem>
                            );
                        })}
                    </ul>,
                    document.getElementById("dropdown")
                )
            }
        </div>
    );
}

export function DropdownMenuItem({value, onClick, disabled, children}) {
    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        console.log("Selected: ", value);
        onClick(value);

        return false;
    }

    return (
        <li className="dropdown-menu-item" aria-disabled={disabled} onClick={handleClick}>{children}</li>
    )
}