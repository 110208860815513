import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getScorecardDataForCase } from '../../state/selectors';
import {getMode} from "../../state/ethereal/selectors";

const useStyles = makeStyles((theme) => ({
  scoreBox: {
    userSelect: 'none',
  },
  scoreCard: {},
  scoreCardHeader: {
    background: theme.palette.shade.main,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 7,
    paddingRight: 7,
    textAlign: 'center'
  },
  scoreCardContent: {
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center'
  },
  circle: {
    borderRadius: theme.spacing(0.5),
    textAlign: 'center',
  },
  yellow: {
    backgroundColor: theme.palette.strikes.warning,
  },
  red: {
    backgroundColor: theme.palette.strikes.low,
  },
  scoreContainer: {
    display: 'flex',
    '& >div': {
      marginLeft: theme.spacing(1),
    },
  },
  scoreCause: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    justifyContent: 'space-between',
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.shade.main}`,
    margin: theme.spacing(0, 0.5),
  },
  title: {
    fontSize: '0.8rem',
    color: '#AAA',
    marginBottom: theme.spacing(0.5),
  },
  count: {
    fontSize: '1.2rem',
    padding: theme.spacing(0.25),
  },
}));

const ScoreBox = ({
  side,
  perempt,
  remaining,
  colorClass,
}) => {
  const classes = useStyles();
  const indicatorClass = classNames(classes.circle, colorClass);
  return (
    <Grid item>
      <Paper
        elevation={0}
        variant={'outlined'}
        className={classes.scoreBox}
      >
        <Box className={classes.scoreCardHeader}>
          <b>{side}</b>
        </Box>
        <Box className={classes.scoreCardContent}>
          <div className={classes.scoreCause}>
            <span className={classes.title}>Count</span>
            <span className={classes.count}>{perempt}</span>
          </div>
          <div className={classes.divider} />
          <div className={classes.scoreCause}>
            <span className={classes.title}>Remaining</span>
            <span
              className={classNames(indicatorClass, classes.count)}
            >
              {remaining}
            </span>
          </div>
        </Box>
      </Paper>
    </Grid>
  );
};

const SimpleBox = ({count, title}) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Paper
        elevation={0}
        variant={'outlined'}
        className={classes.scoreBox}
      >
        <Box className={classes.scoreCardHeader}>
          <b>{title}</b>
        </Box>
        <Box className={classes.scoreCardContent}>
          <div className={classes.scoreCause}>
            <span className={classes.title}>Count</span>
            <span className={classes.count}>{count}</span>
          </div>
        </Box>
      </Paper>
    </Grid>
  );
};

const ScoreCard = ({ caseId }) => {
  const classes = useStyles();

  const mode = useSelector(getMode);
  const {
    causeCount, hardshipCount, prosecutionPeremptCount, defensePeremptCount,
    prosecutionPeremptStrikes, defendantPeremptStrikes, civilCase
  } = useSelector(state => getScorecardDataForCase(state, {caseId, mode}));

  const prosecutionRemaining =
    prosecutionPeremptStrikes - prosecutionPeremptCount;
  const defenseRemaining =
    defendantPeremptStrikes - defensePeremptCount;

  const colorSelector = (numRemaining, total) => {
    if (numRemaining <= Math.floor(total / 3)) {
      return classes.red;
    } else if (numRemaining <= Math.floor((2 * total) / 3)) {
      return classes.yellow;
    }
  };

  const prosecutionColor = colorSelector(
    prosecutionRemaining,
    prosecutionPeremptStrikes,
  );
  const defenseColor = colorSelector(
    defenseRemaining,
    defendantPeremptStrikes,
  );

  return (
    <Grid className={classes.scoreContainer}>
      <ScoreBox
        side={civilCase ? 'Plaintiff' : 'Prosecution'}
        perempt={prosecutionPeremptCount}
        remaining={prosecutionRemaining}
        colorClass={prosecutionColor}
      />
      <ScoreBox
        side={'Defense'}
        perempt={defensePeremptCount}
        remaining={defenseRemaining}
        colorClass={defenseColor}
      />
      <SimpleBox
        count={causeCount} title={"Cause"}
      />
      <SimpleBox count={hardshipCount} title={"Hardship"} />
    </Grid>
  );
};

export default ScoreCard;
