export const findJurorBySeatNumber = (jurorsInfo, seatNum) => {
  if (jurorsInfo) {
    for (let i = 0; i < jurorsInfo.length; i++) {
      if (
        jurorsInfo[i].seatNumber === seatNum &&
        !jurorsInfo[i].discarded
      )
        return jurorsInfo[i];
    }
    return null;
  }
};

export const isJuryBoxEmpty = (jurorsInfo, juryBoxSize) => {
  if (jurorsInfo) {
    let seats = [...(new Array(juryBoxSize).keys())];
    for (let i = 0; i < jurorsInfo.length; i++) {
      if (
        seats.includes(jurorsInfo[i].seatNumber - 1)  &&
        !jurorsInfo[i].discarded
      )
        return false;
    }
  }
  return true;
};

export const isSeatFilledInJuryBox = (jurorsInfo, seatNumber, mode) => {
  let seatNumberAttributeName = mode === "jury-box" ? "seatNumber" : "alternateSeatNumber";
  //Loop through jurors to see if a non dismissed one has this seat number
  if (jurorsInfo) {
    for (let i = 0; i < jurorsInfo.length; i++) {
      if (
        jurorsInfo[i][seatNumberAttributeName] === seatNumber &&
        !jurorsInfo[i].discarded && !jurorsInfo[i].alternateDiscarded
      )
        return true;
    }
  }
  return false;
};

export const findLowestOpenSeatNumber = (jurorsInfo, size, mode) => {
  let seatNumberAttributeName = mode === "jury-box" ? "seatNumber" : "alternateSeatNumber";
  if (jurorsInfo) {
    let filledSeats = jurorsInfo.reduce((acc, juror) => {
      if (juror[seatNumberAttributeName] && !isDiscarded(juror)) {
        //console.log("Not discarded: ", juror);
        acc.push(juror[seatNumberAttributeName]);
      }
      return acc;
    }, []);
    
    console.log("Filled seats: ", {
      size,
      mode,
      filledSeats
    });

    for (let i = 0; i < size; i++) {
      if (filledSeats.indexOf(i + 1) === -1) {
        return i + 1;
      }
    }
  }
  return null;
};

export const isDiscarded = (juror) => {
  return juror.discarded || juror.alternateDiscarded;
};

export const isInPool = (juror) => {
  return !juror.discarded && !juror.alternateDiscarded &&
  (!juror.hasOwnProperty('seatNumber') ||
      juror.seatNumber === undefined ||
      juror.seatNumber === null) &&
  (!juror.hasOwnProperty("alternateSeatNumber") ||
      juror.alternateSeatNumber === undefined ||
      juror.alternateSeatNumber === null);
}

export const isLocalId = (id = '') => id.indexOf('-') >= 0;
