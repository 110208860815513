import {combineReducers} from 'redux';
import appReducer from "./reducer";
import multiSelectReducer from "./multiSelect/reducer";
import etherealReducer from "./ethereal/reducer";

let rootReducer = combineReducers({
  app: appReducer,
  multiSelect: multiSelectReducer,
  ethereal: etherealReducer
});

export default rootReducer;
