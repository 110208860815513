import React from "react";
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlined from '@material-ui/icons/RemoveCircleOutlined';
import {Tooltip} from '@material-ui/core';
import {removeSpacer} from '../../state/asyncActions';
import {useDispatch} from 'react-redux';
import {useViewBasedCardWidth} from "../../util/hooks";

export default ({caseId, spaceNumber}) => {

    const dispatch = useDispatch();
    const minimumWidth = useViewBasedCardWidth();

    const [showButtons, setShowButtons] = React.useState(false);

    function removeSpacerRequested(e) {
        e.preventDefault();
        dispatch(removeSpacer(caseId, spaceNumber));
    }

    return (
        <div className='jb-spacer'
             onMouseOver={() => {
                 setShowButtons(true);
             }}
             onMouseOut={() => {
                 setShowButtons(false);
             }}
             style={{
                 display: "flex", flex: 1,
                 flexDirection: "column",
                 minWidth: minimumWidth,
                 padding: 0, height: 135
             }}>
            <div style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: '#f5f5f5',
                marginRight: "0.5em",
                borderRadius: "4px",
                visibility: showButtons ? 'visible' : 'hidden'
            }}>
                <Tooltip title={"Remove gap"}>
                    <IconButton onClick={removeSpacerRequested}>
                        <RemoveCircleOutlined/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};
