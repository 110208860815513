import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle, faThumbsUp, faThumbsDown} from '@fortawesome/free-solid-svg-icons';
import {tableIcons} from "../../util/icons";
import {dismissReason} from "./constants";
import {Consumer} from "../../components/Case/CaseContext";
import DropdownMenu from "../../components/DropdownMenu";
import {useDispatch} from "react-redux";
import {dismissJuror} from "../../state/asyncActions";

const StatusMenu = ({
                        updateStatus,
                        status,
                        caseId,
                        jurorId,
                        mode,
                        onSwap,
                        size="md",
                        ...props
                    }) => {

    const dispatch = useDispatch();

    function handleChange(value) {
        if (value === status || value === "clear-status") {
            updateStatus(null);
        } else {
            if (typeof value === "string") {
                if (value.indexOf("dismiss-") >= 0) {
                    if (caseId && jurorId) {
                        dispatch(dismissJuror(caseId, jurorId, parseInt(value.replace("dismiss-", ""))));
                    }
                } else if (value.indexOf("move-") >= 0) {
                    if (onSwap) {
                        onSwap();
                    }
                } else {
                    updateStatus(value);
                }
            }
        }
    }

    return (
        <Consumer>
            {context => {

                let label = <tableIcons.More className={'status-menu-icon'}/>;
                switch (status) {
                    case 'keep':
                        label = <tableIcons.ThumbsUp className={'status-menu-icon thumbs-up-icon'}/>;
                        break;
                    case 'question':
                        label = <tableIcons.Question className={'status-menu-icon questions-icon'}/>;
                        break;
                    case 'dismiss':
                        label = <tableIcons.ThumbsDown className={'status-menu-icon thumbs-down-icon'}/>;
                        break;
                }

                let options = [];
                if (status) {
                    options.push({
                        value: "clear-status",
                        label: "Remove status"
                    });
                    options.push({
                        value: "",
                        label: (<hr className="divider" style={{width: "100%"}}/>),
                        disabled: true
                    });
                }

                options = options.concat([
                    {
                        value: 'keep',
                        label: (
                            <div className="status-menu-item">
                                <FontAwesomeIcon icon={faThumbsUp} className={"menu-icon"}/>
                                <span
                                    className={`status-menu-text${status === 'keep' ? ' active' : ''}`}>Intend to keep</span>
                            </div>
                        )
                    },
                    {
                        value: 'question',
                        label: (
                            <div className="status-menu-item">
                                <FontAwesomeIcon icon={faQuestionCircle} className={"menu-icon"}/>
                                <span className={`status-menu-text${status === 'question' ? ' active' : ''}`}>Question further</span>
                            </div>
                        )
                    },
                    {
                        value: 'dismiss',
                        label: (
                            <div className="status-menu-item">
                                <FontAwesomeIcon icon={faThumbsDown} className={"menu-icon"}/>
                                <span className={`status-menu-text${status === 'dismiss' ? ' active' : ''}`}>Intend to dismiss</span>
                            </div>
                        )
                    }
                ]);

                if (caseId && jurorId) {
                    options = options.concat([
                        {
                            value: "",
                            label: (
                                <div className="divider-with-text">
                                    <hr width="45px"/>
                                    &nbsp;Dismiss for&nbsp;
                                    <hr width="45px"/>
                                </div>
                            ),
                            disabled: true
                        },
                        {
                            value: "dismiss-" + dismissReason.ForHardship,
                            label: "Hardship"
                        },
                        {
                            value: "dismiss-" + dismissReason.ForCause,
                            label: "Cause"
                        },
                        {
                            value: "dismiss-" + dismissReason.ProsecutionPerempt,
                            label: "Prosecution Peremptory"
                        },
                        {
                            value: "dismiss-" + dismissReason.DefensePerempt,
                            label: "Defense Peremptory"
                        },
                        {
                            value: "dismiss-" + dismissReason.BothPerempt,
                            label: "Both Peremptory"
                        }
                    ]);
                }

                if (context.numberOfAlternates > 0) {
                    options.push({
                        value: "",
                        label: (<hr className="divider" style={{width: "100%"}}/> ),
                        disabled: true
                    })
                    options.push({
                        value: "move-",
                        label: "Move to " + (mode === 'jury-box' ? "Alternates" : "Jury Box")
                    });
                }

                return (
                    <div {...props}>
                        <DropdownMenu
                            size={size}
                            label={label}
                            options={options}
                            onChange={handleChange}
                        />
                    </div>
                );
            }}
        </Consumer>
    );
};

export default StatusMenu;
