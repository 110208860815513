import React, {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import UndoIcon from '@material-ui/icons/Undo';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import StatusMenu from '../StatusMenu';
import Tooltip from '@material-ui/core/Tooltip';
import {
    handleJurorCardClick,
    restoreJurorToBox,
    swapJurorToOther,
    updateJuror
} from '../../../state/asyncActions';
import {useDispatch, useSelector} from 'react-redux';
import {getJurorDemographics, getJurorError} from '../../../state/selectors';
import Alert from '@material-ui/lab/Alert';
import {ActionCreators} from '../../../state/actions';
import MultiSelectModes from "../../../state/multiSelect/modes";
import {Button} from '@material-ui/core';
import Display from '../../../components/RichTextEditor/Display';
import {CheckBoxOutlineBlank, CheckBoxOutlined} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import {ActionCreators as EtherealActionCreators} from '../../../state/ethereal/actions';
import EmojiIndex from "../EmojiIndex";

import useStyles from './JurorCard.styles.js';
import {getActiveDrag} from "../../../state/ethereal/selectors";
import {useViewBasedCardWidth} from "../../../util/hooks";

const JurorError = ({caseId, juror, jurorError}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    function handleClose(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(ActionCreators.removeJurorError(juror._id));
    }

    function handleRetry(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(updateJuror(caseId, juror._id, juror));
    }

    if (!jurorError) {
        return null;
    }

    return (
        <Alert key={`juror-error-${juror._id}`}
               className={classes.error}
               severity={'error'}
               onClose={handleClose}>
            {jurorError.error ? jurorError.error.toString() : 'An error occurred, please try again'}
            <Button color="inherit" size="small" onClick={handleRetry}>Retry</Button>
        </Alert>
    );
};

const JurorCard = ({
                       caseId,
                       demographicCategories,
                       showDemographics,
                       jurorId,
                       juror,
                       mode,
                       seatNumber,
                       type,
                       multiSelect
                   }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const activeDrag = useSelector(getActiveDrag);

    const minimumJurorCardWidth = useViewBasedCardWidth();

    const jurorError = useSelector((state) => getJurorError(state, {jurorId: juror ? juror._id : 0}));

    const handleClick = async () => {
        //console.log("Handling click...");
        dispatch(handleJurorCardClick(caseId, juror._id, seatNumber));
    };

    const handleEmojiClick = (emoji, occurrence) => {
        dispatch(ActionCreators.openJurorModal(caseId, juror._id, seatNumber, mode, emoji, occurrence));
    }

    const onStart = (e) => {
        dispatch(EtherealActionCreators.setDrag(jurorId));
        e.dataTransfer.setData('text/plain', jurorId);
    }

    const dragStop = (e) => {
        dispatch(EtherealActionCreators.setDrag(null));
    };

    const handleDrag = (e) => {
        const width = window.document.documentElement.clientWidth;
        const height = window.document.documentElement.clientHeight;

        const jbElem = document.getElementById("jury-box-grid-container");

        const pageElem = document.documentElement;

        if (e.pageX < 100) {
            jbElem.scroll({left: jbElem.scrollLeft - 2, behavior: "instant"});
        }

        if (e.pageX > width - 100) {
            jbElem.scroll({left: jbElem.scrollLeft + 2, behavior: "instant"});
        }

        if (e.pageY - pageElem.scrollTop < 100) {
            pageElem.scroll({top: pageElem.scrollTop - 2, behavior: "instant"});
        }

        if (e.pageY - pageElem.scrollTop > height - 100) {
            pageElem.scroll({top: pageElem.scrollTop + 2, behavior: "instant"});
        }

    };

    const restoreJuror = (e) => {
        e.stopPropagation();
        dispatch(restoreJurorToBox(caseId, juror._id, mode));
    };

    const updateStatus = (status) => {
        const newJurorInfo = {...juror, status: status};
        dispatch(updateJuror(caseId, juror._id, newJurorInfo));
    };

    const swapToOther = () => {
        dispatch(swapJurorToOther(caseId, juror._id, mode));
    }

    const getSymbol = () => {
        let symbol = '';
        if (juror.discarder === 'defense') {
            symbol = 'D';
        } else if (juror.discarder === 'prosecutor') {
            symbol = 'P';
        } else if (juror.discarder === "both") {
            symbol = 'B';
        }
        if (juror.discardedReason === 'cause') {
            symbol = 'C';
        }
        if (juror.discardedReason === 'hardship') {
            symbol = 'H';
        }
        return symbol;
    };

    let className = '';
    let symbol = '';
    if (type === 'juror') {
        className = classNames(classes.header, classes.juror);
    } else {
        className = classNames(classes.header, classes.dismissed);
        symbol = getSymbol();
    }

    if (juror) {
        let demographicsItems = '';
        if (juror.demographics) {

            demographicsItems = getJurorDemographics(juror, demographicCategories);

            demographicsItems = demographicsItems.map((demographic, index) => {
                return (
                    <Fragment key={demographic.name + '-' + index}>
                        <Tooltip key={demographic.name} title={demographic.name}>
                            <span>
                              {demographic.value}
                            </span>
                        </Tooltip>
                        {index < demographicsItems.length - 1 && ", "}
                    </Fragment>
                );
            });
        }
        let jurorID = '';
        if (juror.hasOwnProperty('nameAndId')) {
            jurorID = juror.nameAndId;
        } else if (juror.id) {
            jurorID = juror.id;
        }

        if (juror.discarded || juror.alternateDiscarded) {
            return (
                // Dismissed juror card
                <div style={{position: "relative"}} className="discarded-juror">
                    <Paper
                        elevation={3}
                        className={classNames(classes.paper, classes.handle)}
                        onClick={handleClick}
                    >
                        <Box display="flex" p={1} className={className}>
                            <Box flexGrow={1} className={classNames(classes.header, classes.idBox)}>
                                <b>{jurorID}</b>
                            </Box>
                            <Box className={classes.header}>
                                {symbol}
                            </Box>
                            <Box className={classes.header}>
                                <Tooltip title="Restore Juror">
                                    <IconButton
                                        style={{padding: 0, color: 'black'}}
                                        onClick={(e) => restoreJuror(e)}
                                    >
                                        <UndoIcon fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        {showDemographics &&
                            <Box className={classes.demographics}>
                                <Typography variant={"body1"}
                                            className={classes.demographicsText}>{demographicsItems}</Typography>
                            </Box>
                        }
                        <Box className={classes.content}>
                            <Display richTextStyles={juror.commentsStyles || []} text={juror.comments || ""}/>
                        </Box>
                        <JurorError caseId={caseId} juror={juror} jurorError={jurorError}/>
                    </Paper>
                    <EmojiIndex text={juror.comments || ""}
                                onClick={handleEmojiClick}
                                className={classes.emojiIndex}/>
                </div>
            );
        }

        let rightComponent = (
                <StatusMenu
                    updateStatus={updateStatus}
                    mode={mode}
                    onSwap={swapToOther}
                    caseId={caseId}
                    jurorId={jurorId}
                    size={"sm"}
                    status={juror.status ? juror.status : null}/>
            ),
            extraProps = {},
            cardStyles = {flex: 1, minWidth: minimumJurorCardWidth, backgroundColor: "transparent", position: "relative"};
        if (multiSelect && multiSelect.mode === MultiSelectModes.ON) {
            extraProps.onClick = handleClick;
            cardStyles.cursor = "pointer";
            if (multiSelect.selectedJurorIds.indexOf(juror._id) >= 0) {
                rightComponent = (
                    <CheckBoxOutlined style={{fontSize: 20, marginBottom: "-4px"}}/>
                );
            } else {
                rightComponent = (
                    <CheckBoxOutlineBlank style={{fontSize: 20, marginBottom: "-4px"}}/>
                );
            }
        }

        let paperClasses = [classes.paper];
        if (activeDrag === juror._id) {
            paperClasses.push(classes.outline);
        }

        let contentStyles = {flex: 1};
        if (activeDrag === juror._id) {
            contentStyles.display = "none";
        }

        return (
            // Populated juror card
            <div
                style={cardStyles}
                className="juror-card"
                draggable={!(multiSelect && multiSelect.mode === MultiSelectModes.ON)}
                onDragStart={onStart}
                onDragEnd={dragStop}
                onDrag={handleDrag}
                onClick={handleClick}
                id={juror._id}
                {...extraProps}
            >
                <Paper
                    elevation={3}
                    className={classNames(...paperClasses)}
                >
                    <Box display="flex" p={1} className={className}>
                        <Box flexGrow={1} className={classNames(classes.header, classes.idBox)}>
                            <div className={classNames(classes.handle, 'handle')}>
                                <Box className={classes.idBox}>
                                    <b>{jurorID}</b>
                                </Box>
                            </div>
                        </Box>
                        <Box className={classes.header}>
                            <div className={classNames(classes.handle, 'handle')}>
                                <b>{seatNumber}</b>
                            </div>
                        </Box>
                        <Box className={classes.header}>
                            {rightComponent}
                        </Box>
                    </Box>
                    <div className={classNames(classes.handle, 'handle')} style={contentStyles}>
                        {showDemographics &&
                            <Box className={classes.demographics}>
                                <Typography variant={"body1"}
                                            className={classes.demographicsText}>{demographicsItems}</Typography>
                            </Box>
                        }
                        <Box className={classes.content}>
                            <Display richTextStyles={juror.commentsStyles || []} text={juror.comments || ""}/>
                        </Box>
                    </div>
                    <JurorError caseId={caseId} juror={juror} jurorError={jurorError}/>
                </Paper>
                <EmojiIndex text={juror.comments || ""}
                            onClick={handleEmojiClick}
                            className={classes.emojiIndex}/>
            </div>
        );
    }
};

export default JurorCard;
