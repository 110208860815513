import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {getAlerts} from "../state/ethereal/selectors";
import MuiAlert from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import {ActionCreators as EtherealActions} from "../state/ethereal/actions";

function Alert({messageId, onClose, autoHideDuration, variant, message, ...props}) {
  const handleManualClose = (e) => {
    return handleClose(e, "manualClick");
  }
  
  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(e, messageId, reason);
  };
  
  return (
    <Snackbar open={true} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <MuiAlert elevation={6} variant="filled" severity={variant} {...props}>
        {message}
        <CancelIcon onClick={handleManualClose} fontSize={"small"} color={"inherit"} style={{cursor: "pointer", marginLeft: "10px", marginBottom: "-5px"}} />
      </MuiAlert>
    </Snackbar>
  );
}

export default () => {
  const alerts = useSelector(getAlerts);
  const dispatch = useDispatch();
  
  const handleClose = (e, messageId, reason) => {
    console.log("Closing for reason: ", {
      reason
    });
    
    dispatch(EtherealActions.removeAlert(messageId));
  }
  
  return (
    <div className={"toast-provider"}>
      {alerts.map((alert) => {
        return <Alert key={alert.id} autoHideDuration={alert.autoHideDuration || 3500} onClose={handleClose} messageId={alert.id} {...alert} />
      })}
    </div>
  );
};

