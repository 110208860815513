import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      border: '1px solid #BEBEBE',
      '&:hover': {
        border: '1px solid #5480B1'
      },
      '&:focus-within': {
        //border: '2px solid #5480B1',
        boxShadow: '0 0 0 2px #5480B1',
        border: '1px solid transparent'
      },
      position: 'relative',
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    displayContainer: {
      border: '1px solid #BEBEBE',
      position: 'relative',
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
    },
    displayEditor: {
      minHeight: '300px',
      maxHeight: '500px',
      overflowY: 'auto',
      paddingTop: theme.spacing(1),
      borderTop: '1px solid #BEBEBE',
      cursor: 'text',
      marginLeft: '5px',
      color: 'black',
    },
    editor: {
      minHeight: '300px',
      maxHeight: '500px',
      overflowY: 'auto',
      paddingTop: theme.spacing(1),
      borderTop: '1px solid #BEBEBE',
      cursor: 'text',
      marginLeft: '5px',
      color: 'black',
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      paddingBottom: theme.spacing(1)
    },
    titleContainer: {
      flex: 0
    },
    controls: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end"
    }
  };
});

export {
  useStyles
};
