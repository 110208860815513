import { makeStyles } from '@material-ui/core/styles';

export const formStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  outlinedRoot: {
    '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  inputLabel: {
    userSelect: 'none',
    marginLeft: 1,
  },
  comments: {
    margin: 0,
  },
  title: {
    '&.MuiDialogTitle-root ': {
      padding: theme.spacing(2, 3, 1),
    },
  },
  actions: {
    '&.MuiDialogActions-root': {
      padding: theme.spacing(2, 3),
    },
  },
  gridStartIcon: {
    height: "36px",
    marginTop: "5px"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  flowLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center'
  },
  flowLabelText: {
    fontSize: "0.9em",
    fontWeight: 400,
    marginTop: '5px',
    marginLeft: '1em',
    color: "rgba(0,0,0,0.54)"
  }
}));
