import React, { useEffect } from "react";
import { useDispatch, useSelector} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Cancel from '@material-ui/icons/Cancel';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CheckIcon from '@material-ui/icons/Check';
import {getAll} from "../../state/multiSelect/selectors";
import { ActionCreators } from '../../state/multiSelect/actions';
import Modes from "../../state/multiSelect/modes";

export default (props) => {
  const dispatch = useDispatch();
  const multiSelect = useSelector(getAll);

  function turnOn() {
    dispatch(ActionCreators.setMode(Modes.ON))
  }
  function turnOff() {
    dispatch(ActionCreators.setMode(Modes.OFF));
  }
  function startCommentMode() {
    dispatch(ActionCreators.setMode(Modes.COMPOSING_COMMENT));
  }

  useEffect(() => {
    return () => {
      turnOff();
    }
  }, []);

  let title = "Multiple juror edit",
    icon = <FilterNoneIcon />;
  if (multiSelect.mode === Modes.ON) {
    title = "Cancel selection";
    icon = <Cancel />;
  }

  return (
    <div {...props} key={'multi-select-action-widget'}>
      <Tooltip title={title}>
        <IconButton
        onClick={() => {
          if (multiSelect.mode === Modes.OFF) {
            turnOn();
          }
          else {
            turnOff();
          }
        }}>
          {icon}
        </IconButton>
      </Tooltip>
      {multiSelect.mode === Modes.ON &&
        <React.Fragment>
          {/* All actions; just comment right now */}
          <Tooltip title={"Confirm selection"}>
            <span>
              <IconButton onClick={startCommentMode} disabled={multiSelect.selectedJurorIds.length === 0}>
                <CheckIcon />
              </IconButton>
            </span>
          </Tooltip>
        </React.Fragment>
      }
    </div>
  )
}
