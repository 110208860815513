import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close, Delete } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress
} from '@material-ui/core';
import React from 'react';
import { formStyles } from '../../util/styles';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useAlert } from '../../util/alertDialogContext';


const ShareModal = ({open, onClose, caseId, caseName}) => {
  const classes = formStyles();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [shares, setShares] = React.useState([]);

  const [email, setEmail] = React.useState("");
  const [accessLevel, setAccessLevel] = React.useState("edit");

  const { alert } = useAlert();

  React.useEffect(() => {
    setEmail("");
    setAccessLevel("edit");
    setError(null);
    setShares([]);
    if (open) {
      getShares(caseId);
    }
    else {
      setLoading(false);
    }
  }, [caseId, open]);

  const getShares = async (caseId) => {
    setLoading(true);

    try {
      let response = await axios.get(`/api/share/case/${caseId}`);
      if (response.status === 200) {
        setShares(response.data.shares);
      }
      else {
        console.warn("Unexpected response from server.")
        setError(new Error("Unexpected response from server."));
      }

      setLoading(false);
    }
    catch(error) {
      console.error("Failed to get shares for case: " + caseId, error);
      setError(error);
      setLoading(false);
    }
  }

  const handleClose = () => {
    onClose();
  };

  const handleShare = async (e) => {

    if (!email || email.indexOf("@") === -1) {
      setError(new Error("You must provide a valid email address."));
      return;
    }

    try {
      setError(null);
      setLoading(true);

      let response = await axios.post(`/api/share/case/${caseId}`,
        {
          email,
          accessLevel
        }
      );

      if (response && response.data) {
        let tmpShares = [...shares];
        tmpShares.push({
          ...response.data
        });

        setShares(tmpShares);
        setEmail("");
        setAccessLevel("edit");

        gtag("event", "share", {
          method: "email",
          content_type: "case",
          item_id: caseId
        });
      }

    }
    catch(error) {
      let errorProcessed = false;
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          setError(new Error(error.response.data.error));
          errorProcessed = true;
        }
      }

      if (!errorProcessed) {
        setError(error);
      }
    }
    setLoading(false);
  };

  const removeShare = async (shareId) => {
    try {
      setError(null);
      setLoading(true);

      let response = await axios.delete(`/api/share/case/${caseId}/${shareId}`);

      if (response && response.data) {
        let tmpShares = [...shares];
        tmpShares = tmpShares.filter((oneShare) => oneShare._id !== shareId);
        setShares(tmpShares);
      }

    }
    catch(error) {
      let errorProcessed = false;
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          setError(new Error(error.response.data.error));
          errorProcessed = true;
        }
      }

      if (!errorProcessed) {
        setError(error);
      }
    }
    setLoading(false);
  }

  let progressStyles = {visibility: 'hidden'};
  if (loading) {
    progressStyles.visibility = 'visible';
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth={true}>
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" style={{marginRight: "25px"}}>Share {caseName ? `"${caseName}"` : ''}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        {error !== null &&
        <Alert severity={'error'}
               onClose={() => {
                 setError(null);
               }}
               key={'share-list-error'}>{error.toString().replace("Error: ", "")}</Alert>
        }
        <LinearProgress variant={"indeterminate"} color={"primary"} style={progressStyles} />

        {shares && shares.length > 0 &&
          <React.Fragment>
            <Typography variant={"body2"} color={"textSecondary"}>Shared with {shares.length} {shares.length === 1 ? 'person' : 'people'}</Typography>
          <ul key={'share-list'} style={{listStyle: 'none', paddingLeft: '0'}}>
            {
              shares.map((share) => {
                return (
                  <li key={`share-${share._id}`}>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={10}>
                        {share.email}
                      </Grid>
                      <Grid item xs={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton size="small" onClick={(e) => {
                          e.preventDefault();

                          alert({
                            msg: `Are you sure you want to stop sharing this case with '${share.email}'?`,
                            onSubmit: () => removeShare(share._id),
                            variant: 'danger',
                          });

                        }}><Delete /></IconButton>
                      </Grid>
                    </Grid>
                  </li>
                )
              })
            }
          </ul>
          </React.Fragment>
        }
        {!loading && shares.length === 0 &&
          <Typography variant={"body2"} color={"textSecondary"}>This case has not been shared with anyone yet.</Typography>
        }

        <hr style={{marginTop: '15px', marginBottom: '15px'}} />

        {/* New share form */}
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography variant={"body1"}>Add a user to share the case with:</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              label="Email address"
              autoFocus
              value={email}
              onKeyPress={(e) => {
                e.persist();
                if (e.key === 'Enter') {
                  handleShare(e);
                }
              }}
              onChange={(event) => setEmail(event.target.value)}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
        </Grid>
      </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleShare}
          color="primary"
          variant="contained"
        >
          Add
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ShareModal;
