import React, { useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { formStyles } from '../../../util/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { FormControl, FormLabel } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CreatableGroupSelect from '../CreatableGroupSelect';
import axios from 'axios';

const AddUserModal = ({ open, onClose }) => {
  const classes = formStyles();
  const [userInfo, setUserInfo] = useState({});
  const [errors, setErrors] = useState([]);

  const handleClose = () => {
    setUserInfo({});
    setErrors([]);
    onClose(false);
  };

  const handleSubmit = async () => {
    let errorList = [];

    if (!userInfo.name) {
      errorList.push('Name must be populated');
    }

    if (!userInfo.email || !userInfo.email.includes('@')) {
      errorList.push('Email is not valid');
    }

    if (!userInfo.groupName) {
      errorList.push('User must be part of a group');
    }

    if (errorList.length > 0) {
      setErrors(errorList);
    } else {
      try {
        const createUserBody = {
          name: userInfo.name,
          email: userInfo.email,
          groups: [userInfo.groupID],
          role: userInfo.role,
        };
        const res = await axios.post('/api/user', createUserBody);

        if (res.data.error) {
          setErrors([res.data.error]);
        } else {
          setUserInfo({});
          onClose(true);
          setErrors([]);
        }
      } catch (error) {
        setErrors(["Failed to add user. Likely caused by bad email address."])
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.form}
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Add User</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <Collapse in={errors && errors.length > 0}>
          <Alert severity="error" className={classes.alert}>
            Add Failed:
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              label="Name"
              autoFocus
              value={userInfo.name}
              onChange={(event) =>
                setUserInfo({
                  ...userInfo,
                  name: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={userInfo.email}
              onChange={(event) =>
                setUserInfo({
                  ...userInfo,
                  email: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component={'legend'}>Group</FormLabel>
            <CreatableGroupSelect
              create={true}
              value={{
                label: userInfo.groupName,
                value: userInfo.groupID,
              }}
              onChange={(group) => {
                setUserInfo({
                  ...userInfo,
                  groupName: group.label,
                  groupID: group.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>User Role</FormLabel>
              <RadioGroup
                value={userInfo.role ? userInfo.role : 'User'}
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    role: e.target.value,
                  });
                }}
              >
                <Grid container>
                  <Grid item>
                    <FormControlLabel
                      control={<Radio />}
                      value={'User'}
                      label={'User'}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Radio />}
                      value={'Admin'}
                      label={'Admin'}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
