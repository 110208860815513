import React, { createContext } from 'react';
import { useLocalStorage } from './hooks';
import { useDispatch } from 'react-redux';
import { ActionCreators } from '../state/actions';
import { createSession } from '../state/asyncActions';
import {hashText} from "./hash";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useLocalStorage(
    false,
    'isAuthenticated',
  );
  const [data, setData] = useLocalStorage({}, 'user');
  const [authDate, setAuthDate] = useLocalStorage(
    '',
    'auth-expiration',
  );

  const dispatch = useDispatch();

  return (
    <AuthContext.Provider
      value={{
        data,
        isAuthenticated,
        authDate,
        setData,
        createSession: async (data, expires) => {
          console.log("Creating session...");
          setData(data);
          setAuthenticated(true);
          setAuthDate(expires);
          dispatch(createSession(data, expires));

          let personData = {
              id: await hashText("SHA-1", data.email),
              username: data.name,
              email: data.email
          };
          if (window.Rollbar?.configure) {
              window.Rollbar.configure({
                  payload: {
                      person: personData
                  }
              })
          }
          else {
              console.warn("Rollbar not available!");
          }
        },
        updateSession: (updates, cb) => {
          let copy = {...data};
          for (let name of Object.keys(updates)) {
            copy[name] = updates[name];
          }

          console.log("Setting user data: ", copy);
          setData(copy);
        },
        removeSession: (clearAllData=false) => {
          setData({});
          setAuthenticated(false);
          setAuthDate('');
          dispatch(ActionCreators.sessionEnded(clearAllData));
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
