import React, { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Close from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Refresh from "@material-ui/icons/Refresh";
import Error from '@material-ui/icons/Error';
import EmailIcon from '@material-ui/icons/Email';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyOutlined from "@material-ui/icons/FileCopy";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { Share } from '@material-ui/icons';

export const tableIcons = {
  More: forwardRef((props, ref) => <MoreVertIcon {...props} ref={ref} />),
  Question: forwardRef((props, ref) => <FontAwesomeIcon icon={faQuestionCircle} {...props} ref={ref} />),
  ThumbsUp: forwardRef((props, ref) => <FontAwesomeIcon icon={faThumbsUp} {...props} ref={ref} />),
  ThumbsDown: forwardRef((props, ref) => <FontAwesomeIcon icon={faThumbsDown} {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  Share: forwardRef((props, ref) => <Share {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  DismissJuror: forwardRef((props, ref) => (
    <Close {...props} ref={ref} />
  )),
  Clone: forwardRef((props, ref) => (
    <FileCopyOutlined {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Email: forwardRef((props, ref) => <EmailIcon {...props} ref={ref} />),
  Error: forwardRef((props, ref) => <Error {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} ref={ref} />
  )),
  MoveToJuryBox: forwardRef((props, ref) => (
    <Check {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
  Refresh: forwardRef((props, ref) => (
    <Refresh {...props} ref={ref} />
  )),
  Selected: forwardRef((props, ref) => (
    <CheckBoxOutlined {...props} ref={ref} />
  )),
  NotSelected: forwardRef((props, ref) => (
    <CheckBoxOutlineBlank {...props} ref={ref} />
  ))
};
