import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  accountInfo: {
    padding: '12px',
    display: 'inline-block'
  },
  title: {
    padding: '8px',
    paddingLeft: '12px',
    fontSize: 20,
  },
  infoTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  accountInfoTable: {
    borderCollapse: 'collapse',
    '& td, th': {
      border: '1px solid lightgray',
      padding: '5px 10px',
    },
  },
}));

const AccountInfo = ({ user, onEditOpen, children }) => {
  const classes = useStyles();

  return (
    <>
      {user && (
        <Paper className={classes.accountInfo} elevation={2}>
          <div className={classes.infoTitle}>
            <Typography
              align="left"
              variant="h5"
              className={classes.title}
            >
              Account Info
            </Typography>
            <Tooltip title="Edit account details">
              <IconButton
                onClick={onEditOpen}
                className={classes.editButton}
                size="small"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
          <Grid container spacing={0}>
            <table className={classes.accountInfoTable}>
              <tbody>
                <tr>
                  <td><b>Name</b></td>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td><b>Organization</b></td>
                  <td>{user.companyName}</td>
                </tr>
                <tr>
                  <td><b>Group</b></td>
                  <td>{user.groupName}</td>
                </tr>
                <tr>
                  <td><b>Role</b></td>
                  <td>{user.role || 'User'}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          {children}
        </Paper>
      )}
    </>
  );
};

export default AccountInfo;
