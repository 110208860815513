import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormFields } from '../../util/hooks';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import { formStyles } from '../../util/styles';
import Logo from '../../util/Logo';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  logo: {
    marginTop: '-50px',
    padding: theme.spacing(0, 4, 4),
  },
}));

const ResetPassword = (props) => {
  const classes = formStyles();
  const baseClasses = useStyles();
  const [fields, handleFieldChange] = useFormFields({
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [error, setError] = useState("");
	const [hasSuccess, setHasSuccess] = useState(false);
	const [validLink, setValidLink] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
		if (validLink) {
			try {
				let res = await axios.post('/api/resetpassword', {
					resetPasswordToken: props.token,
					password: fields.password
				});
				if (res && res.data.error){
					setHasErrors(true);
					setError(res.data.error);
					setHasSuccess(false);
				} else {
					setHasSuccess(true);
					setHasErrors(false);
				}
			} catch (error) {
				console.log(error);
				setHasErrors(true);
				let res = error.response;
				if (res && res.data && res.data.error) {
					setError(res.data.error);
				}
				else {
					setError(error.message);
				}
				setHasSuccess(false);
			}
		}
	}

  useEffect(() => {
		//Check if token has expired and validLink to false if not
		const checkToken = async () => {
			try {
				let res = await axios.post('/api/tokenvalid', {
					resetPasswordToken: props.token,
				});
				if (res && res.data.error){
					console.log(res.data.error);
					setValidLink(false);
				}
			}
			catch(error) {
				console.log(error);
				setValidLink(false);
			}
		}
		checkToken();
  }, [props.token]);

	if(validLink){
		return (
			<Container className={baseClasses.root} component="main" maxWidth="xs">
				<CssBaseline />
				<div className={baseClasses.logo}>
					<Logo />
				</div>
				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit}
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography align='center' variant="h4">
								Reset Password
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography align='center' variant="body2">
								Enter your new password to reset
							</Typography>
						</Grid>
						<Collapse in={hasErrors}>
							<Alert severity="error">
								{error}
							</Alert>
						</Collapse>
						<Collapse in={hasSuccess}>
							<Alert severity="success">
								Your password has been reset.&nbsp;
								<Link href="/signin" variant="body2">
									Click here to signin.
								</Link>
							</Alert>
          	</Collapse>
						<Grid item xs={12}>
							<Typography
								variant="overline"
								className={classes.inputLabel}
							>
								Password
							</Typography>
							<TextField
								variant="outlined"
								fullWidth
								name="password"
								type={showPassword ? 'text' : 'password'}
								id="password"
								value={fields.password}
								onChange={handleFieldChange}
								InputProps={{
									classes: {
										root: classes.outlinedRoot,
									},
									endAdornment: (
										<>
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={() => setShowPassword(!showPassword)}
													onMouseDown={(event) =>
														event.preventDefault()
													}
												>
													{showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										</>
									),
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								size="large"
								className={classes.submit}
							>
								Submit new password
							</Button>
						</Grid>
					</Grid>
				</form>
			</Container>
		);
	} else {
		return <h1 align='center'>Link no longer valid</h1>
	}

};

export default ResetPassword;
