import React from 'react';
import {
  Editor,
  EditorState,
  convertToRaw,
  getDefaultKeyBinding
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { colorStyleMap } from './renderUtils';
import ColorControls from './ColorControls';
import { fromDraftJsToJuryBox } from './Transformer';
import useCustomEditorFormat from './useCustomEditorFormat';
import {useStyles} from './styles';
import useEmojiSelector from "../../hooks/useEmojiSelector";

const clickFlag = {
  active: false
};

export default React.forwardRef(({ label, richTextStyles, text, onSave, autoFocus, focusOn= null, focusAfter = 0 }, ref) => {

  const classes = useStyles();

  const [editorState, setEditorState] = useCustomEditorFormat(richTextStyles, text);

  const editor = React.useRef(null);

  function markAsClick() {
    clickFlag.active = true;
    setTimeout(() => {
      clickFlag.active = false;
    }, 700);
  }

  function focusEditor(e) {
    if (editor && editor.current) {
      editor.current.focus();
    }
  }

  React.useEffect(() => {
    if (autoFocus && text !== "") {
      setTimeout(() => {
        focusEditor(null);
      }, 0);
    }
  }, [autoFocus, text]);

  function handleFocus(e) {
    if (!clickFlag.active) {
      e.persist();
      setTimeout(() => {
        let newState = EditorState.moveFocusToEnd(editorState);
        setEditorState(newState);
      }, 1);
    }
  }

  function getContent() {
    let raw = convertToRaw(editorState.getCurrentContent());
    //console.log("Raw: ", raw);
    return fromDraftJsToJuryBox(raw);
  }

  React.useImperativeHandle(ref, () => {
    return {
      getContent,
      focusEditor,
    };
  });

  function keyBindingFn(e) {
    if (e.shiftKey && e.key === "Enter") {
      return 'rte-save-contents';
    }
    return getDefaultKeyBinding(e);
  }

  function handleKeyCommand(command) {
    if (command === "rte-save-contents") {
      onSave();
      return 'handled';
    }
    return 'not-handled'
  }

  useEmojiSelector(editor, editorState, setEditorState, focusOn, focusAfter);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          {label}
        </div>
        <div className={classes.controls}>
          <ColorControls
            editorState={editorState}
            onChange={setEditorState}
          />
        </div>
      </div>
      <div className={classes.editor} onMouseDown={markAsClick} onClick={focusEditor}>
        <Editor ref={editor}
                customStyleMap={colorStyleMap}
                editorState={editorState}
                onChange={setEditorState}
                onFocus={handleFocus}
                handleKeyCommand={handleKeyCommand}
                keyBindingFn={keyBindingFn}
        />
      </div>
    </div>
  );
});
