import {createContext} from "react";

const CaseContext = createContext({
    id: "",
    name: "",
    number: "",
    civilCase: false
});

export const Provider = CaseContext.Provider;
export const Consumer = CaseContext.Consumer;