//import "./wdyr";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';

import {isChrome, isChromium, isEdge, isOpera, browserName, fullBrowserVersion, isFirefox} from "react-device-detect";
import Logo from "./util/Logo";

let isSupported = isChrome || isChromium || isOpera || isFirefox || isEdge || browserName === "Brave";

ReactDOM.render(
    <div id="page-handle">
        {isSupported ? <App/> : (
            <div className="not-supported">
                <div style={{margin: "20px auto", maxWidth: "225px"}}>
                    <Logo/>
                </div>
                <p>Jurybox does not currently support your browser, {browserName} ({fullBrowserVersion}).</p>
                <p>
                    Please use the latest version
                    of <a href={"https://www.google.com/chrome/"}>Chrome</a>, &nbsp;
                    <a href={"https://www.microsoft.com/en-us/edge/download"}>Edge</a>,&nbsp;
                    <a href={"https://brave.com/"}>Brave</a>, or &nbsp;
                    <a href={"https://en.wikipedia.org/wiki/Chromium_(web_browser)"}>almost any Chromium based
                        browser</a>
                    &nbsp; to use this app.
                </p>
                <p>
                    <a href={"https://www.mozilla.org/en-US/firefox/new/"}>Firefox</a> is also partially
                    supported.
                </p>
            </div>
        )}
    </div>,
    document.getElementById('root'),
);
