import React from "react";
import {Consumer} from "../../components/Case/CaseContext";
import {Tooltip} from "@material-ui/core";
import {tableIcons} from "../../util/icons";
import DropdownMenu from "../../components/DropdownMenu";
import {dismissReason} from "./constants";

const DismissMenu = ({current, onSelected}) => {

    function handleChange(name) {
        let dismissedReason = name;
        if (dismissedReason === "undo") {
            dismissedReason = '';
        }
        onSelected(dismissedReason);
    }

    return (
        <Consumer>
            {caseContext => {

                let options = [
                    {
                        value: dismissReason.ForHardship,
                        label: 'Hardship'
                    },
                    {
                        value: dismissReason.ForCause,
                        label: 'Cause'
                    },
                    {
                        value: dismissReason.ProsecutionPerempt,
                        label: (caseContext.civilCase ? 'Plaintiff' : 'Prosecution') + " Peremptory"
                    },
                    {
                        value: dismissReason.DefensePerempt,
                        label: 'Defense Peremptory'
                    },
                    {
                        value: dismissReason.BothPerempt,
                        label: 'Both Peremptory'
                    }
                ];

                if (current) {
                    options.push({
                        value: '',
                        label: <hr className="divider" />,
                        disabled: true
                    });
                    options.push({
                        value: 'undo',
                        label: 'Undo dismiss'
                    })
                }

                return (
                    <DropdownMenu
                        label={
                            <Tooltip title={"Dismiss"}>
                                <tableIcons.DismissJuror color='inherit'
                                                         style={{padding: 0, marginTop: 0}}/>
                            </Tooltip>
                        }
                        options={options}
                        onChange={handleChange}
                    />
                );
            }}
        </Consumer>
    );
};

export default DismissMenu;