import React, {useEffect} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {ActionCreators} from '../../state/actions';
import {Toc} from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import JuryPool from './JuryPool';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {selectBasicCaseInfo} from '../../state/selectors';
import {navigate} from "@reach/router";
import JuryBox from './JuryBox';
import DismissedArea from './DismissedBox';
import useStyles from './Case.styles';
import BasePage from "./BasePage";


export default ({caseId}) => {
    //console.log('Rendering case...', caseId);
    const classes = useStyles();
    const spacing = 1;

    const {caseInfo, caseModalOpen} = useSelector((state) => {
        return selectBasicCaseInfo(state, { caseId: caseId });
    }, shallowEqual);

    const dispatch = useDispatch();

    const handleJurorEdit = React.useCallback((jurorId, emoji, occurrence) => {
        dispatch(ActionCreators.openJurorModal(caseId, jurorId, null, "jury-box", emoji, occurrence));
    }, [caseId]);

    return (
        <BasePage caseId={caseId}
                  topNav={
            <Tooltip title="Data view">
                <IconButton
                    onClick={() => {
                        navigate(`/case/${caseId}/data`)
                    }}
                    size="small">
                    <Toc className={classes.actionButton}/>
                </IconButton>
            </Tooltip>
        }>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.juryBoxGridContainer}>
                    <JuryBox caseId={caseId}
                             key={"jury-box"}
                             civilCase={caseInfo.civilCase}
                             jurorsPerRow={caseInfo.jurorsPerRow || 6}
                             seatNumberStartPosition={caseInfo.seatNumberStartPosition || "topLeft"}
                             seatNumberFlow={caseInfo.seatNumberFlow || "standard"}
                             demographicCategories={caseInfo.demographicCategories}
                             showDemographics={caseInfo.showDemographics || false}
                             spacing={spacing}/>
                </Grid>
                <Grid item xs={12} className={classes.dismissedBoxGridContainer}>
                    <DismissedArea key={"dismissed-area"}
                                  caseId={caseId}
                                  civilCase={caseInfo.civilCase}
                                  demographicCategories={caseInfo.demographicCategories}
                                  showDemographics={caseInfo.showDemographics || false}/>

                </Grid>
                <Grid item xs={12} className={classes.juryPoolGridContainer}>

                    <JuryPool caseId={caseId}
                              key={"jury-pool"}
                              civilCase={caseInfo.civilCase}
                              demographicCategories={caseInfo.demographicCategories}
                              showDemographics={caseInfo.showDemographics || false}
                              onOpenEdit={handleJurorEdit}/>

                </Grid>
            </Grid>
        </BasePage>
    );
}
