import React from 'react';
import {Router} from '@reach/router';
import Page from '../Page';
import Signin from '../../pages/Signin';
import Signup from '../../pages/Signup';
import Case from '../../pages/Case';
import DataView from "../../pages/Case/DataView";
import CaseList from '../../pages/CaseList';
import NotFound from '../../pages/NotFound';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import ExpiredAccount from '../../pages/ExpiredAccount';
import * as guards from '../../util/guards';
import Payment from '../../pages/Payment';
import Account from '../../pages/Account';

const Routes = () => {
    return (
        <Router>
            <Page component={NotFound} default/>
            <Page
                path="/signin"
                guard={guards.unauthenticated}
                component={Signin}
            />
            <Page
                path="/signup"
                guard={guards.unauthenticated}
                component={Signup}
            />
            <Page
                path="/signup/:token"
                guard={guards.unauthenticated}
                component={Signup}
            />
            <Page
                path="/subscribe"
                guard={guards.profileSet}
                component={Payment}
            />
            <Page
                path="/"
                guard={guards.activeSubscription}
                component={CaseList}
            />
            <Page
                path="/case/:id"
                guard={guards.activeSubscription}
                component={Case}
            />
            <Page
                path="/case/:id/data"
                guard={guards.activeSubscription}
                component={DataView}
            />
            <Page
                path="/expired"
                component={ExpiredAccount}
            />
            <Page path="/forgotpassword" component={ForgotPassword}/>
            <Page path="/resetpassword/:token" component={ResetPassword}/>
            <Page
                path="/account"
                guard={guards.profileSet}
                component={Account}
            />
        </Router>
    );
};

export default Routes;
