import nanoid from 'nanoid';

/**
 * @param juryBoxData :: {comments: "", richTextStyles: [{offset: 0, length: 5, styles: ["HIGHLIGHT_BLUE"]}]}
 * @param hasEllipsis :: string
 */
export const fromJuryBoxToDraftJs = (juryBoxData, hasEllipsis=false) => {
  let lines = juryBoxData.comments.split("\n");
  let lastLineIndex = lines.length - 1;
  let lineIndex = 0;
  let lengthProcessed = 0,
    leftoverStyles = juryBoxData.richTextStyles.map(oneRichTextStyle => ({...oneRichTextStyle}));

  let blocks = lines.map((line) => {

    let inlineStyleRanges = [];
    leftoverStyles.forEach((instance, index) => {
      if (instance.offset >= lengthProcessed &&
        instance.offset <= (lengthProcessed + line.length)) {

        let internalOffset = instance.offset - lengthProcessed;

        let leftoversLength = 0;
        let styleLength = instance.length;
        if (instance.length > (line.length - internalOffset)) {
          leftoversLength = instance.length - (line.length - internalOffset);
          styleLength = line.length - internalOffset;
        }
        inlineStyleRanges.push({
          offset: instance.offset - lengthProcessed,
          length: styleLength,
          style: instance.styles.join(" ")
        });

        if (leftoversLength > 0)  {
          leftoverStyles[index].offset = lengthProcessed + line.length + 1;
          leftoverStyles[index].length = leftoversLength - 1;
        }
      }
    });

    if (inlineStyleRanges.length > 0) {
      if (hasEllipsis && lastLineIndex === lineIndex) {
        // Remove the styles from the ellipsis
        let ellipsisOffset = line.length - 3;
        inlineStyleRanges = inlineStyleRanges.filter((oneInlineStyleRange) => {
          // if the style starts at or after the ellipsis offset, remove the styles completely
          return oneInlineStyleRange.offset < ellipsisOffset;
        }).map((oneInlineStyleRange) => {
          // If the style starts before the ellipsis offset but goes over the end of the truncated string, set the styles length to just before the ellipsis.
          if (oneInlineStyleRange.offset + oneInlineStyleRange.length >= ellipsisOffset) {
            oneInlineStyleRange.length = ellipsisOffset - oneInlineStyleRange.offset;
          }
          return oneInlineStyleRange;
        });
      }
    }

    lineIndex++;
    lengthProcessed += line.length + 1; // Got to take that \n into account
    return {
      key: nanoid(4),
      type: "unstyled",
      text: line,
      inlineStyleRanges
    }
  });

  return {
    blocks,
    entityMap: []
  }
};

/**
 * @param draftJs :: {blocks: [{key: "", type: "unstyled", text: "", depth: 0, inlineStyleRanges: [{offset: 0, length: 5, style: "HIGHLIGHT_BLUE"}]}], entityMap: []}
 */
export const fromDraftJsToJuryBox = (draftJs) => {
  let comments = "";
  let richTextStyles = [];
  let baseOffset = 0;

  draftJs.blocks.forEach((block, index) => {

    comments += block.text;
    if (index < draftJs.blocks.length - 1) {
      comments += "\n";
    }

    block.inlineStyleRanges.sort((a, b) => a.offset - b.offset).forEach((inlineStyleRange) => {
      richTextStyles.push({
        offset: inlineStyleRange.offset + baseOffset,
        length: inlineStyleRange.length,
        styles: inlineStyleRange.style.split(" ")
      })
    });

    baseOffset += (block.text.length + 1);
  });

  // Condense those styles down to the least number of styles
  let condensedStyles = [],
    lastStyles = null,
    lastIndex = -1;
  for (let oneStyle of richTextStyles) {
    let addItIn = true;

    if (lastStyles) {
      if ((lastStyles.offset + lastStyles.length + 1) === oneStyle.offset &&
        arrayEquals(oneStyle.styles, lastStyles.styles)) {
        addItIn = false;
        // Modify the last one, increasing the length
        condensedStyles[lastIndex].length += (oneStyle.length + 1);
      }
    }

    if (addItIn) {
      condensedStyles.push({...oneStyle});
      lastIndex++;
    }

    lastStyles = oneStyle;
  }

  return {
    comments,
    richTextStyles: condensedStyles
  };
};

function arrayEquals(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}
