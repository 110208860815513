import Routes from '../Routes';
import React from 'react';
import {useDispatch} from 'react-redux';
import {sendPendingData} from '../../state/asyncActions';
import connectionStatus, {NOT_AUTHORIZED, SERVER_REACHABLE} from '../../connectionStatus';
import axios from 'axios';
import {ActionCreators} from '../../state/actions';
import {API_ENDPOINT} from '../../util/constants';

axios.defaults.timeout = 25000; // 25 seconds
axios.defaults.withCredentials = true;
console.log("API ENDPOINT: " + API_ENDPOINT);
axios.defaults.baseURL = API_ENDPOINT;

export default () => {
    const dispatch = useDispatch();
    React.useEffect(() => {

        //console.log("Running Root useEffect...");

        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.message.indexOf("timeout of") >= 0) {
                    // Tell connection status that the connection is not reliable.
                    console.log('Network request timed out: ', error);
                    connectionStatus.networkError();
                    return Promise.reject(error);
                } else if (error.message.indexOf("Network Error") >= 0) {
                    console.log('Network request failed: ', error);
                    connectionStatus.networkError();
                    return Promise.reject(error);
                } else if (error.response && error.response.status === 401) {
                    if (window.location.pathname !== "/signin") {
                        console.log("Received 401 (unauthorized), navigating to /signin.");
                        dispatch(ActionCreators.sessionEnded());
                        localStorage.clear();
                        window.location = '/signin';
                    }
                } else if (error.response && error.response.status === 402) {
                    console.log("Received 402 (expired), navigating to /expired.");
                    dispatch(ActionCreators.sessionEnded());
                    window.location = '/expired';
                } else {
                    return Promise.reject(error);
                }
            },
        );

        const onServerReachable = () => {
            console.log("Attempting to send pending data to server...");
            dispatch(sendPendingData(true));
        };

        const onNotAuthorized = () => {
            if (window.location.pathname === "/" || window.location.pathname.indexOf("/case/") >= 0) {
                console.log("Not authorized event from connectionStatus. Going to sign in page.");
                dispatch(ActionCreators.sessionEnded());
                localStorage.clear();
                window.location = '/signin';
            }
        }

        connectionStatus.on(SERVER_REACHABLE, onServerReachable);
        connectionStatus.on(NOT_AUTHORIZED, onNotAuthorized);

        if (connectionStatus.isServerReachable()) {
            connectionStatus.emit(SERVER_REACHABLE);
        }

        if (!connectionStatus.authorized) {
            onNotAuthorized();
        }

        return () => {
            //console.log("Tearing down root useEffect...");
            connectionStatus.removeListener(SERVER_REACHABLE, onServerReachable);
        };
    }, [dispatch]);

    return (
        <Routes/>
    )
};
