import React from 'react';
import Case from "./Case";
import { useSelector } from 'react-redux';

const CasePage = ({ id: caseId }) => {
  //console.log("Rendering case page...");

  let name = useSelector((state) => {
    if (state.app.cases[caseId]) {
      return state.app.cases[caseId].name;
    }
    return "Loading...";
  });

  return (
    <Case caseId={caseId} />
  );
};

export default CasePage;
