import React from 'react';
import { AuthProvider } from '../../util/authContext';
import {
  ThemeProvider,
} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import { AlertProvider } from '../../util/alertDialogContext';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../../state/store';
import Root from "./Root";
import { Container } from '@material-ui/core';
import PwaUpdateNotification from '../PwaUpdateNotification';
import theme from "../theme";
import EtherealAlerts from "../EtherealAlerts";

const LoadingIndicator = () => (
  <Container maxWidth={"sm"} style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
    <CircularProgress />
  </Container>
);

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PwaUpdateNotification /> {/* This component is critical for offline use */}
        <PersistGate persistor={persistor}
                     loading={<LoadingIndicator />}>
          <AuthProvider>
            <AlertProvider>
              <Root/>
            </AlertProvider>
            <EtherealAlerts />
          </AuthProvider>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
