export const unauthenticated = (isAuthenticated) => !isAuthenticated;

export const needsMfa = (isAuthenticated, data) => {
  if (isAuthenticated && data && data.data.email) {
    if (data.data.companyRequiresMfa) {
      if (!data.data.mfaEnabled || data.data.mfaEnabled.length === 0) {
        console.warn("No MFA enabled, but company requires it.");
        return true;
      }

      if (!data.data.preferredMfa || data.data.preferredMfa === 'none') {
        console.warn("No preferred MFA, but company requires it.");
        return true;
      }
    }
  }

  //console.log("MFA not required or already set up.");
  return false;
}

export const profileSet = (isAuthenticated, data) => {
  if (isAuthenticated && data && data.data.email) {
    if (!data.data.companyRequiresMfa) {
      //console.log("MFA not required.");
      return true;
    }

    // If the company settings require MFA, they should have mfaEnabled and a preferredMfa setting other than 'none'
    if (data.data.mfaEnabled && data.data.mfaEnabled.length > 0) {
      if (data.data.preferredMfa && data.data.preferredMfa !== 'none') {
        return true;
      }
      else {
        console.warn("MFA required and no preferred setting configured.", data.data);
      }
    }
    else {
      console.warn("MFA required and none of the options are configured.", data.data);
    }
  }
  return false;
}

export const activeSubscription = (isAuthenticated, data) => {
  if (profileSet(isAuthenticated, data)) {
    if (data.data.subscriptionStatus === undefined || data.data.subscriptionStatus === null) {
      return true;
    }
    if (data.data.subscriptionStatus && data.data.subscriptionStatus === "active") {
      return true;
    }
  }

  return false;
};
