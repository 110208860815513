import useStyles from "./Case.styles";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {selectBasicCaseInfo} from "../../state/selectors";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React, {useEffect} from "react";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DemographicsModal from "./DemographicsModal";
import NotesIcon from "@material-ui/icons/Notes";
import CloudUploadIcon from "@material-ui/icons/Publish";
import ModeSwitch from "./ModeSwitch";
import MultiSelectActionWidget from "../../components/MultiSelect/ActionWidget";
import ScoreCard from "./ScoreCard";
import CaseError from "./CaseError";
import EditModal from "./EditModal";
import UploadCsvModal from "./UploadCsvModal";
import MultiEditModal from "../../components/MultiSelect/MultiEditModal";
import CaseNotes from "./CaseNotes";
import {
    closeCaseModal,
    exportCasePdf,
    getCaseInfo,
    listenForCaseChanges,
    stopListeningForCaseChanges
} from "../../state/asyncActions";
import {ActionCreators} from "../../state/actions";
import connectionStatus, {SERVER_REACHABLE} from "../../connectionStatus";
import {ActionCreators as EtherealActionCreators} from "../../state/ethereal/actions";
import OfflineBar from "../../components/OfflineBar";
import JuryBoxAppBar from "../../components/JuryBoxAppBar";
import JurorDialog from "./JurorDialog";
import {Provider} from "../../components/Case/CaseContext";

export default ({caseId, topNav, children}) => {
    const classes = useStyles();
    const spacing = 1;

    const [caseNotesOpen, setCaseNotesOpen] = React.useState(false);
    const [caseUploadCsvModalOpen, setCaseUploadCsvModalOpen] = React.useState(false);

    const {caseInfo, caseModalOpen} = useSelector((state) => {
        return selectBasicCaseInfo(state, {caseId: caseId});
    }, shallowEqual);

    const dispatch = useDispatch();

    const closeEditModal = React.useCallback(async () => {
        dispatch(closeCaseModal(caseId));
    }, [caseId]);

    const openCaseModal = React.useCallback(() => {
        dispatch(ActionCreators.openCaseModal(caseId));
    }, [caseId]);

    useEffect(() => {
        //console.log('Setting up default Case useEffect...');
        let connectionHandle = false;

        function getCaseInfoFromServer() {
            dispatch(getCaseInfo(caseId));
        }

        if (connectionStatus.isServerReachable()) {
            getCaseInfoFromServer();
        } else {
            connectionHandle = true;
            connectionStatus.on(SERVER_REACHABLE, getCaseInfoFromServer);
        }

        return () => {
            //console.log('Tearing down default Case useEffect...');
            if (connectionHandle) {
                connectionStatus.removeListener(SERVER_REACHABLE, getCaseInfoFromServer);
            }
        };
    }, [caseId, dispatch]);

    useEffect(() => {
        //console.log('Setting up Twilio Sync useEffect...');

        function listenToSyncDocument() {
            dispatch(listenForCaseChanges(caseId));
        }

        let connectionHandle = null;
        if (connectionStatus.isServerReachable()) {
            listenToSyncDocument();
        } else {
            connectionHandle = true;
            connectionStatus.on(SERVER_REACHABLE, listenToSyncDocument);
        }

        return () => {
            //console.log('Tearing down Twilio Sync useEffect...');
            dispatch(stopListeningForCaseChanges(caseId));
            if (connectionHandle) {
                //console.log('Closing connection status change listener on Case...');
                connectionStatus.removeListener(SERVER_REACHABLE, listenToSyncDocument);
            }
        };
    }, [caseId, dispatch]);

    const downloadPDF = () => {
        exportCasePdf(caseId, caseInfo.name);
    };

    const openCaseNotes = () => {
        setCaseNotesOpen(true);
    }

    const closeCaseNotes = () => {
        setCaseNotesOpen(false);
    }

    const openCsvModal = () => {
        setCaseUploadCsvModalOpen(true);
    }

    const closeCsvModal = () => {
        setCaseUploadCsvModalOpen(false);
    }

    const showDemographics = React.useCallback(() => {
        dispatch(EtherealActionCreators.setDemographicsModal(true));
    }, [caseId]);

    return (
        <Provider value={{
            id: caseId,
            name: caseInfo.name,
            number: caseInfo.number,
            civilCase: caseInfo.civilCase || false,
            numberOfAlternates: caseInfo.numberOfAlternates || 0
        }}>
            <OfflineBar/>
            <JuryBoxAppBar title={name}/>
            <div className={classes.root}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom={1}
                >
                    <Paper elevation={0} className={classes.infoPanel}>
                        <div className={classes.infoPanelTop}>
                            <Typography variant="h5">
                                {caseInfo.name}
                                &nbsp;
                            </Typography>
                            <Tooltip title="Edit case details">
                                <IconButton
                                    onClick={openCaseModal}
                                    className={classes.editButton}
                                    size="small"
                                >
                                    <Edit className={classes.actionButton}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download PDF of case">
                                <IconButton
                                    onClick={downloadPDF}
                                    className={classes.editButton}
                                    size="small"
                                >
                                    <CloudDownloadIcon className={classes.actionButton}/>
                                </IconButton>
                            </Tooltip>
                            {(caseInfo.demographicCategories && caseInfo.demographicCategories.length > 0) &&
                                <React.Fragment>
                                    <Tooltip title="Demographics Analytics">
                                        <IconButton onClick={showDemographics}
                                                    className={classes.demographicsButton}
                                                    size="small">
                                            <SupervisedUserCircleIcon/>
                                        </IconButton>
                                    </Tooltip>

                                    <DemographicsModal caseId={caseId}/>
                                </React.Fragment>
                            }
                            <Tooltip title="Case Notes">
                                <IconButton onClick={openCaseNotes}
                                            className={classes.demographicsButton}
                                            size="small">
                                    <NotesIcon/>
                                </IconButton>
                            </Tooltip>
                            <div style={{borderLeft: "1px solid #DDD", marginLeft: "5px", paddingLeft: "5px"}}>
                                <Tooltip title="Upload CSV">
                                    <IconButton
                                        onClick={openCsvModal}
                                        size="small"
                                    >
                                        <CloudUploadIcon className={classes.actionButton}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            {!!topNav &&
                                <div style={{borderLeft: "1px solid #DDD", marginLeft: "5px", paddingLeft: "5px"}}>
                                    {topNav}
                                </div>
                            }
                        </div>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            color="textSecondary"
                        >
                            {caseInfo.number}
                        </Typography>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                        >
                            {caseInfo.details}
                        </Typography>
                    </Paper>
                    <div style={{display: "flex", justifyContent: "flex-end", flex: 1}}>
                        <ModeSwitch caseId={caseId}/>
                        <MultiSelectActionWidget/>
                    </div>
                    <ScoreCard caseId={caseId}/>
                </Box>
                <CaseError caseId={caseId}
                           onResolve={openCaseModal}/>
                {children}
                <EditModal
                    key={'edit-modal'}
                    open={caseModalOpen}
                    onClose={closeEditModal}
                    caseData={caseInfo}
                />
                <UploadCsvModal
                    key={'csv-upload-modal'}
                    open={caseUploadCsvModalOpen}
                    onClose={closeCsvModal}
                    caseData={caseInfo}
                />
                <MultiEditModal caseId={caseId}/>
                <CaseNotes caseId={caseId}
                           open={caseNotesOpen}
                           onClose={closeCaseNotes}/>
            </div>
            <JurorDialog
                key={'juror-dialog'}
                caseId={caseId}
            />
        </Provider>
    )
}