import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from '../../util/icons';
import axios from 'axios';
import { useAlert } from '../../util/alertDialogContext';

const columns = [
  { title: 'Name', field: 'name' },
  { title: 'Email', field: 'email' },
  { title: 'Role', field: 'role' },
  { title: 'Group', field: 'groupName' },
  { title: 'Status', field: 'status' },
];

const GroupList = ({
  isAdmin,
  onEditOpen,
  userList,
  onAddOpen,
  user,
  onDelete,
  isTrial,
  canAdd,
}) => {
  const sendInvite = async (email) => {
    const result = await axios
      .post('/api/sendinvite', {
        email: email,
      })
      .catch((error) => console.log("Invite error: ", error));
    if (result) {
      console.log("Invite result: ", result);
    }
  };
  const { alert } = useAlert();

  if (!user) {
    return null;
  }

  const actions = [
    {
      icon: tableIcons.Add,
      tooltip: 'Add user',
      isFreeAction: true,
      hidden: (!isTrial && !canAdd),
      onClick: onAddOpen,
    },
    (rowData) => ({
      icon: tableIcons.Email,
      tooltip: 'Resend invite',
      disabled: rowData.canResend,
      onClick: () => {
        sendInvite(rowData.email);
      },
    }),
    (rowData) => ({
      icon: tableIcons.Edit,
      tooltip: 'Edit user',
      onClick: () => {
        console.log({rowData});
        onEditOpen(rowData);
      },
    }),
    (rowData) => ({
      icon: tableIcons.Delete,
      tooltip: 'Delete user',
      disabled: rowData.email === user.email,
      onClick: (event) => {
        event.preventDefault();
        alert({
          msg:
            'Deleting this user is permanent, would you like to proceed?',
          onSubmit: () => onDelete(rowData),
          variant: 'danger',
        });
      },
    }),
  ]

  return (
    <div id="app-wrapper">
      <MaterialTable
        columns={columns}
        data={userList}
        title="Member Info"
        options={{
          showTitle: true,
          searchFieldAlignment: 'right',
          search: true,
          paging: false,
          sorting: true,
          headerStyle: {
            fontWeight: 'bold',
            fontSize: 16,
            padding: '12px',
          },
          cellStyle: { padding: '12px' },
          actionsColumnIndex: -1,
        }}
        icons={tableIcons}
        actions={isAdmin ? actions : null}
        localization={{
          body: {},
        }}
      />
    </div>
  );
};

export default GroupList;
