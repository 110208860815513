import {produce} from 'immer';
import {ActionTypes} from "./actions";
import { v4 as uuidv4 } from 'uuid';

function getDefaultBounds() {
  return {
    height: 0,
    width: 0,
    top: 0,
    left: 0
  }
}

function getInitialState() {
  return {
    activeDrag: null,
    demographicsModal: false,
    dismissedQuadrantVisible: false,
    highlightedQuadrant: 0,
    juryBoxBounds: getDefaultBounds(),
    juryPoolBounds: getDefaultBounds(),
    dismissedBoxBounds: getDefaultBounds(),
    dismissedQuadrantBounds: getDefaultBounds(),
    mode: "jury-box",
    alerts: []
  }
}

export default produce((draft, action) => {
  switch(action.type) {
    case ActionTypes.setDrag:
      draft.activeDrag = action.payload.jurorId;
      break;
    case ActionTypes.setDemographicsModal:
      draft.demographicsModal = action.payload.open;
      break;
    case ActionTypes.setDismissedQuadrantVisible:
      draft.dismissedQuadrantVisible = action.payload.visible;
      break;
    case ActionTypes.setHighlightedQuadrant:
      draft.highlightedQuadrant = action.payload.highlightedQuadrant;
      break;
    case ActionTypes.setJuryBoxBounds:
      draft.juryBoxBounds = action.payload.bounds;
      break;
    case ActionTypes.setJuryPoolBounds:
      draft.juryPoolBounds = action.payload.bounds;
      break;
    case ActionTypes.setDismissedBoxBounds:
      draft.dismissedBoxBounds = action.payload.bounds;
      break;
    case ActionTypes.setDismissedQuadrantBounds:
      draft.dismissedQuadrantBounds = action.payload.bounds;
      break;
    case ActionTypes.setMode:
      draft.mode = action.payload.mode;
      break;
    case ActionTypes.addAlert:
      draft.alerts.push({id: uuidv4(), ...action.payload});
      break;
    case ActionTypes.removeAlert:
      let alertIndex = draft.alerts.findIndex(oneAlert => oneAlert.id === action.payload.id);
      if (alertIndex >= 0) {
        draft.alerts.splice(alertIndex, 1);
      }
      break;
    case ActionTypes.clearAlerts:
      draft.alerts = [];
      break;
  }
}, getInitialState());
