import React from "react";
import { FormControl, FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DemographicSelect from './DemographicSelect';
import { useSelector } from 'react-redux';
import { listDemographicsOptions } from '../../state/selectors';

export default ({caseId, options, demographics, setDemographics, setOptions, disabled}) => {

  const tmpDemographicsOptions = useSelector((state) => {
    let options = listDemographicsOptions(state, { caseId });
    for (let option of Object.keys(options)) {
      options[option] = [...options[option]].map((oneOption) => ({
        value: oneOption,
        label: oneOption,
      })); // convert to array of options
    }
    return options;
  });

  React.useEffect(() => {
    if (tmpDemographicsOptions) {
      setOptions(tmpDemographicsOptions);
    }
  }, [Object.keys(tmpDemographicsOptions).length])

  return (
    <FormControl component={'fieldset'}
                 fullWidth={true}
                 style={{ border: '1px solid #CCC', borderRadius: '4px', padding: '15px 5px 5px 5px' }}>
      <FormLabel style={{
        padding: 4,
        backgroundColor: 'white',
        position: 'absolute',
        top: '-11px',
        left: '5px',
        fontSize: '12px',
      }}>Demographics</FormLabel>
      <Grid container spacing={1}>
        {Object.keys(options).map(demographicsOption => {

          function handleSelected(newValue) {

            let newDemos = [...demographics].filter(d => !!d && d.value && d.value.length > 0);
            let indexOfExisting = newDemos.findIndex((oneDemo) => oneDemo.name === demographicsOption);
            if (indexOfExisting >= 0) {
              if (newValue !== null) {
                newDemos[indexOfExisting] = { name: demographicsOption, value: newValue };
              } else {
                newDemos.splice(indexOfExisting, 1);
              }
            } else {
              newDemos.push({ name: demographicsOption, value: newValue });
            }
            setDemographics(newDemos);
          }

          let foundValue = demographics.filter(demo => !!demo).find(demo => demo.name === demographicsOption),
            value = null,
            tmpOptions = options[demographicsOption];
          if (foundValue && foundValue.value) {
            value = { value: foundValue.value, label: foundValue.value };

            let foundOption = options[demographicsOption].find(o => o.value === foundValue.value);
            if (!foundOption) {

              tmpOptions.push({
                value: foundValue.value,
                label: foundValue.value,
              });
            }
          }

          return (
            <Grid item md={4} sm={6} xs={12} key={`demographic-option-${demographicsOption}`}>
              <DemographicSelect name={demographicsOption}
                                 value={value}
                                 disabled={disabled}
                                 options={tmpOptions}
                                 onCreateOption={(inputValue) => {

                                   setOptions({
                                     ...options,
                                     [demographicsOption]: [...options[demographicsOption], {
                                       value: inputValue,
                                       label: inputValue,
                                     }],
                                   });

                                   handleSelected(inputValue);
                                 }}
                                 onChange={(newValue, actionMeta) => {
                                   handleSelected(newValue ? newValue.value : null);
                                 }}
              />
            </Grid>
          );
        })}
      </Grid>
    </FormControl>
  )
}
