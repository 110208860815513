import React from "react";
import { convertFromRaw, EditorState } from 'draft-js';
import { fromJuryBoxToDraftJs } from './Transformer';

export default (richTextStyles, text, hasEllipsis=false) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(),
  );

  React.useEffect(() => {
    if (richTextStyles && Array.isArray(richTextStyles) && richTextStyles.length > 0) {
      //console.log("Converting from json: ", json);
      const state = convertFromRaw(fromJuryBoxToDraftJs({
        comments: text,
        richTextStyles
      }, hasEllipsis));
      setEditorState(EditorState.createWithContent(state));
    } else if (text) {
      //console.log("Converting from text: ", text);
      setEditorState(EditorState.createWithText(text));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [richTextStyles, text, hasEllipsis]);

  return [editorState, setEditorState];
}
