import React from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getCaseError } from '../../state/selectors';
import { ActionCreators } from '../../state/actions';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

export default ({ caseId, onResolve }) => {
  const dispatch = useDispatch();
  let error = useSelector((state) => getCaseError(state, {caseId}));
  if (!error) {
    return null;
  }

  let title = 'Unexpected error',
    content = error.error.toString();
  if (error.type === 'create') {
    title = 'Could not create case';
  } else if (error.type === 'update') {
    title = 'Could not update case';
  } else if (error.type === 'delete') {
    title = 'Could not delete case';
  }

  let dismissProps = {};
  if (!error.canDismiss) {
    dismissProps.action = (
      <Button color={"inherit"} size={"small"} onClick={onResolve}>Resolve</Button>
    );
  }
  else {
    dismissProps.onClose = () => {
      dispatch(ActionCreators.removeCaseError(caseId));
    };
  }

  return (
    <Alert severity={"error"} {...dismissProps}>
      <AlertTitle>{title}</AlertTitle>
      {content}
    </Alert>
  );
}
