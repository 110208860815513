import React, { useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Close from "@material-ui/icons/Close";
import Grid from '@material-ui/core/Grid';
import { formStyles } from '../../../util/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
} from '@material-ui/core';
import axios from 'axios';

export default ({ open, onClose, phone, preferredMfa, mfaEnabled, requireMfa = false, loginInfo=null, onComplete=null }) => {
  const classes = formStyles();

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [qrCode, setQrCode] = useState('');
  const [token, setToken] = useState('');
  const [smsToken, setSmsToken] = useState('');
  const [enabled, setEnabled] = useState(mfaEnabled);
  const [verifying, setVerifying] = useState(null);
  const [verified, setVerified] = useState(mfaEnabled);
  const [preferredChannel, setPreferredChannel] = useState(preferredMfa);
  const [userPhone, setUserPhone] = useState(phone);

  React.useEffect(() => {

    setErrors([]);
    setIsLoading(false);

    setEnabled(mfaEnabled);
    setVerified(mfaEnabled);
    setPreferredChannel(preferredMfa);
    setUserPhone(phone);

  }, [open, phone, preferredMfa, mfaEnabled, requireMfa]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    else if (onComplete) {
      onComplete(token, preferredChannel);
    }
  };

  const phoneIsValid = (phone) => {
    if (phone && typeof phone === 'string') {
      if (phone.length === 10 || phone.length === 12 && phone.substr(0, 2) === '+1') {
        return true;
      }
    }

    return false;
  };

  const handlePreferredChannelChange = async (e) => {
    setPreferredChannel(e.target.value);
    try {
      setIsLoading(true);
      setErrors([]);

      let data = {
        channel: e.target.value,
      };

      if (loginInfo) {
        data = {
          ...data,
          ...loginInfo
        };
      }

      let response = await axios.post('/api/mfa/preferred-channel', data);

    } catch (error) {
      console.error('Failed to set preferred channel: ', error);
      setErrors(['Failed to set preferred channel']);
    }

    setIsLoading(false);
  };

  const toggleChecked = (name) => async (e) => {
    let updatedEnabled = [],
      oldEnabled = [...enabled],
      removed = false;
    if (enabled.includes(name)) {
      updatedEnabled = enabled.filter((one) => one !== name);
      removed = true;
    } else {
      updatedEnabled = [...enabled, name];
    }

    setEnabled(updatedEnabled);

    if (removed) {
      try {
        setErrors([]);
        setIsLoading(true);

        let data = {
          channel: name
        };

        if (loginInfo) {
          data = {
            ...data,
            ...loginInfo
          };
        }

        let response = await axios.post("/api/mfa/disable-channel", data);

        if (response.status === 200) {
          if (preferredChannel !== response.data.preferredMfa) {
            setPreferredChannel(response.data.preferredMfa);
          }
        }
      }
      catch(error) {
        console.error("Failed to disable channel: ", error);
        setErrors(['Failed to disable channel']);
      }

      setIsLoading(false);
    }

    if (updatedEnabled.includes('authenticator') && !oldEnabled.includes('authenticator')) {
      initializeSetup('authenticator');
    } else {
      setQrCode('');
    }

    if (updatedEnabled.includes('email') && !oldEnabled.includes('email')) {
      initializeSetup('email');
    }

  };

  const setupUserPhone = async () => {
    let body = {
      phone: userPhone,
    };

    if (loginInfo) {
      body = {
        ...body,
        ...loginInfo
      };
    }

    setErrors([]);
    setIsLoading(true);
    try {
      let response = await axios.post('/api/mfa/add-phone', body);
      if (response.status === 200) {
        await initializeSetup('sms');
      }
    } catch (error) {
      console.error('Error while adding user phone: ', error);
      setErrors(['Failed to add phone number to account']);
    }
    setIsLoading(false);

  };

  const initializeSetup = async (channel) => {
    let setupBody = {
      channel: channel,
    };

    if (loginInfo) {
      setupBody = {
        ...setupBody,
        ...loginInfo
      };
    }

    setErrors([]);
    setIsLoading(true);
    try {
      let response = await axios.post('/api/mfa/setup', setupBody);
      if (response.status === 200) {
        if (response.data.qrCodeUri) {
          setQrCode(response.data.qrCodeUri);
        }
      }
    } catch (error) {
      console.error('Error while initializing MFA setup process: ', error);
      setErrors(['Failed to initialize setup process']);
    }
    setIsLoading(false);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, '').trim();
    if (value.substr(0, 1) === '1') {
      value = '+' + value;
    }

    if (value !== phone) {
      if (verified.includes('sms')) {
        setVerified(verified.filter(channel => channel !== 'sms'));
      }
    } else {
      if (!verified.includes('sms')) {
        setVerified([...verified, 'sms']);
      }
    }
    setUserPhone(value);
  };

  const handleTokenChange = (channel) => (e) => {
    if (channel === 'sms') {
      setSmsToken(e.target.value);
    } else {
      setToken(e.target.value);
    }
    if (e.target.value.length === 6) {
      verifySetup(channel, e.target.value);
    }
  };

  const verifySetup = async (channel, token) => {
    let verifyBody = {
      channel: channel,
      token: token,
    };

    if (loginInfo) {
      verifyBody = {
        ...verifyBody,
        ...loginInfo
      };
    }

    setErrors([]);
    setIsLoading(true);
    setVerifying(channel);
    try {
      let response = await axios.post('/api/mfa/verify-setup', verifyBody);
      if (response.status === 200) {
        if (response.data.verified) {
          if (!verified.includes(channel)) {
            setVerified([...verified, channel]);
            setVerifying(null);
          }
        } else {
          setErrors(['Token was not valid. Please try again.']);
        }
      }
    } catch (error) {
      console.error('Error while verifying MFA setup process: ', error);
      setErrors(['Failed to verify setup process']);
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            className={classes.form}
            fullWidth
            maxWidth="sm">
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Setup MFA</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          disabled={isLoading || (requireMfa && preferredChannel === 'none')}
        >
          <Close/>
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <Collapse in={errors && errors.length > 0}>
          <Alert severity="error" className={classes.alert}>
            MFA setup failed:
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Collapse>

        <Grid container spacing={2} style={{ marginBottom: '7px' }}>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <InputLabel>Preferred channel</InputLabel>
              <Select value={preferredChannel}
                      name={'channel'}
                      onChange={handlePreferredChannelChange}>
                <MenuItem value={'none'}>{requireMfa ? '-- Please select a preferred channel --' : 'None'}</MenuItem>
                <MenuItem value={'authenticator'} disabled={!verified.includes('authenticator') || !enabled.includes('authenticator')}>Authenticator App</MenuItem>
                <MenuItem value={'sms'} disabled={!verified.includes('sms') || !enabled.includes('sms')}>SMS</MenuItem>
                <MenuItem value={'email'} disabled={!verified.includes('email') || !enabled.includes('email')}>Email</MenuItem>
              </Select>
              <Typography variant={'body2'}>Enable options by switching on channels below</Typography>
            </FormControl>
          </Grid>
        </Grid>

        <InputLabel>Available Channels</InputLabel>
        <div>
          <Grid container>
            <Grid item xs={10}>
              <FormControlLabel
                control={<Switch checked={true} />}
                label="Email"
              />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckCircle style={{ color: 'green' }}/>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container>
            <Grid item xs={10}>
              <FormControlLabel
                control={<Switch checked={enabled.includes('sms')} onChange={toggleChecked('sms')}/>}
                label="SMS"
              />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {verifying === "sms" && <CircularProgress size={"small"} />}
              {verified.includes('sms') && <CheckCircle style={{ color: 'green' }}/>}
            </Grid>
          </Grid>
          {enabled.includes('sms') &&
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Phone number"
                  autoFocus
                  value={userPhone}
                  onChange={handlePhoneChange}
                  InputProps={{
                    classes: {
                      root: classes.outlinedRoot,
                    },
                  }}
                />
              </Grid>
              {!verified.includes('sms') &&
              <Grid item xs={4}>
                <Button onClick={setupUserPhone}
                        color={'secondary'}
                        disabled={!phoneIsValid(userPhone) || isLoading}
                        variant={'text'}>Get token</Button>
              </Grid>
              }
            </Grid>
            {!verified.includes('sms') &&
            <Grid item xs={12} style={{ marginTop: '7px' }}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                id="token"
                name="token"
                label="Token"
                autoFocus
                value={smsToken}
                onChange={handleTokenChange('sms')}
                InputProps={{
                  classes: {
                    root: classes.outlinedRoot,
                  },
                }}
              />
            </Grid>
            }
          </Grid>
          }
        </div>
        <div>
          <Grid container>
            <Grid item xs={10}>
              <FormControlLabel
                control={<Switch checked={enabled.includes('authenticator')}
                                 onChange={toggleChecked('authenticator')}/>}
                label="Authenticator app"
              />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {verifying === "authenticator" && <CircularProgress size={"small"} />}
              {verified.includes('authenticator') && <CheckCircle style={{ color: 'green' }}/>}
            </Grid>
          </Grid>
          {qrCode !== '' &&
          <React.Fragment>
            <Grid item xs={12}>
              <Paper elevation={3}
                     style={{ padding: '7px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography>Scan this QR code with your authenticator app (i.e. - Google Authenticator, Microsoft
                  Authenticator, Authy, etc)</Typography>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img src={qrCode} alt={'QR Code for authenticator app'} style={{height: "200px"}}/>
                </div>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="token"
                    name="token"
                    label="Token"
                    autoFocus
                    value={token}
                    onChange={handleTokenChange('authenticator')}
                    InputProps={{
                      classes: {
                        root: classes.outlinedRoot,
                      },
                    }}
                  />
                  <Typography variant={'body2'}>Provide the token from the authenticator app</Typography>
                </Grid>
              </Paper>
            </Grid>
          </React.Fragment>

          }
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} variant={'outlined'} color={'primary'} disabled={requireMfa && preferredChannel === 'none'}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
