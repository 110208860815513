import React, { useEffect, useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { formStyles } from '../../util/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { createCase, updateCase } from '../../state/asyncActions';
import { getCaseError } from '../../state/selectors';
import { FormControl, FormLabel } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from "@material-ui/core/Select";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import GridStartIcon from '../../components/GridStartIcon';
import ChipInput from 'material-ui-chip-input';
import SnakeFlowIcon from "../../components/SnakeFlowIcon";
import StandardFlowIcon from "../../components/StandardFlowIcon";
import MenuItem from "@material-ui/core/MenuItem";

const EditModal = ({ open, onClose, caseData }) => {
  const classes = formStyles();
  const dispatch = useDispatch();
  const caseError = useSelector((state) => getCaseError(state, {caseId: caseData._id}));
  const [caseInfo, setCaseInfo] = useState(caseData);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (caseData) {
      setCaseInfo(caseData);
    }
  }, [caseData]);

  const handleClose = () => {
    onClose();
  };

  const handleAddDemographicCategory = (chip) => {
    let newDemos = [];
    if (Array.isArray(caseInfo.demographicCategories)) {
      newDemos = [...caseInfo.demographicCategories];
    }
    chip = chip.trim();
    if (newDemos.indexOf(chip) === -1) {
      newDemos.push(chip);

      setCaseInfo({
        ...caseInfo,
        demographicCategories: newDemos
      });
    }
  };

  const handleRemoveDemographicCategory = (chip, index) => {
    let newDemos = [...caseInfo.demographicCategories];
    newDemos.splice(index, 1);
    setCaseInfo({
      ...caseInfo,
      demographicCategories: newDemos
    });
  };

  const handleEdit = async () => {
    let errorList = [];

    if (!caseInfo.name) {
      errorList.push('Name must be populated');
    }

    if (!caseInfo.number) {
      errorList.push('Number must be populated');
    }

    if (!caseInfo.juryBoxSize) {
      errorList.push('Jury size must be populated');
    }

    if (caseInfo.juryBoxSize > 100) {
      errorList.push("Jury size can't be greater than 100");
    }

    if (!caseInfo.prosecutionPeremptStrikes) {
      let typeTitle = caseInfo.civilCase ? 'Plaintiff' : 'Prosecution';
      errorList.push(typeTitle + ' strikes must be populated');
    }

    if (!caseInfo.defendantPeremptStrikes) {
      errorList.push('Defendant strikes must be populated');
    }

    if (errorList.length > 0) {
      setErrors(errorList);
    } else {
      const editCaseBody = {
        number: caseInfo.number,
        name: caseInfo.name,
        details: caseInfo.details,
        juryBoxSize: caseInfo.juryBoxSize,
        jurorsPerRow: caseInfo.jurorsPerRow || 6,
        seatNumberStartPosition: caseInfo.seatNumberStartPosition || "topLeft",
        seatNumberFlow: caseInfo.seatNumberFlow || "standard",
        prosecutionPeremptStrikes: caseInfo.prosecutionPeremptStrikes,
        defendantPeremptStrikes: caseInfo.defendantPeremptStrikes,
        civilCase: caseInfo.civilCase,
        demographicCategories: caseInfo.demographicCategories || [],
        showDemographics: caseInfo.showDemographics || false,
        numberOfAlternates: caseInfo.numberOfAlternates || 0,
        alternateProsecutionPeremptStrikes: caseInfo.alternateProsecutionPeremptStrikes || 0,
        alternateDefendantPeremptStrikes: caseInfo.alternateDefendantPeremptStrikes || 0
      };

      if (caseError && caseError.type === "create") {
        editCaseBody.createdAt = (new Date()).toISOString();
        dispatch(createCase(caseData._id, editCaseBody));
      }
      else {
        dispatch(updateCase(caseData._id, editCaseBody));
      }
      onClose();
    }
  };

  if (!caseData) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.form}
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Edit Case</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <Collapse in={errors && errors.length > 0}>
          <Alert severity="error" className={classes.alert}>
            Edit Failed:
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="number"
              name="number"
              label="Case Number"
              autoFocus
              value={caseInfo.number}
              onChange={(event) =>
                setCaseInfo({
                  ...caseInfo,
                  number: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              label="Case Name"
              value={caseInfo.name}
              onChange={(event) =>
                setCaseInfo({
                  ...caseInfo,
                  name: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id="comments"
              name="comments"
              label="Description"
              type="text"
              multiline
              rows={1}
              className={classes.comments}
              value={caseInfo.details}
              onChange={(event) =>
                setCaseInfo({
                  ...caseInfo,
                  details: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Type</FormLabel>
              <RadioGroup row value={caseInfo.civilCase ? "1" : "0"}
                          onChange={(e) => {
                            setCaseInfo({
                              ...caseInfo,
                              civilCase: e.target.value === "1"
                            })
                          }}>
                <FormControlLabel control={<Radio />} value={"0"} label={'Criminal'} />
                <FormControlLabel control={<Radio />} value={"1"} label={'Civil'} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              id="prosecution-strikes"
              name="prosecution-strikes"
              label={`${caseInfo.civilCase ? 'Plaintiff' : 'Prosecution'} Peremptory Strikes`}
              type="number"
              value={caseInfo.prosecutionPeremptStrikes}
              onChange={(event) =>
                setCaseInfo({
                  ...caseInfo,
                  prosecutionPeremptStrikes: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              id="defendant-strikes"
              name="defendant-strikes"
              label="Defense Peremptory Strikes"
              type="number"
              value={caseInfo.defendantPeremptStrikes}
              onChange={(event) =>
                setCaseInfo({
                  ...caseInfo,
                  defendantPeremptStrikes: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} style={{marginBottom: "10px", marginTop: "10px", padding: "4px", border: "1px solid grey", borderRadius: "3px"}}>
              <Grid item xs={12}>
                <Typography variant={"overline"}>Alternates</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="number-of-alternates"
                  name="number-of-alternates"
                  label="Number of alternates"
                  type="number"
                  value={caseInfo.numberOfAlternates}
                  onChange={(event) => {
                    setCaseInfo({
                      ...caseInfo,
                      numberOfAlternates: event.target.value,
                    })
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 30,
                    },
                    classes: {
                      root: classes.outlinedRoot,
                    },
                  }}
                />
              </Grid>
              {caseInfo.numberOfAlternates > 0 &&
                <React.Fragment>
                  <Grid item xs={6}>
                    <TextField
                      required
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="alternate-prosecution-strikes"
                      name="alternate-prosecution-strikes"
                      label={`${caseInfo.civilCase ? 'Plaintiff' : 'Prosecution'} Peremptory Strikes`}
                      type="number"
                      value={caseInfo.alternateProsecutionPeremptStrikes}
                      onChange={(event) => {
                        setCaseInfo({
                          ...caseInfo,
                          alternateProsecutionPeremptStrikes: event.target.value
                        })
                      }}
                      InputProps={{
                        classes: {
                          root: classes.outlinedRoot,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="alternate-defendant-strikes"
                      name="alternate-defendant-strikes"
                      label="Defense Peremptory Strikes"
                      type="number"
                      value={caseInfo.alternateDefendantPeremptStrikes}
                      onChange={(event) => {
                        setCaseInfo({
                          ...caseInfo,
                          alternateDefendantPeremptStrikes: event.target.value
                        })
                      }}
                      InputProps={{
                        classes: {
                          root: classes.outlinedRoot,
                        },
                      }}
                    />
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ChipInput value={caseInfo.demographicCategories || []}
                        fullWidth={true}
                        fullWidthInput={true}
                        variant="outlined"
                        size="small"
                        label="Demographics Tracking Categories"
                        onAdd={handleAddDemographicCategory}
                        onDelete={handleRemoveDemographicCategory} />
            <Typography variant={"caption"}>Press <code>Enter</code> or <code>Tab</code> to create a category</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Show Demographics on Juror Cards</FormLabel>
              <RadioGroup value={caseInfo.showDemographics ? "1" : "0"}
                          onChange={(e) => {
                            setCaseInfo({
                              ...caseInfo,
                              showDemographics: e.target.value === "1"
                            })
                          }}>
                <Grid container>
                  <Grid item><FormControlLabel control={<Radio />} value={"0"} label={'No'} /></Grid>
                  <Grid item><FormControlLabel control={<Radio />} value={"1"} label={'Yes'}/></Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="jury-size"
              name="jury-size"
              label="Number of juror seats (including alternates or six pack)"
              type="number"
              value={caseInfo.juryBoxSize}
              onChange={(event) =>
                setCaseInfo({
                  ...caseInfo,
                  juryBoxSize: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Max Number of Jurors Per Row</FormLabel>
              <Select value={parseInt(caseInfo.jurorsPerRow || 6)}
                      onChange={(e) => {
                        setCaseInfo({
                          ...caseInfo,
                          jurorsPerRow: parseInt(e.target.value),
                        })
                      }}>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Seat Number Starting Position</FormLabel>
              <RadioGroup value={caseInfo.seatNumberStartPosition || "topLeft"}
                          onChange={(e) => {
                            setCaseInfo({
                              ...caseInfo,
                              seatNumberStartPosition: e.target.value
                            })
                          }}>
                <Grid container>
                  <Grid item><FormControlLabel control={<Radio />}
                                               value={"topLeft"}
                                               label={<GridStartIcon className={classes.gridStartIcon} />}
                                               title={'Top Left'} /></Grid>
                  <Grid item><FormControlLabel control={<Radio />}
                                               value={"topRight"}
                                               label={<GridStartIcon className={classes.gridStartIcon}
                                                                     style={{transform: "rotate(90deg)"}} />}
                                               title={'Top Right'} /></Grid>
                  <Grid item><FormControlLabel control={<Radio />}
                                               value={"bottomLeft"}
                                               label={<GridStartIcon className={classes.gridStartIcon}
                                                                     style={{transform: "rotate(270deg)"}} />}
                                               title={'Bottom Left'} /></Grid>
                  <Grid item><FormControlLabel control={<Radio />}
                                               value={"bottomRight"}
                                               label={<GridStartIcon className={classes.gridStartIcon}
                                                                     style={{transform: "rotate(180deg)"}} />}
                                               title={'Bottom Right'} /></Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Seat Number Flow</FormLabel>
              <RadioGroup value={caseInfo.seatNumberFlow || "standard"}
                          onChange={(e) => {
                            setCaseInfo({
                              ...caseInfo,
                              seatNumberFlow: e.target.value
                            })
                          }}>
                <Grid container spacing={4}>
                  <Grid item>
                    <FormControlLabel control={<Radio />}
                                               value={"standard"}
                                               label={<div className={classes.flowLabel}>
                                                 <StandardFlowIcon className={classes.gridStartIcon} />
                                                 <span className={classes.flowLabelText}>Standard</span>
                                                 </div>
                                               }
                                               title={'Standard'} />
                  </Grid>
                  <Grid item>
                    <FormControlLabel control={<Radio />}
                                               value={"snake"}
                                               label={<div className={classes.flowLabel}>
                                                 <SnakeFlowIcon className={classes.gridStartIcon} />
                                                 <span className={classes.flowLabelText}>Snaking</span>
                                               </div>}
                                               title={'Snaking'} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleEdit}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
