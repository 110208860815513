export default function StandardFlowIcon({className}) {

    return (
        <svg viewBox="2.727 2.083 11.594 27.406" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g>
                <path fillRule="evenodd" d="M 3.428 6.397 C 3.428 5.983 3.764 5.647 4.178 5.647 L 10.816 5.647 L 8.658 3.687 C 8.23 3.299 8.383 2.594 8.933 2.417 C 9.203 2.331 9.499 2.404 9.698 2.607 L 13.198 5.857 C 13.504 6.152 13.504 6.642 13.198 6.937 L 9.698 10.187 C 9.294 10.6 8.595 10.421 8.44 9.865 C 8.363 9.591 8.448 9.298 8.658 9.107 L 10.816 7.147 L 4.178 7.147 C 3.764 7.147 3.428 6.811 3.428 6.397 Z" clipRule="evenodd"/>
                <path fillRule="evenodd" d="M 3.499 16.013 C 3.499 15.599 3.835 15.263 4.249 15.263 L 10.887 15.263 L 8.729 13.303 C 8.301 12.915 8.454 12.21 9.004 12.033 C 9.274 11.947 9.57 12.02 9.769 12.223 L 13.269 15.473 C 13.575 15.768 13.575 16.258 13.269 16.553 L 9.769 19.803 C 9.365 20.216 8.666 20.037 8.511 19.481 C 8.434 19.207 8.519 18.914 8.729 18.723 L 10.887 16.763 L 4.249 16.763 C 3.835 16.763 3.499 16.427 3.499 16.013 Z" clipRule="evenodd"/>
                <path fillRule="evenodd" d="M 3.371 24.9 C 3.371 24.486 3.707 24.15 4.121 24.15 L 10.759 24.15 L 8.601 22.19 C 8.173 21.802 8.326 21.097 8.876 20.92 C 9.146 20.834 9.442 20.907 9.641 21.11 L 13.141 24.36 C 13.447 24.655 13.447 25.145 13.141 25.44 L 9.641 28.69 C 9.237 29.103 8.538 28.924 8.383 28.368 C 8.306 28.094 8.391 27.801 8.601 27.61 L 10.759 25.65 L 4.121 25.65 C 3.707 25.65 3.371 25.314 3.371 24.9 Z" clipRule="evenodd"/>
            </g>
        </svg>
    )
}