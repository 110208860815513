import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {formStyles} from '../../util/styles';
import Grid from '@material-ui/core/Grid';
import {createJuror, updateJuror} from '../../state/asyncActions';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {ActionCreators} from '../../state/actions';
import RichTextEditor from '../../components/RichTextEditor';
import {getCase} from '../../state/selectors';
import DemographicsOptions from '../../components/Demographics/DemographicsOptions';
import Display from '../../components/RichTextEditor/Display';
import IconButton from '@material-ui/core/IconButton';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {Tooltip} from '@material-ui/core';
import {useStyles as useRteStyles} from '../../components/RichTextEditor/styles';
import {getMode} from "../../state/ethereal/selectors";
import EmojiIndex from "./EmojiIndex";
import {isMacOs, isWindows} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 600,
        position: 'relative'
    },
    emojiIndex: {
        backgroundColor: "white",
        padding: "3px 5px 2px 5px",
        borderRadius: "4px",
        fontSize: 14,
        position: "absolute",
        zIndex: 100,
        bottom: 5,
        right: 15,
        boxShadow: "2px 2px 5px -2px black"
    },
    formControl: {
        marginRight: theme.spacing(1),
        minWidth: 200,
    },
    commentsLabel: {
        color: '#808080',
        marginLeft: '5px',
        userSelect: 'none',
        fontSize: '12px',
    },
    textFieldControl: {
        margin: 0,
        minWidth: 500,
    },
    previewButton: {
        marginLeft: 'auto'
    },
    seatNumber: {
        marginLeft: 'auto',
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    submitInstructions: {
        marginRight: 'auto',
        color: 'gray',
        fontSize: 14,
        display: "flex",
        flexDirection: "column",
        gap: "0.5em"
    },
    code: {
        backgroundColor: "#333",
        color: "white",
        borderRadius: "4px",
        padding: "3px 5px"
    }
}));

const JurorDialog = ({
                         caseId,
                     }) => {

    //console.log("Rendering juror dialog...");
    const editorRef = React.useRef(null);

    const classes = useStyles();
    const rteClasses = useRteStyles();
    const formClasses = formStyles();

    const dispatch = useDispatch();
    const {jurorInfo, seatNumber, previewingJuror, focusOn, focusAfter} = useSelector((state) => {
        return {
            jurorInfo: state.app.editingJuror,
            seatNumber: state.app.editingSeatNumber,
            previewingJuror: state.app.previewingJuror,
            focusOn: state.app.focusOnEmoji,
            focusAfter: state.app.focusOnOccurrence
        };
    });

    const [selectedEmoji, setSelectedEmoji] = React.useState(null);

    const mode = useSelector(getMode);

    const caseInfo = useSelector((state) => {
        return getCase(state, {caseId});
    });

    const [demographicsOptions, setDemographicsOptions] = React.useState([]);

    //console.log('Demographics options: ', demographicsOptions, tmpDemographicsOptions);

    const [nameAndId, setNameAndId] = React.useState('');
    const [revision, setRevision] = React.useState(0);
    const [demographics, setDemographics] = React.useState([]);
    const [comments, setComments] = React.useState({
        text: '',
        styles: [],
    });

    useEffect(() => {
        if (focusOn) {
            setSelectedEmoji({
                emoji: focusOn,
                occurrence: focusAfter
            });
        }
        else {
            setSelectedEmoji(null);
        }
    }, [focusOn, focusAfter]);

    const handleCancel = (event) => {
        return handleClose(event, 'cancelClick');
    };

    const handleClosePreview = (event) => {
        return handleClose(event, 'closeClick');
    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        console.log("Closing for reason: ", reason);
        dispatch(ActionCreators.closeJurorModal(caseId, jurorInfo._id));
    };

    const togglePreviewing = () => {
        if (previewingJuror) {
            dispatch(ActionCreators.previewJurorEnd());
        } else {
            dispatch(ActionCreators.previewJurorStart());
        }
    };

    const handleOk = () => {

        if (previewingJuror) {
            handleClose(null, 'closeClick');
            return;
        }

        const content = editorRef.current.getContent();
        //console.log("Editor content: ", content);

        let seatNumberAttributeName = mode === 'jury-box' ? 'seatNumber' : 'alternateSeatNumber';

        const shouldEdit = !!jurorInfo._id;
        const jurorBody = {
            ...jurorInfo,
            revision: revision,
            comments: content.comments,
            commentsStyles: content.richTextStyles,
            nameAndId: nameAndId,
            demographics,
            [seatNumberAttributeName]: seatNumber,
        };

        if (shouldEdit) {
            dispatch(updateJuror(caseId, jurorInfo._id, jurorBody));
            handleClose(null, 'saveClick');
        } else {
            const tmpId = uuidv4();
            jurorBody.created = (new Date()).toISOString();
            dispatch(createJuror(caseId, tmpId, jurorBody));
            handleClose(null, 'saveClick');
        }
    };

    const handleKeyPress = (event) => {
        if (event.shiftKey && event.key === 'Enter' && !previewingJuror) {
            handleOk();
        }
    };

    const updateEditingJuror = () => {
        let changes = [
            {name: 'nameAndId', value: nameAndId},
        ];

        dispatch(ActionCreators.updateEditingJuror(changes));
    };

    const onIdChange = (event) => {
        setNameAndId(event.target.value);
    };

    React.useEffect(() => {
        if (jurorInfo) {
            if (jurorInfo.revision) {
                setRevision(jurorInfo.revision);
            }
            if (jurorInfo.nameAndId) {
                setNameAndId(jurorInfo.nameAndId);
            }
            if (jurorInfo.demographics) {
                setDemographics([...jurorInfo.demographics]);
            }
            if (jurorInfo.comments || jurorInfo.commentsStyles) {
                setComments({
                    text: jurorInfo.comments || '',
                    styles: jurorInfo.commentsStyles || [],
                });
            }
        } else {
            setNameAndId('');
            setRevision(0);
            setDemographics([]);
            setDemographicsOptions([]);
            setComments({
                text: '',
                styles: [],
            });
        }
    }, [jurorInfo]);

    if (jurorInfo === null) {
        return null;
    }

    //console.log('Demographics: ', demographics);

    return (
        <Dialog
            open={!!jurorInfo}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            width="md"
        >
            <DialogTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.dialogTitleContainer}>
                        <TextField
                            value={nameAndId}
                            disabled={previewingJuror}
                            onChange={onIdChange}
                            onBlur={updateEditingJuror}
                            autoFocus={!jurorInfo.comments}
                            id="id"
                            label="Juror ID / Name"
                            variant="outlined"
                            style={{width: '30%'}}
                            size="small"
                            className={classes.formControl}
                            InputProps={{
                                classes: {
                                    root: formClasses.outlinedRoot,
                                },
                            }}
                        />
                        {jurorInfo._id &&
                            <Tooltip title={previewingJuror ? 'Allow edits' : 'View only'}
                                     className={classes.previewButton}>
                                <IconButton onClick={togglePreviewing}>
                                    {previewingJuror ? <VisibilityOff key={'preview-btn'}/> :
                                        <Visibility key={'preview-btn'}/>}
                                </IconButton>
                            </Tooltip>
                        }
                        {seatNumber !== null && seatNumber !== undefined &&
                            <span className={classes.seatNumber}>
                Seat number: {seatNumber}
              </span>
                        }
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent onKeyPress={handleKeyPress} className={classes.dialog}>
                <Grid container spacing={2}>
                    {(caseInfo && caseInfo.demographicCategories && caseInfo.demographicCategories.length > 0) &&
                        <Grid item xs={12}>
                            <DemographicsOptions caseId={caseId}
                                                 disabled={previewingJuror}
                                                 options={demographicsOptions}
                                                 demographics={demographics}
                                                 setOptions={setDemographicsOptions}
                                                 setDemographics={setDemographics}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {previewingJuror &&
                            <div className={rteClasses.displayContainer}>
                                <div className={rteClasses.header}>
                                    <div className={rteClasses.titleContainer}>
                                        <Typography variant={'caption'}
                                                    className={classes.commentsLabel}>Comments</Typography>
                                    </div>
                                </div>
                                <div className={rteClasses.displayEditor}>
                                    <Display text={comments.text}
                                             richTextStyles={comments.styles}
                                             key={'juror-comments'}
                                             focusOn={selectedEmoji?.emoji}
                                             focusAfter={selectedEmoji?.occurrence}/>
                                </div>
                            </div>
                        }
                        {!previewingJuror &&
                            <RichTextEditor
                                key={'juror-comments'}
                                label={<Typography variant={'caption'}
                                                   className={classes.commentsLabel}>Comments</Typography>}
                                richTextStyles={comments.styles}
                                text={comments.text}
                                onSave={handleOk}
                                autoFocus
                                focusOn={selectedEmoji?.emoji}
                                focusAfter={selectedEmoji?.occurrence}
                                ref={editorRef}/>
                        }
                    </Grid>
                </Grid>
                <EmojiIndex text={comments.text || ""}
                            className={classes.emojiIndex}
                            onClick={(emoji, occurrence) => {
                                setSelectedEmoji({emoji, occurrence});
                            }}/>
            </DialogContent>
            <DialogActions className={formClasses.actions}>
                {previewingJuror &&
                    <React.Fragment>
                        <div className={classes.submitInstructions}>
                            Real-time updates are enabled while in this view-only mode.
                        </div>
                        <Button onClick={handleClosePreview} color="primary" variant="contained">Close</Button>
                    </React.Fragment>
                }
                {!previewingJuror &&
                    <React.Fragment>
                        <div className={classes.submitInstructions}>
                            {isWindows && <div>Emoji keyboard: <code className={classes.code}>Win</code> + <code className={classes.code}>.</code></div>}
                            {isMacOs && <div>Emoji keyboard: <code className={classes.code}>Cmd</code> + <code className={classes.code}>Ctrl</code> + <code className={classes.code}>Space</code></div>}
                            <div>Confirm: <code className={classes.code}>Shift</code> + <code className={classes.code}>Enter</code></div>
                        </div>
                        <Button
                            onClick={handleOk}
                            color="primary"
                            variant="contained"
                        >
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </React.Fragment>
                }
            </DialogActions>
        </Dialog>
    );
};

export default JurorDialog;
