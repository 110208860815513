import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import axios from 'axios';
import debounce from 'lodash.debounce';

const CreatableGroupSelect = ({ onChange, value, create, disabled }) => {
  const [loading, setLoading] = useState(false);

  const _getGroupOptions = (inputValue, callback) => {
    axios
      .get('/api/groups', {
        params: {
          search: inputValue,
        },
      })
      .then((res) => {
        setLoading(false);
        callback(
          res.data.map((group) => {
            return { label: group.name, value: group._id };
          }),
        );
      })
      .catch((error) => {
        console.log("Get group options error: ", error);
        setLoading(false);
      });
  };

  const getGroupOptions = debounce(_getGroupOptions, 500);

  const handleCreate = async (inputValue) => {
    const result = await axios
      .post('/api/groups', {
        groupName: inputValue,
      })
      .catch((error) => console.log("Group select error: ", error));
    if (result && result.data) {
      onChange({ label: result.data.name, value: result.data._id });
    }
  };

  return create ? (
    <AsyncCreatableSelect
      defaultOptions
      loadOptions={getGroupOptions}
      isDisabled={loading || disabled}
      placeholder={
        loading ? 'Loading data' : 'Select or type to create...'
      }
      value={value}
      onChange={onChange}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      onCreateOption={handleCreate}
    />
  ) : (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={getGroupOptions}
      isDisabled={loading}
      placeholder={
        loading ? 'Loading data' : 'Select or type to search...'
      }
      value={value}
      onChange={onChange}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  );
};

export default CreatableGroupSelect;
