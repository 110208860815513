import React from "react";

export default function Highlighted({content, search, onClick, hideWhenNoMatchFound=false}) {

    if (typeof content !== "string") {
        return null;
    }

    let indexOfSearch = content.indexOf(search);
    if (indexOfSearch === -1 || !search) {
        if (hideWhenNoMatchFound) {
            return null;
        }
        return content;
    }

    let occurrences = [indexOfSearch];
    let hasMore = true;
    while (hasMore) {
        let nextIndex = content.indexOf(search, occurrences[occurrences.length - 1] + 1);
        if (nextIndex >= 0) {
            occurrences.push(nextIndex);
        }
        else {
            hasMore = false;
        }
    }

    let matches = [];
    for (let i = 0; i < occurrences.length; i++) {
        matches.push({
            occurrence: i,
            index: occurrences[i],
            start: occurrences[i] - 200,
            end: occurrences[i] + 200,
            prefix: "...",
            suffix: "..."
        });
    }

    for (let i = 0; i < matches.length; i++) {
        let current = matches[i];
        let prev = null;
        if (i > 0) {
            prev = matches[i-1];
            if (prev.end >= current.index) {
                prev.end = current.index;
                prev.suffix = "";
                current.start = current.index;
                current.prefix = "";
            }
        }
        let next = null;
        if (i < matches.length - 1) {
            next = matches[i + 1];
            if (next.start <= current.index + search.length) {
                next.start = current.index + search.length;
                next.prefix = "";
                current.end = current.index + search.length;
                current.suffix = "";
            }
        }

        if (current.start < 0) {
            current.start = 0;
            current.prefix = "";
        }
        if (current.end > content.length) {
            current.end = content.length;
            current.suffix = "";
        }
    }

    // console.log("Matches: ", matches);

    let toRender = [];
    for (let match of matches) {
        toRender.push(
            <div className="highlighted-match">
                <span>{match.prefix}{content.substring(match.start, match.index)}</span>
                <span className="highlighted" onClick={() =>{
                    if (onClick) {
                        onClick(match.occurrence);
                    }
                }} style={{cursor: onClick ? "cursor" : "default"}}>{content.substring(match.index, match.index + search.length)}</span>
                <span>{content.substring(match.index + search.length, match.end)}{match.suffix}</span>
            </div>
        )
    }


    return (
        <div className="highlighted-wrapper">
            {toRender}
        </div>
    );
}