import React, { useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { formStyles } from '../../../util/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  subscriptionTable: {
    width: '550px',
    borderCollapse: 'collapse',
    '& td, th': {
      border: '1px solid lightgray',
      padding: '10px 20px',
    },
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtons: {
    marginLeft: 'auto',
  },
}));

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const unsubscribeMessage = (endDate) =>
  `If you unsubscribe now your subscription will expire on ${endDate}.  Are you sure you want to cancel your subscription?`;
const editCurrentCycleMessage = (additionalUsers, proratedAmount) =>
  `You have added ${additionalUsers} more user(s) and will be charged a prorated amount of ${proratedAmount} for the remainder of the year.`;
const editNextCycleMessage = (nextUserQuantity, amount) =>
  `You have updated your next subscription cycle to ${nextUserQuantity} user(s) and will be charged the amount of $${parseFloat(amount).toFixed(2)} on your next billing date.`;
const editErrorMessage = (currentUsers, nextUserQuantity) =>
  `You have ${currentUsers} active users and want to reduce your number of users to ${nextUserQuantity} for the next cycle.  Remove ${
    currentUsers - nextUserQuantity
  } user(s) to proceed.`;
const resubscribeMessage = (endDate) =>
  `Your subscription that was set to expire on ${endDate} will be automatically be renewed on that date.`;

//TODO: add coupon for next cycle
const EditSubscriptionModal = ({
  open,
  onClose,
  subscriptionData,
  numCurrentUsers,
}) => {
  if (!subscriptionData) {
    return null;
  }

  const classes = formStyles();
  const tableClasses = useStyles();
  const [subscriptionInfo, setSubscriptionInfo] = useState(
    subscriptionData,
  );
  const [errors, setErrors] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationOption, setConfirmationOption] = useState('');
  const [proratedAmount, setProratedAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleEdit = async () => {
    setIsLoading(true);
    try {
      const updateSubscriptionBody = {
        oldCurrentQuantity: subscriptionData.currUserQuantity,
        oldNextQuantity: subscriptionData.nextUserQuantity,
        newCurrentQuantity: subscriptionInfo.currUserQuantity,
        newNextQuantity: subscriptionInfo.nextUserQuantity,
        nextCoupon: '',
      };

      const res = await axios.post(
        '/api/subscription',
        updateSubscriptionBody,
      );

      if (res.data.error) {
        setErrors([res.data.error]);
      } else {
        return res.data.success;
      }
      setIsLoading(false);
    } catch (error) {
      setErrors([error]);
      setIsLoading(false);
    }
  };

  const handleIncreaseCurrent = () => {
    setSubscriptionInfo((prevState) => ({
      ...prevState,
      currUserQuantity: prevState.currUserQuantity + 1,
      nextUserQuantity: prevState.currUserQuantity + 1,
    }));
  };

  const handleDecreaseCurrent = () => {
    setSubscriptionInfo((prevState) => ({
      ...prevState,
      currUserQuantity: prevState.currUserQuantity - 1,
      nextUserQuantity: prevState.currUserQuantity - 1,
    }));
  };

  const handleIncreaseNext = () => {
    setSubscriptionInfo((prevState) => ({
      ...prevState,
      nextUserQuantity: prevState.nextUserQuantity + 1,
    }));
  };

  const handleDecreaseNext = () => {
    setSubscriptionInfo((prevState) => ({
      ...prevState,
      nextUserQuantity: prevState.nextUserQuantity - 1,
    }));
  };

  const handleConfirmationOpen = (type) => async () => {
    if (type === 'updateConfirm') {
      setIsLoading(true);
      const invoiceBody = {
        newQuantity: subscriptionInfo.currUserQuantity,
        newNextQuantity: subscriptionInfo.nextUserQuantity,
        nextCoupon: '',
      };
      try {
        const result = await axios.post(
          '/api/subscription/calculate',
          invoiceBody,
        );

        console.log("Result from calculate:", result);

        setProratedAmount(
          formatter.format(result.data.amountOwed / 100),
        );
      } catch (error) {
        console.log(error);
      }
    }

    setIsLoading(false);
    setConfirmationOption(type);
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = (doUpdate) => async () => {
    if (doUpdate && confirmationOption !== 'updateError') {
      const res = await CONFIRMATION_OPTIONS[
        confirmationOption
      ].onConfirm();
    }

    setConfirmationOption('');
    setConfirmationOpen(false);
    setIsLoading(false);

    if (doUpdate && errors.length < 1) {
      onClose(true);
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await axios.post('/api/unsubscribe');
      return 'Unsubscribe';
    } catch (error) {
      console.log(error);
    }
  };

  const handleResubscribe = async () => {
    try {
      await axios.post('/api/resubscribe');
      return 'Resubscribe';
    } catch (error) {
      console.log(error);
    }
  };

  const CONFIRMATION_OPTIONS = {
    unsubscribe: {
      message: unsubscribeMessage(subscriptionInfo.currEnd),
      onConfirm: handleUnsubscribe,
    },
    resubscribe: {
      message: resubscribeMessage(subscriptionInfo.currEnd),
      onConfirm: handleResubscribe,
    },
    updateConfirm: {
      message: (
        <>
          <p>
            {editCurrentCycleMessage(
              subscriptionInfo.currUserQuantity -
                subscriptionData.currUserQuantity,
              proratedAmount,
            )}
          </p>
          <p>Do you authorize this charge?</p>
        </>
      ),
      onConfirm: handleEdit,
    },
    update: {
      message: (
        <p>
          {editNextCycleMessage(
            subscriptionInfo.nextUserQuantity,
            subscriptionInfo.nextUserQuantity *
              subscriptionData.nextPricePerUnit,
          )}
        </p>
      ),
      onConfirm: handleEdit,
    },
    updateError: {
      message: editErrorMessage(
        numCurrentUsers,
        subscriptionInfo.nextUserQuantity,
      ),
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.form}
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Edit Subscription</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          disabled={isLoading}
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <Collapse in={errors && errors.length > 0}>
          <Alert severity="error" className={classes.alert}>
            Edit Failed:
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <table className={tableClasses.subscriptionTable}>
            <thead>
              <tr>
                <th />
                <th>Current Cycle</th>
                {!subscriptionData.canceled && <th>Next Cycle</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Paid Accounts</b></td>
                <td>
                  <div className={tableClasses.quantity}>
                    <span>{subscriptionInfo.currUserQuantity}</span>
                    <div className={tableClasses.iconButtons}>
                      {!subscriptionData.canceled && (
                        <>
                          <IconButton
                            aria-label="increase"
                            size="small"
                            onClick={handleIncreaseCurrent}
                          >
                            <AddIcon />
                          </IconButton>
                          <IconButton
                            aria-label="decrease"
                            size="small"
                            onClick={handleDecreaseCurrent}
                            disabled={
                              subscriptionInfo.currUserQuantity <=
                              subscriptionData.currUserQuantity
                            }
                          >
                            <RemoveIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                </td>
                {!subscriptionData.canceled && (
                  <td>
                    <div className={tableClasses.quantity}>
                      <span>{subscriptionInfo.nextUserQuantity}</span>
                      <div className={tableClasses.iconButtons}>
                        <IconButton
                          aria-label="increase"
                          size="small"
                          onClick={handleIncreaseNext}
                        >
                          <AddIcon />
                        </IconButton>
                        <IconButton
                          aria-label="decrease"
                          size="small"
                          onClick={handleDecreaseNext}
                          disabled={
                            subscriptionInfo.nextUserQuantity <= 1
                          }
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    </div>
                  </td>
                )}
              </tr>
              <tr>
                <td><b>Annual Cost</b></td>
                <td>
                  {formatter.format(
                    subscriptionInfo.currUserQuantity *
                      subscriptionData.currPricePerUnit,
                  )}
                </td>
                {!subscriptionData.canceled && (
                  <td>
                    {formatter.format(
                      subscriptionInfo.nextUserQuantity *
                        subscriptionData.nextPricePerUnit,
                    )}
                  </td>
                )}
              </tr>
              <tr>
                <td><b>Cycle End Date</b></td>
                <td>{subscriptionData.currEnd}</td>
                {!subscriptionData.canceled && (
                  <td>{subscriptionData.nextEnd}</td>
                )}
              </tr>
            </tbody>
          </table>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {subscriptionData.canceled ? (
          <Button
            onClick={handleConfirmationOpen('resubscribe')}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Re-subscribe
          </Button>
        ) : (
          <>
            <Button
              onClick={handleConfirmationOpen('unsubscribe')}
              variant="contained"
              disableElevation
              disabled={isLoading}
            >
              Unsubscribe
            </Button>
            <Button
              onClick={handleConfirmationOpen(
                (subscriptionInfo.nextUserQuantity < numCurrentUsers)
                  ? 'updateError'
                  : (subscriptionData.currUserQuantity !== subscriptionInfo.currUserQuantity)
                    ? 'updateConfirm'
                    : 'update',
              )}
              color="primary"
              variant="contained"
              disabled={
                (subscriptionData.currUserQuantity ===
                  subscriptionInfo.currUserQuantity &&
                  subscriptionData.nextUserQuantity ===
                    subscriptionInfo.nextUserQuantity) ||
                isLoading
              }
            >
              Update
            </Button>
          </>
        )}
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
      </DialogActions>
      {confirmationOption && !isLoading && (
        <Dialog
          open={confirmationOpen}
          onClose={handleConfirmationClose(false)}
        >
          <DialogContent>
            <div>
              {CONFIRMATION_OPTIONS[confirmationOption].message}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmationClose(
                confirmationOption !== 'updateError',
              )}
              color="primary"
              variant="contained"
              disableElevation
              autoFocus
              disabled={isLoading}
            >
              {confirmationOption == 'update' || confirmationOption == 'updateConfirm'
                ? 'Yes'
                : 'OK'}
            </Button>
            {confirmationOption !== 'updateError' && (
              <Button
                onClick={handleConfirmationClose(false)}
                color="primary"
                disabled={isLoading}
              >
                No
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default EditSubscriptionModal;
