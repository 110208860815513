import React from "react";
import {Editor} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {colorStyleMap} from './renderUtils';
import useCustomEditorFormat from './useCustomEditorFormat';
import {useStyles} from "./styles";
import useEmojiSelector from "../../hooks/useEmojiSelector";

export default ({richTextStyles, text = "", maxLines = 0, maxCharacters = 0, focusOn = null, focusAfter = 0}) => {

    const classes = useStyles();

    let needsEllipsis = false;
    if (maxLines) {
        let lines = text.split("\n");
        if (lines.length > maxLines) {
            needsEllipsis = true;
            text = lines.slice(0, maxLines).join("\n");
        }
    }
    if (maxCharacters) {
        if (text.length > maxCharacters) {
            needsEllipsis = true;
            text = text.substr(0, maxCharacters);
        }
    }

    if (needsEllipsis) {
        text += "...";
    }

    const [editorState, setEditorState] = useCustomEditorFormat(richTextStyles, text, needsEllipsis);

    const editor = React.useRef(null);

    useEmojiSelector(editor, editorState, setEditorState, focusOn, focusAfter);

    return (
        <Editor ref={editor}
                customStyleMap={colorStyleMap}
                editorState={editorState}
                onChange={() => {
                }}
                readOnly={true}
        />
    );
};
