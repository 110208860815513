import React, { useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { formStyles } from '../../../util/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { FormControl, FormLabel } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CreatableGroupSelect from '../CreatableGroupSelect';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  checkbox: {
    marginTop: -9,
    alignSelf: 'flex-start',
  },
  subtitle: {
    display: 'block',
    color: 'gray',
    fontSize: '0.775rem',
  },
}));

const EditUserModal = ({
  open,
  onClose,
  userData,
  isAdmin,
  selfUserEmail,
}) => {
  if (!userData) {
    return null;
  }

  const checkboxClasses = useStyles();
  const classes = formStyles();
  const [userInfo, setUserInfo] = useState(userData);
  const [moveCases, setMoveCases] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleEdit = async () => {
    let errorList = [];

    if (!userInfo.name) {
      errorList.push('Name must be populated');
    }

    if (!userInfo.groupName) {
      errorList.push('User must be part of a group');
    }

    if (!userInfo.companyName) {
      errorList.push('Org name must be populated');
    }

    if (errorList.length > 0) {
      setErrors(errorList);
    } else {
      try {
        const editUserBody = {
          ...userInfo,
          groups: [userInfo.groupID],
          moveCases,
        };
        setIsLoading(true);
        const res = await axios.put('/api/user', editUserBody);
        setIsLoading(false);
        onClose(true);
      } catch (error) {
        setIsLoading(false);
        setErrors([error]);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.form}
      fullWidth
      maxWidth="sm"
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Edit User</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          disabled={isLoading}
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <Collapse in={errors && errors.length > 0}>
          <Alert severity="error" className={classes.alert}>
            Edit Failed:
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              label="Name"
              autoFocus
              value={userInfo.name}
              onChange={(event) =>
                setUserInfo({
                  ...userInfo,
                  name: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={userInfo.email}
              disabled={true}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          {isAdmin && selfUserEmail === userData.email && (<Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              id="company"
              name="company"
              label="Company"
              value={userInfo.companyName}
              onChange={(event) =>
                setUserInfo({
                  ...userInfo,
                  companyName: event.target.value,
                })
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>)}
          <Grid item xs={12}>
            <FormLabel component={'legend'}>Group</FormLabel>
            <CreatableGroupSelect
              create={true}
              value={{
                label: userInfo.groupName,
                value: userInfo.groupID,
              }}
              onChange={(group) => {
                setUserInfo({
                  ...userInfo,
                  groupName: group.label,
                  groupID: group.value,
                });
              }}
              disabled={!isAdmin}
            />
            {userInfo.groupID !== userData.groupID && (
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      classes={{ root: checkboxClasses.checkbox }}
                    />
                  }
                  label={
                    <>
                      Move cases to new group
                      <Typography
                        component="span"
                        classes={{
                          root: checkboxClasses.subtitle,
                        }}
                      >
                        This will be done automatically if there are
                        no other users in the group
                      </Typography>
                    </>
                  }
                  onChange={() => setMoveCases(!moveCases)}
                />
              </Grid>
            )}
          </Grid>
          {isAdmin && selfUserEmail !== userData.email && (
            <Grid item xs={12}>
              <FormControl component={'fieldset'}>
                <FormLabel component={'legend'}>User Role</FormLabel>
                <RadioGroup
                  value={userInfo.role ? userInfo.role : 'User'}
                  onChange={(e) => {
                    setUserInfo({
                      ...userInfo,
                      role: e.target.value,
                    });
                  }}
                >
                  <Grid container>
                    <Grid item>
                      <FormControlLabel
                        control={<Radio />}
                        value={'User'}
                        label={'User'}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={<Radio />}
                        value={'Admin'}
                        label={'Admin'}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleEdit}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
