import axios from 'axios';
let userInfo = null;
let userInfoLastRefreshed = null;

export const initUserInfo = async () => {
  const result = await axios
    .get('/api/user')
    .catch((error) => console.log("Failed to get user info: ", error));
  if (result && result.data) {
    userInfo = result.data;
    userInfoLastRefreshed = Date.now();
  }
}

export const getUserInfo = async () => {
  if(userInfo && userInfoLastRefreshed > (Date.now() - (1000 * 60))){ // User info should never be more than a minute old
    return userInfo;
  }
  else {
    await initUserInfo();
    return userInfo;
  }
}

export const resetUserInfo = () => {
  userInfo = null;
  userInfoLastRefreshed = null;
}

//window.getUserInfo = getUserInfo;
