import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: '680px',
    padding: theme.spacing(1),
    overflowY: 'visible',
  },
  title: {
    position: "relative"
  },
  container: {
    background: theme.palette.shade.light,
    padding: theme.spacing(2),
    overflowY: 'visible',
  },
  juryBoxGridContainer: {
    zIndex: 3,
    overflowY: 'visible'
  },
  dismissedBoxGridContainer: {
    zIndex: 1
  },
  juryPoolGridContainer: {
    zIndex: 2
  },
  dismissedContainer: {
    minHeight: 210,
    position: "relative"
  },
  dismissedPreLine: {
    whiteSpace: 'pre-line'
  },
  dismissedSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(3),
    borderBottom: "1px solid darkgrey",
    '&:hover': {
      borderBottom: "2px solid black",
    },
    '&:focus-within': {
      borderBottom: "2px solid " + theme.palette.primary.light,
    }
  },
  dismissedSearchInput: {
    flex: 1,
    marginLeft: theme.spacing(1)
  },
  dismissedSearchInputReal: {
    padding: theme.spacing(0.5)
  },
  actionButton: {
    color: "rgba(0, 0, 0, 0.4)"
  },
  infoPanel: {
    marginLeft: theme.spacing(1.5),
    background: 'transparent'
  },
  infoPanelTop: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  demographicsButton: {
    color: 'rgba(0,0,0,0.4)'
  },
  boxTitle: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  boxButtons: {
    marginRight: theme.spacing(1)
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  formRow: {
    display: 'flex',
    marginBottom: '0.5em',
    paddingTop: '2px'
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: "#d9534f",
    color: "#f7f7f7",
    padding: theme.spacing(1),
    width: '100%'
  },
  errorBody: {
    flex: 1
  },
  errorActions: {
    flex: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export default useStyles;
