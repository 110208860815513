import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {formStyles} from "../../util/styles";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import RichTextEditor from "../../components/RichTextEditor";
import Button from "@material-ui/core/Button";
import {updateCaseNotes} from "../../state/asyncActions";
import {isMacOs, isWindows} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 600,
    },
    formControl: {
        marginRight: theme.spacing(1),
        minWidth: 200,
    },
    commentsLabel: {
        color: '#808080',
        marginLeft: '5px',
        userSelect: 'none',
        fontSize: '12px',
    },
    textFieldControl: {
        margin: 0,
        minWidth: 500,
    },
    previewButton: {
        marginLeft: 'auto'
    },
    seatNumber: {
        marginLeft: 'auto',
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    submitInstructions: {
        marginRight: 'auto',
        color: 'gray',
        fontSize: 14,
        display: "flex",
        flexDirection: "column",
        gap: "0.5em"
    },
    code: {
        backgroundColor: "#333",
        color: "white",
        borderRadius: "4px",
        padding: "3px 5px"
    }
}));
export default function CaseNotes({caseId, open, onClose}) {
    const editorRef = React.useRef(null);

    const [cachedNotes, setCachedNotes] = React.useState("");
    const [cachedNotesStyles, setCachedNotesStyles] = React.useState([]);
    const [cachedRevision, setCachedRevision] = React.useState(0);

    const classes = useStyles();
    const formClasses = formStyles();

    const dispatch = useDispatch();

    const {notes, notesStyles, revision} = useSelector(state => {
        let caseInfo = state.app.cases[caseId];
        if (caseInfo) {
            return {
                notes: state.app.cases[caseId]?.notes,
                notesStyles: state.app.cases[caseId]?.notesStyles,
                revision: state.app.cases[caseId]?.revision
            }
        }

        return {
            notes: "",
            notesStyles: [],
            revision: 0
        }
    });

    React.useEffect(() => {
        if (open) {
            setCachedNotes(notes);
            setCachedNotesStyles(notesStyles);
            setCachedRevision(revision);
        }
        else {
            setCachedNotes("");
            setCachedNotesStyles([]);
            setCachedRevision(0);
        }
    }, [open]);

    const handleCancel = (event) => {
        return handleClose(event, 'cancelClick');
    };

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        console.log("Closing case notes for reason: ", reason);
        onClose();
    };

    const handleOk = () => {
        const content = editorRef.current.getContent();

        dispatch(updateCaseNotes(caseId, content.comments, content.richTextStyles, cachedRevision));

        handleClose(null, "saveClick");
    };

    const handleKeyPress = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            handleOk();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            width="md"
        >
            <DialogTitle>Notes</DialogTitle>
            <DialogContent onKeyPress={handleKeyPress} className={classes.dialog}>
                <RichTextEditor
                    key={'case-notes'}
                    label={<Typography variant={'caption'} className={classes.commentsLabel}>Notes</Typography>}
                    richTextStyles={cachedNotesStyles}
                    text={cachedNotes}
                    onSave={handleOk}
                    autoFocus
                    ref={editorRef}/>
            </DialogContent>
            <DialogActions className={formClasses.actions}>
                <div className={classes.submitInstructions}>
                    {isWindows && <div>Emoji keyboard: <code className={classes.code}>Win</code> + <code className={classes.code}>.</code></div>}
                    {isMacOs && <div>Emoji keyboard: <code className={classes.code}>Cmd</code> + <code className={classes.code}>Ctrl</code> + <code className={classes.code}>Space</code></div>}
                    <div>Confirm: <code className={classes.code}>Shift</code> + <code className={classes.code}>Enter</code></div>
                </div>
                <Button
                    onClick={handleOk}
                    color="primary"
                    variant="contained"
                >
                    Save
                </Button>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

}