import React, {useState} from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {Close} from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import {formStyles} from '../../util/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import {createCase} from '../../state/asyncActions';
import {v4 as uuidv4} from "uuid";
import {useDispatch} from 'react-redux';
import {FormControl, FormLabel} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import GridStartIcon from '../../components/GridStartIcon';
import ChipInput from 'material-ui-chip-input';
import StandardFlowIcon from "../../components/StandardFlowIcon";
import SnakeFlowIcon from "../../components/SnakeFlowIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const CreateModal = ({open, onClose, onCreate}) => {
  const classes = formStyles();
  
  const dispatch = useDispatch();
  
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [comments, setComments] = useState('');
  const [jurySize, setJurySize] = useState(18);
  const [numberOfAlternates, setNumberOfAlternates] = useState(0);
  const [alternateProsecutionStrikes, setAlternateProsecutionStrikes] = useState(0);
  const [alternateDefendantStrikes, setAlternateDefendantStrikes] = useState(0);
  const [jurorsPerRow, setJurorsPerRow] = useState(6);
  const [seatNumberStartPosition, setSeatNumberStartPosition] = useState("topLeft");
  const [seatNumberFlow, setSeatNumberFlow] = useState("standard");
  const [prosecutionStrikes, setProsecutionStrikes] = useState(10);
  const [defendantStrikes, setDefendantStrikes] = useState(10);
  const [civilCase, setCivilCase] = useState(false);
  const [demographicCategories, setDemographicCategories] = useState([]);
  const [showDemographics, setShowDemographics] = useState(false);
  const [errors, setErrors] = useState([]);
  
  const handleClose = () => {
    onClose();
  };
  
  const handleAddDemographicCategory = (chip) => {
    let newDemos = [...demographicCategories];
    chip = chip.trim();
    if (newDemos.indexOf(chip) === -1) {
      newDemos.push(chip);
      
      setDemographicCategories(newDemos);
    }
  };
  
  const handleRemoveDemographicCategory = (chip, index) => {
    let newDemos = [...demographicCategories];
    newDemos.splice(index, 1);
    setDemographicCategories(newDemos);
  };
  
  const handleCreate = async () => {
    let errorList = [];
    
    if (!name) {
      errorList.push('Name must be populated');
    }
    
    if (!number) {
      errorList.push('Number must be populated');
    }
    
    if (!jurySize) {
      errorList.push('Number of juror seats must be populated');
    }
    
    if (jurySize > 60) {
      errorList.push("Number of juror seats can't be greater than 60");
    }
    
    if (!prosecutionStrikes) {
      let typeTitle = civilCase ? 'Plaintiff' : 'Prosecution';
      errorList.push(typeTitle + ' strikes must be populated');
    }
    
    if (!defendantStrikes) {
      errorList.push('Defendant strikes must be populated');
    }
    
    if (errorList.length > 0) {
      setErrors(errorList);
    } else {
      const createCaseBody = {
        name: name,
        number: number,
        details: comments,
        juryBoxSize: jurySize,
        prosecutionPeremptStrikes: prosecutionStrikes,
        defendantPeremptStrikes: defendantStrikes,
        jurorsPerRow: jurorsPerRow,
        seatNumberStartPosition: seatNumberStartPosition,
        seatNumberFlow: seatNumberFlow,
        civilCase: civilCase,
        createdAt: (new Date()).toISOString(),
        demographicCategories: demographicCategories,
        showDemographics: showDemographics,
        numberOfAlternates,
        alternateProsecutionPeremptStrikes: alternateProsecutionStrikes,
        alternateDefendantPeremptStrikes: alternateDefendantStrikes,
        notes: "",
        notesStyles: [],
        revision: 0
      };
      let tmpId = uuidv4();
      dispatch(createCase(tmpId, createCaseBody));
      onCreate(tmpId);
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Create Case</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close/>
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <Collapse in={errors && errors.length > 0}>
          <Alert severity="error" className={classes.alert}>
            Create Failed:
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              id="number"
              name="number"
              label="Case Number"
              autoFocus
              value={number}
              onChange={(event) => setNumber(event.target.value)}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              id="name"
              name="name"
              label="Case Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              id="comments"
              name="comments"
              label="Description"
              type="text"
              multiline
              rows={1}
              className={classes.comments}
              value={comments}
              onChange={(event) => setComments(event.target.value)}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Type</FormLabel>
              <RadioGroup row value={civilCase ? "1" : "0"}
                          onChange={(e) => {
                            setCivilCase(e.target.value === "1")
                          }}>
                <FormControlLabel control={<Radio/>} value={"0"} label={'Criminal'}/>
                <FormControlLabel control={<Radio/>} value={"1"} label={'Civil'}/>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              id="prosecution-strikes"
              name="prosecution-strikes"
              label={`${civilCase ? 'Plaintiff' : 'Prosecution'} Peremptory Strikes`}
              type="number"
              value={prosecutionStrikes}
              onChange={(event) =>
                setProsecutionStrikes(event.target.value)
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              id="defendant-strikes"
              name="defendant-strikes"
              label="Defense Peremptory Strikes"
              type="number"
              value={defendantStrikes}
              onChange={(event) =>
                setDefendantStrikes(event.target.value)
              }
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} style={{marginBottom: "10px", marginTop: "10px", padding: "4px", border: "1px solid grey", borderRadius: "3px"}}>
              <Grid item xs={12}>
                <Typography variant={"overline"}>Alternates</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="number-of-alternates"
                  name="number-of-alternates"
                  label="Number of alternates"
                  type="number"
                  value={numberOfAlternates}
                  onChange={(event) => setNumberOfAlternates(event.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 30,
                    },
                    classes: {
                      root: classes.outlinedRoot,
                    },
                  }}
                />
              </Grid>
              {numberOfAlternates > 0 &&
                <React.Fragment>
                  <Grid item xs={6}>
                    <TextField
                      required
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="alternate-prosecution-strikes"
                      name="alternate-prosecution-strikes"
                      label={`${civilCase ? 'Plaintiff' : 'Prosecution'} Peremptory Strikes`}
                      type="number"
                      value={alternateProsecutionStrikes}
                      onChange={(event) =>
                        setAlternateProsecutionStrikes(event.target.value)
                      }
                      InputProps={{
                        classes: {
                          root: classes.outlinedRoot,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="alternate-defendant-strikes"
                      name="alternate-defendant-strikes"
                      label="Defense Peremptory Strikes"
                      type="number"
                      value={alternateDefendantStrikes}
                      onChange={(event) =>
                        setAlternateDefendantStrikes(event.target.value)
                      }
                      InputProps={{
                        classes: {
                          root: classes.outlinedRoot,
                        },
                      }}
                    />
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ChipInput value={demographicCategories || []}
                       fullWidth={true}
                       fullWidthInput={true}
                       variant="outlined"
                       size="small"
                       label="Demographics Tracking Categories"
                       onAdd={handleAddDemographicCategory}
                       onDelete={handleRemoveDemographicCategory}/>
            <Typography variant={"caption"}>Press <code>Enter</code> or <code>Tab</code> to create a
              category</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Show Demographics on Juror Cards</FormLabel>
              <RadioGroup value={showDemographics ? "1" : "0"}
                          onChange={(e) => {
                            setShowDemographics(e.target.value === "1")
                          }}>
                <Grid container>
                  <Grid item><FormControlLabel control={<Radio/>} value={"0"} label={'No'}/></Grid>
                  <Grid item><FormControlLabel control={<Radio/>} value={"1"} label={'Yes'}/></Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              size="small"
              variant="outlined"
              fullWidth
              id="jury-size"
              name="jury-size"
              label="Number of juror seats (including alternates or six pack)"
              type="number"
              value={jurySize}
              onChange={(event) => setJurySize(event.target.value)}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 100,
                },
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Max Number of Jurors Per Row</FormLabel>
              <Select value={parseInt(jurorsPerRow)}
                      onChange={(e) => {
                        setJurorsPerRow(parseInt(e.target.value));
                      }}>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Seat Number Starting Position</FormLabel>
              <RadioGroup value={seatNumberStartPosition}
                          onChange={(e) => {
                            setSeatNumberStartPosition(e.target.value);
                          }}>
                <Grid container>
                  <Grid item><FormControlLabel control={<Radio/>}
                                               value={"topLeft"}
                                               label={<GridStartIcon className={classes.gridStartIcon}/>}
                                               title={'Top Left'}/></Grid>
                  <Grid item><FormControlLabel control={<Radio/>}
                                               value={"topRight"}
                                               label={<GridStartIcon className={classes.gridStartIcon}
                                                                     style={{transform: "rotate(90deg)"}}/>}
                                               title={'Top Right'}/></Grid>
                  <Grid item><FormControlLabel control={<Radio/>}
                                               value={"bottomLeft"}
                                               label={<GridStartIcon className={classes.gridStartIcon}
                                                                     style={{transform: "rotate(270deg)"}}/>}
                                               title={'Bottom Left'}/></Grid>
                  <Grid item><FormControlLabel control={<Radio/>}
                                               value={"bottomRight"}
                                               label={<GridStartIcon className={classes.gridStartIcon}
                                                                     style={{transform: "rotate(180deg)"}}/>}
                                               title={'Bottom Right'}/></Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component={'fieldset'}>
              <FormLabel component={'legend'}>Seat Number Flow</FormLabel>
              <RadioGroup value={seatNumberFlow}
                          onChange={(e) => {
                            setSeatNumberFlow(e.target.value)
                          }}>
                <Grid container spacing={4}>
                  <Grid item>
                    <FormControlLabel control={<Radio />}
                                      value={"standard"}
                                      label={<div className={classes.flowLabel}>
                                        <StandardFlowIcon className={classes.gridStartIcon} />
                                        <span className={classes.flowLabelText}>Standard</span>
                                      </div>
                                      }
                                      title={'Standard'} />
                  </Grid>
                  <Grid item>
                    <FormControlLabel control={<Radio />}
                                      value={"snake"}
                                      label={<div className={classes.flowLabel}>
                                        <SnakeFlowIcon className={classes.gridStartIcon} />
                                        <span className={classes.flowLabelText}>Snaking</span>
                                      </div>}
                                      title={'Snaking'} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleCreate}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModal;
