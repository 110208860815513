import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    paper: {
        padding: 0,
        height: 135,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        overflow: 'hidden',
        color: 'black',
        userSelect: 'none',
        background: 'white',
        borderBottom: "6px solid white",
        marginRight: "0.5em"
    },
    outline: {
        outline: `3px solid ${theme.palette.secondary.main}`,
    },
    juror: {
        background: theme.palette.secondary.main,
        color: 'white',
    },
    dismissed: {
        background: theme.palette.shade.main,
    },
    empty: {
        background: theme.palette.shade.dark,
    },
    header: {
        padding: 2,
        paddingRight: 4,
        paddingLeft: 4,
    },
    idBox: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: 0,
    },
    autoMarginLeft: {
        marginLeft: 'auto',
    },
    demographics: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        wordBreak: "break-word"
    },
    demographicsText: {
        fontSize: 14,
        color: theme.palette.text.hint
    },
    content: {
        padding: 8,
        paddingTop: 2,
        paddingBottom: 2,
        whiteSpace: 'pre-wrap',
        wordBreak: "break-word",
        fontSize: 14
    },
    emojiIndex: {
        backgroundColor: "white",
        padding: "3px 5px 2px 5px",
        borderRadius: "4px",
        fontSize: 12,
        position: "absolute",
        zIndex: 100,
        bottom: -3,
        right: 6,
        boxShadow: "2px 2px 5px -2px black"
    },
    handle: {
        cursor: 'pointer',
    },
    error: {
        flex: 0,
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 1
    },
    errorContent: {
        color: '#f7f7f7',
    },
}));