import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Logo from '../../util/Logo';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '50px'
  },
  callToAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px'
  },
}));

const ExpiredAccount = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container className={classes.root} component="main" maxWidth="xs">
        <div className={classes.logo}>
          <Logo />
        </div>
      </Container>
      <Container component="main" maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align='center' variant="h4">
              Your account has expired
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align='center' variant="h6">
              Subscribe to receieve all the benefits of Jurybox:
            </Typography>
            <Typography align='left' variant="body1">
              <ul>
                <li>Track juror responses and observations on digital sticky notes</li>
                <li>Automatically track peremptory and for-cause dismissals in real time</li>
                <li>Store all your data securely in the Cloud</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.callToAction} component="main" maxWidth="xs">
        <Typography align='center' variant="h6" style={{padding:'10px'}}>
          Click the button to subscribe
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          href="/subscribe"
        >
          Buy Now
        </Button>
      </Container>
    </>
  );
};

export default ExpiredAccount;
