export const getAll = (state) => {
  return state.multiSelect;
}

export const getMode = (state) => {
  return state.multiSelect.mode;
}

export const getSelectedJurorIds = (state) => {
  return state.multiSelect.selectedJurorIds;
}

export const getJurorNames = (state) => {
  let jurorIds = getSelectedJurorIds(state);

  let names = [];
  for (let jurorId of jurorIds) {
    if (state.app.jurors[jurorId]) {
      names.push(state.app.jurors[jurorId].nameAndId || jurorId);
    }
  }
  return names;
}
