import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from '@material-ui/core/AppBar';
import connectionStatus, { SERVER_NOT_REACHABLE, SERVER_REACHABLE } from '../../connectionStatus';

const useStyles = makeStyles((theme) => ({
  offlineBar: {
    color: '#FFF',
    background: '#ff6961',
    minWidth: '680px',
  },
}));

const OfflineBar = () => {
  const classes = useStyles();
  const [showOffline, setShowOffline] = useState(!connectionStatus.isServerReachable());
  const [checkingConnection, setCheckingConnection] = useState(false);

  const hide = () => {
    setShowOffline(false);
  };

  const show = () => {
    setShowOffline(true);
  };

  useEffect(() => {
    connectionStatus.on(SERVER_REACHABLE, hide);
    connectionStatus.on(SERVER_NOT_REACHABLE, show);

    return () => {
      connectionStatus.removeListener(SERVER_REACHABLE, hide);
      connectionStatus.removeListener(SERVER_NOT_REACHABLE, show);
    };
  }, []);

  return (
    <AppBar position="static"
            className={classes.offlineBar}
            style={{
              display: (showOffline ? '' : 'none'),
            }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "5px"
      }}>
        <WifiOffIcon className={classes.action}/>
        <Typography variant="body1" style={{ paddingLeft: '5px' }}>
          Offline
        </Typography>
        <a href={'#retry-connection'}
           style={{ color: 'white', fontSize: '10px', marginLeft: '15px' }}
           onClick={(e) => {
             e.preventDefault();
             setCheckingConnection(true);

             setTimeout(() => {
               connectionStatus.testIsReachable().then(() => {
                 setCheckingConnection(false);
               }, (error) => {
                 console.warn('Failed to try reconnecting due to rejection...', error);
                 setCheckingConnection(false);
               }).catch((error) => {
                 console.warn('Failed to try reconnecting due to exception...', error);
                 setCheckingConnection(false);
               });
             }, 1000);

           }}>Try to reconnect</a>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
      }}>
        <LinearProgress style={{
          visibility: (checkingConnection ? 'visible' : 'hidden'),
          width: '75px',
        }}/>
      </div>
    </AppBar>
  );
};

export default OfflineBar;
