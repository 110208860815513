export const exportHtmlOptions = {
  inlineStyles: {
    HIGHLIGHT_WHITE:{
      style: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      }
    },
    HIGHLIGHT_YELLOW: {
      style: {
        //color: 'rgba(180, 180, 0, 1.0)',
        backgroundColor: 'rgba(255, 255, 0, 0.7)',
      }
    },
    HIGHLIGHT_RED: {
      style: {
        //color: 'rgba(255, 0, 0, 1.0)',
        backgroundColor: 'rgba(255, 0, 0, 0.3)'
      }
    },
    HIGHLIGHT_ORANGE: {
      style: {
        //color: 'rgba(255, 127, 0, 1.0)',
        backgroundColor: 'rgba(255, 127, 0, 0.3)',
      }
    },
    HIGHLIGHT_GREEN: {
      style: {
        //color: 'rgba(0, 180, 0, 1.0)',
        backgroundColor: 'rgba(0, 180, 0, 0.3)'
      }
    },
    HIGHLIGHT_BLUE: {
      style: {
        //color: 'rgba(0, 0, 255, 1.0)',
        backgroundColor: 'rgba(0, 0, 255, 0.3)'
      }
    },
    HIGHLIGHT_INDIGO: {
      style: {
        //color: 'rgba(75, 0, 130, 1.0)',
        backgroundColor: 'rgba(75, 0, 130, 0.3)',
      }
    },
    HIGHLIGHT_VIOLET: {
      style: {
        //color: 'rgba(127, 0, 255, 1.0)',
        backgroundColor: 'rgba(127, 0, 255, 0.3)'
      }
    }
  }
};

export const colorStyleMap = Object.keys(exportHtmlOptions.inlineStyles).reduce((acc, currentKey) => {
  acc[currentKey] = exportHtmlOptions.inlineStyles[currentKey].style;
  return acc;
}, {});

export const COLORS = [
  { label: 'White', style: 'HIGHLIGHT_WHITE' },
  { label: 'Yellow', style: 'HIGHLIGHT_YELLOW' },
  { label: 'Red', style: 'HIGHLIGHT_RED' },
  { label: 'Orange', style: 'HIGHLIGHT_ORANGE' },
  { label: 'Green', style: 'HIGHLIGHT_GREEN' },
  { label: 'Blue', style: 'HIGHLIGHT_BLUE' },
  { label: 'Indigo', style: 'HIGHLIGHT_INDIGO' },
  { label: 'Violet', style: 'HIGHLIGHT_VIOLET' },
];
