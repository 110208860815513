import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import AlertDialog from '../components/AlertDialog';

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const [alertOptions, setAlertOptions] = useState({
    msg: 'Something went wrong!',
    title: 'Alert',
    onSubmit: null,
    variant: 'info',
  });

  const alert = (msg, title, onSubmit, variant) => {
    setAlertOptions({ msg, title, onSubmit, variant });
    setOpen(true);
  };

  const handleOnSubmit = () => {
    if (alertOptions.onSubmit) {
      alertOptions.onSubmit();
    }

    setOpen(false);
  };

  return (
    <>
      <AlertContext.Provider
        value={{
          alert: useCallback(
            ({ msg, title, onSubmit, variant }) =>
              alert(msg, title, onSubmit, variant),
            [],
          ),
        }}
      >
        {children}
      </AlertContext.Provider>
      <AlertDialog
        open={open}
        msg={alertOptions.msg}
        title={alertOptions.title}
        variant={alertOptions.variant}
        onSubmit={handleOnSubmit}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

const useAlert = () => useContext(AlertContext);

export { AlertProvider, useAlert };
