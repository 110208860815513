import axios from 'axios';
import { ActionCreators } from './actions';
import connectionStatus from '../connectionStatus';
import { saveAs } from 'file-saver';
import { navigate } from '@reach/router';
import {
  getCase,
  getJurorsForCase,
  getModalStatuses,
  getPending, getSessionData,
  hasPendingDataToSync,
  TIMED_OUT,
} from './selectors';
import { addDocumentListener, closeDocumentListener, getClient } from '../util/twilioSync';
import { getUserInfo } from '../util/userInfo';
import {findLowestOpenSeatNumber, isLocalId, isSeatFilledInJuryBox} from '../util/juror-helpers';
import {ActionCreators as MultiSelectActionCreators} from "./multiSelect/actions";
import Modes from "./multiSelect/modes";
import {v4 as uuidv4} from "uuid";
import {ActionCreators as EtherealActionCreators, ActionCreators as EtherealActions} from "./ethereal/actions";
import { getMode } from './ethereal/selectors';
import MultiSelectModes from "./multiSelect/modes";
import {useSelector} from "react-redux";
import {getAll} from "./multiSelect/selectors";
import jsonifyCompare from "../util/jsonifyCompare";
import {dismissReason} from "../pages/Case/constants";

export const apiAction = ({ name, apiCall, request, success, failure, temporaryFailure, noConnection }) => {
  request();
  if (!connectionStatus.isServerReachable()) {
    console.warn(`No connection for [${name}].`);
    noConnection();
  } else {
    apiCall().then((res) => {
      if (res && !res.data.error) {
        success(res);
      } else {
        console.error(`Error in response from [${name}]: `, (res && res.data) ? res.data.error : null);
        failure(res?.data?.error);
      }
    }).catch(error => {
      console.error(`Caught exception [${name}]: `, error);
      // Permanent or temporary failure?
      if (error.toString().indexOf('Network') >= 0) {
        // Temporary failure, maybe this check should be done in the action definitions themselves?
        console.log('Temporary failure, so not firing failure callback...');
        if (temporaryFailure) {
          temporaryFailure(error);
        }
      } else {
        // Permanent failure?
        failure(error);
      }
    });
  }
};

export const handleCaseDeleted = (message = null, callback=null) => {
  if (!message) {
    message = "Case not found. It has either been deleted or you are no longer authorized to view it.";
  }

  if (window.location.pathname !== "/") {
    alert(message);
    navigate("/").then(() => {
      console.log("Sent to case list due to: " + message );
      if (callback) {
        callback();
      }
    }).catch((error) => {
      console.error("Failed to navigate to case list: ", error);
    });
  }
}

export const createSession = (sessionData, expires) => {
  return (dispatch, getState) => {
    // Obviously has internet connection now
    connectionStatus.setServerReachable(true);
    dispatch(ActionCreators.sessionCreated(sessionData, expires));
    dispatch(sendPendingData(true));
  };
};

let pendingTimeoutHandle = null;
export const checkForChanges = (forceFullRefresh = false) => {
  return (dispatch, getState) => {
    let now = new Date();

    if (!connectionStatus.isServerReachable()) {
      return;
    }

    let currentState = getState();

    let hasPendingData = hasPendingDataToSync(currentState, (1000 * 60 * 1));
    if (hasPendingData !== false) {
      if (hasPendingData === true) {
        if (pendingTimeoutHandle === null) {
          console.warn("Waiting to get new data until after the pending data is pushed to the server...");
          pendingTimeoutHandle = setTimeout(() => {
            pendingTimeoutHandle = null;
            dispatch(checkForChanges(forceFullRefresh));
          }, 5000); // Check every five seconds until it works
        }
        else {
          console.warn("Already has a pending timeout handle. Not setting up another one.");
        }
        return;
      } else if (hasPendingData === TIMED_OUT) {
        console.warn('Need to handle timed out situation for pending updates.');
        // TODO handle this situation: reset a flag or force an update from the server?
      }
    }

    let checking = currentState.app.checkingForCaseChanges;

    // Make sure we aren't in the middle of a pending sync
    if (checking) {
      let checkingDate = new Date(checking);
      if (checkingDate instanceof Date && isFinite(checkingDate)) {
        let diff = now - checkingDate;
        if (diff >= (1000 * 60 * 3)) { // It's been more than three minutes
          // Let it issue another request, since it is taking too long
          console.log('Allowing another request, since it has been too long: ' + diff + ' milliseconds');
        } else {
          // Nothing to do
          console.log('Not issuing another check, since one is running and it hasn\'t been more than 3 minutes since it was requested.');
          return;
        }
      }

    }

    let newTimestamp = now.toISOString();

    let timestamp = getState().app.lastCaseChangeCheckTimestamp;
    let query = '';
    if (!forceFullRefresh) {
      if (timestamp) {
        query = '?changesSince=' + timestamp;
      }
    }

    apiAction({
      name: 'checkChanges',
      apiCall: async () => axios.get('/api/cases/changes' + query),
      request: () => {
        dispatch(ActionCreators.checkingForCaseChanges());
      },
      success: (res) => {
        console.log('Found changes to ' + res.data.cases.length + ' cases. Fetching changes...', {
          responseData: res.data,
        });

        if (forceFullRefresh) {
          //dispatch(ActionCreators.clearCaseList());
        }

        for (let caseID of res.data.cases) {
          console.log(`Case ${caseID} changed. Getting updated info...`, {
            allCases: res.data.cases,
          });

          dispatch(getCaseInfo(caseID));
        }

        if (res.data.deletedCases) {
          console.log('Found ' + res.data.deletedCases.length + ' deleted cases. Removing...');
          if (res.data.deletedCases.length > 0) {
            dispatch(ActionCreators.deleteCasesSuccess(res.data.deletedCases));
          }
        }

        dispatch(ActionCreators.checkingForCaseChangesSuccess(newTimestamp));
      },
      failure: (error) => {
        dispatch(ActionCreators.checkingForCaseChangesFailure(error));
      },
      noConnection: () => {
        dispatch(ActionCreators.checkingForCaseChangesFailure(null));
      },
      temporaryFailure: (error) => {
        console.warn('Temporary failure during checking for changes: ', error);
      },
    });
  };
};

export const listCases = (forceFullRefresh = false) => {
  return (dispatch, getState) => {
    (async () => {
      // Compare the session data with the userInfo
      let userInfo = await getUserInfo();
      if (!userInfo) {
        console.warn("No user info for listCases")
        return null;
      }
      let sessionData = getSessionData(getState());
      if (!sessionData) {
        console.warn("No session data for listCases...");
        return null;
      }

      let updateSessionData = false;

      if (userInfo.groupsLastUpdated && userInfo.groupsLastUpdated !== sessionData.groupsLastUpdated) {
        console.log("Forcing full refresh because groups have changed since last session update: ", {
          session: sessionData.groupsLastUpdated,
          currently: userInfo.groupsLastUpdated
        });
        forceFullRefresh = true;
        updateSessionData = true;
      }
      else {
        console.log("Listing cases doesn't need full refresh: ", {
          session: sessionData ? sessionData.groupsLastUpdated : 'unknown',
          currently: userInfo ? userInfo.groupsLastUpdated : 'unknown'
        });
      }

      apiAction({
        name: 'listCases',
        apiCall: async () => axios.get('/api/cases'),
        request: () => dispatch(ActionCreators.listCases()),
        success: (res) => {
          dispatch(ActionCreators.listCasesSuccess(res.data));
          if (updateSessionData) {
            // Update the session data so it doesn't attempt update it every time
            dispatch(ActionCreators.updateSessionDataPoint('groupsLastUpdated', userInfo.groupsLastUpdated));
          }
          setTimeout(() => {
            dispatch(checkForChanges(forceFullRefresh));
          }, 500);
        },
        failure: (error) => {
          dispatch(ActionCreators.listCasesFailure(error));
        },
        noConnection: () => {
          dispatch(ActionCreators.listCasesFailure(null));
        },
      });
    })();
  };
};

export const createCase = (tmpId, createCaseBody) => {
  return dispatch => {
    dispatch(ActionCreators.createCase(tmpId, createCaseBody));
    dispatch(createCaseOnServer(tmpId, createCaseBody));
  };
};

export const cloneCase = (caseId) => {
  return (dispatch, getState) => {
    let oldCase = getCase(getState(), {caseId});
    let caseTmpId = uuidv4();
    let newCase = JSON.parse(JSON.stringify(oldCase));
    newCase.name = "Copy of " + newCase.name;
    newCase.createdAt = (new Date).toISOString();
    delete newCase._id;

    // Copy the jurors too
    let oldJurors = getJurorsForCase(getState(), {caseId}),
      newJurors = [];
    for (let oldJuror of oldJurors) {
      let newJuror = JSON.parse(JSON.stringify(oldJuror));
      delete newJuror._id;
      newJurors.push(newJuror);
    }

    // Copied the case
    dispatch(ActionCreators.createCase(caseTmpId, newCase));

    // Copied the jurors
    for (let newJuror of newJurors) {
      let jurorTmpId = uuidv4();
      dispatch(ActionCreators.createJuror(caseTmpId, jurorTmpId, newJuror));
    }

    dispatch(sendPendingData(false));
  };
}

export const createCaseOnServer = (tmpId, createCaseBody) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let caseInfo = { ...createCaseBody };

      if (caseInfo.jurors) {
        delete caseInfo.jurors; // Don't send this info to the server
      }
      if (caseInfo._id) {
        delete caseInfo._id;
      }

      const pendingStart = (new Date()).toISOString();
      apiAction({
        name: 'createCase',
        apiCall: async () => axios.post('/api/cases', caseInfo),
        request: () => {
        },
        noConnection: () => {
          // Not rejecting, because we don't care if it failed
          resolve();
        },
        success: (res) => {
          dispatch(ActionCreators.createCaseSuccess(tmpId, res.data._id, res.data, pendingStart));
          if (window.location.pathname === '/case/' + tmpId && tmpId !== res.data._id) {
            console.log('Navigating to updated case id: ', {
              tmpId,
              caseId: res.data._id,
            });
            navigate(`/case/${res.data._id}`, { replace: true }).then(() => {
              resolve();
            });
          } else {
            resolve();
          }
        },
        failure: (error) => {
          console.error('Failed to create case.', error);
          dispatch(ActionCreators.createCaseFailure(tmpId, error));
          // Not rejecting, because we don't care if the there was an error,
          //  we just want to know when it finished
          reject(error);
        },
        temporaryFailure: (error) => {
          console.warn('Temporary failure while creating a case: ', error);
          resolve(error);
        },
      });
    });
  };
};

let pendingCaseInfoTimeoutHandles = {};
export const getCaseInfo = (caseId, fromTwilio=false) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      console.log('Getting data for case: ' + caseId);
      if (isLocalId(caseId)) {
        // This is only a local case right now. It must be synced.
        resolve(null);
        return;
      }

      let currentState = getState();
      let hasPendingData = hasPendingDataToSync(currentState, (1000 * 60 * 1));
      if (hasPendingData !== false) {
        if (hasPendingData === true) {
          if (connectionStatus.isServerReachable()) {
            console.log('Waiting for pending updates to be pushed...');
            if (!pendingCaseInfoTimeoutHandles[caseId]) {
              pendingCaseInfoTimeoutHandles[caseId] = setTimeout(() => {
                pendingCaseInfoTimeoutHandles[caseId] = null;
                dispatch(getCaseInfo(caseId, fromTwilio));
              }, 5000); // Check every five seconds until it works
            }
            else {
              console.warn("Already has case timeout handle. Not creating another.", {
                caseId
              });
            }
            resolve(null);
          } else {
            console.warn('Internet is not currently reachable.');
            // Don't keep trying
          }
          return;
        } else if (hasPendingData === TIMED_OUT) {
          console.warn('Need to handle timed out situation for pending updates.');
          // TODO handle this situation: reset a flag or force an update from the server?
        }
      }

      const pendingStart = (new Date()).toISOString();
      apiAction({
        name: 'getCaseInfo',
        apiCall: async () => axios.get('/api/cases/' + caseId),
        request: () => dispatch(ActionCreators.getCaseInfo(caseId)),
        noConnection: () => {
          ActionCreators.getCaseInfoFailure(caseId, null);
          resolve(null);
        },
        success: (res) => {
          dispatch(ActionCreators.getCaseInfoSuccess(caseId, res.data, pendingStart, fromTwilio));
          resolve(res);
        },
        failure: (error) => {
          if (error && error.response && error.response.status === 403) {
            // Case has probably been unshared
            handleCaseDeleted("You are not authorized to view this case", () => {
              dispatch(ActionCreators.deleteCase(caseId));
              dispatch(ActionCreators.deleteCasesSuccess([caseId]));
            });
          }
          else if (error && error.response && error.response.status === 404) {
            handleCaseDeleted();
          }
          else {
            dispatch(ActionCreators.getCaseInfoFailure(caseId, error));
            reject(error);
          }
        },
        temporaryFailure: (error) => {
          // Nothing to do here
          reject(error);
        },
      });
    });
  };
};

export const updateCase = (caseId, editCaseBody) => {
  return (dispatch, getState) => {
    (async () => {
      let pendingUpdate = getState().app.pendingUpdateCases[caseId];
      let caseInfo = getCase(getState(), {caseId});

      if (!caseInfo) {
        return;
      }

      const updateBody = {...editCaseBody, revision: caseInfo.revision};

      let caseUpdatingPromise;

      dispatch(ActionCreators.updateCase(caseId, updateBody));
      // Check if this case still needs to be created or updated on the server
      if (getState().app.pendingCreateCases[caseId] === undefined &&
        pendingUpdate === undefined) {
        try {
           caseUpdatingPromise = updateCaseOnServer(caseId, updateBody)(dispatch);
        }
        catch(error) {
          console.warn("Did not update case data on server: ", error);
        }
      }

      let jurors = getJurorsForCase(getState(), {caseId});

      let newJuryBoxSize = parseInt(editCaseBody.juryBoxSize);
      let changesMade = false;

      if (caseInfo.juryBoxSize > newJuryBoxSize) {
        console.log(`Jury Box is now smaller ${caseInfo.juryBoxSize} -> ${editCaseBody.juryBoxSize}`);
        for (let juror of jurors) {
          if (juror.seatNumber > newJuryBoxSize) {
            // Moving to the jury pool.
            let updatedJuror = {...juror, seatNumber: null};
            try {
              changesMade = true;
              dispatch(ActionCreators.updateJuror(caseId, juror._id, updatedJuror));
            }
            catch(error) {
              console.warn("Failed to update juror after updating case juryBoxSize: ", error);
            }
          }
        }
      }
      else {
        console.log(`Jury Box size is larger or the same: ${caseInfo.juryBoxSize} -> ${editCaseBody.juryBoxSize}`);
      }

      let newAlternatesSize = parseInt(editCaseBody.numberOfAlternates);
      if (caseInfo.numberOfAlternates > newAlternatesSize) {
        console.log(`Number of alternates is now lower ${caseInfo.numberOfAlternates} -> ${editCaseBody.numberOfAlternates}`);
        for (let juror of jurors) {
          if (newAlternatesSize === 0) {
            // Unmark alternate dismissed as well
            if (juror.alternateDiscarded || juror.alternateSeatNumber) {
              //console.log("Moving alternate to jury pool or marking as not alternated discard: ", juror);
              let updatedJuror = {...juror, alternateSeatNumber: null, alternateDiscarded: null, discardedReason: "", discarder: ""};
              try {
                changesMade = true;
                dispatch(ActionCreators.updateJuror(caseId, juror._id, updatedJuror));
              }
              catch(error) {
                console.warn("Failed to mark juror as not alternate discarded after updating case numberOfAlternates to 0: ", error);
              }
            }
          }
          else {
            if (juror.alternateSeatNumber > newAlternatesSize) {
              // Moving to the jury pool.
              //console.log("Moving alternate to jury pool: ", juror);
              let updatedJuror = {...juror, alternateSeatNumber: null};
              try {
                changesMade = true;
                dispatch(ActionCreators.updateJuror(caseId, juror._id, updatedJuror));
              }
              catch(error) {
                console.warn("Failed to update juror after updating case numberOfAlternates: ", error);
              }
            }
          }
        }
      }
      else {
        console.log(`Number of alternates is higher or the same: ${caseInfo.numberOfAlternates} -> ${editCaseBody.numberOfAlternates}`);
      }

      if (newAlternatesSize === 0) {
        let mode = getMode(getState());
        if (mode === "alternates") {
          dispatch(EtherealActions.setMode("jury-box"));
        }
      }

      if (caseUpdatingPromise) {
        await caseUpdatingPromise;
      }
      if (changesMade) {
        dispatch(sendPendingData(false));
      }

    })();
  };
};

export const updateCaseNotes = (caseId, notes, notesStyles, revision) => {
  return (dispatch, getState) => {
    (async () => {
      let caseInfo = getCase(getState(), {caseId});
      let updatedCase = {...caseInfo, notes, notesStyles, revision};
      dispatch(updateCase(caseId, updatedCase));
    })();
  }
}

export const updateCaseOnServer = (caseId, editCaseBody) => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      if (isLocalId(caseId)) {
        console.warn('Case is only local so far. It must be created on the server before it can be updated.');
        resolve();
        return;
      }

      let caseInfo = { ...editCaseBody };

      if (caseInfo.jurors) {
        delete caseInfo.jurors; // Don't send this info to the server
      }

      let updateStarted = (new Date()).toISOString();

      apiAction({
        name: 'updateCase',
        apiCall: async () => axios.put(`/api/cases/${caseId}`, caseInfo),
        request: () => {
        },
        noConnection: () => {
          reject(new Error('No connection.'));
        },
        success: (res) => {
          dispatch(ActionCreators.updateCaseSuccess(caseId, res.data, updateStarted));
          resolve(res);
        },
        failure: (error) => {
          console.error('Failed to update case: ', error);
          dispatch(ActionCreators.updateCaseFailure(caseId, error));
          if (error && error.response && error.response.status === 403) {
            // Case has probably been unshared
            handleCaseDeleted("You are not authorized to view this case", () => {
              dispatch(ActionCreators.deleteCase(caseId));
              dispatch(ActionCreators.deleteCasesSuccess([caseId]));
            });
          }
          else if (error && error.response && error.response.status === 404) {
            handleCaseDeleted();
          }
          else {
            reject(error);
          }
        },
        temporaryFailure: (error) => {
          resolve(error);
        },
      });
    });
  };
};

export const deleteCase = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.deleteCase(caseId));
    // Check if this case still needs to be created or updated on the server
    if (getState().app.pendingCreateCases[caseId] === undefined) {
      deleteCaseOnServer(caseId)(dispatch).then((result) => {
        console.log("Delete case on server success.");
      }, (error) => {
        console.warn("Delete case on server rejected: ", error);
      }).catch(error => {
        console.warn("Delete case on server exception: ", error);
      });
    }
  };
};

export const deleteCaseOnServer = (caseId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      if (isLocalId(caseId)) {
        console.warn('Nothing to delete on the server, this is a local id.');
        dispatch(ActionCreators.deleteCasesSuccess([caseId]));
        resolve();
      }

      apiAction({
        name: 'deleteCase',
        apiCall: async () => axios.delete(`/api/cases/${caseId}`),
        request: () => {
        },
        noConnection: () => {
          reject(new Error('No connection.'));
        },
        success: (res) => {
          dispatch(ActionCreators.deleteCasesSuccess([caseId]));
          resolve(res);
        },
        failure: (error) => {
          if (error && error.response && error.response.status === 404) {
            dispatch(ActionCreators.deleteCasesSuccess([caseId]));
          }
          else {
            let action = ActionCreators.deleteCaseFailure(caseId, error);
            console.log('Dispatching error action: ', action);
            dispatch(action);
            reject(error);
          }
        },
        temporaryFailure: (error) => {
          resolve(error);
        },
      });
    });
  };
};

export const addSpacer = (caseId, spaceNumber) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(ActionCreators.addSpacer(caseId, spaceNumber));
      // Queue up update on server
      dispatch(sendPendingData(false, 2000));
    });
  };
};

export const removeSpacer = (caseId, spaceNumber) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(ActionCreators.removeSpacer(caseId, spaceNumber));
      // Queue up update on server
      dispatch(sendPendingData(false, 2000));
    });
  };
};

export const createJuror = (caseId, tmpId, jurorBody) => {
  return (dispatch, getState) => {
    // Check for seat number and alternate seat number. Any existing juror in that (alternate) seat means this one goes in the jury pool
    console.log("Does this one have a seat number? ", {
      tmpId,
      jurorBody
    })
    if (jurorBody.seatNumber || jurorBody.alternateSeatNumber) {
      let jurors = getJurorsForCase(getState(), {caseId});
      let mode = 'jury-box';
      let seatNumber = jurorBody.seatNumber;
      if (jurorBody.alternateSeatNumber) {
        mode = 'alternates';
        seatNumber = jurorBody.alternateSeatNumber;
      }
      console.log("Checking for filled seat...", {
        seatNumber,
        mode,
        jurors
      });
      if (isSeatFilledInJuryBox(jurors, seatNumber, mode)) {
        console.log("Seat is filled. Sending to jury pool...");
        jurorBody.seatNumber = null;
        jurorBody.alternateSeatNumber = null;
      }
    }
    dispatch(ActionCreators.createJuror(caseId, tmpId, jurorBody));
    if (getState().app.pendingCreateCases[caseId] === undefined) {
      createJurorOnServer(caseId, tmpId, jurorBody)(dispatch).then((result) => {
        console.log("Create juror on server success.");
      }, (error) => {
        console.warn("Create juror on server rejected: ", error);
      }).catch(error => {
        console.warn("Create juror on server exception: ", error);
      });
    }
  };
};

export const createJurorOnServer = (caseId, tmpId, jurorBody) => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      if (isLocalId(caseId)) {
        console.warn('This case is not yet created on the server. Juror cannot yet be synced.');
        resolve();
        return;
      }

      if (jurorBody._id) {
        delete jurorBody._id;
      }

      apiAction({
        name: 'createJuror',
        apiCall: async () => axios.post(`/api/cases/${caseId}/jurors/`, jurorBody),
        request: () => {
        },
        noConnection: () => {
          reject(new Error('No connection.'));
        },
        success: (res) => {
          dispatch(ActionCreators.createJurorSuccess(caseId, tmpId, res.data._id, res.data));
          resolve(res);
        },
        failure: (error) => {
          dispatch(ActionCreators.createJurorFailure(caseId, tmpId, error));
          if (error && error.response && error.response.status === 404) {
            alert("Case is not available on the server. It has probably been deleted.");
            navigate("/").then(() => {console.log("Sent to case list due to deleted case.")}).catch((error) => {
              console.error("Failed to navigate to case list: ", error);
            });
          }
          else {
            reject(error);
          }
        },
        temporaryFailure: (error) => {
          resolve(error);
        },
      });
    });
  };
};

export const restoreJurorToBox = (caseId, jurorId, mode) => {
  return (dispatch, getState) => {
    console.log("Restoring juror...", {
      jurorId,
      mode
    });
    let seatNumberAttributeName = mode === "jury-box" ? "seatNumber" : "alternateSeatNumber";
    let caseInfo = getState().app.cases[caseId];
    let jurors = getJurorsForCase(getState(), {caseId});
    let juror = getState().app.jurors[jurorId];
    if (!juror) {
      console.warn(`Unable to find juror for juror id '${jurorId}'.`)
      return false;
    }
    let updatedJuror = {...juror, alternateDiscarded: false, discarded: false};
    let seatNumber = juror[seatNumberAttributeName];

    let size = mode === "jury-box" ? caseInfo.juryBoxSize : caseInfo.numberOfAlternates;

    if (seatNumber) {
      console.log('Already has seat number: ', {
        seatNumber
      });
      if (isSeatFilledInJuryBox(jurors, seatNumber, mode)) {
        console.log("Seat is taken, finding different seat...");
        // Pick the lowest available box
        seatNumber = findLowestOpenSeatNumber(jurors, size, mode);
      }
    }
    else {
      seatNumber = findLowestOpenSeatNumber(jurors, size, mode);
    }

    console.log("Updating juror with seat number: ", {
      seatNumberAttributeName,
      seatNumber
    });

    // If there is no seat available, send the juror to the jury pool
    updatedJuror[seatNumberAttributeName] = seatNumber;
    dispatch(updateJuror(caseId, jurorId, updatedJuror));
  }
};

export const swapJurorToOther = (caseId, jurorId, mode) => {
  return (dispatch, getState) => {
    let destinationMode = mode === 'jury-box' ? 'alternates' : 'jury-box';
    let oldSeatNumberAttributeName = mode === "jury-box" ? "seatNumber" : "alternateSeatNumber";
    let seatNumberAttributeName = destinationMode === "jury-box" ? "seatNumber" : "alternateSeatNumber";

    let caseInfo = getState().app.cases[caseId];
    let jurors = getJurorsForCase(getState(), {caseId});
    let juror = getState().app.jurors[jurorId];
    let updatedJuror = {...juror, [oldSeatNumberAttributeName]: null, [seatNumberAttributeName]: juror[oldSeatNumberAttributeName]};
    let seatNumber = juror[oldSeatNumberAttributeName];

    let size = destinationMode === "jury-box" ? caseInfo.juryBoxSize : caseInfo.numberOfAlternates;

    if (seatNumber && seatNumber <= size) {
      console.log('Already has seat number: ', {
        seatNumber
      });
      if (isSeatFilledInJuryBox(jurors, seatNumber, destinationMode)) {
        console.log("Seat is taken, finding different seat...");
        // Pick the lowest available box
        seatNumber = findLowestOpenSeatNumber(jurors, size, destinationMode);
      }
    }
    else {
      seatNumber = findLowestOpenSeatNumber(jurors, size, destinationMode);
    }

    console.log("Updating juror with seat number: ", {
      seatNumberAttributeName,
      seatNumber
    });

    if (seatNumber === null) {
      dispatch(EtherealActions.addAlert("Juror could not be mode to destination since it is full. The juror has been placed in the jury pool instead.", "Destination full", "warning"));
    }

    // If there is no seat available, send the juror to the jury pool
    updatedJuror[seatNumberAttributeName] = seatNumber;
    dispatch(updateJuror(caseId, jurorId, updatedJuror));
  }
}

export const updateJuror = (caseId, jurorId, juror) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      console.log("Updating juror: ", {
        juror
      });
      dispatch(ActionCreators.updateJuror(caseId, jurorId, juror));
      // Queue up update on server
      dispatch(sendPendingData(false));
    });
  };
};

export const multiJurorEdit = (caseId, jurorIds, commentText, commentsStyles, demographics) => {
  return (dispatch, getState) => {
    // Send multi-update to reducer
    dispatch(ActionCreators.multiJurorComment(caseId, jurorIds, commentText, commentsStyles));
    if (demographics.length > 0) {
      dispatch(ActionCreators.multiJurorDemographics(caseId, jurorIds, demographics));
    }
    // Close modal
    dispatch(MultiSelectActionCreators.setMode(Modes.OFF));
    // send pending updates to server
    dispatch(sendPendingData(false));
  };
};

export const fillEmptySpaces = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.fillEmptySpaces(caseId));
    // send pending updates to server
    dispatch(sendPendingData(false));
  }
};

export const fillEmptyAlternateSpaces = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.fillEmptyAlternateSpaces(caseId));
    // send pending updates to server
    dispatch(sendPendingData(false));
  }
};

export const emptyJuryBox = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.emptyJuryBox(caseId));
    // send pending updates to server
    dispatch(sendPendingData(false));
  }
}

export const emptyAlternates = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.emptyAlternates(caseId));
    // send pending updates to server
    dispatch(sendPendingData(false));
  }
}

export const fillFromPool = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.fillFromPool(caseId));
    // send pending updates to server
    dispatch(sendPendingData(false));
  }
}

export const fillAlternatesFromPool = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.fillAlternatesFromPool(caseId));
    // send pending updates to server
    dispatch(sendPendingData(false));
  }
}

export const deleteJuror = (caseId, jurorId) => {
  console.log('Deleting juror async action called...');

  return (dispatch, getState) => {
    console.log('Dispatching initial action...');
    dispatch(ActionCreators.deleteJuror(caseId, jurorId));
    // Check if this case still needs to be created or updated on the server
    if (getState().app.pendingCreateJurors[jurorId] === undefined &&
      getState().app.pendingCreateCases[caseId] === undefined) {

      console.log('Deleting on server...');
      deleteJurorOnServer(caseId, jurorId)(dispatch).then(() => {
        console.log('Removed the juror.', jurorId);
      }, (error) => {
        console.warn('Juror not removed on server: ', error);
      }).catch(error => {
        console.error('Exception while trying to remove from server: ', {
          error,
          caseId,
          jurorId,
        });
      });
    }
  };
};

export const deleteJurorOnServer = (caseId, jurorId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {

      if (isLocalId(caseId)) {
        console.warn('This case is not yet created on the server. Juror can be deleted safely locally.');
        dispatch(ActionCreators.deleteJurorSuccess(caseId, jurorId));
        resolve();
        return;
      }

      if (isLocalId(jurorId)) {
        console.warn('This juror is not yet created on the server. Juror can be deleted safely locally.');
        dispatch(ActionCreators.deleteJurorSuccess(caseId, jurorId));
        resolve();
        return;
      }

      apiAction({
        name: 'deleteJuror',
        apiCall: async () => axios.delete(`/api/cases/${caseId}/jurors/${jurorId}`),
        request: () => {
        },
        noConnection: () => {
          reject(new Error('No connection'));
        },
        success: (res) => {
          dispatch(ActionCreators.deleteJurorSuccess(caseId, jurorId));
          resolve(res);
        },
        failure: (error) => {
          dispatch(ActionCreators.deleteJurorFailure(caseId, jurorId, error));
          if (error && error.response && error.response.status === 403) {
            // Case has probably been unshared
            handleCaseDeleted("You are not authorized to view this case", () => {
              dispatch(ActionCreators.deleteCase(caseId));
              dispatch(ActionCreators.deleteCasesSuccess([caseId]));
            });
          }
          else if (error && error.response && error.response.status === 404) {
            handleCaseDeleted();
          }
          else {
            reject(error);
          }
        },
        temporaryFailure: (error) => {
          resolve(error);
        },
      });
    });
  };
};

export const exportCasePdf = async (caseId, caseName) => {
  try {
    const result = await axios.get('/api/pdf/' + caseId, {
      method: 'GET',
      responseType: 'blob',
    });
    if (!result.data.error) {
      const file = new Blob(
        [result.data],
        { type: 'application/pdf' },
      );
      saveAs(file, caseName + '.pdf');
    } else {
      console.log('Server error when exporting PDF: ', result.data.error);
    }
  } catch (error) {
    console.error('Failed to export PDF: ', error);
  }
};

// Fire this when server is reachable
export const sendPendingData = (checkForChangesAfter=false, debounceTimer=1000) => {
  const thunk = (dispatch, getState) => {
    let sessionData = getSessionData(getState());

    if (!sessionData || !sessionData.email) {
      console.log("Not logged in.");
      return true;
    }

    async function processPending() {

      let pending = getPending(getState());

      dispatch(ActionCreators.processingPending());
      const internalPendingStart = (new Date()).toISOString();

      if (pending.totalPending === 0) {
        console.log('Nothing to send.');
        return true;
      }

      let pendingCreateCaseIds = Object.keys(pending.cases.toCreate);
      console.log('Need to send ' + pendingCreateCaseIds.length + ' new cases...');
      for (let pendingCreateCaseId of pendingCreateCaseIds) {
        let caseInfo = { ...getState().app.cases[pendingCreateCaseId] };
        await createCaseOnServer(pendingCreateCaseId, caseInfo)(dispatch);
      }
      let pendingUpdateCaseIds = Object.keys(pending.cases.toUpdate);
      console.log('Need to send ' + pendingUpdateCaseIds.length + ' updated cases...');
      for (let pendingUpdateCaseId of pendingUpdateCaseIds) {
        let caseInfo = { ...getState().app.cases[pendingUpdateCaseId] };
        await updateCaseOnServer(pendingUpdateCaseId, caseInfo)(dispatch);
      }
      let pendingDeleteCaseIds = Object.keys(pending.cases.toDelete);
      console.log('Need to send ' + pendingDeleteCaseIds.length + ' deleted cases...');
      for (let pendingDeleteCaseId of pendingDeleteCaseIds) {
        await deleteCaseOnServer(pendingDeleteCaseId)(dispatch);
      }

      let groupedByCaseId = {};
      const setupBatchJurorUpdate = (aCaseId) => {
        if (!groupedByCaseId[aCaseId]) {
          groupedByCaseId[aCaseId] = {
            created: [],
            updated: [],
            deleted: []
          };
        }
      };

      let pendingCreateJurorIds = Object.keys(pending.jurors.toCreate);
      const appState = getState().app;
      console.log('Need to send ' + pendingCreateJurorIds.length + ' new jurors...');
      for (let pendingCreateJurorId of pendingCreateJurorIds) {
        let jurorInfo = { ...appState.jurors[pendingCreateJurorId] };
        if (jurorInfo?._id) {
          let caseId = appState.pendingCreateJurors[pendingCreateJurorId].caseId;
          setupBatchJurorUpdate(caseId);
          groupedByCaseId[caseId].created.push(jurorInfo);
        }
        else {
          console.log(`Removing false pending juror create ${pendingCreateJurorId}...`, {
            jurorInfo
          });
          dispatch(ActionCreators.removePendingJurorCreate(pendingCreateJurorId));
        }
      }
      let pendingUpdateJurorIds = Object.keys(pending.jurors.toUpdate);
      console.log('Need to send ' + pendingUpdateJurorIds.length + ' updated jurors...');
      for (let pendingUpdateJurorId of pendingUpdateJurorIds) {
        if (isLocalId(pendingUpdateJurorId)) {
          dispatch(ActionCreators.removePendingJurorCreate(pendingUpdateJurorId));
        }
        else {
          let jurorInfo = {...appState.jurors[pendingUpdateJurorId]};
          let caseId = appState.pendingUpdateJurors[pendingUpdateJurorId].caseId;
          setupBatchJurorUpdate(caseId);
          groupedByCaseId[caseId].updated.push(jurorInfo);
        }
      }
      let pendingDeleteJurorIds = Object.keys(pending.jurors.toDelete);
      console.log('Need to send ' + pendingDeleteJurorIds.length + ' deleted jurors...');
      for (let pendingDeleteJurorId of pendingDeleteJurorIds) {
        let caseId = appState.pendingDeleteJurors[pendingDeleteJurorId].payload.caseId;
        setupBatchJurorUpdate(caseId);
        groupedByCaseId[caseId].deleted.push(pendingDeleteJurorId);
      }

      for (const [caseId, jurorChanges] of Object.entries(groupedByCaseId)) {
        console.log(`Sending juror changes for ${caseId}...`, jurorChanges);
        try {
          let response = await axios.post(`/api/cases/${caseId}/jurors/batch-update`, jurorChanges, {
            timeout: 0, // Don't time out on this request
          });
          if (response.status === 200) {
            dispatch(ActionCreators.batchUpdateJurorsSuccess(caseId, response.data, internalPendingStart));
            if (response.data.createdFailed.length > 0 ||
              response.data.updatedFailed.length > 0 ||
              response.data.deletedFailed.length > 0) {
              throw new Error("Some operations failed. Please review the errors and make the necessary updates.");
            }
          }
          else {
            // Something failed
            console.warn("Unexpected response during batch update: ", response.data);
            throw new Error('Unexpected response: ' + response.data);
          }
        }
        catch(error) {
          console.error("Failed to do batch update: ", error, {
            res: error.response
          });
          if (error && error.response && error.response.status === 403) {
            // Case has probably been unshared
            handleCaseDeleted("You are not authorized to view this case", () => {
              dispatch(ActionCreators.deleteCase(caseId));
              dispatch(ActionCreators.deleteCasesSuccess([caseId]));
            });
          }
          else if (error && error.response && error.response.status === 404) {
            handleCaseDeleted();
          }
          else {
            throw error;
          }
        }

        return true;
      }
    }

    console.log('Sending pending updates to server...');
    let pendingStart = getState().app.processingPendingStart;

    if (pendingStart) {
      console.log('Looks like the process is already running', {
        lastStart: pendingStart
      });

      return true;
    }

    processPending().then(() => {
      console.log('Pending creates/updates/deletes are resolved.');
      dispatch(ActionCreators.processingPendingSuccess());
      if (checkForChangesAfter) {
        // Check for changes from the server
        console.log("Checking for changes after sending pending data...");
        dispatch(checkForChanges());
      }
    }, (error) => {
      console.log('Rejected while processing pending: ', error);
      dispatch(ActionCreators.processingPendingFailure(error));
    }).catch((error) => {
      console.log('Exception while processing pending: ', error);
      dispatch(ActionCreators.processingPendingFailure(error));
    });
  };

  thunk.meta = {
    debounce: {
      time: debounceTimer,
      key: 'SEND_PENDING_CHANGES'
    }
  };

  return thunk;
};

export const listenForCaseChanges = (caseId) => {
  return (dispatch, getState) => {
    (async () => {
      let client = await getClient();
      if (client) {
        await addDocumentListener(caseId, async (data) => {
          if (data.data && data.data.sessionID) {
            const userInfo = await getUserInfo();
            if (userInfo && userInfo.sessionID) {
              if (userInfo.sessionID !== data.data.sessionID) {
                let { editingCase, editingJuror, previewingJuror } = getModalStatuses(getState());
                if (editingCase || (editingJuror && !previewingJuror)) {
                  console.log("Deferring update...");
                  dispatch(ActionCreators.caseNeedsUpdateFromTwilio(caseId))
                } else {
                  console.log("Requesting update now...");
                  await getCaseInfo(caseId, true)(dispatch, getState);
                }
              }
            } else {
              console.log("Failed to get user info: ", userInfo);
            }
          }
          else {
            console.log("Failed to get data value from Sync push: ", data);
          }
        });
      }
    })();
  }
};

export const stopListeningForCaseChanges = (caseId) => {
  return (dispatch, getState) => {
    (async () => {
      await closeDocumentListener(caseId);
    })()
  }
};

export const closeCaseModal = (caseId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.closeCaseModal(caseId));
    let pendingChangesFromTwilio = getState().app.casesNeedingUpdateFromTwilio;
    if (pendingChangesFromTwilio[caseId]) {
      dispatch(getCaseInfo(caseId, true));
    }
  }
};

export const closeJurorModal = (caseId, jurorId) => {
  return (dispatch, getState) => {
    dispatch(ActionCreators.closeJurorModal(caseId, jurorId));
    let pendingChangesFromTwilio = getState().app.casesNeedingUpdateFromTwilio;
    if (pendingChangesFromTwilio[caseId]) {
      dispatch(getCaseInfo(caseId, true));
    }
  }
};

export const updateJurorSeat = (caseId, jurorId, seatNumber) => {
  return (dispatch, getState) => {
    const juror = getState().app.jurors[jurorId];
    const mode = getMode(getState());
    const seatNumberAttributeName = mode === "jury-box" ? "seatNumber" : "alternateSeatNumber";

    const newJurorInfo = {...juror, [seatNumberAttributeName]: seatNumber};
    console.log('Updated: ', newJurorInfo);
    dispatch(updateJuror(caseId, jurorId, newJurorInfo));
  }
};

export const dismissJuror = (caseId, jurorId, reason) => {
  return (dispatch, getState) => {
    const juror = getState().app.jurors[jurorId];
    const mode = getMode(getState());
    const discardedAttributeName = mode === "jury-box" ? "discarded" : "alternateDiscarded";
    const newJurorProps = {
      [discardedAttributeName]: true,
    };
    if (reason === dismissReason.ProsecutionPerempt) {
      newJurorProps.discardedReason = 'peremptory';
      newJurorProps.discarder = 'prosecutor';
    } else if (reason === dismissReason.DefensePerempt) {
      newJurorProps.discardedReason = 'peremptory';
      newJurorProps.discarder = 'defense';
    } else if (reason === dismissReason.BothPerempt) {
      newJurorProps.discardedReason = 'peremptory';
      newJurorProps.discarder = 'both';
    } else if (reason === dismissReason.ForCause) {
      newJurorProps.discardedReason = 'cause';
    } else if (reason === dismissReason.ForHardship) {
      newJurorProps.discardedReason = 'hardship';
    }
    let newJurorInfo = {...juror, ...newJurorProps};
    newJurorInfo.updated = (new Date()).toISOString();
    dispatch(updateJuror(caseId, jurorId, newJurorInfo));
  }
}

export const handleJurorCardClick = (caseId, jurorId, seatNumber) => {
  return (dispatch, getState) => {
    const appState = getState().app;
    const juror = jurorId ? appState.jurors[jurorId] : {};
    const mode = getMode(getState())
    const seatNumberAttributeName = mode === "jury-box" ? "seatNumber" : "alternateSeatNumber";
    let tmpJurorInfo = {...juror};
    if (!tmpJurorInfo) {
      tmpJurorInfo = {
        [seatNumberAttributeName]: seatNumber,
      };
    }
    if (tmpJurorInfo[seatNumberAttributeName] === undefined) {
      tmpJurorInfo[seatNumberAttributeName] = seatNumber;
    }

    const multiSelect = getAll(getState());

    if (multiSelect && multiSelect.mode === MultiSelectModes.ON) {
      if (tmpJurorInfo._id) {
        if (multiSelect.selectedJurorIds.indexOf(tmpJurorInfo._id) === -1) {
          dispatch(MultiSelectActionCreators.addJurorId(tmpJurorInfo._id));
        } else {
          dispatch(MultiSelectActionCreators.removeJurorId(tmpJurorInfo._id));
        }
      }
    } else {
      let seatNumber = tmpJurorInfo[seatNumberAttributeName]
      if (juror.discarded || juror.alternateDiscarded) {
        seatNumber = null;
      }
      dispatch(ActionCreators.openJurorModal(caseId, tmpJurorInfo._id, seatNumber, mode));
    }
  }
}
