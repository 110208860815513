import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useFormFields } from '../../util/hooks';
import axios from 'axios';
import { useAuth } from '../../util/authContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { formStyles } from '../../util/styles';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import MfaSetupModal from '../Account/Modals/MfaSetupModal';

const SignInForm = () => {
  const classes = formStyles();
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");
  const [showTokenInput, setShowTokenInput] = useState(false);
  const [preferredMfaChannel, setPreferredMfaChannel] = useState(null);
  const [allowedMfaChannels, setAllowMfaChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mfaMessage, setMfaMessage] = useState(null);
  const [configureMfa, setConfigureMfa] = useState(false);
  const [mfaPromptOpen, setMfaPromptOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [errors, setErrors] = useState([]);

  const { createSession } = useAuth();

  const handleTokenChange = (e) => {
    let tmpToken = e.target.value;
    setToken(tmpToken);
    if (tmpToken.length === 6) {
      setTimeout(() => {
        handleSubmit(null, tmpToken);
      }, 300);
    }
  }

  const getUserInfo = async () => {
    setIsLoading(true);

    let tmpUserData = null;
    try {
      const result = await axios
        .get(`/api/user?${(new URLSearchParams({email: fields.email, password: fields.password})).toString()}`);
      if (result && result.data) {
        tmpUserData = result.data;
        setUserInfo(result.data);
      }
    } catch (e) {
      console.error('Error while getting user info for MFA setup: ', e);
      setErrors(['Failed to get user info. Please check your password']);
    }

    setIsLoading(false);

    return tmpUserData;
  };

  const sendSms = async (event) => {
    event.preventDefault();
    await sendToken('sms');
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    await sendToken('email');
  };

  const sendToken = async (channel) => {
    setPreferredMfaChannel(channel);
    try {
      setIsLoading(true);
      setMfaMessage('Sending...');
      let res = await axios.post('/api/mfa/send-token', {
        email: fields.email,
        channel: channel,
      });

      console.log('Token sent status: ', res.data);
      if (res && res.data.status) {
        setMfaMessage('Token sent via ' + channel + '!');
      }
    } catch (error) {
      console.error('Failed to send MFA token via ' + channel, error);
      setMfaMessage('An error occurred.');
      setErrors(['Failed to send MFA token via ' + channel]);
    }

    setIsLoading(false);
  };

  const handleSubmit = async (event, tmpToken=null, tmpChannel = null) => {
    if (event) {
      event.preventDefault();
    }

    let hasError = false,
      signInRes = null;

    try {
      setErrors([])
      setIsLoading(true);
      let payload = {
        email: fields.email,
        password: fields.password,
      };

      if (showTokenInput || tmpToken || tmpChannel) {
        payload.token = tmpToken || token;
        payload.channel = tmpChannel || preferredMfaChannel;
      }

      let res = await axios.post('/api/signin', payload);

      if (res && res.status === 200 && res.data.user) {
        gtag("event", "login", {
          method: payload.token ? "MFA" : "Password"
        });

        await createSession(
          {
            name: res.data.user.name,
            email: res.data.user.email,
            groupsLastUpdated: res.data.user.groupsLastUpdated,
            mfaEnabled: res.data.user.mfaEnabled,
            companyRequiresMfa: res.data.user.companyRequiresMfa,
            preferredMfa: res.data.user.preferredMfa,
            subscriptionStatus: res.data.subscriptionStatus || ""
          },
          res.data.expires
        );
        return;
      } else {
        hasError = true;
        signInRes = res;
      }
    } catch (error) {
      hasError = true;
      let hasResponse = false;
      if (error.response) {
        signInRes = error.response;
        if (error.response.data && error.response.data.error) {
          hasResponse = true;
        }
      }
      if (!hasResponse) {
        setErrors([error.message]);
      }
    }

    if (hasError) {
      if (signInRes) {
        console.log('Got to error handling...', signInRes.data);
        if (signInRes) {
          if (signInRes && signInRes.data.error === 'Username/password is invalid') {
            setErrors([
              'The username/password entered is incorrect. Please try again.',
            ]);
          } else if (signInRes && signInRes.data.error === 'Missing MFA token') {
            setShowTokenInput(true);
            let message = 'Please provide the token.';
            switch(signInRes.data.channel) {
              case 'authenticator':
                message += " Check your authenticator app.";
                break;
              case 'sms':
                message += " A text message has been sent with the token."
                break;
              case 'email':
                message += " An email has been sent with the token."
                break;
            }
            setMfaMessage(message);
            setAllowMfaChannels(signInRes.data.allowedChannels);
            setPreferredMfaChannel(signInRes.data.channel);
          } else if (signInRes && signInRes.data.error === 'Invalid MFA token') {
            setErrors([
              'The MFA token entered is incorrect. Please try again.',
            ]);
            setMfaMessage('Token is invalid');
          } else if (signInRes && signInRes.data.errorType === "MFA_REQUIRED_BY_COMPANY") {
            await getUserInfo();
            setMfaPromptOpen(true);
            setConfigureMfa(true);
          }
          else {
            setErrors([signInRes.data.error]);
          }
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Collapse in={errors && errors.length > 0}>
              <Alert severity="error">
                <div>
                  Login Failed:
                  <ul>
                    {errors.map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </div>
              </Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              autoFocus
              value={fields.email}
              onChange={handleFieldChange}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              label="Password"
              value={fields.password}
              onChange={handleFieldChange}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) =>
                          event.preventDefault()
                        }
                      >
                        {showPassword ? (
                          <Visibility/>
                        ) : (
                          <VisibilityOff/>
                        )}
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Grid>
          {showTokenInput &&
          <Card style={{width: '100%', margin: '7px'}}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Multi-factor Authentication
              </Typography>
              <Grid item xs={12}>
                {mfaMessage &&
                <Typography variant={'body2'}>{mfaMessage}</Typography>
                }
                <TextField
                  variant="outlined"
                  fullWidth
                  id="token"
                  name="token"
                  label="Token"
                  autoComplete="token"
                  autoFocus
                  value={token}
                  onChange={handleTokenChange}
                  InputProps={{
                    classes: {
                      root: classes.outlinedRoot,
                    },
                  }}
                  style={{ marginTop: '7px' }}
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}><hr/></Grid>
                  <Grid item xs={6} style={{textAlign: 'center'}}> Having trouble? </Grid>
                  <Grid item xs={3}><hr/></Grid>
                </Grid>
                {allowedMfaChannels.includes('sms') &&
                <Button type={'button'} color={"primary"} onClick={sendSms} disabled={isLoading}>{preferredMfaChannel === 'sms' ? 'Re-send SMS' : 'Send SMS'}</Button>
                }
              <Button type={'button'} color={"primary"} onClick={sendEmail} disabled={isLoading}>{preferredMfaChannel === 'email' ? 'Re-send Email' : 'Send Email'}</Button>
              </Grid>
            </CardActions>
          </Card>
          }
          <Grid item xs={12}>
            <Link href="/forgotpassword" variant="body1">
              Forgot Password?
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={isLoading}
              className={classes.submit}
            >
              Sign In
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Link
              href="/signup"
              variant="body1"
            >
              Don't have an account? Sign Up
            </Link>
          </Grid>
          <Grid>
            <br/>
            <Typography variant={'body2'}>
              Email us at <Link href = "mailto:support@juryboxapp.com">support@juryboxapp.com</Link> for support
            </Typography>
          </Grid>
        </Grid>

        {configureMfa && userInfo !== null && <MfaSetupModal key={'mfa-prompt-modal'}
                                        open={mfaPromptOpen}
                                        onComplete={(token, channel) => {
                                          setMfaPromptOpen(false);
                                          setShowTokenInput(true);

                                          if (channel && channel === "email" && !token) {
                                            sendToken('email');
                                          }

                                          if (token) {
                                            if (channel) {
                                              setPreferredMfaChannel(channel);
                                            }
                                            setToken(token);
                                            handleSubmit(null, token, channel);
                                          }
                                        }}
                                        loginInfo={{ email: fields.email, password: fields.password }}
                                        phone={userInfo.phone || ''}
                                        preferredMfa={userInfo.preferredMfa}
                                        requireMfa={userInfo.companyRequiresMfa}
                                        mfaEnabled={userInfo.mfaEnabled}
        />}
      </form>
    </>
  );
};

export default SignInForm;
