import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#5480B1',
    },
    secondary: {
      main: '#104279',
    },
    juror: {
      dismissed: '#d3d3d3',
      inactive: '#707070',
    },
    strikes: {
      many: '#92C9C4',
      warning: '#FFE69B',
      low: '#FFD6CE',
    },
    shade: {
      light: '#f5f5f5',
      main: '#d3d3d3',
      dark: '#707070',
    },
  },
});
