export const ActionTypes = {
  setDrag: "ETHEREAL/setDrag",
  setDemographicsModal: "ETHEREAL/setDemographicsModal",
  setDismissedQuadrantVisible: "ETHEREAL/setDismissedQuadrantVisible",
  setHighlightedQuadrant: "ETHEREAL/setHighlightedQuadrant",
  setJuryBoxBounds: "ETHEREAL/setJuryBoxBounds",
  setJuryPoolBounds: "ETHEREAL/setJuryPoolBounds",
  setDismissedBoxBounds: "ETHEREAL/setDismissedBoxBounds",
  setDismissedQuadrantBounds: "ETHEREAL/setDismissedQuadrantBounds",
  setMode: "ETHEREAL/setMode",
  addAlert: "ETHEREAL/alert/add",
  removeAlert: "ETHEREAL/alert/remove",
  clearAlerts: "ETHEREAL/alert/clear-all"
};

export const ActionCreators = {
  setDrag: (jurorId) => ({type: ActionTypes.setDrag, payload: {jurorId}}),
  setDemographicsModal: (open) => ({type: ActionTypes.setDemographicsModal, payload: {open}}),
  setDismissedQuadrantVisible: (visible) => ({type: ActionTypes.setDismissedQuadrantVisible, payload: {visible}}),
  setHighlightedQuadrant: (highlightedQuadrant) => ({type: ActionTypes.setHighlightedQuadrant, payload: {highlightedQuadrant}}),
  setJuryBoxBounds: (bounds) => ({type: ActionTypes.setJuryBoxBounds, payload: {bounds}}),
  setJuryPoolBounds: (bounds) => ({type: ActionTypes.setJuryPoolBounds, payload: {bounds}}),
  setDismissedBoxBounds: (bounds) => ({type: ActionTypes.setDismissedBoxBounds, payload: {bounds}}),
  setDismissedQuadrantBounds: (bounds) => ({type: ActionTypes.setDismissedQuadrantBounds, payload: {bounds}}),
  setMode: (mode) => ({type: ActionTypes.setMode, payload:{mode}}),
  addAlert: (message, title, variant) => ({type: ActionTypes.addAlert, payload: {message, title, variant}}),
  removeAlert: (id) => ({type: ActionTypes.removeAlert, payload: {id}}),
  clearAlerts: () => ({type: ActionTypes.clearAlerts})
};
