import React , { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormFields } from '../../util/hooks';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import { formStyles } from '../../util/styles';
import Logo from '../../util/Logo';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  logo: {
    marginTop: '-50px',
    padding: theme.spacing(0, 4, 4),
  },
}));

const ForgotPassword = () => {
  const classes = formStyles();
  const baseClasses = useStyles();
  const [fields, handleFieldChange] = useFormFields({
    email: ''
  });
  const [hasErrors, setHasErrors] = useState(false);
  const [error, setError] = useState("");
  const [hasSuccess, setHasSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let res = await axios.post('/api/forgotpassword', {
        email: fields.email,
      });
      if (res && res.data.error){
        setError(res.data.error);
        setHasErrors(true);
      } else {
        setHasErrors(false);
        setHasSuccess(true);
      }
    }
    catch(error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      }
      else {
        setError(error.message);
      }
      setHasErrors(true);
    }

  }

  return (
    <Container className={baseClasses.root} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={baseClasses.logo}>
        <Logo />
      </div>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align='center' variant="h4">
              Forgot Password?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align='center' variant="body2">
              Enter your email and we'll send you a password reset link
            </Typography>
          </Grid>
          <Collapse in={hasErrors}>
            <Alert severity="error">
              {error}
            </Alert>
          </Collapse>
          <Collapse in={hasSuccess}>
            <Alert severity="success">
              A password reset email has been sent. Check your spam folder if you don't see it in your inbox.
            </Alert>
          </Collapse>
          <Grid item xs={12}>
            <Typography
              variant="overline"
              className={classes.inputLabel}
            >
              Email
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={fields.email}
              onChange={handleFieldChange}
              InputProps={{
                classes: {
                  root: classes.outlinedRoot,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
            >
              Send Email
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ForgotPassword;
