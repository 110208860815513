import Box from "@material-ui/core/Box";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {SpaceBarOutlined} from "@material-ui/icons";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getActiveDrag, getMode} from "../../../state/ethereal/selectors";
import {addSpacer, handleJurorCardClick, updateJurorSeat} from "../../../state/asyncActions";

import useStyles from "./JurorCard.styles";
import {ActionCreators} from "../../../state/ethereal/actions";
import {useViewBasedCardWidth} from "../../../util/hooks";

export default function EmptyJuryBoxSeat({caseId, seatNumber, spaceNumber}) {
    const classes = useStyles();
    const [showAdd, setShowAdd] = React.useState('hidden');
    const [showOutline, setShowOutline] = React.useState(false);
    const mode = useSelector(getMode);

    const activeDrag = useSelector(getActiveDrag);
    const minimumJurorCardWidth = useViewBasedCardWidth();
    const dispatch = useDispatch();

    function addSpacerRequested(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(addSpacer(caseId, spaceNumber));
    }

    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        dispatch(handleJurorCardClick(caseId, null, seatNumber));
    }

    function handleMouseEnter(e) {
        setShowAdd('visible');
    }
    function handleMouseLeave(e) {
        setShowAdd('hidden');
    }

    function handleDragEnter(e) {
        e.preventDefault();
        setShowOutline(true);
    }

    function handleDragOver(e) {
        e.preventDefault();
        if (!showOutline) {
            setShowOutline(true);
        }
    }

    function handleDragLeave(e) {
        e.preventDefault();
        setShowOutline(false);
    }

    function handleDrop(e) {
        dispatch(updateJurorSeat(caseId, activeDrag, seatNumber));
        dispatch(ActionCreators.setDrag(null));
    }

    let renderedClasses = classNames(classes.paper, classes.handle);
    if (showOutline) {
        renderedClasses = classNames(classes.paper, classes.handle, classes.outline);
    }

    return (
        <div style={{flex: 1, minWidth: minimumJurorCardWidth}}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             onDragEnter={handleDragEnter}
             onDragOver={handleDragOver}
             onDragLeave={handleDragLeave}
             onDrop={handleDrop}>
            <Box
                className={renderedClasses}
                onClick={handleClick}
                borderRadius={5}>
                <Box display="flex"
                     className={classes.header}>
                    <Box className={classNames(classes.autoMarginLeft, classes.header)}>
                        <b>{seatNumber ?? ""}</b>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexGrow={1}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    style={{visibility: showAdd}}
                    p={2}
                >

                    <Tooltip title={"Add juror"}>
                        <IconButton color='primary' size='small'>
                            <AddCircleIcon style={{fontSize: 40}}/>
                        </IconButton>
                    </Tooltip>
                    <div style={{
                        position: 'absolute',
                        right: 10,
                        top: 0,
                        display: mode === "jury-box" ? "block" : "none"
                    }}>
                        <Tooltip title={"Add gap"}>
                            <IconButton size='small' onClick={addSpacerRequested}>
                                <SpaceBarOutlined/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </Box>
            </Box>
        </div>
    )
}