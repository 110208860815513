import {API_ENDPOINT} from "./util/constants";

export const SERVER_REACHABLE = "serverReachable";
export const SERVER_NOT_REACHABLE = "serverNotReachable";
export const NOT_AUTHORIZED = "notAuthorized";

class ConnectionStatus {
  constructor() {
    this.authorized = true; // Initialize to true
    this.serverReachable = false; // Initialize to false
    this._events = {};
    this._networkErrorRecheckHandle = null;
    this._timesCheckedForBetterConnection = 0;
    this._whenLastConnectedAfterOutage = null;
  }

  setServerReachable = (status) => {
    if (status === true) {
      this.authorized = true;
    }
    this.serverReachable = status;
    if (status) {
      this.emit(SERVER_REACHABLE);
    }
    else {
      this.emit(SERVER_NOT_REACHABLE);
    }
  }

  isServerReachable = () => {
    return this.serverReachable;
  }

  on = (name, listener) => {
    //console.log(`Attaching listener to ${name}...`);
    if (!this._events[name]) {
      this._events[name] = [];
    }

    this._events[name].push(listener);
  };

  removeListener(name, listenerToRemove) {
    if (this._events[name]) {
      const filterListeners = (listener) => listener !== listenerToRemove;
      this._events[name] = this._events[name].filter(filterListeners);
    }
  }

  emit(name, data) {
    //console.log(`Emitting ${name}...`);
    if (!this._events[name]) {
      console.warn("No listeners attached to " + name + " event.");
    }

    const fireCallbacks = (callback) => {
      callback(data);
    };

    if (this._events[name] && this._events[name].length > 0) {
      this._events[name].forEach(fireCallbacks);
    }
  }

  networkError() {
    if (!this._networkErrorRecheckHandle) {
      this.setServerReachable(false);

      if (this.authorized) {
        let delay = 5000,
          fiveMinutesAgo = (new Date()).getTime() - (60000 * 5);

        // If the connection has been successful after an outage within
        // the last 5 minutes, wait at least another 60 seconds before
        // running it again
        if (this._whenLastConnectedAfterOutage && this._whenLastConnectedAfterOutage > fiveMinutesAgo) {
          delay = 60000; // 60 seconds
        }
        this._networkErrorRecheckHandle = setTimeout(
          this.testIsReachable,
          delay
        );
      }
      else {
        console.log("Network error, but not setting up next check because client is not authorized.");
      }
    }
  }

  testIsReachable = async () => {
    console.log('Checking for a better connection...', {
      iterations: this._timesCheckedForBetterConnection
    });

    this._timesCheckedForBetterConnection++;
    let delay = 5000 * (2**this._timesCheckedForBetterConnection);
    if (delay > 300000) {
      delay = 300000; // Max out at 5 minutes
    }

    if (navigator.onLine) {
      let reachable = await isReachable(API_ENDPOINT + "/api/test-connection");
      if (reachable) {
        console.log("Got to the server!");
        this._timesCheckedForBetterConnection = 0;
        this._whenLastConnectedAfterOutage = (new Date()).getTime();
        this.clearTimedOutHandle();
        this.setServerReachable(true);
      }
      else {
        if (this.authorized) {
          console.log("No dice. Trying again after: " + delay + " milliseconds...");
          this._networkErrorRecheckHandle = setTimeout(this.testIsReachable, delay)
        }
        else {
          console.log("No dice. Not checking again, because the client is not authorized.");
        }
      }
    }
    else {
      // We don't need to have this checking, since the online event
      //   will give us what we need
      console.log("Not online anyway.");
      this.clearTimedOutHandle();
    }
  }

  clearTimedOutHandle = () => {
    if (this._networkErrorRecheckHandle) {
      clearTimeout(this._networkErrorRecheckHandle);
      this._networkErrorRecheckHandle = null;
    }
  }
}

const connectionStatus = new ConnectionStatus();

window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);

let checkTimeoutHandle = null;

function handleConnection() {
  //console.log("Online/Offline event: " + navigator.onLine);
  if (navigator.onLine) {
    isReachable(API_ENDPOINT + "/api/test-connection").then(function(online) {
      if (online) {
        if (checkTimeoutHandle) {
          clearTimeout(checkTimeoutHandle);
        }
        // handle online status
        connectionStatus.clearTimedOutHandle();
        connectionStatus.setServerReachable(true);
      } else {
        // technically online, but not able to access server
        console.log('no connectivity');
        connectionStatus.setServerReachable(false);

        if (connectionStatus.authorized) {
          // Check every 30 seconds for connection, if the client is authorized
          checkTimeoutHandle = setTimeout(handleConnection, 30000);
        }
        else {
          console.log("Not checking for connection since the client is not authorized.");
        }

      }
    });
  } else {
    // handle offline status
    if (checkTimeoutHandle) {
      clearTimeout(checkTimeoutHandle);
    }
    console.log('offline');
    connectionStatus.setServerReachable(false);
  }
}

function isReachable(url) {
  /**
   * Note: fetch() still "succeeds" for 404s on subdirectories,
   * which is ok when only testing for domain reachability.
   *
   * Example:
   *   https://google.com/noexist does not throw
   *   https://noexist.com/noexist does throw
   */
  return fetch(url, { method: 'HEAD', mode: 'no-cors', credentials: "include" })
    .then(function(resp) {
      if (resp) {
        if (resp.ok || resp.type === 'opaque') {
          connectionStatus.authorized = true;
          return true;
        }

        console.warn("Not 'ok' response...", resp);
        if (resp.status === 401 || resp.status === 402) {
          connectionStatus.authorized = false;
          connectionStatus.emit(NOT_AUTHORIZED);
        }
      }
      return false;
    }, function(err) {
      console.warn('[conn test error]:', err);
      return false;
    })
    .catch(function(err) {
      console.warn('[conn test failure]:', err);
      return false;
    });
}

// Run for initial values
handleConnection();
window.connectionStatus = connectionStatus;
export default connectionStatus;
