import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import './styles.scss';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../util/authContext';
import { navigate } from '@reach/router';
import { useAlert } from '../../util/alertDialogContext';
import LogoIcon from '../../components/LogoIcon';
import { Alert } from '@material-ui/lab';
import { STRIPE_PUBLISH_KEY } from "../../util/constants";

// Custom styling can be passed to options when creating an Element.
const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#104279',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      iconColor: '#104279',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: '10px',
  },
  deleteIcon: {
    fontSize: 20,
    margin: 10,
    cursor: 'pointer',
    opacity: 0.5,
    color: '#80574F',
    '&:hover': {
      color: 'red',
    },
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td, th': {
      border: '1px solid lightgray',
      padding: '5px 10px',
    },
  },
  tier: {
    '& td, th': {
      fontWeight: 'normal'
    }
  },
  tierSelected: {
    '& td, th': {
      fontWeight: 'bold'
    }
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtons: {
    marginLeft: 'auto',
  },
}));

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  disabled,
  readOnly,
  actionElement,
  onBlur,
  min,
}) => {
  return (
    <div className="FormRow">
      {label && (
        <label htmlFor={id} className="FormRowLabel">
          {label}
        </label>
      )}
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={onBlur}
        min={min}
      />
      {actionElement}
    </div>
  );
};

const SubmitButton = ({ processing, children, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="secondary"
      size="large"
      disabled={processing || disabled}
      className={classes.submit}
    >
      {processing ? 'Processing...' : children}
    </Button>
  );
};

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#5480B1"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#FFF"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CheckoutForm = ({ price, quantity, onQuantityChange, minQty }) => {
  const user = useAuth();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState(user.data.email);
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(null);

  const stripe = useStripe();
  const elements = useElements();
  const { alert } = useAlert();

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleCouponChange = (e) => {
    if (e.target.value === "") {
      setDiscount(null);
      setError(null);
      setProcessing(false);
    }
    setCoupon(e.target.value);
  }

  const getCoupon = async () => {
    if (!coupon) {
      setDiscount(null);
      setError(null);
      setProcessing(false);
      return;
    }

    try {
      const couponBody = {
        couponCode: coupon,
      };
      const res = await axios.post('/api/validateCoupon', couponBody);

      if (!res.data.error) {
        setDiscount(res.data);
        setError(null);
        setProcessing(false);
      } else {
        setDiscount(0);
        setError('Invalid Coupon');
        setProcessing(false);
      }
    } catch (error) {
      setDiscount(0);
      setError('Invalid Coupon');
      setProcessing(false);
    }
  };

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleQuantityChange = (event) => {
    onQuantityChange(event.target.value);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    if (!emailIsValid(email)) {
      setError(`${email} is not a valid email`);
      setProcessing(false);
      return;
    }

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!paymentMethod) {
      setError(`Invalid Card`);
      setProcessing(false);
      return;
    }

    if (paymentMethod.error) {
      setError(`Payment failed ${paymentMethod.error.message}`);
      setProcessing(false);
    } else {
      try {
        const gTrackPurchase = {
          currency: 'USD',
          transaction_id: '',
          value: ((price * quantity) * (1 - (discount ? discount.percentOff : 0))),
          coupon,
          items: [
            {
              item_id: 'DUMMY_ENTRY',
              item_name: 'Jurybox user license',
              quantity: quantity,
              price: price
            }
          ]
        };

        const subscribeBody = {
          coupon,
          invoiceEmail: email,
          paymentMethodId: paymentMethod.id,
          quantity,
        };
        const res = await axios.post(
          '/api/subscribe',
          subscribeBody,
          { timeout: 300000 },
        );
        if (!res.data.error) {

          gTrackPurchase.transaction_id = res.data.id;

          if (res.data.items && Array.isArray(res.data.items.data)) {
            gTrackPurchase.items = res.data.items.data.map((oneItem) => {
              return {
                item_id: oneItem.price.id,
                item_name: 'Jurybox user license',
                item_variant: oneItem.price.recurring.interval,
                quantity: oneItem.quantity,
                price: price
              };
            });
          }

          if (res.data.status === 'active') {

            gtag("event", "purchase", gTrackPurchase);

            alert({
              msg:
                'Subscription payment was successful! Welcome to Jurybox. Click OK to continue.',
            });

            navigate('/');
          }
          else {
            let paymentIntent = res.data.latest_invoice.payment_intent;

            if (paymentIntent.status === 'requires_action') {
              try {
                const confirmPayment = await stripe.confirmCardPayment(
                  paymentIntent.client_secret,
                  {
                    payment_method: paymentMethod.id,
                  },
                );

                if (confirmPayment.error) {
                  setError(confirmPayment.error.message);
                  setProcessing(false);
                }
                else if (confirmPayment.paymentIntent.status === 'succeeded') {

                  gtag("event", "purchase", gTrackPurchase);

                  alert({
                    msg:
                      'Subscription payment was successful! Welcome to Jurybox. Click OK to continue.',
                  });

                  navigate('/');
                }
              } catch (error) {
                setError(error);
                setProcessing(false);
              }
            }
            else if (paymentIntent.status === 'requires_payment_method') {
              setError(
                'Your card was declined.  Please try a different card.',
              );
              setProcessing(false);
            }
          }
        } else {
          setError(res.data.error.message);
          setProcessing(false);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setError(error.response.data.error);
        } else {
          setError(error.message);
        }
        setProcessing(false);
      }
    }
  };

  let total = price * quantity;
  let discountPercent = discount ? discount.percentOff : 0;
  let temporaryDiscount = !!discount && discount.duration === "once";

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <Field
          label="Email"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={processing}
          readOnly={processing}
        />
        <Field
          label="Number of Users"
          type="number"
          required
          value={quantity}
          onChange={handleQuantityChange}
          disabled={processing}
          readOnly={processing}
          min={minQty}
        />
        <Field
          label="Coupon"
          value={coupon}
          onChange={handleCouponChange}
          disabled={processing}
          readOnly={processing}
          actionElement={
            <Button
              color="primary"
              disabled={!coupon}
              onClick={() => getCoupon()}
            >
              Apply
            </Button>
          }
        />
      </fieldset>
      <fieldset className="FormGroup">
        <CardField onChange={(event) => handleChange(event)} />
      </fieldset>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {discount &&
      <div className="Coupon">
        <span className="CouponName"><strong>Coupon:</strong> {discount.name}</span>
      </div>
      }
      {temporaryDiscount ?
        <React.Fragment>
        <div className={"total"}>
          <h2>First year</h2>
          <h3>${(total * (1 - discountPercent)).toFixed(2)}</h3>
        </div>
          <div className={"total"}>
            <h3>After the first year</h3>
            <h4>${total.toFixed(2)} per year</h4>
          </div>
        </React.Fragment>
        :
        <div className="total">
          <h2>Total</h2>
          <h3>${(total * (1 - discountPercent)).toFixed(2)} per year</h3>
        </div>
      }
      {quantity >= 30 &&
        <Alert severity={'info'}>We should probably talk. <a href={"https://calendly.com/jurybox-training/start"}>Meet with us</a></Alert>
      }
      <SubmitButton
        processing={processing}
        error={error}
        disabled={!stripe || total <= 0 || disabled}
      >
        Subscribe
      </SubmitButton>
    </form>
  );
};

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

const Payment = () => {

  const classes = useStyles();

  const [price, setPrice] = useState(0);
  const [tiers, setTiers] = useState([]);
  const [minQty, setMinQty] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [qtyLoading, setQtyLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);

  useEffect(() => {
    const fetchPriceAndQuantity = async () => {
      setPriceLoading(true);
      try {
        const res = await axios.post('/api/price');
        setPriceLoading(false);
        if (!res.data.error && res.data.price !== null) {
          setPrice(res.data.price);
        }
        else if (!res.data.error && res.data.tiers !== null) {
          setTiers(res.data.tiers);
          setPrice(res.data.tiers[0].price);
        }
        else {
          alert(
            'There is an issue connecting to the payment system right now. Please try again later.',
          );
        }
        setQtyLoading(true);
        const result = await axios
          .get('/api/companymembercount')
          .catch((error) => {
            setQtyLoading(false);
            console.log(error);
          });
        setQtyLoading(false);
        setMinQty(result?.data?.count || 1);
        handleQuantityChange(result?.data?.count || 1);
      } catch (error) {
        setPriceLoading(false);
        setQtyLoading(false);
        console.log(error);
        alert(
          'There is an issue connecting to the payment system right now. Please try again later.',
        );
      }
    };
    fetchPriceAndQuantity();
  }, []);

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity < minQty) {
      newQuantity = minQty;
    }
    setQuantity(newQuantity);
    // determine the price
    if (tiers && tiers.length > 0) {
      let maxTier = null,
        newPrice = null;
      for (let tier of tiers) {
        if (!tier.up_to) {
          maxTier = tier;
        }
        else if (newQuantity <= tier.up_to) {
          newPrice = tier.price;
          break;
        }
      }

      if (!newPrice && maxTier) {
        newPrice = maxTier.price;
      }

      setPrice(newPrice);
    }
  };

  if (priceLoading || qtyLoading || !quantity || !minQty) {
    return null;
  }

  return (
    <div className="subscription-cart">

      <LogoIcon />

      <div className="title">
        <h1>Payment Details</h1>
        {!tiers &&
          <div className="desc">
            Subscribe to Jurybox for{' '}
            <span className="price">${price}/year per user</span>
          </div>
        }
        {(tiers && tiers.length) &&
        <table className={classes.table}>
          <thead>
          <tr>
            <th># of Users</th>
            <th>Price per user</th>
          </tr>
          </thead>
          <tbody>
          {tiers.map((tier) => {
            let className = classes.tier;
            if (tier.price === price) {
              className = classes.tierSelected
            }
            return (
              <tr className={className} key={tier.price}>
                <td>{tier.label}</td>
                <td style={{textAlign: 'right'}}>${tier.price.toFixed(2)}</td>
              </tr>
            );
          })}
          </tbody>
        </table>
        }
      </div>
      <div className="form-wrapper">
        <Elements stripe={stripePromise}>
          <CheckoutForm price={price}
                        minQty={minQty}
                        quantity={quantity}
                        onQuantityChange={handleQuantityChange}/>
        </Elements>
      </div>
    </div>
  );
};

export default Payment;
