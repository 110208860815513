import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {tableIcons} from '../../util/icons';
import {TextField} from '@material-ui/core';
import {createTheme, makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {deleteJuror, dismissJuror, restoreJurorToBox, updateJuror} from '../../state/asyncActions';
import {
    getJurorDemographics,
    getJuryPoolErrorsForCase,
    getJuryPoolJurors,
    getJuryPoolSorting,
} from '../../state/selectors';
import {ActionCreators} from '../../state/actions';
import {ActionCreators as MultiSelectActionCreators} from "../../state/multiSelect/actions";
import MultiSelectModes from "../../state/multiSelect/modes";
import * as MultiSelectSelectors from "../../state/multiSelect/selectors";
import Display from '../../components/RichTextEditor/Display';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StatusMenu from './StatusMenu';
import appTheme from '../../components/theme';
import sort from '../../util/sort';
import jsonifyCompare from '../../util/jsonifyCompare';
import {ActionCreators as EtherealActionCreators} from '../../state/ethereal/actions';
import {getActiveDrag, getJuryPoolBounds, getMode} from '../../state/ethereal/selectors';
import EmojiIndex from "./EmojiIndex";
import DismissMenu from "./DismissMenu";
import JuryPoolDropSection from "./JuryPoolDropSection";

const theme = createTheme({
    palette: {...appTheme.palette},
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: '#f5f5f5',
                padding: '0 16px 16px 16px',
            },
            elevation2: {
                boxShadow: '0 0 0 0',
            },
        },
        MuiTable: {
            root: {
                backgroundColor: 'white',
                borderRadius: '4px 4px 0 0',
            },
        },
        MuiTableCell: {
            root: {
                borderRadius: '4px',
            },
        },
        MuiIconButton: {
            root: {
                padding: '6px',
                marginRight: '4px',
                marginLeft: '4px',
            },
        },
        MuiToolbar: {
            gutters: {
                paddingLeft: '8px',
                '@media (min-width: 600px)': {
                    paddingLeft: '8px',
                },
            },
        },
        MuiTypography: {
            h6: {
                fontWeight: 'bolder !important',
                padding: 0,

            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    demographics: {
        marginRight: theme.spacing(1),
        alignItems: "center"
    },
    demographicsText: {
        fontSize: 14,
        color: theme.palette.text.hint
    },
    emojiIndex: {
        position: "absolute",
        top: -7,
        right: 0,
        backgroundColor: "white",
        padding: "3px 5px 2px 5px",
        borderRadius: "4px",
        fontSize: 12,
        zIndex: 100,
        boxShadow: "2px 2px 5px -2px black",
        overflowX: "hidden",
        maxWidth: "250px"
    }
}));

const columns = (onJurorUpdate, multiSelect, demographicCategories, showDemographics, classes, sorting, onOpenEdit) => ([
    {
        width: 180,
        title: 'Juror ID / Name',
        field: 'nameAndId',
        editComponent: props => (
            <TextField
                size="small"
                variant="outlined"
                fullWidth
                id="nameAndId"
                name="nameAndId"
                label="Juror ID / Name"
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
            />
        ),
        defaultSort: sorting && sorting[0] ? sorting[0] : undefined,
        customSort: (a, b) => sort(a.nameAndId, b.nameAndId)
    },
    {
        title: 'Comments',
        field: 'comments',
        render: (rowData) => {

            let demographicsText = "";
            if (rowData.demographics) {
                demographicsText = getJurorDemographics(rowData, demographicCategories).map(demographic => demographic.value).join(", ");
            }

            return (
                <Box display="flex" flexDirection="row" justifyContent="space-between" gridGap={"1em"}>
                    <Box display="flex" flexDirection="row" alignItems="center" position="relative" flex={1}>
                        {showDemographics && !!demographicsText &&
                            <Box className={classes.demographics}>
                                <Typography variant="body2"
                                            className={classes.demographicsText}>{demographicsText}</Typography>
                            </Box>
                        }
                        <Display richTextStyles={rowData.commentsStyles || []}
                                 text={rowData.comments || ""}
                                 maxLines={1}
                                 maxCharacters={125}/>
                        <EmojiIndex text={rowData.comments || ""}
                                    onClick={(emoji, occurrence) => {
                                        onOpenEdit(rowData._id, emoji, occurrence)
                                    }}
                                    className={classes.emojiIndex}/>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" gridGap={"0.5em"} flex={0}>
                        {multiSelect.mode === MultiSelectModes.OFF &&
                            <StatusMenu key={`jury-pool-${rowData._id}`}
                                        updateStatus={(status) => {
                                            const newJurorInfo = {...rowData, status: status};
                                            onJurorUpdate(rowData._id, newJurorInfo);
                                        }}
                                        status={rowData.status ? rowData.status : null}
                                        className="icon-lg"
                            />
                        }

                    </Box>
                </Box>
            );
        },
        editComponent: props => (
            <TextField
                size="small"
                variant="outlined"
                fullWidth
                id="comments"
                name="comments"
                label="Comments"
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
            />
        ),
        defaultSort: sorting && sorting[1] ? sorting[1] : undefined,
        customFilterAndSearch: (term, rowData) => {
            if (rowData.comments?.indexOf(term) >= 0) {
                return true;
            }
            if (rowData.nameAndId?.indexOf(term) >= 0) {
                return true;
            }
            if (rowData.demographics) {
                for (let oneDemographic of rowData.demographics) {
                    if (oneDemographic.value?.indexOf) {
                        if (oneDemographic.value.indexOf(term) >= 0) {
                            return true;
                        }
                    }
                }
            }
        }
    },
]);

const JuryPool = ({caseId, onOpenEdit, civilCase, demographicCategories, showDemographics}) => {
    const classes = useStyles();

    //console.log("Rendering JuryPool...");
    const dispatch = useDispatch();

    const activeDrag = useSelector(getActiveDrag);

    const poolBounds = useSelector(getJuryPoolBounds);
    const mode = useSelector(getMode);

    const modeLabel = mode === "jury-box" ? "Jury Box" : "Alternates";

    let poolRef = null;

    const updateBounds = (ref) => {
        if (ref) {
            poolRef = ref;
            if (!jsonifyCompare('pool bounds')(poolBounds, ref.getBoundingClientRect())) {
                dispatch(EtherealActionCreators.setJuryPoolBounds(ref.getBoundingClientRect()));
            }
        }
    };

    function tmpGetJurors(state) {
        return getJuryPoolJurors(state, {caseId});
    }

    function tmpGetJurorErrors(state) {
        return getJuryPoolErrorsForCase(state, {caseId});
    }

    function tmpGetJuryPoolSorting(state) {
        return getJuryPoolSorting(state, {caseId});
    }

    const {jurors} = useSelector(tmpGetJurors, (prev, next) => {
        return prev.updated === next.updated && prev.caseUpdated === next.caseUpdated;
    });
    const jurorsErrors = useSelector(tmpGetJurorErrors, (prev, next) => {
        if (Object.keys(prev).length === Object.keys(next).length) {
            return jsonifyCompare('Juror errors')(prev, next);
        }

        return false;
    });
    const multiSelect = useSelector(MultiSelectSelectors.getAll, jsonifyCompare('multiSelect'));
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(0);
    const sorting = useSelector(tmpGetJuryPoolSorting, jsonifyCompare('sorting'));

    const [width, setWidth] = useState(window.innerWidth);
    const updateWidth = () => {
        setWidth(window.innerWidth);
        if (poolRef) {
            dispatch(EtherealActionCreators.setJuryPoolBounds(poolRef.getBoundingClientRect()));
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        window.addEventListener('scroll', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
            window.removeEventListener('scroll', updateWidth);
        }
    }, [width]);

    const jurorsWithErrors = jurors.map((juror) => {
        return {
            ...juror,
            error: jurorsErrors[juror._id] || undefined,
        };
    }).sort((jurorA, jurorB) => {
        if (jurorA.created && jurorB.created) {
            return jurorB.created.localeCompare(jurorA.created) * -1; // ASC
        } else if (jurorA.created) {
            return 1;
        } else if (jurorB.created) {
            return -1;
        }
        return jurorA._id.localeCompare(jurorB._id);
    });

    const handleDeleteJuror = async (oldJurorData) => {
        dispatch(deleteJuror(caseId, oldJurorData._id));
    };

    const handleEditJuror = async (jurorId, newData) => {
        dispatch(updateJuror(caseId, jurorId, newData));
    }

    let jurorsInPage = pageSize,
        jurorsLeftOver = jurors.length - (page * pageSize);
    if (jurors.length < pageSize) {
        jurorsInPage = jurors.length;
    }
    if (jurorsLeftOver < pageSize) {
        jurorsInPage = jurorsLeftOver;
    }

    let minHeight = (jurorsInPage * 39) + 50 + 70;

    if (activeDrag) {
        return (
            <JuryPoolDropSection key="juryPoolDropSection" caseId={caseId} />
        );
    }

    return (
        <ThemeProvider theme={theme} key="juryPoolRendered">
            <div ref={updateBounds} style={{minHeight}}>
                <MaterialTable
                    title="Jury Pool"
                    columns={columns(handleEditJuror, multiSelect, demographicCategories, showDemographics, classes, sorting, onOpenEdit)}
                    data={jurorsWithErrors}
                    onChangePage={(newPage) => {
                        //console.log("New page: ", newPage);
                        setPage(newPage);
                    }}
                    onChangeRowsPerPage={(newPageSize) => {
                        setPageSize(newPageSize);
                    }}
                    onOrderChange={(orderedColumnId, orderDirection) => {
                        dispatch(ActionCreators.updatePoolSort(caseId, orderedColumnId, orderDirection));
                    }}
                    options={{
                        showTitle: true,
                        search: true,
                        paging: true,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 25, 50, 75],
                        emptyRowsWhenPaging: false,
                        sorting: true,
                        headerStyle: {
                            fontWeight: 'bold',
                            fontSize: 16,
                            padding: '8px',
                        },
                        cellStyle: {padding: '8px'},
                        actionsColumnIndex: -1,
                    }}
                    icons={tableIcons}
                    editable={{
                        isDeleteHidden: function () {
                            return multiSelect.mode !== MultiSelectModes.OFF
                        },
                        onRowDelete: (oldData) => {
                            //console.log('Attempting to delete juror: ', oldData);
                            return handleDeleteJuror(oldData);
                        },
                    }}
                    actions={[
                        (rowData) => ({
                            icon: tableIcons.MoveToJuryBox,
                            hidden: multiSelect.mode !== MultiSelectModes.OFF,
                            tooltip: `Move to ${modeLabel}`,
                            onClick: (event, rowData) => {
                                console.log(`Moving to ${modeLabel}: `, {
                                    jurorId: rowData._id,
                                    mode
                                });
                                dispatch(restoreJurorToBox(caseId, rowData._id, mode));
                            },
                        }),
                        (rowData) => ({
                            hidden: multiSelect.mode !== MultiSelectModes.OFF,
                            icon: () => (
                                <DismissMenu onSelected={(reason) => {
                                    dispatch(dismissJuror(caseId, rowData._id, reason));
                                }}/>
                            )
                        }),
                        {
                            icon: tableIcons.Add,
                            hidden: multiSelect.mode !== MultiSelectModes.OFF,
                            tooltip: 'Add juror',
                            isFreeAction: true,
                            onClick: (e) => {
                                e.preventDefault();
                                onOpenEdit('new');
                            },
                        },
                        (rowData) => ({
                            icon: tableIcons.Error,
                            hidden: rowData.error === undefined || multiSelect.mode !== MultiSelectModes.OFF,
                            tooltip: rowData.error && rowData.error.error ? rowData.error.error.toString() : '',
                            onClick: (event, rowData) => {
                                dispatch(ActionCreators.removeJurorError(rowData._id));
                            },
                        }),
                        (rowData) => {
                            return {
                                hidden: multiSelect.mode === MultiSelectModes.OFF,
                                icon: (multiSelect.selectedJurorIds.indexOf(rowData._id) >= 0) ? tableIcons.Selected : tableIcons.NotSelected
                            }
                        }
                    ]}
                    onRowClick={(event, rowData) => {

                        if (event.target?.closest(".dropdown-menu")) {
                            return true;
                        }

                        event.preventDefault();

                        if (multiSelect.mode === MultiSelectModes.ON) {
                            if (multiSelect.selectedJurorIds.indexOf(rowData._id) >= 0) {
                                dispatch(MultiSelectActionCreators.removeJurorId(rowData._id));
                            } else {
                                dispatch(MultiSelectActionCreators.addJurorId(rowData._id));
                            }
                        } else {
                            onOpenEdit(rowData._id);
                        }
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <b>Click plus button to add jurors to pool</b>,
                        },
                    }}
                />
            </div>
        </ThemeProvider>
    );
};

export default React.memo(JuryPool);
