import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import JuryBoxAppBar from '../../components/JuryBoxAppBar';
import MaterialTable from 'material-table';
import { tableIcons } from '../../util/icons';
import { useAlert } from '../../util/alertDialogContext';
import { navigate } from '@reach/router';
import CreateModal from './CreateModal';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getClient } from '../../util/twilioSync';
import { getUserInfo } from '../../util/userInfo';
import { useDispatch, useSelector } from 'react-redux';
import { listCases, deleteCase, cloneCase } from '../../state/asyncActions';
import Alert from '@material-ui/lab/Alert';
import { getCaseListState } from '../../state/selectors';
import { ActionCreators } from '../../state/actions';
import connectionStatus, { SERVER_REACHABLE } from '../../connectionStatus';
import OfflineBar from '../../components/OfflineBar';
import moment from 'moment';
import ShareModal from './ShareModal';

const columns = [
  {
    title: 'Number',
    field: 'number',
  },
  { title: 'Name', field: 'name' },
  { title: 'Comments', field: 'details' },
  { title: 'Creator', field: 'creator' },
  {
    title: 'Created', field: 'createdAt', render: (rowData) => {
      return moment(rowData.createdAt).format('YYYY-MM-DD h:mm a');
    },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    width: '100%',
  },
  table: {
    '& tbody>.MuiTableRow-root:hover': {
      background: 'rgba(0, 0, 0, 0.07)',
    },
  },
}));

const CaseList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { caseList, caseListLoading, caseListError, casesErrors } = useSelector(getCaseListState);

  let caseListWithErrors = caseList.map((caseListItem) => {
    let error = undefined;
    if (casesErrors[caseListItem._id]) {
      //console.log("Error: ", casesErrors[caseListItem._id].error);
      error = casesErrors[caseListItem._id].error.message;
    }
    return {
      ...caseListItem,
      error,
    };
  }).sort((caseA, caseB) => {
    if (caseA.createdAt && caseB.createdAt) {
      return (caseA.createdAt.localeCompare(caseB.createdAt) * -1); // Largest to smallest
    }
    return 0;
  });

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [shareModel, setShareModel] = useState({
    open: false,
    caseId: null,
    caseName: null
  });

  const { alert } = useAlert();

  const handleCreateCase = async (caseId) => {
    setCreateModalOpen(false);
    //console.log('Navigating to case id: ', caseId);
    navigate(`/case/${caseId}`);
  };

  const handleListCases = () => {
    dispatch(listCases(true));
  };

  useEffect(() => {
    //console.log('Running CaseList useEffect...');

    let ignore = false;
    const updateTwilio = () => {
      if (!ignore) {
        dispatch(listCases());
      }
    };

    let docHandles = [],
      connectionHandle = null;

    function listenToSyncDocument() {
      getClient().then(async client => {
        if (!client) {
          console.warn('Client not initialized.');
          return;
        }
        //console.log("Subscribed to changes from group...");
        const userInfo = await getUserInfo();
        if (userInfo && userInfo.groupIDs && userInfo.groupIDs.length > 0) {
          for (let groupID of userInfo.groupIDs) {
            client.document(groupID).then(doc => {
              docHandles.push(doc);
              doc.on('updated', updateTwilio);
            });
          }
        }
      });
    }

    if (!createModalOpen) {
      (async () => {
        dispatch(listCases());
      })();

      if (connectionStatus.isServerReachable()) {
        listenToSyncDocument();
      } else {
        connectionHandle = true;
        connectionStatus.on(SERVER_REACHABLE, listenToSyncDocument);
      }
    }
    return () => {
      //console.log("Closing CaseList useEffect...");
      if (docHandles && docHandles.length > 0) {
        for (let docHandle of docHandles) {
          if (docHandle.close) {
            docHandle.close();
          }
          //console.log("Closing document subscription...");
        }
        docHandles = [];
      }

      if (connectionHandle) {
        //console.log("Removing connection status change listener on CaseList...");
        connectionStatus.removeListener(SERVER_REACHABLE, listenToSyncDocument);
      }

      ignore = true;
    };
  }, [createModalOpen, dispatch]);

  return (
    <div id="app-wrapper">
      <OfflineBar/>
      <JuryBoxAppBar title={'Cases'}/>
      {caseListLoading && <LinearProgress/>}
      {!!caseListError && (
        <Alert severity={'error'}
               onClose={() => {
                 dispatch(ActionCreators.removeCaseListError());
               }}
               key={'case-list-error'}>{caseListError.error.toString()}</Alert>
      )}
      <div className={classes.root}>
        <div className={classes.table}>
          <MaterialTable
            columns={columns}
            data={caseListWithErrors}
            options={{
              showTitle: false,
              searchFieldAlignment: 'left',
              search: true,
              paging: false,
              sorting: true,
              headerStyle: {
                fontWeight: 'bold',
                fontSize: 16,
              },
              actionsColumnIndex: -1,
            }}
            icons={tableIcons}
            actions={[
              {
                icon: tableIcons.Add,
                tooltip: 'Create Case',
                isFreeAction: true,
                onClick: () => setCreateModalOpen(true),
              },
              {
                icon: tableIcons.Refresh,
                tooltip: 'Refresh list',
                isFreeAction: true,
                onClick: () => handleListCases(),
              },
              (rowData) => ({
                icon: tableIcons.Clone,
                tooltip: "Clone Case",
                onClick: (event) => {
                  event.preventDefault();
                  alert({
                    msg:
                      'Are you sure you want to make a clone of this case?',
                    onSubmit: () => dispatch(cloneCase(rowData._id)),
                    variant: 'danger',
                  });
                }
              }),
              (rowData) => ({
                icon: tableIcons.Share,
                tooltip: rowData.canShare ? 'Share Case' : "Can't share cases created by others",
                disabled: !rowData.canShare,
                onClick: (event) => {
                  event.preventDefault();
                  setShareModel({
                    open: true,
                    caseId: rowData._id,
                    caseName: rowData.name,
                    loading: false
                  });
                }
              }),
              (rowData) => ({
                icon: tableIcons.Delete,
                tooltip: rowData.canDelete ? 'Delete Case' : "Can't delete cases created others",
                disabled: !rowData.canDelete,
                onClick: (event) => {
                  event.preventDefault();
                  alert({
                    msg:
                      'Deleting this case is permanent, would you like to proceed?',
                    onSubmit: () => dispatch(deleteCase(rowData._id)),
                    variant: 'danger',
                  });
                },
              }),
              (rowData) => ({
                icon: tableIcons.Error,
                hidden: rowData.error === undefined,
                tooltip: rowData.error ? rowData.error : '',
                onClick: (event) => {
                  event.preventDefault();
                  // Nothing to do really
                  dispatch(ActionCreators.removeCaseError(rowData._id))
                },
              }),
            ]}
            onRowClick={(event, rowData) => {
              //console.log('Navigating to case id: ', rowData._id);
              navigate(`/case/${rowData._id}`);
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: '25px' }}>
                    <Button
                      onClick={() => setCreateModalOpen(true)}
                      variant="contained"
                      color="secondary"
                      startIcon={<Add/>}
                    >
                      Add Case
                    </Button>
                  </div>
                ),
              },
            }}
          />
        </div>
      </div>
      <CreateModal
        open={createModalOpen}
        key={'create-modal'}
        onClose={() => setCreateModalOpen(false)}
        onCreate={handleCreateCase}
      />
      <ShareModal open={shareModel.open}
                  caseId={shareModel.caseId}
                  caseName={shareModel.caseName}
                  key={'share-modal'} onClose={() => setShareModel({
        open: false,
        caseId: null,
        caseName: null
      })}/>
    </div>
  );
};

export default CaseList;
