import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close, Delete as DeleteIcon } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { formStyles } from '../../../util/styles';
import Alert from '@material-ui/lab/Alert';
import { Dialog, DialogActions, DialogContent, Grid, LinearProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Chip from '@material-ui/core/Chip';

import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { STRIPE_PUBLISH_KEY } from '../../../util/constants';

const useStyles = makeStyles((theme) => ({
  paymentMethodsTable: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td, th': {
      border: '1px solid lightgray',
      padding: '10px 20px',
    },
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtons: {
    marginLeft: 'auto',
  },
}));

// Custom styling can be passed to options when creating an Element.
const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#333',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      iconColor: '#333',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(
  STRIPE_PUBLISH_KEY
);

const AddCardWidget = ({ onSuccess, setError }) => {

  const [disabled, setDisabled] = React.useState(false);
  const [useNewAsDefault, setUseNewAsDefault] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const handleUseAsDefaultChange = (e) => {
    setUseNewAsDefault((old) => {
      return !old;
    });
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setError('');
    setProcessing(true);

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!paymentMethod) {
      setError(`Invalid Card`);
      setProcessing(false);
      return;
    }

    if (paymentMethod.error) {
      setError(`Payment failed ${paymentMethod.error.message}`);
      setProcessing(false);
    } else {
      // Associate payment method with customer
      try {
        let res = await axios.post('/api/payment-method', {
          paymentMethodId: paymentMethod.id,
          setAsDefault: useNewAsDefault,
        });

        if (res && res.data) {
          if (res.data.defaultPaymentMethod && res.data.paymentMethods) {
            onSuccess(res.data);
            setUseNewAsDefault(false);
            setProcessing(false);
            elements.getElement(CardElement).clear(); // Reset
          } else {
            setError('Unexpected response from payment processor. Please try again.');
            setProcessing(false);
          }
        } else {
          setError('Unexpected response from payment processor. Please try again.');
          setProcessing(false);
        }
      } catch (e) {
        console.error('Failed to set payment method: ', {
          error,
        });
        setError('Error: ' + e.message);
        setProcessing(false);
      }
    }
  };

  return (
    <div
      style={{ width: '100%', border: '1px solid #ddd', borderRadius: '4px', padding: '10px 15px', marginTop: '15px' }}>
      <strong>Add a new payment method:</strong>
      <div className="FormRow" style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: '450px' }}>
          <CardElement options={CARD_OPTIONS} onChange={handleChange}/>
        </div>
        <div style={{ flex: 1, padding: '0 10px' }}>
          <FormControlLabel control={<Switch checked={useNewAsDefault}
                                             onChange={handleUseAsDefaultChange}
                                             name={'useAsDefault'}/>}
                            label={'Use as default'}
                            disabled={disabled || processing}/>
        </div>
        <div style={{ flex: 0 }}>
          <Button variant={'contained'} color={'primary'} onClick={handleSubmit}
                  disabled={disabled || processing}>Add</Button>
        </div>
      </div>
      {processing && <LinearProgress color={'primary'}/>}
    </div>
  );
};

export default ({ open, onClose }) => {
  const [error, setError] = React.useState('');
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const tableClasses = useStyles();
  const classes = formStyles();

  const listPaymentMethods = React.useCallback(async () => {
    setLoading(true);
    try {
      let res = await axios.get('/api/payment-method');
      if (res && res.data) {
        setPaymentMethods(res.data.paymentMethods);
        setDefaultPaymentMethod(res.data.defaultPaymentMethod || null);
      }
    } catch (oneError) {
      setError(oneError.message);
    }
    setLoading(false);
  }, []);

  const changeDefaultPaymentMethod = async (paymentMethodId) => {
    let confirmed = confirm("Are you sure you want to set this as your default payment method?");
    if (!confirmed) {
      return;
    }
    try {
      setError('');
      setLoading(true);

      let res = await axios.post('/api/payment-method/set-default', {
        paymentMethodId,
      });

      if (res && res.data && res.data.updated) {
        setDefaultPaymentMethod(paymentMethodId);
        setLoading(false);
      } else {
        console.warn('Unexpected response from updating default payment method: ', res);
        setError('Unexpected response. Please try again later or contact support if the issue persists.');
        setLoading(false);
      }
    } catch (e) {
      console.error('Failed to update default payment method: ', e);
      setError('Failed to update default payment method: ' + e.message);
      setLoading(false);
    }
  };

  const removePaymentMethod = async (paymentMethodId) => {
    let confirmed = confirm("Are you sure you want to remove this payment method?");
    if (!confirmed) {
      return;
    }
    try {
      setError('');
      setLoading(true);

      let res = await axios.delete('/api/payment-method/' + paymentMethodId);

      if (res && res.data && res.data.paymentMethods) {
        setPaymentMethods(res.data.paymentMethods);
        setLoading(false);
      } else {
        console.warn('Unexpected response from removing payment method: ', res);
        setError('Unexpected response. Please try again later or contact support if the issue persists.');
        setLoading(false);
      }
    } catch (e) {
      console.error('Failed to remove payment method: ', e);
      setError('Failed to remove payment method: ' + e.message);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (open) {
      listPaymentMethods();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleSuccessAddPayment = (data) => {
    setPaymentMethods(data.paymentMethods);
    setDefaultPaymentMethod(data.defaultPaymentMethod);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth={true}
      className={classes.form}
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Payment methods</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          disabled={loading}
        >
          <Close/>
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        {loading && <LinearProgress color={'primary'}/>}
        <Collapse in={!!error && error.length > 0}>
          <Alert severity="error" className={classes.alert}>
            Error: {error}
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <table className={tableClasses.paymentMethodsTable}>
            <thead>
            <tr>
              <th>Card #</th>
              <th>Expires</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {paymentMethods && paymentMethods.length > 0 &&
              paymentMethods.map((paymentMethod) => {
                return (
                  <tr key={paymentMethod.id}>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span>**** **** **** {paymentMethod.card.last4}</span>
                        <Chip color={'secondary'} label={paymentMethod.card.brand} variant={'outlined'} size={'small'}/>
                      </div>
                    </td>
                    <td>{paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}</td>
                    <td>
                      {defaultPaymentMethod === paymentMethod.id &&
                        <Chip color={'primary'} label={'Default'} variant={'outlined'} size={'small'}/>}
                      {defaultPaymentMethod !== paymentMethod.id &&
                        <React.Fragment>
                          <Button variant={'text'}
                                  color={"primary"}
                                  size={"small"}
                                  onClick={(e) => {
                            changeDefaultPaymentMethod(paymentMethod.id)
                          }}>Set as default</Button>

                          <Tooltip title={'Remove'}>
                            <IconButton onClick={() => {
                              removePaymentMethod(paymentMethod.id);
                            }} color={'secondary'}>
                              <DeleteIcon fontSize={'small'}/>
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      }
                    </td>
                  </tr>
                );
              })
            }
            </tbody>
          </table>
          <Elements stripe={stripePromise}>
            <AddCardWidget onSuccess={handleSuccessAddPayment} setError={setError}/>
          </Elements>
        </Grid>
      </DialogContent>
      <DialogActions/>
    </Dialog>
  );
}
