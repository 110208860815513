export const ActionTypes = {
  sessionCreated: 'SESSION/created',
  updateSessionDataPoint: 'SESSION/update-data-point',
  sessionEnded: 'SESSION/ended',
  checkingForCaseChanges: 'CASES/checking-for-changes',
  checkingForCaseChangesSuccess: 'CASES/checking-for-changes/success',
  checkingForCaseChangesFailure: 'CASES/checking-for-changes/failure',
  clearCaseList: 'CASES/clear-all',
  listCases: 'CASES/list',
  listCasesSuccess: 'CASES/list/success',
  listCasesFailure: 'CASES/list/failure',
  removeCaseListError: "CASES/list/error/remove",
  createCase: 'CASE/create',
  createCaseSuccess: 'CASE/create/success',
  createCaseFailure: 'CASE/create/failure',
  removeCaseError: 'CASE/error/remove',
  getCaseInfo: 'CASE/get',
  getCaseInfoSuccess: 'CASE/get/success',
  getCaseInfoFailure: 'CASE/get/failure',
  updateCase: 'CASE/update',
  updateCaseSuccess: 'CASE/update/success',
  updateCaseFailure: 'CASE/update/failure',
  deleteCase: 'CASE/delete',
  deleteCasesSuccess: 'CASES/delete/success',
  deleteCaseFailure: 'CASE/delete/failure',
  createJuror: 'JUROR/create',
  createJurors: 'JUROR/create/multiple',
  createJurorSuccess: 'JUROR/create/success',
  createJurorFailure: 'JUROR/create/failure',
  removeJurorError: 'JUROR/error/remove',
  updateJuror: 'JUROR/update',
  updateJurorSuccess: 'JUROR/update/success',
  updateJurorFailure: 'JUROR/update/failure',
  deleteJuror: 'JUROR/delete',
  deleteJurorSuccess: 'JUROR/delete/success',
  deleteJurorFailure: 'JUROR/delete/failure',
  processingPending: 'PENDING/processing',
  processingPendingSuccess: 'PENDING/processing/success',
  processingPendingFailure: 'PENDING/processing/failure',
  caseNeedsUpdateFromTwilio: 'PENDING/twilio-sync/case/needs-update',
  removePendingJurorCreate: "PENDING/remove/juror-create",
  openCaseModal: 'MODAL/case/open',
  closeCaseModal: 'MODAL/case/close',
  openJurorModal: 'MODAL/juror/open',
  previewJurorStart: 'PREVIEW/juror/start',
  previewJurorEnd: 'PREVIEW/juror/end',
  closeJurorModal: 'MODAL/juror/close',
  updateEditingJuror: 'EDITING/juror/update',
  batchUpdateJurorsSuccess: "BATCH/jurors/update",
  updateBounds: "BOUNDS/update",
  multiJurorComment: "JUROR/multi/comment",
  fillEmptySpaces: "JUROR/multi/fill-empty-spaces",
  emptyJuryBox: "JUROR/multi/empty-jury-box",
  fillFromPool: "JUROR/multi/fill-from-pool",
  fillEmptyAlternateSpaces: "JUROR/multi/fill-empty-alternate-spaces",
  emptyAlternates: "JUROR/multi/empty-alternates",
  fillAlternatesFromPool: "JUROR/multi/fill-alternates-from-pool",
  updatePoolSort: "POOL/sort/update",
  multiJurorDemographics: "JUROR/multi/demographics",
  addSpacer: "CASE/spacer/add",
  removeSpacer: "CASE/spacer/remove"
};

export const ActionCreators = {
  sessionCreated: (data, expires) => ({ type: ActionTypes.sessionCreated, payload: { data, expires } }),
  updateSessionDataPoint: (name, value) => ({type: ActionTypes.updateSessionDataPoint, payload: {name, value}}),
  sessionEnded: (clearAllData=false) => ({ type: ActionTypes.sessionEnded, payload: {clearAllData} }),
  processingPending: () => ({type: ActionTypes.processingPending}),
  processingPendingSuccess: () => ({type: ActionTypes.processingPendingSuccess}),
  processingPendingFailure: (error) => ({type: ActionTypes.processingPendingFailure, payload: {error}}),
  checkingForCaseChanges: () => ({ type: ActionTypes.checkingForCaseChanges }),
  checkingForCaseChangesSuccess: (timestamp) => ({
    type: ActionTypes.checkingForCaseChangesSuccess,
    payload: { timestamp },
  }),
  checkingForCaseChangesFailure: (error) => ({ type: ActionTypes.checkingForCaseChangesFailure, payload: { error } }),
  clearCaseList: () => ({type: ActionTypes.clearCaseList}),
  listCases: () => ({ type: ActionTypes.listCases }),
  listCasesSuccess: (caseList) => ({ type: ActionTypes.listCasesSuccess, payload: { caseList } }),
  listCasesFailure: (error) => ({ type: ActionTypes.listCasesFailure, payload: { error } }),
  removeCaseListError: () => ({type: ActionTypes.removeCaseListError}),
  createCase: (tmpId, createCaseBody) => ({
    type: ActionTypes.createCase,
    payload: { tmpId, createCaseBody },
  }),
  createCaseSuccess: (tmpId, serverId, caseInfo, pendingStart) => ({
    type: ActionTypes.createCaseSuccess,
    payload: { tmpId, serverId, caseInfo, pendingStart },
  }),
  createCaseFailure: (tmpId, error) => ({
    type: ActionTypes.createCaseFailure,
    payload: { tmpId, error },
  }),
  removeCaseError: (caseId) => ({type: ActionTypes.removeCaseError, payload: { caseId } }),
  getCaseInfo: (caseId) => ({ type: ActionTypes.getCaseInfo, payload: { caseId } }),
  getCaseInfoSuccess: (caseId, caseInfo, pendingStart, fromTwilio=false) => ({
    type: ActionTypes.getCaseInfoSuccess,
    payload: { caseId, caseInfo, fromTwilio, pendingStart},
  }),
  getCaseInfoFailure: (caseId, error) => ({ type: ActionTypes.getCaseInfoFailure, payload: { caseId, error } }),
  updateCase: (caseId, editCaseBody) => ({
    type: ActionTypes.updateCase,
    payload: { caseId, editCaseBody },
  }),
  updateCaseSuccess: (caseId, caseInfo, updateStarted) => ({ type: ActionTypes.updateCaseSuccess, payload: { caseId, caseInfo, updateStarted } }),
  updateCaseFailure: (caseId, error) => ({ type: ActionTypes.updateJurorFailure, payload: { caseId, error } }),
  deleteCase: (caseId) => ({ type: ActionTypes.deleteCase, payload: { caseId } }),
  deleteCasesSuccess: (caseIds) => ({ type: ActionTypes.deleteCasesSuccess, payload: { caseIds } }),
  deleteCaseFailure: (caseId, error) => ({ type: ActionTypes.deleteCaseFailure, payload: { caseId, error } }),
  createJuror: (caseId, tmpId, jurorBody) => ({ type: ActionTypes.createJuror, payload: { caseId, tmpId, jurorBody } }),
  createJurors: (caseId, jurorPayloads) => ({type: ActionTypes.createJurors, payload: {caseId, jurorPayloads}}),
  createJurorSuccess: (caseId, tmpId, serverId, jurorBody) => ({
    type: ActionTypes.createJurorSuccess,
    payload: { caseId, tmpId, serverId, jurorBody },
  }),
  createJurorFailure: (caseId, tmpId, error) => ({
    type: ActionTypes.createJurorFailure,
    payload: { caseId, tmpId, error },
  }),
  updateJuror: (caseId, jurorId, juror) => ({ type: ActionTypes.updateJuror, payload: { caseId, jurorId, juror } }),
  updateJurorFailure: (caseId, jurorId, juror, error) => ({
    type: ActionTypes.updateJurorFailure,
    payload: { caseId, jurorId, juror, error },
  }),
  removeJurorError: (jurorId) => ({type: ActionTypes.removeJurorError, payload: {jurorId}}),
  multiJurorComment: (caseId, jurorIds, commentText, commentsStyles) => ({type: ActionTypes.multiJurorComment, payload: {caseId, jurorIds, commentText, commentsStyles}}),
  multiJurorDemographics: (caseId, jurorIds, demographics) => ({type: ActionTypes.multiJurorDemographics, payload: {caseId, jurorIds, demographics}}),
  deleteJuror: (caseId, jurorId) => ({ type: ActionTypes.deleteJuror, payload: { caseId, jurorId } }),
  deleteJurorSuccess: (caseId, jurorId) => ({ type: ActionTypes.deleteJurorSuccess, payload: { caseId, jurorId } }),
  deleteJurorFailure: (caseId, jurorId, error) => ({
    type: ActionTypes.deleteJurorFailure,
    payload: { caseId, jurorId, error },
  }),
  caseNeedsUpdateFromTwilio: (caseId) => ({type: ActionTypes.caseNeedsUpdateFromTwilio, payload: {caseId}}),
  openCaseModal: (caseId) => ({type: ActionTypes.openCaseModal, payload: {caseId}}),
  closeCaseModal: (caseId) => ({type: ActionTypes.closeCaseModal, payload: {caseId}}),
  openJurorModal: (caseId, jurorId, seatNumber, mode, focusOn = null, focusAfter = 0) => ({type: ActionTypes.openJurorModal, payload: {caseId, jurorId, seatNumber, mode, focusOn, focusAfter}}),
  previewJurorStart: () => ({type: ActionTypes.previewJurorStart}),
  previewJurorEnd: () => ({type: ActionTypes.previewJurorEnd}),
  closeJurorModal: (caseId, jurorId) => ({type: ActionTypes.closeJurorModal, payload: {caseId, jurorId}}),
  updateEditingJuror: (changes) => ({type: ActionTypes.updateEditingJuror, payload: {changes}}),
  batchUpdateJurorsSuccess: (caseId, data, pendingStart) => ({type: ActionTypes.batchUpdateJurorsSuccess, payload: {caseId, data, pendingStart}}),
  removePendingJurorCreate: (jurorId) => ({type: ActionTypes.removePendingJurorCreate, payload: {jurorId}}),
  fillEmptySpaces: (caseId) => ({type: ActionTypes.fillEmptySpaces, payload: {caseId}}),
  emptyJuryBox: (caseId) => ({type: ActionTypes.emptyJuryBox, payload: {caseId}}),
  fillFromPool: (caseId) => ({type: ActionTypes.fillFromPool, payload: {caseId}}),
  fillEmptyAlternateSpaces: (caseId) => ({type: ActionTypes.fillEmptyAlternateSpaces, payload: {caseId}}),
  emptyAlternates: (caseId) => ({type: ActionTypes.emptyAlternates, payload: {caseId}}),
  fillAlternatesFromPool: (caseId) => ({type: ActionTypes.fillAlternatesFromPool, payload: {caseId}}),
  updatePoolSort: (caseId, columnNumber, sortingDirection) => ({type: ActionTypes.updatePoolSort, payload: {caseId, columnNumber, sortingDirection}}),
  updateBounds: (name, bounds) => ({type: ActionTypes.updateBounds, payload: {name, bounds}}),
  addSpacer: (caseId, spaceNumber) => ({type: ActionTypes.addSpacer, payload: {caseId, spaceNumber}}),
  removeSpacer: (caseId, spaceNumber) => ({type: ActionTypes.removeSpacer, payload: {caseId, spaceNumber}})
};
