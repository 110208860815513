import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage';
import rootReducer from "./rootReducer";
import createDebounce from "redux-debounced";

// import logger from "redux-logger";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let persistConfig = {
  key: "root",
  storage: localforage,
  blacklist: ['multiSelect', 'ethereal']
}

let persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => {
  let middlewares = [createDebounce(), thunkMiddleware/*, logger*/];
  let middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers = composeEnhancers(middlewareEnhancer);
  let store = createStore(persistedReducer, composedEnhancers);
  let persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept("./rootReducer", () => {
      const nextRootReducer = require("./rootReducer").default;
      store.replaceReducer(
        persistReducer(persistConfig, nextRootReducer)
      )
    })
  }

  return {
    store,
    persistor
  };
}

const configured = configureStore();

export const store = configured.store;
export const persistor = configured.persistor;


