import React, { useEffect, useState } from 'react';
import ImageListItem from '@material-ui/core/ImageListItem';
import JurorCard from './JurorCard/JurorCard';
import { updateJuror } from '../../state/asyncActions';
import { InputBase, Paper } from '@material-ui/core';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ImageList from '@material-ui/core/ImageList';
import { useDispatch, useSelector } from 'react-redux';
import { getDismissedJurors } from '../../state/selectors';
import useStyles from './Case.styles';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { ActionCreators } from '../../state/ethereal/actions';
import {getActiveDrag, getMode} from '../../state/ethereal/selectors';
import DismissQuadrant from "./DismissQuadrant";

const DismissedArea = ({caseId, civilCase, demographicCategories, showDemographics}) => {
  const activeDrag = useSelector(getActiveDrag);

  if (activeDrag) {
    return (
        <DismissQuadrant key={"dismissed-quadrants"}
                         civilCase={civilCase}
                         caseId={caseId} />
    )
  }

  return (
    <DismissedBox key="dismissed-box"
                  caseId={caseId}
                  civilCase={civilCase}
                  demographicCategories={demographicCategories}
                  showDemographics={showDemographics} />
  )
}

const DismissedBox = ({
    caseId, civilCase, demographicCategories, showDemographics
  }) => {
  //console.log('Rendering dismissed box...');

  const classes = useStyles();

  let boxRef = null;

  const items = [];
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [search, setSearch] = useState("");
  const [scrollLeft, setScrollLeft] = useState(0);

  const updateWidth = () => {
    setWidth(window.innerWidth);
    updateSelection();
    if (boxRef) {
      dispatch(ActionCreators.setDismissedBoxBounds(boxRef.getBoundingClientRect()));
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const clearSearch = (e) => {
    e.preventDefault();
    setSearch("");
  }

  let listRef = null;
  const mode = useSelector(getMode);
  let dismissedJurors = useSelector((state) => getDismissedJurors(state, {caseId, scrollLeft, search, mode}));

  function updateSelection(e) {
    let itemWidth = (listRef.offsetWidth * 0.166666666667);
    let leftIndex = Math.floor(listRef.scrollLeft / itemWidth);
    if (scrollLeft !== leftIndex) {
      setScrollLeft(leftIndex);
    }
  }

  function setupListRef(oneListRef) {
    if (oneListRef) {
      listRef = oneListRef;
      listRef.addEventListener('scroll', updateSelection);
    }
  }
  
  for (let i = 0; i < dismissedJurors.total; i++) {
    if (i >= scrollLeft && i < scrollLeft + dismissedJurors.items.length) {
      items.push(<ImageListItem key={i} rows={0.80}>
        <JurorCard
          type="dismissed"
          juror={dismissedJurors.items[i - scrollLeft]}
          caseId={caseId}
          civilCase={civilCase}
          mode={mode}
          demographicCategories={demographicCategories}
          showDemographics={showDemographics}
          updateJurorInParent={updateJuror}
        />
      </ImageListItem>)
    }
    else {
      items.push(<ImageListItem key={i} rows={0.80}><div style={{border: "1px solid lightgrey", backgroundColor: "white", height: "100%"}}></div></ImageListItem>)
    }
  }

  useEffect(() => {
    //console.log("Checking if DismissedBox bounds have changed...");
    window.addEventListener('resize', updateWidth);
    window.addEventListener('scroll', updateWidth);
    return () => {
      if (listRef) {
        listRef.removeEventListener('scroll', updateSelection);
      }
      window.removeEventListener('resize', updateWidth);
      window.removeEventListener('scroll', updateWidth);
    }
  }, [width]);

  let dragMessage = '';
  if (items.length === 0) {
    dragMessage = '\n\nDrag a juror card here to dismiss';
  }

  return (
    <Paper
      key={"dismissed-box"}
      elevation={0}
      className={classNames(
        classes.container,
        classes.dismissedContainer,
      )}>
      <Typography className={classes.boxTitle} variant="h6">
        <b>Dismissed{mode === "alternates" ? " Alternates" : ""}</b>
      </Typography>
      <div className={classes.dismissedSearch}>
        <Search fontSize={"small"} />
        <InputBase placeholder={"Search"}
                   className={classes.dismissedSearchInput}
                   inputProps={{
                     className: classes.dismissedSearchInputReal
                   }}
                   value={search}
                   onChange={handleSearch} />
        <IconButton onClick={clearSearch}
                    disabled={search.length === 0}
                    size={"small"}>
          <Close fontSize={"small"}/>
        </IconButton>
      </div>
      <Typography
        variant="body2"
        align="center"
        className={classes.dismissedPreLine}
      >
        <b>{dragMessage}</b>
      </Typography>
      <ImageList className={classes.gridList} cols={6} gap={8} ref={setupListRef}>
        {items}
      </ImageList>
    </Paper>
  );
};

export default DismissedArea;
