import React from "react";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Close from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { formStyles } from '../../util/styles';
import { useDispatch, useSelector } from 'react-redux';
import {getCivilCase, getDemographicsData} from '../../state/selectors';
import PlotlyCustom from '../../components/PlotlyCustom';
import createPlotlyComponent from 'react-plotly.js/factory';
import { ActionCreators } from '../../state/ethereal/actions';
import { isDemographicsModalOpen } from '../../state/ethereal/selectors';
const Plot = createPlotlyComponent(PlotlyCustom);

export default ({caseId}) => {

  const classes = formStyles();

  const civilCase = useSelector((state) => {
    return getCivilCase(state, {caseId})
  });

  const dispatch = useDispatch();

  const [includeJuryPool, setIncludeJuryPool] = React.useState(false);
  const open = useSelector(isDemographicsModalOpen);

  function handleClose() {
    dispatch(ActionCreators.setDemographicsModal(false));
  }

  let demographicsData = useSelector((state) => {
    return getDemographicsData(state, {caseId});
  });

  // Turn it into charts
  let demographicsCharts = [];
  let yAxesToCheck = [
    { col: 'prosecutorPeremptory', label: (civilCase ? 'Plaintiff' : 'Prosecution') + ' Peremptory'},
    { col: 'defensePeremptory', label: 'Defense Peremptory'},
    { col: 'all', label: 'Juror Population'}
  ];
  Object.keys(demographicsData.details).forEach(demographicCategory => {
    let demographicsCategoryChart = {
      id: `${demographicCategory}-chart`,
      data: [],
      layout: {
        title: `Percent Jurors Dismissed by ${demographicCategory} vs Population`,
        barmode: 'group',
        yaxis: {
          tickformat: ',.0%',
          autorange: true
        }
      }
    };

    let Xs = Object.keys(demographicsData.details[demographicCategory]);
    let totalToCompare = includeJuryPool ? demographicsData.overall.total : (demographicsData.overall.totalInBox + demographicsData.overall.totalDismissed);
    for (let oneY of yAxesToCheck) {
      // Find yData for this demographicCategory
      let yData = [];
      for (let oneX of Xs) {
        let xData;
        if (oneY.col === 'all') {
          let totalToCheck = includeJuryPool ? demographicsData.details[demographicCategory][oneX].total : (demographicsData.details[demographicCategory][oneX].totalInBox + demographicsData.details[demographicCategory][oneX].totalDismissed);
          xData = (totalToCheck / totalToCompare).toFixed(3);
        }
        else {
            let toDivideBy;
            if (oneY.col === 'prosecutorPeremptory') {
              toDivideBy = demographicsData.overall.totalDismissedByProsecutor;
            }
            else {
              toDivideBy = demographicsData.overall.totalDismissedByDefense;
            }
           if (toDivideBy > 0) {
             xData = ((demographicsData.details[demographicCategory][oneX].dismissed[oneY.col] / toDivideBy)).toFixed(3);
           }
           else {
             xData = 0;
           }
        }
        yData.push(xData);
      }

      let trace = {
        x: Xs,
        y: yData,
        name: oneY.label,
        type: 'bar'
      };

      demographicsCategoryChart.data.push(trace);
    }

    //console.log("Adding demographics chart data: ", demographicsCategoryChart);
    demographicsCharts.push(demographicsCategoryChart);
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <div style={{display: "flex", width: "100%"}}>
          <div style={{flex: 1}}>
          <Typography variant="h6">Demographics Analytics</Typography>
          </div>
          <div style={{flex: 0, marginRight: 30, minWidth: 175}}>
            <FormControlLabel
              control={
                <Checkbox checked={includeJuryPool}
                          onChange={() => {
                            setIncludeJuryPool(!includeJuryPool);
                          }} name="includeJuryPool" color="primary" />
              }
              label="Include Jury Pool"
            />
          </div>
        </div>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        {demographicsCharts.map(chart => (
          <Plot key={chart.id} data={chart.data} layout={chart.layout} />
        ))}
        {/*<pre>{JSON.stringify(demographicsData, null, 2)}</pre>*/}
      </DialogContent>
    </Dialog>
  );
}
