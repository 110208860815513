import React, { useEffect, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import LogoIcon from '../LogoIcon';
import AppBar from '@material-ui/core/AppBar';
import axios from 'axios';
import { useAuth } from '../../util/authContext';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';
import List from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import { resetClient } from '../../util/twilioSync';
import { resetUserInfo } from '../../util/userInfo';
import connectionStatus from '../../connectionStatus';

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: '#FFF',
    background: theme.palette.primary,
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
    minWidth: '680px',
  },
  logo: {
    '& g path, & g polyline': {
      fill: '#FFF',
    },
    height: theme.spacing(4),
    marginLeft: theme.spacing(-1.5),
  },
  toolBar: {
    '& img': {
      marginRight: theme.spacing(2),
    },
  },
  left: {
    maxHeight: 36,
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  right: {
    maxHeight: 36,
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  action: {
    color: '#FFF',
    marginLeft: theme.spacing(1.5)
  },
  buy: {
    color: '#FFFF99',
    marginLeft: theme.spacing(1.5)
  },
}));

const JuryBoxAppBar = () => {
  const { removeSession } = useAuth();
  const classes = useStyles();
  const [showBuyNow, setShowBuyNow] = useState('hidden');
  const [expirationDate, setExpirationDate] = useState(new Date(Date.now()));

  useEffect(() => {
    //console.log("Running AppBar useEffect...");

    let isMounted = true;

    const checkExpiration = async () => {
      try {
        const res = await axios.get(`/api/trialexpiration`);
  
        if (isMounted) {
          if (res && res.data && res.data.trialExpiration) {
            setShowBuyNow('');
            setExpirationDate(new Date(res.data.trialExpiration));
          }
        }
      }
      catch(err) {
        console.log("Error while checking for expiration: ", error);
      }
    };

    checkExpiration().then(() => {
      console.log("Completed initial check for expiration.");
    }).catch(err => {
      console.warn("Exception during initial check for expiration: ", err);
    });

    let checkHandle = setInterval(() => {
      if (connectionStatus.isServerReachable()) {
        checkExpiration().then(() => {
          console.log("Completed later check for expiration.");
        }).catch(err => {
          console.warn("Exception during later check for expiration: ", err);
        });
      }
    }, (60000 * 5)); // Check every 5 minutes

    return () => {
      isMounted = false;
      if (checkHandle) {
        clearInterval(checkHandle);
      }
    }
  }, []);

  const handleLogout = async () => {
    let confirmed = true;
    if (!connectionStatus.serverReachable) {
      confirmed = window.confirm("Are you sure you want to log out while you are offline? Any unsaved data will be lost since you are not connected to the internet.");
    }

    if (confirmed) {
      await axios.post('/api/signout');
      removeSession(true);
      resetClient();
      resetUserInfo();
    }
  };

  //console.log("Rendering app bar...");

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar variant="dense" className={classes.toolBar}>
        <div className={classes.left}>
        <Button
            startIcon={<LogoIcon className={classes.logo}/>}
            onClick={() => navigate('/')}
          >
          </Button>
        </div>
        <div className={classes.right}>
          <Typography
            variant="body1"
            className={classes.buy}
            style={{ visibility: showBuyNow, minWidth:'150px'}}
          >
            Trial ends {expirationDate.toLocaleDateString()}
          </Typography>
          <Button
            className={classes.buy}
            startIcon={<ShoppingCartIcon />}
            onClick={() => navigate('/subscribe')}
            style={{ visibility: showBuyNow }}
          >
            Subscribe
          </Button>
          <Button
            className={classes.action}
            startIcon={<List />}
            onClick={() => {
              // Only navigate if we aren't already there.
              if (window.location.pathname !== "/") {
                navigate('/');
              }
            }}
          >
            Cases
          </Button>
          <Button
            className={classes.action}
            startIcon={<PersonIcon />}
            onClick={() => navigate('/account')}
          >
            Account
          </Button>
          <Button
            className={classes.action}
            startIcon={<ExitToApp />}
            onClick={handleLogout}
            style={{ minWidth:'120px'}}
          >
            Sign Out
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default JuryBoxAppBar;
