export const ActionTypes = {
  setMode: "MULTI-SELECT/set-mode",
  addJurorId: "MULTI-SELECT/add-juror-id",
  removeJurorId: "MULTI-SELECT/remove-juror-id",
  resetSelectedJurors: "MULTI-SELECT/reset-selected-jurors"
};

export const ActionCreators = {
  setMode: (mode) => ({type: ActionTypes.setMode, payload: {mode}}),
  addJurorId: (jurorId) => ({type: ActionTypes.addJurorId, payload: {jurorId}}),
  removeJurorId: (jurorId) => ({type: ActionTypes.removeJurorId, payload: {jurorId}}),
  resetSelectedJurors: () => ({type: ActionTypes.resetSelectedJurors})
};
