import React from 'react';
import { Redirect, useLocation } from '@reach/router';
import { useAuth } from '../../util/authContext';
import { needsMfa } from "../../util/guards";

export default ({ component: Component, guard, ...props }) => {
  const user = useAuth();
  const location = useLocation();
  const page = <Component {...props} />;

  //console.log("Attempting to render page...");

  React.useEffect(() => {
    gtag("page_view", location.pathname + location.search);
  }, []);

  /**
   * When no guard provided
   * or user is not authenticated and guard allows - rendering the page
   */
  if (!guard || (!user.isAuthenticated && guard(false))) {
    //console.log("Page has no guard or user isn't authenticated and the guard is okay with that.");
    return page;
  }

  /**
   * When cookie has expired, reset local storage authentication flags
   */
  if (user.authDate && new Date() >= user.authDate) {
    //console.log("Removing session in page, due to authDate being old.");
    user.removeSession();
  }

  /**
   * When user is not authenticated and guard denies - redirecting a user
   * to the page according to his user data
   */
  if (!user.isAuthenticated && !guard(false)) {
    let redirect = redirectTo(false);
    //console.log(`Redirecting to '${redirect}' due to user not being authenticated...`);
    return <Redirect noThrow to={redirect} />;
  }

  /**
   * Otherwise rendering a component for Authenticated users
   */
  return (
    <Authenticated guard={guard} user={user}>
      {page}
    </Authenticated>
  );
};

const Authenticated = ({ children, guard, user }) => {
  if (!guard(true, user)) {
    const goingTo = redirectTo(true, user.data);
    // console.log(`Redirecting to '${goingTo}' from Authenticated component: `, {
    //   user
    // });
    return <Redirect noThrow to={goingTo} />;
  }

  //console.log("Rendering children...");

  return children;
};

export const redirectTo = (isAuthenticated, userData) => {
  if (!isAuthenticated) {
    return '/signin';
  }

  if (needsMfa(true, {data: userData})) {
    return '/mfa';
  }

  if (userData.subscriptionStatus && userData.subscriptionStatus !== "active") {
    console.warn("Subscription is not active!");
    return '/account';
  }

  return '/';
};
