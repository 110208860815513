import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import {dismissReason} from "./constants";
import {useDispatch, useSelector} from "react-redux";
import {getActiveDrag} from "../../state/ethereal/selectors";
import {dismissJuror} from "../../state/asyncActions";
import {ActionCreators} from '../../state/ethereal/actions';

const useStyles = makeStyles((theme) => ({
  dismissGrid: {
    padding: theme.spacing(2),
    background: theme.palette.shade.light,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  dismissCard: {
    textAlign: 'center',
    minHeight: '210px',
  },
  header: {
    padding: theme.spacing(1),
    background: theme.palette.shade.main,
    borderRadius: '4px 4px 0 0',
  },
  cardBorder: {
    flex: 1,
    borderRadius: theme.spacing(0.5),
  },
  cardBorderWithHighlight: {
    flex: 1,
    borderRadius: theme.spacing(0.5),
    boxShadow: `0 0 0 3px ${theme.palette.secondary.main}`,
  }
}));

const DismissQuadrants = (props) => {
  const classes = useStyles();

  return (
        <Box className={classes.dismissGrid}>
          <DismissQuadrant caseId={props.caseId} dismissKey={dismissReason.ProsecutionPerempt} name={(props.civilCase ? "Plaintiff": "Prosecution") + " Peremptory (P)"} />
          <DismissQuadrant caseId={props.caseId} dismissKey={dismissReason.DefensePerempt} name="Defense Peremptory (D)" />
          <DismissQuadrant caseId={props.caseId} dismissKey={dismissReason.BothPerempt} name="Both Peremptory (B)" />
          <DismissQuadrant caseId={props.caseId} dismissKey={dismissReason.ForCause} name="For Cause (C)" />
          <DismissQuadrant caseId={props.caseId} dismissKey={dismissReason.ForHardship} name="Hardship (H)" />
        </Box>
  );
};

const DismissQuadrant = ({caseId, name, dismissKey}) => {
  const classes = useStyles();
  const [isHighlighted, setHighlighted] = React.useState(false);

  const activeDrag = useSelector(getActiveDrag);
  const dispatch = useDispatch();

  function handleDragEnter(e) {
    e.preventDefault();
    setHighlighted(true);
  }

  function handleDragOver(e) {
    e.preventDefault();
    if (!isHighlighted) {
      setHighlighted(true);
    }
  }

  function handleDragLeave(e) {
    e.preventDefault();
    setHighlighted(false);
  }

  function handleDrop(e) {
    dispatch(dismissJuror(caseId, activeDrag, dismissKey));
    dispatch(ActionCreators.setDrag(null));
  }

  let oneClassName = classes.cardBorder;
  if (isHighlighted) {
    oneClassName = classes.cardBorderWithHighlight;
  }

  return (

        <Box className={oneClassName}
             onDragEnter={handleDragEnter}
             onDragOver={handleDragOver}
             onDragLeave={handleDragLeave}
             onDrop={handleDrop}>
          <Paper className={classes.dismissCard}>
            <Box className={classes.header}>
              <b>{name}</b>
            </Box>
            <br />
            <br />
            Drag here to dismiss
          </Paper>
        </Box>
  );
}

export default DismissQuadrants;
