import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {navigate} from "@reach/router";
import BasePage from "./BasePage";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "./Case.styles";
import {
    ArrowDropDown,
    ArrowDropUp,
    ArrowLeft,
    ArrowRight,
    ClearAllRounded,
    TableChart
} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {getCivilCase, getDemographicsData, getJurorsForCase} from "../../state/selectors";
import {InputBase} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EmojiIndex from "./EmojiIndex";
import {ActionCreators} from "../../state/actions";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import * as MultiSelectSelectors from "../../state/multiSelect/selectors";
import MultiSelectModes from "../../state/multiSelect/modes";
import jsonifyCompare from "../../util/jsonifyCompare";
import {ActionCreators as MultiSelectActionCreators} from "../../state/multiSelect/actions";
import {tableIcons} from "../../util/icons";
import {indexEmoji} from "../../util/emoji";
import {getMode} from "../../state/ethereal/selectors";
import StatusMenu from "./StatusMenu";
import {updateJuror} from "../../state/asyncActions";
import DismissMenu from "./DismissMenu";
import AddBox from "@material-ui/icons/AddBox";
import sort from "../../util/sort";
import {DateTime} from "luxon";
import Highlighted from "../../components/Highlighted";

export default ({id: caseId}) => {

    const classes = useStyles();

    const [search, setSearch] = useState("");
    const [pageSize, setPageSize] = useState(20);
    const [sorting, setSorting] = useState([]);
    const [page, setPage] = useState(1);

    const multiSelect = useSelector(MultiSelectSelectors.getAll, jsonifyCompare('multiSelect'));
    const mode = useSelector(getMode);
    const civilCase = useSelector((state) => {
        return getCivilCase(state, {caseId})
    });

    const dispatch = useDispatch();

    const clearSorting = () => {
        setSorting([]);
    }
    const toggleSorting = (name) => {
        setSorting(prev => {
            let foundIndex = prev.findIndex(s => s.name === name);

            if (foundIndex >= 0) {
                let currentOne = prev[foundIndex];
                let newSort = [...prev];
                if (currentOne.direction === "asc") {
                    newSort[foundIndex].direction = "desc";

                } else {
                    newSort.splice(foundIndex, 1);
                }
                return newSort;
            } else {
                return [...prev, {name, direction: "asc"}];
            }
        });
    }

    const handleJurorEdit = React.useCallback((jurorId, seatNumber, emoji, occurrence) => {
        dispatch(ActionCreators.openJurorModal(caseId, jurorId, seatNumber, "jury-box", emoji, occurrence));
    }, [caseId]);

    const handleEmojiClick = (jurorId, seatNumber) => (emoji, occurrence) => {
        dispatch(ActionCreators.openJurorModal(caseId, jurorId, seatNumber, "jury-box", emoji, occurrence));
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1);
    }
    const clearSearch = (e) => {
        setSearch("");
        setPage(1);
    }
    const handlePageChange = (e) => {
        setPage(e.target.value);
    }
    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
        setPage(1);
    };

    const jurors = useSelector(state => {
        return getJurorsForCase(state, {caseId});
    });

    const demographicsData = useSelector(state => {
        return getDemographicsData(state, {caseId});
    });

    const demographics = Object.keys(demographicsData.details);

    let mappedJurors = jurors.map(j => {
        let discardedReason = "";
        let discarder = j.discarder || "";
        if (discarder === "prosecutor") {
            if (civilCase) {
                discarder = "plaintiff";
            }
        }
        if (j.discarded) {
            discardedReason = `${j.discardedReason} ${j.discardedReason === "peremptory" && j.discarder ? `(${discarder})` : ''}`;
        } else if (j.alternateDiscarded) {
            discardedReason = `${j.discardedReason} ${j.discardedReason === "peremptory" && j.discarder ? `(${discarder})` : ''} [alternate]`;
        }
        let seatNumber = '';
        if (!j.discarded && !j.alternateDiscarded) {
            if (j.seatNumber) {
                seatNumber = j.seatNumber;
            } else if (j.alternateSeatNumber) {
                seatNumber = `${j.alternateSeatNumber} [alternate]`;
            }
        }

        let m = {
            _raw: j,
            _id: j._id,
            nameAndId: j.nameAndId,
            status: j.status ?? "",
            seatNumber: seatNumber,
            discardedReason: discardedReason,
            comments: j.comments || "",
            commentsStyles: j.commentsStyles,
            demographics: j.demographics,
            emojiIndex: indexEmoji(j.comments || "").emojis.map(e => e.emoji).join(""),
            created: j.created,
            updated: j.updated || j.created
        }

        for (let demo of demographics) {
            m["_d_" + demo] = j.demographics.find(d => d.name === demo)?.value ?? "";
        }

        return m;
    });

    if (mode === "alternates") {
        mappedJurors = mappedJurors.filter(j => {
            return j.seatNumber.toString()?.indexOf("alternate") >= 0 || j.discardedReason?.indexOf('[alternate]') >= 0;
        })
    }


    const filteredJurors = mappedJurors.filter(j => {
        const basics = j.nameAndId?.indexOf(search) >= 0 ||
            j.comments?.indexOf(search) >= 0 ||
            j.discardedReason?.indexOf(search) >= 0 ||
            j.status?.indexOf(search) >= 0 ||
            j.emojiIndex?.indexOf(search) >= 0;

        if (basics) {
            return basics;
        }

        // Check the demographics values as well
        for (let demo of demographics) {
            if (j["_d_" + demo]?.indexOf(search) >= 0) {
                return true;
            }
        }

        return false;
    });

    let sortedJurors = filteredJurors.toSorted((a, b) => {
        for (let oneSorting of sorting) {
            let reversal = (oneSorting.direction === "asc" ? 1 : -1);
            let a_value = a[oneSorting.name];
            let b_value = b[oneSorting.name];
            if (a_value && b_value && a_value !== b_value) {
                if (Number.isInteger(a_value)) {
                    return (a_value - b_value) * reversal;
                }

                if (oneSorting.name === "nameAndId") {
                    return sort(a.nameAndId, b.nameAndId) * reversal;
                }

                return a_value?.toString().localeCompare(b_value?.toString()) * reversal;
            }
            if (!a_value) {
                return 1 * reversal;
            }
            if (!b_value) {
                return -1 * reversal;
            }
        }
    });

    const startingPoint = (page - 1) * pageSize;
    const numPages = Math.ceil(filteredJurors.length / pageSize);
    const pageOfJurors = sortedJurors.slice(startingPoint, startingPoint + pageSize);

    return (
        <BasePage caseId={caseId} topNav={
            <Tooltip title="Jury box view">
                <IconButton
                    onClick={() => {
                        navigate(`/case/${caseId}`)
                    }}
                    size="small">
                    <TableChart className={classes.actionButton}/>
                </IconButton>
            </Tooltip>
        }>
            <div style={{width: '100%'}}>
                <div className="data-table-toolset">
                    <div className="search-container">
                        <div className={classes.dismissedSearch} style={{position: "relative", top: 0, right: 0}}>
                            <Search fontSize={"small"}/>
                            <InputBase placeholder={"Search"}
                                       fullWidth
                                       className={classes.dismissedSearchInput}
                                       inputProps={{
                                           className: classes.dismissedSearchInputReal
                                       }}
                                       value={search}
                                       onChange={handleSearchChange}/>
                            <IconButton onClick={clearSearch}
                                        disabled={search.length === 0}
                                        size={"small"}>
                                <Close fontSize={"small"}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className="toolset-actions">
                        <Tooltip title="Add juror">
                            <IconButton onClick={() => {
                                handleJurorEdit("new");
                            }}>
                                <AddBox/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear sorting" style={{visibility: sorting.length > 0 ? "visible" : "hidden"}}>
                            <IconButton onClick={clearSorting} size="small">
                                <ClearAllRounded/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className="data-table-container">
                    <table className="data-table">
                        <thead>
                        <tr>
                            <th colSpan="5"></th>
                            <th colSpan={demographics.length} style={{
                                textAlign: "left",
                                borderRight: "2px solid #DDD",
                                borderLeft: "2px solid #DDD"
                            }}>Demographics
                            </th>
                            <th colSpan="2"></th>
                        </tr>
                        <tr>
                            <Th name={"nameAndId"} sorting={sorting} handleClick={toggleSorting}
                                style={{minWidth: "250px"}}>Name / ID</Th>
                            <Th name={"status"} sorting={sorting} handleClick={toggleSorting}
                                style={{minWidth: "120px"}}>Status</Th>
                            <Th name={"seatNumber"} sorting={sorting} handleClick={toggleSorting}
                                style={{minWidth: "75px"}}>Seat #</Th>
                            <Th name={"discardedReason"} sorting={sorting} handleClick={toggleSorting}
                                style={{minWidth: "200px"}}>Dismissed</Th>
                            <Th name={"emojiIndex"} sorting={sorting} handleClick={toggleSorting}
                                style={{borderRight: "2px solid #DDD"}}
                                className="emoji-column">Emoji</Th>
                            {demographics.map(demo => {
                                return (<Th key={`demographic-${demo}`} name={"_d_" + demo} sorting={sorting}
                                            style={{minWidth: "100px"}}
                                            handleClick={toggleSorting}>{demo}</Th>)
                            })}
                            <Th name="created" sorting={sorting} handleClick={toggleSorting}
                                style={{borderLeft: "2px solid #DDD", minWidth: "175px"}}>created</Th>
                            <Th name="updated" sorting={sorting} handleClick={toggleSorting}
                                style={{minWidth: "175px"}}>updated</Th>
                        </tr>
                        </thead>
                        <tbody>
                        {pageOfJurors.length === 0 &&
                            <tr>
                                <td colSpan={7 + demographics.length}>No {search && "matching"} data ...</td>
                            </tr>
                        }
                        {pageOfJurors.map((juror, index) => {
                            let selectIcon = null;
                            if (multiSelect.mode === MultiSelectModes.ON) {
                                selectIcon = (multiSelect.selectedJurorIds?.indexOf(juror._id) >= 0) ?
                                    <tableIcons.Selected/> : <tableIcons.NotSelected/>
                            }

                            let comments = null;
                            if (search && juror.comments?.indexOf(search) >= 0) {
                                comments = <Highlighted content={juror.comments} search={search} hideWhenNoMatchFound />;
                            }

                            return (
                                <React.Fragment key={`${juror._id}-fragment`}>
                                    <tr key={juror._id} className={index % 2 === 0 ? "even" : "odd"}>
                                        <td className="juror-edit" onClick={() => {
                                            if (multiSelect.mode === MultiSelectModes.ON) {
                                                if (multiSelect.selectedJurorIds?.indexOf(juror._id) >= 0) {
                                                    dispatch(MultiSelectActionCreators.removeJurorId(juror._id));
                                                } else {
                                                    dispatch(MultiSelectActionCreators.addJurorId(juror._id));
                                                }
                                            } else {
                                                handleJurorEdit(juror._raw._id, juror._raw.seatNumber ?? null, null, null);
                                            }
                                        }}>
                                            <div className="juror-edit-content">
                                                {selectIcon}
                                                <Highlighted content={juror.nameAndId} search={search} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='status-col'>
                                                <StatusMenu
                                                    updateStatus={(status) => {
                                                        const newJurorInfo = {...juror._raw, status: status};
                                                        dispatch(updateJuror(caseId, juror._id, newJurorInfo));
                                                    }}
                                                    status={juror._raw.status ? juror._raw.status : null}
                                                />
                                                <Highlighted content={juror.status} search={search} />
                                            </div>
                                        </td>
                                        <td>{juror.seatNumber}</td>
                                        <td>
                                            <div className="dismiss-col">
                                                <DismissMenu current={juror.discardedReason} onSelected={(reason) => {
                                                    let jurorData = {...juror._raw};
                                                    if (reason === "") {
                                                        jurorData.discarder = null;
                                                        jurorData.discardedReason = null;
                                                        jurorData.discarded = null;
                                                        jurorData.alternateDiscarded = null;
                                                    } else {
                                                        let discardAttributeName = mode === "jury-box" ? "discarded" : "alternateDiscarded";
                                                        jurorData[discardAttributeName] = true;
                                                        jurorData.discardedReason = reason;
                                                        if (reason === 'prosecutor' || reason === 'defense' || reason === "both") {
                                                            jurorData.discarder = reason;
                                                            jurorData.discardedReason = 'peremptory';
                                                        }
                                                    }
                                                    jurorData.updated = (new Date()).toISOString();
                                                    dispatch(updateJuror(caseId, jurorData._id, jurorData));
                                                }}/>
                                                {!!juror.discardedReason &&
                                                    <span className="discarded-reason"><Highlighted content={juror.discardedReason} search={search}/> </span>
                                                }
                                            </div>
                                        </td>
                                        <td className="emoji-column">
                                            <EmojiIndex text={juror.comments || ""}
                                                        onClick={handleEmojiClick(juror._id, juror.seatNumber ?? null)}/>
                                        </td>
                                        {demographics.map(demo => {
                                            return (
                                                <td key={`demographic-${demo}`}><Highlighted content={juror["_d_" + demo]} search={search} /></td>
                                            )
                                        })}
                                        <td>{DateTime.fromISO(juror.created).toFormat("f")}</td>
                                        <td>{DateTime.fromISO(juror.updated).toFormat("f")}</td>
                                    </tr>
                                    {!!comments &&
                                    <tr key={`${juror._id}-comments`} className={"matching-comments" + (index % 2 === 0 ? " even" : " odd")}>
                                        <td colSpan={7 + demographics.length}>
                                            {comments}
                                        </td>
                                    </tr>
                                    }
                                </React.Fragment>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="table-pagination">
                    <div className="page-size">
                        <label>Items per page</label>
                        <Select value={pageSize} onChange={handlePageSizeChange}>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </div>
                    <div className="page-selector">
                        <div>
                            <Tooltip title={"Previous"}>
                            <span>
                                <IconButton size="small" disabled={page === 1} onClick={() => {
                                    setPage(prev => prev - 1);
                                }}>
                                    <ArrowLeft/>
                                </IconButton>
                            </span>
                            </Tooltip>
                            {Math.min(((page - 1) * pageSize) + 1, filteredJurors.length)} - {Math.min(((page) * pageSize), filteredJurors.length)} of {filteredJurors.length} {filteredJurors.length !== jurors.length &&
                            <span>(filtered)</span>}
                            <Tooltip title={"Next"}>
                            <span>
                                <IconButton size="small" disabled={page === numPages} onClick={() => {
                                    setPage(prev => prev + 1);
                                }}>
                                    <ArrowRight/>
                                </IconButton>
                            </span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </BasePage>
    )
}

function Th({className, style, name, sorting, handleClick, children}) {

    function onClick(e) {
        handleClick(name);
    }

    let icon = null;
    let sorted = sorting.find(s => s.name === name);
    if (sorted) {
        if (sorted.direction === "asc") {
            icon = <ArrowDropUp/>
        } else {
            icon = <ArrowDropDown/>
        }
    }

    return (
        <th onClick={onClick} style={{cursor: "pointer", ...style}} className={className}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                {children}
                {icon}
            </div>
        </th>
    )
}