import React, {useEffect, useState, useRef} from 'react';
import {useDispatch} from 'react-redux';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {CircularProgress} from "@material-ui/core";
import {
    Close,
    ChevronRight
} from '@material-ui/icons';

import {formStyles} from '../../util/styles';
import {
    sendPendingData,
    updateCase
} from '../../state/asyncActions.js';

import Papa from "papaparse";
import {v4 as uuidv4} from 'uuid';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {ActionCreators} from "../../state/actions";

const UploadCsvModal = ({open, onClose, caseData}) => {
    const classes = formStyles();
    const fileRef = useRef(null);
    const dispatch = useDispatch();

    const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
    const columns = [
        {
            field: 'nameAndId',
            headerName: 'Name or ID'
        },
        {
            field: 'comments',
            headerName: 'Notes'
        },
        {
            field: 'seatNumber',
            headerName: 'Seat Number'
        },
        {
            field: 'alternateSeatNumber',
            headerName: 'Alternate Seat Number'
        }
    ];

    const [caseInfo, setCaseInfo] = useState(caseData);
    const [pageCaseCsvUploadModal, setPageCaseCsvUploadModal] = useState(1);
    const [columnsMaxLength, setColumnsMaxLength] = useState(0);

    const [withHeaders, setWithHeaders] = useState(false);
    const [loading, setLoading] = useState(false);

    const [parsed, setParsed] = useState(false);
    const [parsedWithHeaders, setParsedWithHeaders] = useState({
        meta: {
            fields: []
        }
    });

    const [sampleData, setSampleData] = useState([]);
    const [sampleDataWithHeaders, setSampleDataWithHeaders] = useState([]);

    const [formData, setFormData] = useState([]);
    const [formDataWithHeaders, setFormDataWithHeaders] = useState({});

    // this is where the bug is for "no headers" parse
    const demographicCategoriesWithHeaders = {};
    parsedWithHeaders.meta.fields.map((meta, i) => {
        demographicCategoriesWithHeaders[i] = meta;
    });

    const demographicCategories = {};
    for (let i = 0; i < columnsMaxLength; i++) {
        demographicCategories[i] = alphabet[i];
    }

    useEffect(() => {
        if (caseData) {
            setCaseInfo(caseData);
        }
    }, [caseData]);

    const handleClose = () => {
        resetModalValues();
        onClose();
    };

    const resetModalValues = () => {
        setPageCaseCsvUploadModal(1);
        setColumnsMaxLength(0);

        setWithHeaders(false);
        setLoading(false);

        setParsed(false);
        setParsedWithHeaders({
            meta: {
                fields: []
            }
        });

        setSampleData([]);
        setSampleDataWithHeaders([]);

        setFormData({});
        setFormDataWithHeaders({});
    };

    const handleFileUploadSubmit = (values) => {
        setLoading(true);

        setTimeout(() => {
            const reader = new FileReader();

            reader.onloadend = () => {

                // parse the CSV with and without headers
                let result1 = Papa.parse(reader.result);
                let result2 = Papa.parse(reader.result, {
                    header: true
                });

                setParsed(result1);
                setParsedWithHeaders(result2);

                //console.log('CSV Parse Result 1: ', result1);
                //console.log('CSV Parse Result 2: ', result2);

                Array.prototype.spliceNoMutation = function (start, howMany) {
                    return this.filter((item, index) => {
                        return index >= start && index < howMany + start;
                    })
                };

                // set a sampling of the csv
                let sample = result1.data.spliceNoMutation(0, 3);
                let sampleWithHeaders = result2.data.spliceNoMutation(0, 3);
                setSampleData(sample);
                setSampleDataWithHeaders(sampleWithHeaders);

                // get the number of columns that have data in them
                let maxLength = 0;
                result1.data.forEach(arr => {
                    if (arr.length > maxLength) {
                        maxLength = arr.length;
                    }
                });

                // validate --> only alphabet's worth of csv
                if (maxLength > 26) {
                    alert('The maximum number of columns is 26');
                } else {
                    setColumnsMaxLength(maxLength);
                    setPageCaseCsvUploadModal(2);
                }

                setLoading(false);
            };

            reader.readAsText(values.file);
        }, 200);
    };

    const handleHeadersSubmit = e => {
        setLoading(true);

        setTimeout(async () => {

            let map = {};
            let rows = [];
            const caseInfoDispatch = JSON.parse(JSON.stringify(caseInfo));
            Object.preventExtensions(caseInfoDispatch);

            const processOne = row => {
                for (let field in row) {

                    // if it's not a predefined column, it's a demographic
                    let isColumn = columns.find(c => c.field === field);
                    if (!isColumn) {

                        // new demographics
                        if (field.includes('newDemographic')) {
                            let id = parseInt(field.replace('newDemographic', '')),
                                demographicCategory = demographicCategories[id];

                            if (!caseInfoDispatch.demographicCategories.includes(demographicCategory)) {
                                caseInfoDispatch.demographicCategories.push(demographicCategory);
                            }

                            row.demographics.push({
                                name: demographicCategory,
                                value: row[field]
                            });

                            delete row[field];
                        }

                        // ignore the demographics field itself
                        else if (field === 'demographics') {
                        }

                        // already existing demographics
                        else {
                            row.demographics.push({
                                name: field,
                                value: row[field]
                            });

                            delete row[field];
                        }
                    }
                }

                return row;
            };
            const processOneWithHeaders = row => {
                for (let field in row) {

                    // if it's not a predefined column, it's a demographic
                    let isColumn = columns.find(c => c.field === field);
                    if (!isColumn) {

                        // new demographics
                        if (field.includes('newDemographic')) {
                            let id = parseInt(field.replace('newDemographic', '')),
                                demographicCategory = demographicCategoriesWithHeaders[id];

                            if (!caseInfoDispatch.demographicCategories.includes(demographicCategory)) {
                                caseInfoDispatch.demographicCategories.push(demographicCategory);
                            }

                            row.demographics.push({
                                name: demographicCategory,
                                value: row[field]
                            });

                            delete row[field];
                        }

                        // ignore the demographics field itself
                        else if (field === 'demographics') {
                        }

                        // already existing demographics
                        else {
                            row.demographics.push({
                                name: field,
                                value: row[field]
                            });

                            delete row[field];
                        }
                    }
                }

                for (let r in row) {
                    let value = row[r];

                    if (Array.isArray(value) && r !== 'demographics') {
                        row[r] = value.join(' ');
                    }
                }

                return row;
            };

            if (withHeaders) {

                // create field key map
                parsedWithHeaders.meta.fields.map((header, i) => {
                    if (formDataWithHeaders[i] !== undefined && formDataWithHeaders[i] !== " ") {
                        map[header] = formDataWithHeaders[i];
                    }
                })

                // loop through each CSV row
                parsedWithHeaders.data.map(async parsedRowWithHeaders => {
                    let row = {demographics: []};

                    // set all the values on 'row'
                    let hasValues = false;
                    for (let m in map) {
                        let id = map[m],
                            value = parsedRowWithHeaders[m];

                        if (value) {
                            if (row.hasOwnProperty(id)) {
                                row[id] += " " + value;
                            } else {
                                row[id] = value;
                            }

                            hasValues = true;
                        }
                    }

                    if (hasValues) {
                        row = processOneWithHeaders(row);
                        rows.push(row);
                    }
                });
            } else {

                // loop through each CSV row
                parsed.data.map(async parsedRow => {
                    let row = {demographics: []};

                    // clean it off
                    parsedRow = JSON.parse(JSON.stringify(parsedRow));

                    // set all the values on 'row'
                    let hasValues = false;
                    for (let r in parsedRow) {
                        if (r !== "spliceNoMutation") {
                            let id = formData[r],
                                value = parsedRow[r];

                            if (value && id !== " ") {
                                if (row.hasOwnProperty(id)) {
                                    row[id] += " " + value;
                                } else {
                                    row[id] = value;
                                }
                                hasValues = true;
                            }
                        }
                    }

                    if (hasValues) {
                        row = processOne(row);
                        rows.push(row);
                    }
                });
            }

            for (let row of rows) {
                if (row.seatNumber !== undefined && row.seatNumber !== "") {
                    row.seatNumber = parseInt(row.seatNumber);
                }
                if (row.alternateSeatNumber !== undefined && row.alternateSeatNumber !== "") {
                    row.alternateSeatNumber = parseInt(row.alternateSeatNumber);
                }
            }
            //console.log('rows: ', rows);

            // update the case with all the new rows' new demographics
            await dispatch(updateCase(caseInfoDispatch._id, caseInfoDispatch));

            let payloads = rows.map(r => ({
                tmpId: uuidv4(),
                jurorBody: r
            }));
            dispatch(ActionCreators.createJurors(caseInfo._id, payloads));

            dispatch(sendPendingData(false));

            // close the modal
            handleClose();

        }, 200);
    };

    const setSelectValue = (e, withHeaders, i) => {
        if (withHeaders) {
            let obj = {...formDataWithHeaders};
            obj[i] = e.target.value;

            setFormDataWithHeaders(obj);
        } else {
            let obj = {...formData};
            obj[i] = e.target.value;

            setFormData(obj);
        }
    };

    const mappingRows = [];
    for (let i = 0; i < columnsMaxLength; i++) {
        let oneSelectValue = (withHeaders ? formDataWithHeaders[i] : formData[i]) ?? " ";
        mappingRows.push((
            <TableRow key={i}>
                <TableCell><b>{alphabet[i]}</b></TableCell>
                <TableCell align={'right'} style={{width: '150px'}}>
                    <ChevronRight style={{marginTop: '6px'}}/>
                </TableCell>
                <TableCell align={'right'}>
                    <Select value={oneSelectValue} onChange={e => setSelectValue(e, false, i)}>
                        <MenuItem key={"leave-blank"} value={" "}>Do not use</MenuItem>
                        {columns.map((column, i) => (
                            <MenuItem key={i} value={column.field}>{column.headerName}</MenuItem>
                        ))}
                        <MenuItem key={'newDemographic'} value={'newDemographic' + i.toString()}>New
                            Demographic</MenuItem>
                        {caseInfo.demographicCategories.map((demographicCategory, i) => (
                            <MenuItem key={demographicCategory}
                                      value={demographicCategory}>Demographic: {demographicCategory}</MenuItem>
                        ))}
                    </Select>
                </TableCell>
            </TableRow>
        ));
    }

    const mappingWithHeadersRows = parsedWithHeaders.meta.fields.map((meta, i) => {
        let oneSelectedValue = (withHeaders ? formDataWithHeaders[i] : formData[i]) ?? " ";
        return (
            <TableRow key={i}>
                <TableCell><b>{meta}</b></TableCell>
                <TableCell align={'left'} style={{width: '150px'}}>
                    <ChevronRight style={{marginTop: '6px'}}/>
                </TableCell>
                <TableCell align={'right'}>
                    <Select value={oneSelectedValue} onChange={e => setSelectValue(e, true, i)}>
                        <MenuItem key={"leave-blank"} value={" "}>Do not use</MenuItem>
                        {columns.map((column, index) => (
                            <MenuItem key={index} value={column.field}>{column.headerName}</MenuItem>
                        ))}
                        <MenuItem key={'newDemographic'} value={'newDemographic' + i.toString()}>New Demographic</MenuItem>
                        {caseInfo.demographicCategories.map((demographicCategory, i) => (
                            <MenuItem key={demographicCategory}
                                      value={demographicCategory}>Demographic: {demographicCategory}</MenuItem>
                        ))}
                    </Select>
                </TableCell>
            </TableRow>
        )
    });

    const sampleDataRows = sampleData.map((row, i) => (
        <TableRow key={i}>
            {Object.keys(row).map((key, index) => (
                <TableCell key={index} style={{whiteSpace: 'nowrap'}}>
                    {row[key]}
                </TableCell>
            ))}
        </TableRow>
    ));

    const sampleDataWithHeadersRows = sampleDataWithHeaders.map((row, i) => (
        <TableRow key={i}>
            {Object.keys(row).map((key, index) => (
                <TableCell key={index} style={{whiteSpace: 'nowrap'}}>
                    {row[key]}
                </TableCell>
            ))}
        </TableRow>
    ));

    const sampleDataHeaders = [];
    for (let i = 0; i < columnsMaxLength; i++) {
        sampleDataHeaders.push((
            <TableCell key={i}>
                <b>{alphabet[i]}</b>
            </TableCell>
        ))
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className={classes.form}
        >
            <MuiDialogTitle disableTypography className={classes.title}>
                <Typography variant="h6">Upload CSV</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <Close/>
                </IconButton>
            </MuiDialogTitle>

            {pageCaseCsvUploadModal === 1 &&
                <React.Fragment>
                    <p style={{padding: "10px 25px", fontSize: "12px"}}>Provide a CSV file with your juror data to
                        quickly populate the case. </p>


                    <DialogContent>
                        <div style={{paddingBottom: "15px"}}>
                        {loading &&
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                <CircularProgress/>
                            </div>
                        }
                        {!loading &&
                            <input
                                type="file"
                                name="file"
                                ref={fileRef}
                                accept={'.csv'}
                                onChange={(event) => {
                                    if (event.currentTarget.files.length > 0) {
                                        handleFileUploadSubmit({file: event.currentTarget.files[0]});
                                    }
                                }}
                            />
                        }
                        </div>
                    </DialogContent>
                </React.Fragment>
            }

            {pageCaseCsvUploadModal === 2 &&
                <React.Fragment>
                    <DialogContent>

                        {loading &&
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "15px", minWidth: "300px"}}>
                                <p>Processing file...</p>
                                <CircularProgress />
                            </div>
                        }
                        {!loading &&
                            <React.Fragment>

                                <FormControlLabel control={<Checkbox checked={withHeaders}
                                                                     onChange={event => setWithHeaders(!withHeaders)}/>}
                                                  label="Use First Row As Headers"/>

                                <br/>
                                <br/>

                                {withHeaders &&

                                    <React.Fragment>
                                        <Paper>
                                            <TableContainer sx={{width: '100%'}}>
                                                <Table size={'small'}>
                                                    <TableHead>
                                                        <TableRow className={'text-success'}>
                                                            {parsedWithHeaders.meta.fields.map((meta, i) => (
                                                                <TableCell key={i} component={'th'}
                                                                           style={{whiteSpace: 'nowrap'}}>
                                                                    <b>{meta}</b>
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {sampleDataWithHeadersRows}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>

                                        <br/>

                                        <TableContainer component={'div'}>
                                            <Table>
                                                <TableBody>

                                                    {mappingWithHeadersRows}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </React.Fragment>

                                    ||

                                    <React.Fragment>
                                        <Paper>
                                            <TableContainer sx={{maxHeight: 200}}>
                                                <Table size={'small'}>
                                                    <TableHead>
                                                        <TableRow>
                                                            {sampleDataHeaders}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {sampleDataRows}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>

                                        <br/>

                                        <TableContainer component={'div'}>
                                            <Table>
                                                <TableBody>

                                                    {mappingRows}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleHeadersSubmit} disabled={loading}>
                            Submit
                        </Button>
                    </DialogActions>
                </React.Fragment>
            }

        </Dialog>
    );
};

export default UploadCsvModal;
