import React, { useState } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { formStyles } from '../../../util/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { FormLabel } from '@material-ui/core';
import CreatableGroupSelect from '../CreatableGroupSelect';
import axios from 'axios';

const SelectGroupModal = ({ open, onClose, userData }) => {
  if (!userData) {
    return null;
  }

  const classes = formStyles();
  const [group, setGroup] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    let errorList = [];

    if (!group) {
      errorList.push('User must be part of a group');
    }

    if (errorList.length > 0) {
      setErrors(errorList);
    } else {
      try {
        const deleteUserBody = {
          email: userData.email,
          groupId: group.groupID,
        };
        setIsLoading(true);
        const res = await axios.delete('/api/user', {
          data: deleteUserBody,
        });
        setIsLoading(false);
        onClose(true);
      } catch (error) {
        setIsLoading(false);
        setErrors([error]);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.form}
      fullWidth
      maxWidth="sm"
    >
      <MuiDialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">Delete User</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          disabled={isLoading}
        >
          <Close />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <Collapse in={errors && errors.length > 0}>
          <Alert severity="error" className={classes.alert}>
            Edit Failed:
            <ul>
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Collapse>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              The user to be deleted is the last in their group.
              Please choose a group to move the associated cases to.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel component={'legend'}>Group</FormLabel>
            <CreatableGroupSelect
              create={false}
              value={{
                label: group.groupName,
                value: group.groupID,
              }}
              onChange={(group) => {
                setGroup({
                  groupName: group.label,
                  groupID: group.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleDelete}
          color="primary"
          variant="contained"
          disabled={isLoading || !Object.keys(group).length || group.groupID === userData.groupID}
        >
          {isLoading ? 'Deleting...' : 'Delete & Move Cases'}
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectGroupModal;
