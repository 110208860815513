let basicEmojiRegex = /[\p{Emoji}\u200d+]/gu;

export const indexEmoji = (text) => {
    let result = {
        original: text,
        emojis: []
    };

    let occurrences = {};

    function addOccurrence(emoji) {
        if (occurrences[emoji] === undefined) {
            occurrences[emoji] = 0;
        }
        else {
            occurrences[emoji]++;
        }
    }


    let lines = text.split("\n");
    let lineIndex = 0;
    for (let line of lines) {
        let matches = basicEmojiRegex.test(line);
        if (matches) {
            let matchResult = line.match(basicEmojiRegex);

            // Preprocess emoji
            let processed = [];
            for (let i = 0; i < matchResult.length; i++) {
                let builtEmoji = matchResult[i];
                if (!/^\d|\+|#|\*$/.test(matchResult[i])) {
                    if (i < matchResult.length - 2) {
                        while (/^\u200d$/u.test(matchResult[i+1])) {
                            /*console.log("Considering: ", {
                                space: matchResult[i+1],
                                next: matchResult[i+2]
                            });*/
                            builtEmoji = builtEmoji + matchResult[i+1] + matchResult[i + 2];
                            i += 2;
                        }
                    }
                    processed.push(builtEmoji);
                }
            }

            for (let emoji of processed) {
                addOccurrence(emoji);
                result.emojis.push({
                    emoji: emoji,
                    occurrence: occurrences[emoji]
                });
            }
        }
        lineIndex++;
    }

    return result;
};