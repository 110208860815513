import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  subscriptionInfo: {
    padding: '12px',
    marginLeft: 'auto',
  },
  title: {
    padding: '8px',
    paddingLeft: '12px',
    fontSize: 20,
  },
  infoTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
  subscriptionTable: {
    borderCollapse: 'collapse',
    '& td, th': {
      border: '1px solid lightgray',
      padding: '5px 10px',
    },
  },
}));

const SubscriptionInfo = ({ subscriptionData, onEditOpen, children }) => {
  if (!subscriptionData) {
    return null;
  }

  const classes = useStyles();

  return (
    <Paper className={classes.subscriptionInfo} elevation={2} key="subscription-info">
      <div className={classes.infoTitle}>
        <Typography
          align="left"
          variant="h5"
          className={classes.title}
        >
          Subscription Info
        </Typography>
        <Tooltip title="Change subscription">
          <IconButton
            onClick={onEditOpen}
            className={classes.editButton}
            size="small"
          >
            <Edit />
          </IconButton>
        </Tooltip>
      </div>
      <table className={classes.subscriptionTable}>
        <thead>
          <tr>
            <th />
            <th>Current Cycle</th>
            {subscriptionData.hasNext && <th>Next Cycle</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Paid Accounts</b></td>
            <td>{subscriptionData.currUserQuantity}</td>
            {subscriptionData.hasNext && <td>{subscriptionData.nextUserQuantity}</td>}
          </tr>
          <tr>
            <td><b>Available Accounts</b></td>
            <td>{subscriptionData.currQuantityAvailable}</td>
            {subscriptionData.hasNext && <td>{subscriptionData.nextQuantityAvailable}</td>}
          </tr>
          <tr>
            <td><b>Cycle End Date</b></td>
            <td>{subscriptionData.currEnd}</td>
            {subscriptionData.hasNext && <td>{subscriptionData.nextEnd}</td>}
          </tr>
        </tbody>
      </table>
      {children}
    </Paper>
  );
};

export default SubscriptionInfo;
